import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Devon from './Devon1.jpg'
import Ferrari from './ferrarilarge.jpg'
import IdleTimer from 'react-idle-timer'




export class LoginPage extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0,
                  username:''
    };
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
  }

  _onIdle=e=> {
    this.props.setStep(1001)



  }
  usernameSet = e => {
    e.preventDefault();
    this.setState({username:e.target.value})
    console.log(this.state.username);
  }

  continue = e => {
    const email=this.state.username;
    this.props.getLoggedInUserInfo(1003,email);
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  keyPress=(e)=>{
        if(e.keyCode == 13){
           this.continue();
           // put the login here
        }
     }



  render() {
    return(

      <MuiThemeProvider>
        <React.Fragment>

        <div style={{height:'100vh',flex:'auto',backgroundImage: `url(${Ferrari})`}}>
        <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={1000 * 60 * 1} />
      <Grid container style={{paddingTop:"15%"}} >
          <Grid item xs={12}>


          <Paper style={{opacity:0.89,padding:"2%"}}>


          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs style={{textAlign:'center', paddingLeft:"20%",paddingRight:"20%"}}>

              <h2>
                Enter your Username below
              </h2>
              <Typography alignJustify variant="body2" color="textSecondary">
                It is an offence to use someone else's credentials for access. If your account security is at risk, please contact Support as soon as Possible!
              </Typography>



                <TextField
            autoFocus
            margin="dense"
            id="insPass"
            hintText="Enter your username"
            onKeyDown={this.keyPress}
            onChange={this.usernameSet}
            value={this.state.username}
            type="email"
            fullWidth
          />
          <Grid item>
          <RaisedButton label="Continue" primary={true} onClick={e=>{e.preventDefault();this.continue()}} fullWidth='true' style={{marginTop:20}}/>
          <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back  </Button>

          </Grid>
              </Grid>

            </Grid>
          </Grid>
            </Paper>
          </Grid>





        </Grid>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default LoginPage
