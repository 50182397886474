import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Toggle from 'material-ui/Toggle';
import DatePicker from 'material-ui/DatePicker';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ActionFavorite from 'material-ui/svg-icons/action/favorite';
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border';
import ReactExport from 'react-data-export';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;







export class DisplayPreFlightAuths extends Component{



  constructor(props) {
    super(props);
    this.state={value:0,loading:true,
    }


  }

/*method to select what conditions are displayed depending on formID selection from the previous page*/





  back = e => {
      e.preventDefault();
      this.props.setStep(7005)


  }


export=e=>{


}






  render() {
    const { values, handleChange, defaultButton, formRating,createRadioGroups} = this.props;
    const ratingState= values.formRating;
    const flightData=[
        {
            columns: [{title: "AC Rego", width: {wpx: 80}},//pixels width
                                           {title: "Student/Hirer", width: {wpx: 100}},//pixels width
                                           {title: "Instructor/Pilot", width: {wpx: 100}},//pixels width
                                           {title: "Route/Flight Details", width: {wpx: 190}},//pixels width
                                           {title: "Task P,A,C,X", width: {wpx: 100}},//pixels width
                                           {title: "Start Fuel (L)", width: {wpx: 100}},//pixels width
                                           {title: "Total DUAL", width: {wpx: 100}},//pixels width
                                           {title: "Total PIC", width: {wpx: 100}},//pixels width
                                           {title: "Student/Hirer Signature", width: {wpx: 190}},//pixels width
                                           {title: "Instructor/Authorization Signature", width: {wpx: 190}}]

            ,
            data: [
                [
                  {value: ""},
                  {value: ""},
                  {value: ""},//01
                  {value: ""},//02
                  {value: ""},//03
                  {value: ""},//04
                  {value: ""},//05
                  {value: ""},//06
                  {value: ""},//07
                  {value: ""},//08
                  //02
                ]
            ]
        }
    ];

    values.flightRecords.map(
      item=>{
        const record=[{value: item.rego},{value: item.name},{value: item.authByname},{value: item.description},{value: item.fType},{value: item.fuelAtStart},{value: item.dualHrs},{value: item.picHrs},{value:"Digitally Authorized"},{value:"Digitally Authorized"}]
        flightData[0].data.push(record)
      }
    )


    return(



      <MuiThemeProvider>
        <React.Fragment>



          <div>


            <Grid item xs={12} style={{marginLeft:'5%'}}><h1>Flight Authorizations</h1></Grid>
            <Paper style={{marginLeft:'2%',marginRight:'2%',paddingLeft:'2%',paddingRight:'2%'}}>
            <Grid container  spacing={1}>


            <Grid item xs={1}> <Typography variant="subtitle2">Pre-Flight ID</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">Date</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">Hirer</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">Instructor</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">Fuel</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">Dual Hrs </Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">PIC Hrs</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2">Rego</Typography></Grid>
            <Grid item xs={2}> <Typography variant="subtitle2">Comments</Typography></Grid>
            <Grid item xs={1}> <Typography variant="subtitle2"></Typography></Grid>


            </Grid>

          <GridList style={{height:400}}>

              <Grid item xs={12}>{createRadioGroups()}</Grid>
              </GridList>





              </Paper>
                <Grid item xs={12} style={{ margin: "2%", display:"none"}}>
                <ExcelFile  element={<RaisedButton fullWidth>Export</RaisedButton>}>
                    <ExcelSheet dataSet={flightData} name="Organization"/>
                </ExcelFile>
                </Grid>

                <Grid item xs={12} style={{ margin: "2%"}}>

                <RaisedButton fullWidth style={{ marginTop: "2%"}} label="Back" secondary={true} onClick={this.back}/>

                </Grid>


          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default DisplayPreFlightAuths
