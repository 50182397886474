import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { validations, validateAll } from 'indicative/validator';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as moment from 'moment';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardInput from 'react-credit-card-input';




const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const handleDateChange = e => {
   console.log(e);

 };

export class CreditCardInfo extends Component{

  constructor(props) {
    super(props);
    this.state = {value         : 0,
                  disclaimer    :true,
                  cvc           : '',
                  expiry        : '',
                  focus         : '',
                  name          : '',
                  number        : '',
                  creditCardCVCerr        : '',
                  creditCardExpiryerr     : '',
                  focuserr      : '',
                  creditCardNameerr       : '',
                  creditCardNumbererr     : '',
                    };

  }


  continue = e =>{
    if (this.state.creditCardCVCerr=="" && this.state.creditCardExpiryerr=="" && this.state.creditCardNameerr=="" && this.state.creditCardNumbererr=="") {
      this.props.cCEncrpt();
      this.props.setStep(2002);
    } else {
        console.log('err');
    }

  }

  back=e=>{
    e.preventDefault();
    this.props.setStep(2001);
  }


  handleInputFocus = (field)=>e => {
    e.preventDefault();
     this.setState({ focus: field });
   }

   handleInputChange = (e) => {
     const { name, value } = e.target;
     this.props.handleChangeInput(name,value)
     this.setState({creditCardNameerr:''})
     this.setState({ [name]: value });
   }



handleCardNumberChange= (e) => {
  const { value } = e.target;
  this.props.handleChangeInput('creditCardNumber',value)
  this.setState({creditCardNumbererr:''})
  this.setState({ number: value });
}



handleCardExpiryChange= (e) => {
  const { value } = e.target;
  this.props.handleChangeInput('creditCardExpiry',value)
  this.setState({creditCardExpiryerr:''})
  this.setState({ expiry: value });
  }




handleCardCVCChange= (e) => {
  const { value } = e.target;
  this.props.handleChangeInput('creditCardCVC',value)

  this.setState({creditCardCVCerr:''})
  this.setState({ cvc: value });
}


handleErrNumber = (e) => {
   this.setState({creditCardNumbererr:e})
 };
 handleErrExpiry = (e) => {
    this.setState({creditCardExpiryerr:e})
  };
  handleErrCvc = (e) => {
     this.setState({creditCardCVCerr:e})
   };

   validate=e=>{
     const {values} = this.props;
     const data = values;
     const rules= {
                   creditCardName      : 'string|required|max:25|min:1',
                   creditCardNumber    :  'required|max:25',
                   creditCardExpiry    :  'required',
                   creditCardCVC       :  'required'


   }
     const messages={
       alpha                 : 'Alphabetical characters only!',
       date                 : 'Invalid Date',
       number                : 'Numbers Only',
       float                 : "That's invalid",
       required              : 'This Cannot be empty',
       min                   : "That's too short!",
       max                   : "That's too long!",
             }

     validateAll(data, rules, messages)
     .catch(errors =>{
       console.log(errors);

       const formattedErrors = {}
       errors.forEach(error =>this.setState({[error.field+"err"]:error.message})
       )



     })
     .then(this.continue)
   }



  render() {
    const { values, log, handlePicChange} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >


          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Enter Credit Card Details</h3>
              </Grid>
              <Grid item xs={6}>

                  <Cards
                    cvc={values.creditCardCVC}
                    expiry={values.creditCardExpiry}
                    focused={this.state.focus}
                    name={values.creditCardName}
                    number={values.creditCardNumber}
                      />
                      </Grid>
                      <Grid item xs={6}>
                      <TextField
                              required
                              name="creditCardName"
                              label="Name as Shown on Card"
                              margin="normal"
                              inputProps={{maxLength: 25}}
                              variant="outlined" style={{width:'100%'}}
                              onChange={this.handleInputChange}
                              onFocus={this.handleInputFocus("name")}
                              value={values.creditCardName}
                              error={this.state.creditCardNameerr!=""}
                              helperText={this.state.creditCardNameerr != "" ? this.state.creditCardNameerr : ''}
                              />

              <CreditCardInput
                        fullWidth
                        cardNumberInputProps={{ value: values.creditCardNumber, onChange: this.handleCardNumberChange, onFocus:this.handleInputFocus("number"), onError: err => this.handleErrNumber(err)}}
                        cardExpiryInputProps={{ value: values.creditCardExpiry, onChange: this.handleCardExpiryChange,onFocus:this.handleInputFocus("expiry"), onError: err => this.handleErrExpiry(err) }}
                        cardCVCInputProps={{ value: values.creditCardCVC, onChange: this.handleCardCVCChange,onFocus:this.handleInputFocus("cvc"), onError: err => this.handleErrCvc(err) }}
                        />
                  </Grid>





                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.validate}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>

                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      open={this.state.disclaimer}>
                      <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                      You must provide details of a valid Credit/Debit Card
                      </DialogTitle>
                      <DialogContent dividers>
                      <Typography gutterBottom>
                      Immediate payment is required for all flights prior to departure for any student or hirer with insufficient credit without valid credit card details
                      <br></br><br></br>
                      Flights may be granted to a student or hirer with insufficient credit at provider's discretion if credit card details are provided.
                      <br></br><br></br>
                      By clicking "I Agree" below, I authorize HTT to charge the amounts owing to my nominated credit/debit card, if I (student/hirer/Account holder) fail to reconsile an overdue account within 90 days.
                      </Typography>
                      </DialogContent>
                      <DialogActions>
                      <Button onClick={e=>this.setState({disclaimer:false})} color="secondary">
                      I AGREE
                      </Button>
                      <Button onClick={this.back} color="primary">
                      I Disagree
                      </Button>
                      </DialogActions>
                      </Dialog>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default CreditCardInfo
