import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AvatarPic from './avatar.png'
import Webcam from 'react-webcam';
import ModelPhoto from './modelPhoto.jpg'


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const videoConstraints = {
  width: 151.181102362,
  height: 188.976377953,
  facingMode: "user"
};





export class PassportPicConfirm extends Component{

  setRef = webcam => {
    this.webcam = webcam;
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.props.setPassportPhoto(imageSrc);
  };

  constructor(props) {
    super(props);
    this.state = {value:0};
  }




  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  handleChange = (event, index, value) => this.setState({value});



  showWidget = (widget) =>{
    widget.open()
  }

  checkUploadResult = (resultEvent,widget) =>{
    if (resultEvent.event === 'success'){
      widget.close();
      this.props.handleChangeInput('ins_PassPhoto',resultEvent.info.secure_url)
      this.props.setStep(4006)
    }}


  render() {
    const {values, setPassportPhoto,passportPhoto} = this.props;
    let widget = window.cloudinary.createUploadWidget({
      sources:['local','camera'],
      cloudName:"donas",
      uploadPreset:"eg8ivjdr",
      cropping: true

    },(error,result)=>{this.checkUploadResult(result,widget) })

    return(

      <MuiThemeProvider>
        <React.Fragment>


          <div>
          <Grid container spacing={2} style={{padding:'10%',paddingTop:'2%'}}>
          <Grid item xs={12}>

            <Paper style={{opacity:0.89}}>


              <Grid style={{padding:'2%'}}>

              <Grid item xs={12}>
              <RaisedButton fullWidth style={{height:60}} label="Upload" primary={true} onClick={e=>{e.preventDefault();this.showWidget(widget)}}/>
                </Grid>

                        </Grid>



                <Grid item>
                  <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back </Button>
                </Grid>

              </Paper>
              </Grid>





</Grid>
            </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default PassportPicConfirm
