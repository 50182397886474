import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';





export class JammedFlightControls extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }

  render() {
    const {values} = this.props;
    const ct = new Date(values.transTimeStamp);

    var date1 = new Date(values.fl_time);
    var flightDate = date1.getDate() + '/' + (date1.getMonth()+1) + '/' + date1.getFullYear();
    let current_time =  ct.getDate() + '/' + (ct.getMonth()+1) + '/' + ct.getFullYear();
    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>


          <Grid item xs={12} sm container style={{padding:50}}>


              <table style={{textAlign:'center',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#BFBFBE',width:'42%'}}>
              <b>HELICOPTER TRAINING AND TRANSPORT <br></br>
              Commercial Pilot Licence – Helicopter Category Rating</b>
              </td>
              </tr>
              </table>


              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H)  15: JAMMED FLIGHT CONTROLS</b>
              </td>
              </tr>
              </table>



              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse', backgroundColor:'#EEECDF'}}>Flight no: </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> 105CPL(H)15.{values.lessonNo}    </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Trainee name <br></br> & ARN: </th>
              <td style={{padding:10, columnSpan:'all',borderBottomStyle:'solid',borderWidth:1}}> {values.fName} {values.lName}<br></br> {values.stu_arn}  </td>
              </tr>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Date </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>  {flightDate}     </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Instructor: </th>
              <td style={{padding:10, columnSpan:'all'}}>{values.ins_fname} {values.ins_lname}  </td>

              </tr>
              </table>
              <table style={{textAlign:'left',borderStyle:'none', width:'100%', borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft<br></br>registration: </th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> {values.rego}   </td>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft Type:</th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{values.wing_type}     </td>
              <th style={{width:'10%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Flight Time:</th>
              <td style={{borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{(parseFloat(values.soloGH)+parseFloat(values.soloNav)).toFixed(1)} S <br></br>{(parseFloat(values.dualGH)+parseFloat(values.dualNav)).toFixed(1)} D</td>
              </tr>

              </table>

              <Grid item xs style={{textAlign:'left', marginTop:20, paddingLeft:10, paddingTop:5,borderWidth:1, borderStyle:'solid'}}>
              <b>Lesson Overview</b>

              <ul>
              <li> Jammed flight controls in ground effect</li>
              <li>Jammed flight controls in cruise</li>
              </ul>

              </Grid>

              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <th style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#BFBFBE',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              PRE-FLIGHT KNOWLEDGE<br></br>
              Long Briefing: 1.0 hour    Pre-flight Briefing: 0.3 hour<br></br>
              Underpinning knowledge: as required
              </th>
              </tr>
              <tr>
              <td style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#EEECDF',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}> Content
              </td>
              </tr>
              <tr><td style={{ borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Long briefing </b>– Jammed Flight Controls   <br></br>
              <ul>
              <li>The aerodynamics associated with jammed flight controls (secondary effects of controls, the power required curve)</li>
              <li>Jammed flight controls drill (controls check, PAN call, passenger brief)</li>
              <li>Jammed flight controls technique in the cruise</li>
              <li>Jammed flight controls technique in ground effect</li>

              </ul>
              </td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Underpinning knowledge</b> (relevant to the stage of training):<br></br>
              <ul>
              <li>Review/expand previously introduced knowledge as required</li>
              <li>Helicopter aerodynamics (vector diagrams, power required curve)</li>
              <li>Upset recovery techniques [H7(e)]</li>

              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>HF & NTS</b><br></br>
              <ul>
              <li>Look out/ listen out</li>
              <li>Carry out recurring scan of local environment for traffic prior to beginning each new exercise</li>
              <li>Hand over / take over technique</li>
              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%', borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Pre-flight briefing</b><br></br>
              <ul>
              <li>Review flight sequences, what to expect, see & do</li>
              <li>Check essential knowledge</li>
              <li>Reinforce threat & error management</li>
              <li>Reinforce significant airmanship points</li>
              </ul></td>
              </tr>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',width:'42%',paddingLeft:10,paddingBottom:40, borderRightStyle:'solid', borderWidth:1}}>
              <b>Pre-flight knowledge components complete: <br></br></b>{values.pfk_bool}</td>
              <td style={{paddingLeft:10}}><b>Instructor’s signature & date</b><br></br>
              <img style={{maxWidth:'60%'}} src={values.ins_Signature}/>{current_time}</td>
              </tr>
              </table>



              <hr style={{marginTop:250, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 15  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 1</p2></td>

              </tr>

              </table>
              </Grid>


          <Grid item xs={12} sm container style={{padding:50, paddingTop:45}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H)  15: JAMMED FLIGHT CONTROLS</b>
              </td>
              </tr>
              </table>

              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr style={{textAlign:'center',backgroundColor:'#BFBFBE',width:'100%',paddingLeft:10,paddingBottom:40}}>
              <b>Performance Standard</b>
              </tr>
              </table>

              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>3</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>2</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>1</b></td>
              </tr>
              <tr>
              <td style={{ width: '33.35%',borderStyle:'solid',borderWidth:1, padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Has received training in the element, however is not able to consistently demonstrate competency to the standard required for qualification issue</td>
              <td style={{ width: '33.34%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Demonstrates a developing level of proficiency, and is deemed safe to conduct solo practice under direct supervision</td>
              <td style={{ width: '33%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Achieves competency to the standard required for qualification issue</td>
              </tr>

              </table>


              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>FLIGHT TRAINING<br></br>
              Suggested flight time:  1.0 hour dual</b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
              </tr>

              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H7.4  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Manage a jammed flight control system  </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a) </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>jammed or malfunctioning flight control system malfunction is correctly identified  </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[0].rate}</td>
                    </tr>

                        <tr>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>control of the helicopter is established and a suitable landing area is selected  </td>
                          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[1].rate}</td>
                          </tr>
                              <tr>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> all emergency checks are performed and a plan is formulated to achieve an approach and landing </td>
                                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[2].rate}</td>
                                </tr>

                                    <tr>
                                      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d) </td>
                                      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergency radio messages of intentions are transmitted; </td>
                                      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                                      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[3].rate}</td>
                                      </tr>

                                          <tr>
                                            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e) </td>
                                            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> a controlled landing with a jammed flight control is performed </td>
                                            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                                            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[4].rate}</td>
                                            </tr>
                                                <tr>
                                                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f) </td>
                                                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>situational awareness is maintained at all times during jammed flight control handling manoeuvres  </td>
                                                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                                                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[5].rate}</td>
                                                  </tr>



              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H7.5   </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Manage a malfunction of a helicopter operating system	  </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a) </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> abnormal situations involving a helicopter system malfunction are correctly identified and confirmed </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[6].rate}</td>
                    </tr>

                        <tr>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>appropriate emergency procedures are conducted in accordance with the aircraft flight manual while maintaining control of the helicopter flight path  </td>
                          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[7].rate}</td>
                          </tr>
                              <tr>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> system malfunctions are managed whilst situational awareness is maintained at all times during a system malfunction </td>
                                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[8].rate}</td>
                                </tr>






              </table>

              <hr style={{marginTop:800, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 15  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 2</p2></td>

              </tr>

              </table>


              </Grid>

              <Grid item xs={12} sm container style={{paddingTop:150, padding:50}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H)  15: JAMMED FLIGHT CONTROLS</b>
              </td>
              </tr>
              </table>
              <p><i><b>*Enter the performance standard achieved if it is different to that required</b><br></br>
              Where it has not been possible to introduce performance criteria or the trainee has not achieved the
              required standard, the performance criteria must be covered during the next lesson. Enter these performance
              criteria in the lesson record for the subsequent lesson.</i></p>

              <table style={{borderStyle:'solid', width:'100%', marginTop:30, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>CONSOLIDATION AND/OR REMEDIAL TRAINING<br></br></b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>

              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              </table>



              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, borderStyle:'solid',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>DEBRIEFING</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,borderStyle:'solid', backgroundColor:'#EEECDF',width:'100%'}}>
              Content
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <ul>
              <li>Training review and outcomes achieved against lesson objectives and the Part 61 MOS competency standards</li>
              <li>Recommendations for next lesson (including any carryover/remedial training)</li>
              <li>Trainee preparation for next lesson</li>
              <li>Training record completion and sign off</li>
              </ul>
              </td>
              </tr>
              </table>


              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>COMMENTS AND OUTCOME</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderStyle:'solid', borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <br></br>
              {values.commts}
              <br></br>
              <br></br>
              <br></br>
              <br></br>


              </td>
              </tr>
              </table>
              <table style={{textAlign:'left',borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderBottomStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'80%'}}>
              <b>Proceed to next training session?</b>
              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>
              <b>{values.nextLesson_bool}</b>
              </td>
              <td style={{ borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>

              </td>
              </tr>

              </table>

              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Instructor’s signature & date</b>
              </td>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Trainee’s signature & date</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.ins_Signature}/>{flightDate}

              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.stu_Signature}/>{flightDate}
              </td>

              </tr>

              </table>

              <hr style={{marginTop:245, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 15  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 3</p2></td>

              </tr>

              </table>
              </Grid>


          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default JammedFlightControls
