import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { validations, validateAll } from 'indicative/validator';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as moment from 'moment';


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const handleDateChange = e => {
   console.log(e);

 };

export class CreateStudentForm extends Component{

  constructor(props) {
    super(props);
    this.state = {value         : 0,
                  medical1   : false,
                  medical2   : false,
                  medical3   : false,
                  medical1err : "",
                  medical2err : "",
                  medical3err : "",
                  disclaimer    : true,
                  fNameerr       : "",
                  mNameserr      : "",
                  lNameerr       : "",
                  doberr         : "",
                  emailerr       : "",
                  mAddresserr    : "",
                  rAddresserr    : "",
                  phoneerr       : "",
                  nextKinerr     : "",
                  nextKinPhoneerr: "",
                  cWeighterr     : "",
                  stu_arnerr     : "",
                  LicTypeerr     : "",
                  accNoteserr    : "",
                  bloodTypeerr   : "",
                  CLicTypeerr    : "",

        };

  }


  continue = e =>{
    if (this.state.medical1err=="" && this.state.CLicTypeerr=="" && this.state.medical2err=="" && this.state.medical3err=="" && this.state.fNameerr=="" && this.state.mNameserr=="" && this.state.lNameerr=="" && this.state.doberr=="" && this.state.emailerr=="" && this.state.mAddresserr=="" && this.state.rAddresserr=="" && this.state.phoneerr=="" && this.state.nextKinerr=="" && this.state.nextKinPhoneerr=="" && this.state.cWeighterr=="" && this.state.stu_arnerr=="" && this.state.LicTypeerr=="" && this.state.accNoteserr=="" && this.state.bloodTypeerr=="") {
      this.props.handleChangeInput("xs_level","s");
      this.props.setStep(2010);
    } else {
        console.log('err');
    }

  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  validate=e=>{
    e.preventDefault();
     this.validateEmail()

  }
  handleChange = field => e => {
    console.log(e.target.value)
    const { values, log,handleChangeInput, handlePicChange} = this.props;

  e.preventDefault();
  this.setState({[field+"err"]:''})
  handleChangeInput(field,e.target.value);
  /// Do something here
}



checkedFunc=nam=>e=>{
  if (e.target.checked==false) {
this.props.handleChangeInput(nam,moment().format('MM/DD/YYYY'))
this.props.handleChangeInput(nam+"Checked",e.target.checked)
this.setState({[nam]:e.target.checked})
}else{
  this.props.handleChangeInput(nam+"Checked",e.target.checked)
  this.setState({[nam]:e.target.checked})

}
}

clearErr= field => e =>{
  e.preventDefault();
  this.setState({[field+"err"]:''})
}

  validateEmail=e=>{
    const { values} = this.props;
    const data = values
    const rules= {fName       : 'string|required|string|max:50',
                  mNames      : 'string|string|max:50',
                  lName       : 'string|required|string|max:50',
                  dob         : 'date|required',
                  email       : 'email|required',
                  mAddress    : 'required|string|min:2|max:300',
                  rAddress    : 'required|string|min:2|max:300',
                  phone       : 'required|number',
                  nextKin     : 'required|string|max:300',
                  nextKinPhone: 'required|number',
                  cWeight     : 'float|required',
                  LicType     : 'required',
                  stu_arn     : 'number|required',
                  accNotes    : 'max:5000',
                  bloodType   : 'required',
                  CLicType    : 'required',

  }
    const messages={
      alpha                 : 'Alphabetical characters only!',
      date                 : 'Invalid Date',
      number                : 'Numbers Only',
      float                 : "That's invalid",
      required              : 'This Cannot be empty',
      min                   : "That's too short!",
      max                   : "That's too long!",
      email                 : 'Invalid Email',
      alpha_numeric         : 'Illegal Characters in the password',
      'password.confirmed'  : 'The password confirmation does not match the entered password',
            }

    validateAll(data, rules, messages)
    .catch(errors =>{
      console.log(errors);

      const formattedErrors = {}
      errors.forEach(error =>this.setState({[error.field+"err"]:error.message})
      )



    })
    fetch(`${process.env.REACT_APP_SERVER_URL}/validateEmail?email=${values.email}`, {
      headers: {
      'x-access-token': values.token
    }})
    .catch(err=>console.error(err))
    .then(response => response.json())
    .then(response=>{if(response.data[0].count>0){
      this.setState({emailerr:"This email already exists!"})
    }else {
      this.continue();
    }
    })

    .catch(err=>console.error(err))
  }

  log1=e=>{
    this.setState({medical1err:''});
    this.props.log1(e);

  }
  log2=e=>{
    this.setState({medical2err:''});
    this.props.log2(e);

  }
  log3=e=>{
    this.setState({medical3err:''});
    this.props.log3(e);

  }

  log=e=>{
    this.setState({doberr:''});
    this.props.log(e);

  }


  render() {
    const { values, handlePicChange} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >


          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Enter New Account Details</h3>
              </Grid>
              <Grid item xs={4}>
              <TextField
                  required
                  id="First_Name"
                  label="First Name"
                  type="fname"
                  name="First Name"
                  autoComplete="fname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('fName')}
                  value={values.fName}
                  error={this.state.fNameerr!=""}
                  helperText={this.state.fNameerr != "" ? this.state.fNameerr : ''}

                  /></Grid>


              <Grid item xs={4}>
              <TextField
                  id="Middle_Names"
                  label="Middle Names"
                  type="mname"
                  name="Middle Name"
                  autoComplete="mname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('mNames')}
                  value={values.mNames}
                  error={this.state.mNameserr!=""}
                  helperText={this.state.mNameserr != "" ? this.state.mNameserr : ''}

                  /></Grid>
              <Grid item xs={4}>
              <TextField
                  required
                  id="Last_Name"
                  label="Last Name"
                  type="lname"
                  name="Last Name"
                  autoComplete="lname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('lName')}
                  value={values.lName}
                  error={this.state.lNameerr!=""}
                  helperText={this.state.lNameerr != "" ? this.state.lNameerr : ''}

                  /></Grid>


              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                  required
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Date Of Birth"
                onChange={this.log}
                value={values.dob}
                error={this.state.doberr!=""}
                helperText={this.state.doberr != "" ? this.state.doberr : ''}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4}>
              <TextField
                  required
                  id="Email"
                  label="E-mail"
                  type="email"
                  name="email"
                  error={this.state.emailerr!=""}
                  helperText={this.state.emailerr != "" ? this.state.emailerr : 'e.g. johnsmith@example.com'}
                  autoComplete="email"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('email')}
                  value={values.email}
                  /></Grid>








              <Grid item xs={2}>
              <TextField
                  required
                  id="phone"
                  label="phone"
                  type="phone"
                  name="phone"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('phone')}
                  value={values.phone}
                  error={this.state.phoneerr!=""}
                  helperText={this.state.phoneerr != "" ? this.state.phoneerr : ''}
                  /></Grid>

              <Grid item xs={2}>
              <FormControl
                required='true'
                variant="outlined"
                style={{width:'100%',marginTop:'9%'}}>

                    <InputLabel  htmlFor="outlined-age-simple">
                        Blood Type
                        </InputLabel>


                      <Select
                      onChange={this.handleChange('bloodType')}
                      value={values.bloodType}
                      error={this.state.bloodTypeerr!=""}
                      helperText={this.state.bloodTypeerr != "" ? this.state.bloodTypeerr : ''}

                    inputProps={{
                      name: 'bloodType',
                      id: 'bloodType',
                    }}
                        >
                        <MenuItem value={"x"}>Unsure</MenuItem>
                        <MenuItem value={"A1"}>A+</MenuItem>
                        <MenuItem value={"A0"}>A-</MenuItem>
                        <MenuItem value={"B1"}>B+</MenuItem>
                        <MenuItem value={"B0"}>B-</MenuItem>
                        <MenuItem value={"O1"}>O+</MenuItem>
                        <MenuItem value={"O0"}>O-</MenuItem>
                        <MenuItem value={"AB1"}>AB+</MenuItem>
                        <MenuItem value={"AB0"}>AB-</MenuItem>
                        </Select>

                        </FormControl></Grid>

              <Grid item xs={12}>
              <TextField
                  required
                  id="res_address"
                  label="Residential Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('rAddress')}
                  value={values.rAddress}
                  error={this.state.rAddresserr!=""}
                  helperText={this.state.rAddresserr != "" ? this.state.rAddresserr : ''}
                  /></Grid>

              <Grid item xs={12}>
              <TextField
                  required
                  id="m_address"
                  label="Mailing Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('mAddress')}
                  value={values.mAddress}
                  error={this.state.mAddresserr!=""}
                  helperText={this.state.mAddresserr != "" ? this.state.mAddresserr : ''}
                  /></Grid>

              <Grid item xs={8}>
              <TextField
                  required
                  id="Next_of_Kin"
                  label="Next of Kin"
                  autoComplete="name"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('nextKin')}
                  value={values.nextKin}
                  error={this.state.nextKinerr!=""}
                  helperText={this.state.nextKinerr != "" ? this.state.nextKinerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  required
                  id="Next_of_Kin_phone"
                  label="Next of Kin Contact"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('nextKinPhone')}
                  value={values.nextKinPhone}
                  error={this.state.nextKinPhoneerr!=""}
                  helperText={this.state.nextKinPhoneerr != "" ? this.state.nextKinPhoneerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  id="ARN"
                  label="ARN"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('stu_arn')}
                  value={values.stu_arn}
                  error={this.state.stu_arnerr!=""}
                  helperText={this.state.stu_arnerr != "" ? this.state.stu_arnerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  required
                  id="Weight"
                  label="Current Weight"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('cWeight')}
                  value={values.cWeight}
                  error={this.state.cWeighterr!=""}
                  helperText={this.state.cWeighterr != "" ? this.state.cWeighterr : ''}
                  /></Grid>
                <Grid item xs={12}>
              <Grid item xs={6}>
            <FormControl
                required variant="outlined" style={{width:'100%',marginTop:'5%'}}

            >
                    <InputLabel  htmlFor="outlined-age-simple">
                      Currently held License type
                    </InputLabel>


                    <Select
                      value={values.CLicType}
                      onChange={this.handleChange('CLicType')}
                      error={this.state.CLicTypeerr!=""}
                      helperText={this.state.CLicTypeerr != "" ? this.state.CLicTypeerr : ''}
                      inputProps={{name: 'age',id: 'licType',}}>
                      <MenuItem value="none">
                      <em>None</em>
                      </MenuItem>
                      <MenuItem value={"RPLH"}>RPL-H</MenuItem>
                      <MenuItem value={"RPLA"}>RPL-A</MenuItem>
                      <MenuItem value={"PPLH"}>PPL-H</MenuItem>
                      <MenuItem value={"PPLA"}>PPL-A</MenuItem>
                      <MenuItem value={"CPLH"}>CPL-H</MenuItem>
                      <MenuItem value={"CPLA"}>CPL-A</MenuItem>
                      <MenuItem value={"ATPLH"}>ATPL-H</MenuItem>
                      <MenuItem value={"ATPLA"}>ATPL-A</MenuItem>
                      <MenuItem value={"other"}>Other - See notes</MenuItem>


                      </Select>

                      </FormControl></Grid>
              <Grid item xs={6}>
            <FormControl
                required variant="outlined" style={{width:'100%',marginTop:'5%'}}

            >
                    <InputLabel  htmlFor="outlined-age-simple">
                      License type
                    </InputLabel>


                    <Select
                      value={values.LicType}
                      onChange={this.handleChange('LicType')}
                      error={this.state.LicTypeerr!=""}
                      helperText={this.state.LicTypeerr != "" ? this.state.LicTypeerr : ''}
                      inputProps={{name: 'age',id: 'licType',}}>
                      <MenuItem value="none">
                      <em>None</em>
                      </MenuItem>
                      <MenuItem value={"RPLH"}>RPL-H</MenuItem>
                      <MenuItem value={"PPLH"}>PPL-H</MenuItem>
                      <MenuItem value={"CPLH"}>CPL-H</MenuItem>
                      </Select>

                      </FormControl></Grid></Grid>
              <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox checked={values.medical1Checked} onChange={this.checkedFunc("medical1")} value="class1Check" />
                }
                label="Class 1 Medical"
                />
              </Grid>
              <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                disabled={this.state.medical1!=true}
                disablePast
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Medical 1"
                onChange={this.log1}
                value={values.medical1}
                error={this.state.medical1err!=""}
                helperText={this.state.medical1err != "" ? this.state.medical1err : ''}
                style={{width:'100%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox checked={values.medical2Checked} onChange={this.checkedFunc("medical2")} value="class2Check" />
                }
                label="Class 2 Medical"
                />
              </Grid>
              <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                disabled={this.state.medical2!=true}
                disablePast
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Medical 2"
                onChange={this.log2}
                value={values.medical2}
                error={this.state.medical2err!=""}
                helperText={this.state.medical2err != "" ? this.state.medical2err : ''}
                style={{width:'100%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox checked={values.medical3Checked} onChange={this.checkedFunc("medical3")} value="class3Check" />
                }
                label="Class 3 Medical"
                />
              </Grid>
              <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                disabled={this.state.medical3!=true}
                disablePast
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Medical 3"
                onChange={this.log3}
                value={values.medical3}
                error={this.state.medical3err!=""}
                helperText={this.state.medical3err != "" ? this.state.medical3err : ''}
                style={{width:'100%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
              <TextField
                  id="Notes"
                  label="Special Notes"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('accNotes')}
                  value={values.accNotes}
                  error={this.state.accNoteserr!=""}
                  helperText={this.state.accNoteserr != "" ? this.state.accNoteserr : ''}
                  />
                  </Grid>
                  <Grid item xs={12}>

                    </Grid>


                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.validate}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>

                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      open={this.state.disclaimer}>
                      <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                      DECLARATION
                      </DialogTitle>
                      <DialogContent dividers>
                      <Typography gutterBottom>
                      I hereby certify that I understand;
                      <br></br>  <br></br>
                      That it may constitute an offence under the Australian Taxation Laws if you were to indicate that you were undertaking a Commercial Course of Training(CPL-H) but in fact had no intention of doing so.
                      <br></br><br></br>
                      That any refunds of pre-paid amounts, less the amount already used calculated at the casual training rate will be made within 30 days(Booking cancellation fees may apply - Intentions to cancel or discontinue training should be communicated to the Chief Flying Instructor as soon as possible so arrangements can be made to facilitate any regulatory obligations).
                      <br></br><br></br>
                      That any personal data and other information provided will be held securely in accordance with the provider's privacy policy and our privacy policy.Also that said information may also be used for marketting and research purposes(You may request a copy of our privacy policy by contacting your education provider).
                      <br></br><br></br>
                      That I understand and will comply with the conditions of training and hire set out by the education provider and will adhere to all neccessary guidelines.
                      </Typography>
                      </DialogContent>
                      <DialogActions>
                      <Button onClick={e=>this.setState({disclaimer:false})} color="secondary">
                      I AGREE
                      </Button>
                      <Button onClick={this.props.prevStep} color="primary">
                      I Disagree
                      </Button>
                      </DialogActions>
                      </Dialog>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default CreateStudentForm
