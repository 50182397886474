import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Toggle from 'material-ui/Toggle';
import DatePicker from 'material-ui/DatePicker';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ActionFavorite from 'material-ui/svg-icons/action/favorite';
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border';








export class LessonOne extends Component{



  constructor(props) {
    super(props);
    this.state={value:0}
  }

/*method to select what conditions are displayed depending on formID selection from the previous page*/



  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    const {values}=this.props;
    switch (values.loggedInUser.accessLevel) {
      case "s":
      e.preventDefault();
      this.props.setStep(6000)
      this.props.handleChangeInput("transactions",[{transId: '', type: '', amount: '', entryTimeStamp: '', paymentTimeStamp: '', accId: '', accName: '', transDesc: '', processedBy: '', eftposId: '', balanceAfter: ''}])

        break;
      default:
      e.preventDefault();
      this.props.handleChangeInput("transactions",[{transId: '', type: '', amount: '', entryTimeStamp: '', paymentTimeStamp: '', accId: '', accName: '', transDesc: '', processedBy: '', eftposId: '', balanceAfter: ''}])
      this.props.prevStep();

    }
  }









  render() {
    const { values, handleChange, defaultButton, formRating,createRadioGroups} = this.props;
    const ratingState= values.formRating;



    return(



      <MuiThemeProvider>
        <React.Fragment>



          <div style={{ padding: 10}}>


            <Grid item xs={12} style={{marginLeft:'5%'}}><h1>Transaction History for {values.loggedInUser.accessLevel=="s"? values.loggedInUser.fName:values.searchKeyword.fname} {values.loggedInUser.accessLevel=="s"? values.loggedInUser.lName:values.searchKeyword.lname}</h1></Grid>
            <Grid item xs={6} style={{marginLeft:'5%'}}><p1>Currently held License:{values.CLicType}</p1></Grid>
            <Grid item xs={6} style={{marginLeft:'5%',marginBottom:'2%'}}><p1>Training for          :{values.LicType}</p1></Grid>
            <Paper style={{margin:'1%',padding:'1%',paddingLeft:"3%",paddingRight:"3%"}}>
            <Grid container  spacing={1}>


            <Grid item xs={1}> <h3>Transaction #</h3></Grid>
            <Grid item xs={1}> <h3>EFTPOS ID</h3></Grid>
            <Grid item xs={2}> <h3>Payment Date & Time</h3></Grid>
            <Grid item xs={1}> <h3>Type</h3></Grid>
            <Grid item xs={3}> <h3>Transaction Info</h3></Grid>
            <Grid item xs={1}> <h3>Amount</h3></Grid>
            <Grid item xs={1}> <h3>Balance </h3></Grid>
            <Grid item xs={2} style={{display:values.loggedInUser.accessLevel== "sa" ? "":"none"}} > <h3>Invoiced(?)</h3></Grid>
            </Grid>

          <GridList style={{height:400}}>
              <Grid item xs={12}>{createRadioGroups()}</Grid>
              </GridList>
              </Paper>
                <Grid item xs={12} style={{ marginTop: "2%"}}>
                <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>

                </Grid>

          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default LessonOne
