import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PasswordHash from 'password-hash';




const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class SignIns extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0,
                  pass:''};
  }


  continue = e => {
    this.props.nextStep();
  }


  validate= e => {
    const { values} = this.props;

    fetch(`${process.env.REACT_APP_SERVER_URL}/authKi?email=${values.ins_email}`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{if(response.data.length>0){
      const hashed= response.data[0].keypass;
      const password=this.state.pass;
      const authSuccess= PasswordHash.verify(password,hashed);
      console.log(authSuccess)

      if(authSuccess==true){this.continue()}else{this.setState({openWrongPass:true})}

    }else{this.setState({openWrongPass:true})}


    })
    .catch(err=>console.log(err))

  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }




  handleChange = e=> {
    this.setState({pass: e.target.value});
  }
  componentDidMount(){
    this.props.nextStep();
  }




    handleCloseIncorrectPassword = () => {
        this.setState({
          openWrongPass: false,
          pass:''
        });
      };


  render() {
    const { values} = this.props;




    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>
          <Grid container spacing={2} style={{padding:50}}>
          <Grid item xs={2}/>
          <Grid item xs={8}>


          <Paper>


          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs style={{textAlign:'center', padding:20}}>

              <h2>
                Instructor Signature Authentication
              </h2>
              <Typography alignJustify variant="body2" color="textSecondary">
                It is an offence to share passwords or use someone else's credentials for access. If your passphrase security is at risk, please contact Support as soon as Possible!
              </Typography>
              <Avatar style={{ margin: 20 }} src={values.ins_profPhoto} size={150}/>

                <Typography gutterBottom variant="subtitle1">
                {values.ins_fname} {values.ins_lname}
                </Typography>


                <TextField
            autoFocus
            margin="dense"
            id="insPass"
            hintText="Enter Your Password"
            onChange={this.handleChange}
            value={this.state.pass}
            type="password"
            fullWidth
          />
          <Grid item>
          <RaisedButton label="Validate & Continue" primary={true} onClick={this.validate} fullWidth='true' style={{marginTop:20}}/>
          <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back  </Button>


        <Dialog
          fullWidth="true"
          maxWidth="md"
          onClose={this.handleCloseIncorrectPassword}
          aria-labelledby="customized-dialog-title"
          open={this.state.openWrongPass}
          >
          <DialogContent style={{textAlign:'center'}}id="customized-dialog-title" onClose={this.handleCloseIncorrectPassword}>
            <Typography  style={{padding:'2%'}}variant="h5">  Invalid Password</Typography>
            <Typography style={{padding:'2%'}} variant="body2">  The entered password does not match the selected user</Typography>
                </DialogContent>
        <DialogActions>
          <RaisedButton fullWidth style={{marginBottom:'2%'}} onClick={this.handleCloseIncorrectPassword} secondary={true} >
            Try again
          </RaisedButton>

        </DialogActions>
        </Dialog>



            </Grid>
              </Grid>

            </Grid>
          </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}/>



        </Grid>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default SignIns
