import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';




const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class FinanceManagement extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue =(stepNo)=> e => {
    e.preventDefault();
    this.props.clear();
    this.props.setStep(stepNo);
  }
  formId = id => {
    this.props.setFormId(id);

  }

  back = e => {
    e.preventDefault();
    this.props.startAgain();
  }

ViewFinance =()=> e => {
  e.preventDefault();
  this.props.clear();
  this.props.getLoggedInUserTransactions(6301);
}




  render() {
    const {values} = this.props;

    switch (values.loggedInUser.accessLevel) {
      case "sa":
        return(
          <MuiThemeProvider>
            <React.Fragment>

              <div>
              <Grid container spacing={2} style={{padding:50}}>


                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.continue(6100)}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      Add Credit to an Account
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to add credit to student or customer accounts
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.continue(6300)}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      View Account Finance History
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to view finance history of an Account
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.continue(6200)}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      Expense an Account
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to expense an accounts for miscellaneous expenses
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.props.getAllTransactions}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      View All Transactions
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to view all transactions upto date
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>



              <Grid item xs={12}>
              <RaisedButton label="Back" secondary={true} onClick={this.back} fullWidth='true' style={{marginTop:20}}/>
              </Grid>

              </Grid>


              </div>
            </React.Fragment>
          </MuiThemeProvider>



    )
      case "i":
        return(
          <MuiThemeProvider>
            <React.Fragment>

              <div>
              <Grid container spacing={2} style={{padding:50}}>


                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.continue(6100)}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      Add Credit to an Account
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to add credit to student or customer accounts
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.continue(6300)}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      View Account Finance History
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to view finance history of an Account
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.continue(6200)}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      Expense an Account
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to expense an accounts for miscellaneous expenses
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card>
                    <CardActionArea value='h1' onClick={this.props.getAllTransactions}>
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                      View All Transactions
                      </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Click here to view all transactions upto date
                      </Typography>
                      </CardContent>
                      </CardActionArea>
                      </Card>
                </Grid>



              <Grid item xs={12}>
              <RaisedButton label="Back" secondary={true} onClick={this.back} fullWidth='true' style={{marginTop:20}}/>
              </Grid>

              </Grid>


              </div>
            </React.Fragment>
          </MuiThemeProvider>



    )

      case "s":
        return(
          <MuiThemeProvider>
            <React.Fragment>

            <div>
            <Grid container spacing={2} style={{padding:50}}>


              <Grid item xs={4}>
                <Card>
                  <CardActionArea value='h1' onClick={this.continue(61900)}>
                  <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    Add Credit to an Account
                    </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Click here to add credit to your account
                    </Typography>
                    </CardContent>
                    </CardActionArea>
                    </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardActionArea value='h1' onClick={this.ViewFinance()}>
                  <CardContent>
                  <Typography gutterBottom variant="h6" component="h2">
                    View Account Finance History
                    </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Click here to view finance history of an Account
                    </Typography>
                    </CardContent>
                    </CardActionArea>
                    </Card>
              </Grid>





            <Grid item xs={12}>
            <RaisedButton label="Back" secondary={true} onClick={this.back} fullWidth='true' style={{marginTop:20}}/>
            </Grid>

            </Grid>


            </div>
          </React.Fragment>
        </MuiThemeProvider>



  )
  }
}
}


export default FinanceManagement
