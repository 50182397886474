import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as moment from 'moment';


export class SelectEngineHours extends Component{

  constructor(props) {
    super(props);
    this.state = {value   : 0,
                  gst     : false,
                  totalGst: 0   ,
                  gstWarning: false,
                };
    const { values,handleChange} = this.props;


  }

  continue = e => {
  const { values} = this.props;
  const soloGHf    =  parseFloat(values.soloGH);
  const soloNavf   =  parseFloat(values.soloNav);
  const dualGHf    =  parseFloat(values.dualGH);
  const dualNavf   =  parseFloat(values.dualNav);
  const gstValue   =  parseFloat(this.state.totalGst);
  const totalFlightTime=parseFloat(soloGHf+soloNavf+dualGHf+dualNavf).toFixed(1);
  const totalcost=((values.costPerHour*totalFlightTime)+gstValue).toFixed(2);

  this.props.handleChangeInput('lessonCost',totalcost);
  this.props.handleChangeInput('fl_clock',totalFlightTime);

  this.props.nextStep();
}

proceed = e => {
  if(this.props.values.accNotes.includes("G1S2T3") && !this.state.gst) {
    this.setState({gstWarning:true})

  }else{
    this.continue();
  }



}
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  componentDidMount(){
    this.props.getCustomPrice();
  }

  handleChange = name => event => {
      this.setState({ ...this.state, [name]: event.target.checked });
      const { values} = this.props;
      const soloGHf    =  parseFloat(values.soloGH);
      const soloNavf   =  parseFloat(values.soloNav);
      const dualGHf    =  parseFloat(values.dualGH);
      const dualNavf   =  parseFloat(values.dualNav);
      const totalFlightTime=parseFloat(soloGHf+soloNavf+dualGHf+dualNavf).toFixed(1);
      const gstValue=(((values.costPerHour*totalFlightTime)*10)/100).toFixed(2);

      if(this.state.gst==false){this.setState({totalGst:gstValue})}else{this.setState({totalGst:0})}


    };


  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    const soloGHf    =  parseFloat(values.soloGH);
    const soloNavf   =  parseFloat(values.soloNav);
    const dualGHf    =  parseFloat(values.dualGH);
    const dualNavf   =  parseFloat(values.dualNav);
    const gstValue   =  parseFloat(this.state.totalGst);
    const totalFlightTime=parseFloat(soloGHf+soloNavf+dualGHf+dualNavf).toFixed(1);
    const totalcost=((values.costPerHour*totalFlightTime)+gstValue).toFixed(2);
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>

              {(moment(values.medical2,'MM/DD/YYYY').unix()<=moment().unix() && moment(values.medical1,'MM/DD/YYYY').unix()<=moment().unix()) && <Grid item xs={4} style={{backgroundColor:"red",color:"white"}}>
              <h2>Check Medical!</h2>
              </Grid>}

              {values.tillBalance<1000 && <Grid item xs={4} style={{backgroundColor:values.tillBalance<1000?"red":"white",color:values.tillBalance<1000?"white":"black"}}>
              <h2>Account Balance low!</h2>
              </Grid>}




              <Grid xs={12}>
              <h3>Enter Your Flight Hours</h3>
              <p4>Please enter Engine Hours Correctly as your account will be charged accordingly. You may use your arrow-keys to alter values </p4>

              </Grid>

              <Grid item xs={12} style={{paddingTop:'2%'}}/>
              <Grid item xs={2}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Dual G/H Hrs</InputLabel>
              <OutlinedInput
                  id="dualGH"
                  type="number"
                  autoFocus="true"
                  inputProps={{ step: "0.1", min:"0.0" }}
                  fullWidth="true"
                  style={{marginTop:'2%',marginBottom:'2%'}}
                  value={values.dualGH}
                  onChange={handleChange('dualGH')}
                  endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                  aria-describedby="Solo General Handling"

                  labelWidth={0}
                  />
                  </FormControl>

                </Grid>

                  <Grid item xs={2}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Dual Nav Hrs</InputLabel>
                  <OutlinedInput
                      id="dualNav"
                      type="number"
                      inputProps={{ step: "0.1", min:"0.0" }}
                      fullWidth
                      style={{marginTop:'2%',marginBottom:'2%'}}
                      value={values.dualNav}
                      onChange={handleChange('dualNav')}
                      endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                      aria-describedby="Solo General Handling"

                      labelWidth={0}
                      />
                      </FormControl></Grid>

                      <Grid item xs={2}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Solo G/H Hrs</InputLabel>
                      <OutlinedInput
                          id="soloGH"
                          type="number"
                          inputProps={{ step: "0.1", min:"0.0" }}
                          fullWidth
                          style={{marginTop:'2%',marginBottom:'2%'}}
                          value={values.soloGH}
                          onChange={handleChange('soloGH')}
                          endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                          aria-describedby="Solo General Handling"

                          labelWidth={0}
                          />
                          </FormControl></Grid>


                          <Grid item xs={2}>
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Solo Nav Hrs</InputLabel>
                          <OutlinedInput
                              id="soloNav"
                              type="number"
                              inputProps={{ step: "0.1", min:"0.0" }}
                              fullWidth
                              style={{marginTop:'2%',marginBottom:'2%'}}
                              value={values.soloNav}
                              onChange={handleChange('soloNav')}
                              endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                              aria-describedby="Solo General Handling"

                              labelWidth={0}
                              />
                              </FormControl></Grid>
                              <Grid item xs={4}>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Total Flight Hours</InputLabel>
                              <OutlinedInput
                              disabled
                                  id="flightHours"
                                  type="number"
                                  autoFocus="true"
                                  fullWidth
                                  style={{marginTop:'2%',marginBottom:'2%'}}
                                  value={totalFlightTime}
                                  endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                                  aria-describedby="Engine hours as per the flight clock"

                                  labelWidth={0}
                                  />             </FormControl></Grid>

                              <Grid item xs={12}>
                              <h3>Cost Per Hour</h3>
                              </Grid>
                              <Grid item xs={12}>
                              <OutlinedInput
                                  id="costPerHour"
                                  type="number"
                                  inputProps={{ step: "5.00", min:"0.00" }}
                                  style={{marginTop:'2%',marginBottom:'2%'}}
                                  value={values.costPerHour}
                                  onChange={this.props.handleChange('costPerHour')}
                                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                  aria-describedby="Cost per hour"

                                  labelWidth={0}
                                  />              </Grid>

                                  <Grid item xs={6}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={this.state.gst} onChange={this.handleChange('gst')} value="gst" />
                                    }
                                    label="Add 10% GST"
                                    />
                                   </Grid>
                                   <Grid item xs={6}>
                                   <div>
                                   {values.accNotes.includes("G1S2T3") ? <p style={{color:"red"}}> {`<<< GST APPLIES`}</p>  : <p></p>}
                                   </div>
                                    </Grid>


                              <Grid item xs={12}>
                              <h3>Total Lesson Cost</h3>
                              </Grid>


                              <Grid item xs={12}>
                              <p4> Total cost based on Engine hours and Cost per hour</p4>
                              </Grid>






                  <Grid item xs={12}>
                  <OutlinedInput
                      id="lessonCost"
                      disabled
                      type="number"
                      inputProps={{ step: "50.00", min:"0.00" }}
                      style={{marginTop:'2%',marginBottom:'2%'}}
                      value={totalcost}
                      onChange={this.props.handleChange('lessonCost')}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      aria-describedby="Total Lesson Cost"

                      labelWidth={0}
                      />
                    </Grid>

                    <Grid item xs={2}> <h2>  Account Balance</h2></Grid><Grid item xs={10}><h2>: $ {values.tillBalance}</h2></Grid>




                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.proceed} />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>


                      <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        aria-labelledby="customized-dialog-title"
                        open={this.state.gstWarning}
                        >
                        <DialogContent style={{textAlign:'center'}}id="customized-dialog-title" >
                          <Typography  style={{padding:'2%'}}variant="h5">  Warning!</Typography>
                          <Typography style={{padding:'2%'}} variant="body2">  This account has been flagged for GST! Are you sure you'd like to proceed without adding GST?</Typography>
                              </DialogContent>
                      <DialogActions>
                        <RaisedButton fullWidth label="Proceed with no GST" onClick={this.continue} secondary={true}/>


                      </DialogActions>
                      <DialogActions>

                        <RaisedButton fullWidth style={{height:40}} label="Go back to add GST" primary={true} onClick={e=>{e.preventDefault();this.setState({gstWarning:false})}} />

                      </DialogActions>
                      </Dialog>



</Grid>
              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SelectEngineHours
