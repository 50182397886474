import React, { Component } from 'react';
import { useRef } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridList from '@material-ui/core/GridList';
import Pdf from 'html-pdf';
import Fs from 'file-system';
import ReactToPrint from 'react-to-print';


import EffectsOfControls from './forms/01_EffectsOfControls';
import Coordination from './forms/02_Coordination';
import AdvancedCoordination from './forms/03_AdvancedCoordination';
import Hover from './forms/04_Hover';
import HoverTurnsAndHoverTaxi from './forms/05_HoverTurnsAndHoverTaxi';
import LandingsAndLiftOffs from './forms/06_LandingsAndLiftOffs';
import Transitions from './forms/07_Transitions';
import Circuits from './forms/08_Circuits';
import MaximumPerformanceTakeOffsAndSteepApproaches from './forms/09_MaximumPerformanceTakeOffsAndSteepApproaches';
import LimitedPowerOperations from './forms/10_LimitedPowerOperations';
import BasicAutorotation from './forms/11_BasicAutorotation';
import EngineFailureInTheHoverAndHoverTaxi from './forms/12_EngineFailureInTheHoverAndHoverTaxi';
import PracticeForcedLandings from './forms/13_PracticeForcedLandings';
import TailRotorFailures from './forms/14_TailRotorFailures';
import JammedFlightControls from './forms/15_JammedFlightControls';
import AdvancedEmergencies from './forms/16_AdvancedEmergencies';
import CircuitsPreSolo from './forms/17_CircuitsPreSolo';
import FirstSoloCircuit from './forms/18_FirstSoloCircuit';
import CircuitConsolidation from './forms/19_CircuitConsolidation';
import SoloCircuits from './forms/20_SoloCircuits';
import CircuitConsolidation2 from './forms/21_CircuitConsolidation2';
import SoloCircuits2 from './forms/22_SoloCircuits2';
import CircuitConsolidation3 from './forms/23_CircuitConsolidation3';
import SoloCircuits3 from './forms/24_SoloCircuits3';
import SlopeLandings from './forms/25_SlopeLandings';
import ConfinedAreas from './forms/26_ConfinedAreas';
import SteepTurns from './forms/27_SteepTurns';
import PreTrainingAreaSolo from './forms/28_PreTrainingAreaSolo';
import FirstTrainingAreaSolo from './forms/29_FirstTrainingAreaSolo';
import ConfinedAreasConsolidation from './forms/30_ConfinedAreasConsolidation';
import TrainingAreaSolo from './forms/31_TrainingAreaSolo';
import EmergenciesConsolidation from './forms/32_EmergenciesConsolidation';
import TrainingAreaSolo2 from './forms/33_TrainingAreaSolo2';
import NavEx1 from './forms/34_NavEx1';
import NavEx2 from './forms/35_NavEx2';
import NavEx3 from './forms/36_NavEx3';
import NavEx4FirstSolo from './forms/37_NavEx4FirstSolo';
import NavEx5 from './forms/38_NavEx5';
import NavEx6SoloNav12DME from './forms/39_NavEx6SoloNav12DME';
import NavEx7 from './forms/40_NavEx7';
import NavEx8SoloNavR555 from './forms/41_NavEx8SoloNavR555';
import NavEx9 from './forms/42_NavEx9';
import NavEx10SoloNav from './forms/43_NavEx10SoloNav';
import NavEx11 from './forms/44_NavEx11';
import NavEx12SoloNav from './forms/45_NavEx12SoloNav';
import NavEx13 from './forms/46_NavEx13';
import NavEx14SoloNav from './forms/47_NavEx14SoloNav';
import DualConsolidation from './forms/48_DualConsolidation';
import SoloConsolidation from './forms/49_SoloConsolidation';
import PreCPL from './forms/50_PreCPL';
import AccCompetency from './forms/51_AccCompetency';
import Na from './forms/NA';




export class FormReview extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};

  }


  continue = e => {
    this.props.enterTrainingRecord();
    if(parseFloat(this.props.values.lessonCost) > 0){this.props.addDebit();}
    this.props.nextStep();


  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }
  repeat = e => {
    e.preventDefault();
    this.props.startAgain();
  }

  renderForm=()=>{
        const {values} = this.props;
        const formId=values.formId;

      switch (formId) {
        case "NA":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "01_105CPL_H":
        return(<EffectsOfControls ref={el => (this.componentRef = el)} values={values}/>)
        case "02_105CPL_H":
        return(<Coordination ref={el => (this.componentRef = el)} values={values}/>)
        case "03_105CPL_H":
        return(<AdvancedCoordination ref={el => (this.componentRef = el)} values={values}/>)
        case "04_105CPL_H":
        return(<Hover ref={el => (this.componentRef = el)} values={values}/>)
        case "05_105CPL_H":
        return(<HoverTurnsAndHoverTaxi ref={el => (this.componentRef = el)} values={values}/>)
        case "06_105CPL_H":
        return(<LandingsAndLiftOffs ref={el => (this.componentRef = el)} values={values}/>)
        case "07_105CPL_H":
        return(<Transitions ref={el => (this.componentRef = el)} values={values}/>)
        case "08_105CPL_H":
        return(<Circuits ref={el => (this.componentRef = el)} values={values}/>)
        case "09_105CPL_H":
        return(<MaximumPerformanceTakeOffsAndSteepApproaches ref={el => (this.componentRef = el)} values={values}/>)
        case "10_105CPL_H":
        return(<LimitedPowerOperations ref={el => (this.componentRef = el)} values={values}/>)
        case "11_105CPL_H":
        return(<BasicAutorotation ref={el => (this.componentRef = el)} values={values}/>)
        case "12_105CPL_H":
        return(<EngineFailureInTheHoverAndHoverTaxi ref={el => (this.componentRef = el)} values={values}/>)
        case "13_105CPL_H":
        return(<PracticeForcedLandings ref={el => (this.componentRef = el)} values={values}/>)
        case "14_105CPL_H":
        return(<TailRotorFailures ref={el => (this.componentRef = el)} values={values}/>)
        case "15_105CPL_H":
        return(<JammedFlightControls ref={el => (this.componentRef = el)} values={values}/>)
        case "16_105CPL_H":
        return(<AdvancedEmergencies ref={el => (this.componentRef = el)} values={values}/>)
        case "17_105CPL_H":
        return(<CircuitsPreSolo ref={el => (this.componentRef = el)} values={values}/>)
        case "18_105CPL_H":
        return(<FirstSoloCircuit ref={el => (this.componentRef = el)} values={values}/>)
        case "19_105CPL_H":
        return(<CircuitConsolidation ref={el => (this.componentRef = el)} values={values}/>)
        case "20_105CPL_H":
        return(<SoloCircuits ref={el => (this.componentRef = el)} values={values}/>)
        case "21_105CPL_H":
        return(<CircuitConsolidation2 ref={el => (this.componentRef = el)} values={values}/>)
        case "22_105CPL_H":
        return(<SoloCircuits2 ref={el => (this.componentRef = el)} values={values}/>)
        case "23_105CPL_H":
        return(<CircuitConsolidation3 ref={el => (this.componentRef = el)} values={values}/>)
        case "24_105CPL_H":
        return(<SoloCircuits3 ref={el => (this.componentRef = el)} values={values}/>)
        case "25_105CPL_H":
        return(<SlopeLandings ref={el => (this.componentRef = el)} values={values}/>)
        case "26_105CPL_H":
        return(<ConfinedAreas ref={el => (this.componentRef = el)} values={values}/>)
        case "27_105CPL_H":
        return(<SteepTurns ref={el => (this.componentRef = el)} values={values}/>)
        case "28_105CPL_H":
        return(<PreTrainingAreaSolo ref={el => (this.componentRef = el)} values={values}/>)
        case "29_105CPL_H":
        return(<FirstTrainingAreaSolo ref={el => (this.componentRef = el)} values={values}/>)
        case "30_105CPL_H":
        return(<ConfinedAreasConsolidation ref={el => (this.componentRef = el)} values={values}/>)
        case "31_105CPL_H":
        return(<TrainingAreaSolo ref={el => (this.componentRef = el)} values={values}/>)
        case "32_105CPL_H":
        return(<EmergenciesConsolidation ref={el => (this.componentRef = el)} values={values}/>)
        case "33_105CPL_H":
        return(<TrainingAreaSolo2 ref={el => (this.componentRef = el)} values={values}/>)
        case "34_105CPL_H":
        return(<NavEx1 ref={el => (this.componentRef = el)} values={values}/>)
        case "35_105CPL_H":
        return(<NavEx2 ref={el => (this.componentRef = el)} values={values}/>)
        case "36_105CPL_H":
        return(<NavEx3 ref={el => (this.componentRef = el)} values={values}/>)
        case "37_105CPL_H":
        return(<NavEx4FirstSolo ref={el => (this.componentRef = el)} values={values}/>)
        case "38_105CPL_H":
        return(<NavEx5 ref={el => (this.componentRef = el)} values={values}/>)
        case "39_105CPL_H":
        return(<NavEx6SoloNav12DME ref={el => (this.componentRef = el)} values={values}/>)
        case "40_105CPL_H":
        return(<NavEx7 ref={el => (this.componentRef = el)} values={values}/>)
        case "41_105CPL_H":
        return(<NavEx8SoloNavR555 ref={el => (this.componentRef = el)} values={values}/>)
        case "42_105CPL_H":
        return(<NavEx9 ref={el => (this.componentRef = el)} values={values}/>)
        case "43_105CPL_H":
        return(<NavEx10SoloNav ref={el => (this.componentRef = el)} values={values}/>)
        case "44_105CPL_H":
        return(<NavEx11 ref={el => (this.componentRef = el)} values={values}/>)
        case "45_105CPL_H":
        return(<NavEx12SoloNav ref={el => (this.componentRef = el)} values={values}/>)
        case "46_105CPL_H":
        return(<NavEx13 ref={el => (this.componentRef = el)} values={values}/>)
        case "47_105CPL_H":
        return(<NavEx14SoloNav ref={el => (this.componentRef = el)} values={values}/>)
        case "48_105CPL_H":
        return(<DualConsolidation ref={el => (this.componentRef = el)} values={values}/>)
        case "49_105CPL_H":
        return(<SoloConsolidation ref={el => (this.componentRef = el)} values={values}/>)
        case "50_105CPL_H":
        return(<PreCPL ref={el => (this.componentRef = el)} values={values}/>)
        case "51_105CPL_H":
        return(<AccCompetency ref={el => (this.componentRef = el)} values={values}/>)


        case "60_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "61_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "62_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "63_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "64_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "65_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "66_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "67_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "68_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "69_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "70_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)
        case "71_105CPL_HGS":
        return(<Na ref={el => (this.componentRef = el)} values={values}/>)



        default:
        return(<AccCompetency ref={el => (this.componentRef = el)} values={values}/>)

      }

      }





  render() {
    const {values} = this.props;
    const formId=values.formId;




        return (


          <MuiThemeProvider>
            <React.Fragment>

                <GridList style={{height: 450,maxWidth:'90%', borderStyle:'solid',borderColor:'grey',borderWidth:1, marginRight:'10%', marginLeft:'10%', marginTop:35}}>
                {this.renderForm()}</GridList>
                <Grid item xs={10} style={{marginLeft:'10%',marginRight:'10%',marginTop:20}}>
                <RaisedButton  onClick={this.continue} primary fullWidth='true' label="Submit" style={{color:"white",marginTop:20, height:60}}/>
                <RaisedButton fullWidth secondary onClick={this.back} style={{marginTop:20}}>Back</RaisedButton>
                </Grid>
            </React.Fragment>
          </MuiThemeProvider>
        )


}
}

export default FormReview
