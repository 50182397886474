import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AvatarPic from './avatar.png'
import Webcam from 'react-webcam';
import ModelPhoto from './modelPhoto.jpg'



export class PassportPicEditConfirm extends Component{


  constructor(props) {
    super(props);
    this.state = {value:0};
  }



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  handleChange = (event, index, value) => this.setState({value});

  showWidget = (widget) =>{
    widget.open()
  }

  checkUploadResult = (resultEvent,widget) =>{
    if (resultEvent.event === 'success'){
      widget.close();
      this.props.handleChangeInput('ins_PassPhoto',resultEvent.info.secure_url)
      this.props.setStep(4203)
    }}







  render() {
    const {values, setPassportPhoto,passportPhoto} = this.props;
    let widget = window.cloudinary.createUploadWidget({
      sources:['local','camera'],
      cloudName:"donas",
      uploadPreset:"eg8ivjdr",
      cropping: true

    },(error,result)=>{this.checkUploadResult(result,widget) })
    return(

      <MuiThemeProvider>
        <React.Fragment>


          <div>
          <Grid container spacing={2} style={{padding:'10%',paddingTop:'2%'}}>
          <Grid item xs={12}>

            <Paper style={{opacity:0.89}}>


              <Grid style={{padding:'2%'}}>
              <Grid style={{margin:'35%',marginTop:"2%",marginBottom:'2%',height:'30%',width:'30%'}}>


                        </Grid>
                        </Grid>



                <Grid item>
                <RaisedButton fullWidth style={{height:60}} label="Upload" primary={true} onClick={e=>{e.preventDefault();this.showWidget(widget)}}/>

                  <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back </Button>
                </Grid>

              </Paper>
              </Grid>





</Grid>
            </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default PassportPicEditConfirm
