import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';


import AppBar from 'material-ui/AppBar/AppBar';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import Loading from './loading';
import WelcomePage from './WelcomePage';
import LoginPage from './LoginPage';
import LoginPageAgain from './LoginPageAgain';
import SelectStudentFormAccHistory from './finance/AccHistory/SelectStudentForm';
import SelectStudentFormAddExpense from './finance/addExpense/SelectStudentForm';
import SelectStudentFormAddPayment from './finance/addPayment/SelectStudentForm';
import PropTypes from 'prop-types';
import ReactEncrypt from 'react-encrypt'

import PasswordReset from './passwordReset';
import HomePage from './HomePage';
import LoginPagePassword from './LoginPagePassword';
import LoginPagePasswordAgain from './LoginPagePasswordAgain';
import AppBarr from './appBar';

import AddRecord from './trainingMgmt/addrecord/AddRecord';
import SelectStudentForm from './trainingMgmt/addrecord/SelectStudentForm';
import SelectInstructorForm from './trainingMgmt/addrecord/SelectInstructorForm';
import SelectWingsForm from './trainingMgmt/addrecord/SelectWingsForm';
import SelectDate from './trainingMgmt/addrecord/SelectDate';
import SelectEngineHours from './trainingMgmt/addrecord/SelectEngineHours';
import AddComments from './trainingMgmt/addrecord/addComments';
import LessonCond from './trainingMgmt/addrecord/LessonOne';
import SignIns from './trainingMgmt/addrecord/SignIns';
import SignStu from './trainingMgmt/addrecord/SignStu.js';
import FormReview from './trainingMgmt/addrecord/FormReview';
import Success from './trainingMgmt/addrecord/Success';
import Success1 from './trainingMgmt/addrecord/Success1';
import Success2 from './accountMgmt/editStudentAccount/Success1';

import CreditCardInfoView from './accountMgmt/editStudentAccount/CreditCardInfoView';
import CreditCardInfoEdit from './accountMgmt/editStudentAccount/CreditCardInfoEdit';
import CreditCardInfoVerify from './accountMgmt/editStudentAccount/CreditCardInfoVerify';

import SelectForm from './trainingMgmt/addrecord/SelectForm';

import EditSelectStudentForm from './trainingMgmt/editRecord/SelectStudentForm';
import EditSelectInstructorForm from './trainingMgmt/editRecord/SelectInstructorForm';
import EditSelectWingsForm from './trainingMgmt/editRecord/SelectWingsForm';
import EditSelectDate from './trainingMgmt/editRecord/SelectDate';
import EditSelectEngineHours from './trainingMgmt/editRecord/SelectEngineHours';
import EditAddComments from './trainingMgmt/editRecord/addComments';
import EditLessonCond from './trainingMgmt/editRecord/LessonOne';
import EditSignIns from './trainingMgmt/editRecord/SignIns';
import EditSignStu from './trainingMgmt/editRecord/SignStu.js';
import EditSuccess from './trainingMgmt/editRecord/Success';
import EditSelectForm from './trainingMgmt/editRecord/SelectForm';
import EditFormReview from './trainingMgmt/editRecord/EditFormReview';
import FormView from './trainingMgmt/editRecord/FormView';


import AddHours from './trainingMgmt/addPreviousHours/SelectEngineHours';
import ReviewAddHours from './trainingMgmt/addPreviousHours/reviewAddHours';
import FlightHistoryTable from './trainingMgmt/trainingHistory/HistoryTable';
import FlightHistoryTable2 from './trainingMgmt/trainingHistory/HistoryTable2';


import CreateStudentForm from './accountMgmt/addStudent/CreateStudentForm';
import CreditCardInfo from './accountMgmt/addStudent/CreditCardInfo';
import ProfilePicUpload from './accountMgmt/addStudent/ProfilePicUpload';
import SetAccPassword from './accountMgmt/addStudent/SetAccPassword';
import PassportPicCapture from './accountMgmt/addStudent/PassportPicCapture';
import PassportPicConfirm from './accountMgmt/addStudent/passportPicConfirm';
import VerifyNewStudentDetails from './accountMgmt/addStudent/VerifyNewStudentDetails';
import SearchStudent from './accountMgmt/editStudentAccount/SearchStudent';
import EditStudentAccount from './accountMgmt/editStudentAccount/EditStudentInfo';
import PassportPicEditQ from './accountMgmt/editStudentAccount/passportPicEditQ';
import PassportPicEditCapture from './accountMgmt/editStudentAccount/PassportPicEditCapture';
import PassportPicEditConfirm from './accountMgmt/editStudentAccount/passportPicEditConfirm';
import VerifyEditStudentDetails from './accountMgmt/editStudentAccount/VerifyEditStudentDetails';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CreateInstructorForm from './InsMgmt/addInstructor/CreateInstructorForm';
import VerifyNewInstructorDetails from './InsMgmt/addInstructor/VerifyNewInstructorDetails';
import InsProfilePicUpload from './InsMgmt/addInstructor/InsProfilePicUpload';
import InsPassportPicCapture from './InsMgmt/addInstructor/InsPassportPicCapture';
import InsPassportPicConfirm from './InsMgmt/addInstructor/InspassportPicConfirm';
import InsSignatureCapture from './InsMgmt/addInstructor/InssignatureCapture';
import InsSignatureConfirm from './InsMgmt/addInstructor/InssignatureConfirm';
import EditInstructorForm from './InsMgmt/editInstructor/EditInstructorForm';
import VerifyEditInstructorDetails from './InsMgmt/editInstructor/VerifyEditInstructorDetails';
import AddAircraft from './fleetManagement/addAirCraft/addAircraft';
import EditAircraft from './fleetManagement/editAirCraft/editAircraft';
import DeleteAircraft from './fleetManagement/deleteAirCraft/deleteAircraft';
import FleetManagement from './fleetManagement/fleetManagement';
import AccountManagement from './accountMgmt/accountManagement';
import InsManagement from './InsMgmt/insManagement';
import SetAccPasswordIns from './InsMgmt/addInstructor/SetAccPassword';
import TrainingManagement from './trainingMgmt/trainingManagement';
import FinanceManagement from './finance/financeManagement';
import SelectAmount from './finance/addPayment/SelectAmount';
import Confirmation from './finance/addPayment/confirmation';
import ConfirmationDebit from './finance/addExpense/confirmation';
import HistoryTable from './finance/AccHistory/HistoryTable';
import AllHistoryTable from './finance/AccHistory/HistoryTable2';
import SignatureCapture from './accountMgmt/addStudent/signatureCapture';
import SignatureConfirm from './accountMgmt/addStudent/signatureConfirm';
import ResetAccPassword from './accountMgmt/editStudentAccount/resetAccPassword';
import PasswordDBupdate from './accountMgmt/editStudentAccount/PasswordDBupdate';
import ProfilePicUpdate from './accountMgmt/editStudentAccount/ProfilePicUpdate';
import UpdateDBpic from './accountMgmt/editStudentAccount/UpdateDBpic';
import UpdateDBpassport from './accountMgmt/editStudentAccount/UpdateDBpassport';
import UpdateSignatureQ from './accountMgmt/editStudentAccount/UpdateSignatureQ';
import SignatureDBupdate from './accountMgmt/editStudentAccount/SignatureDBupdate';
import SignatureEditCapture from './accountMgmt/editStudentAccount/SignatureEditCapture';
import SignatureEditConfirm from './accountMgmt/editStudentAccount/SignatureEditConfirm';


import InsPassportPicEditQ from './InsMgmt/editInstructor/InspassportPicEditQ';
import InsPassportPicEditCapture from './InsMgmt/editInstructor/InsPassportPicEditCapture';
import InsPassportPicEditConfirm from './InsMgmt/editInstructor/InspassportPicEditConfirm';
import InsUpdateDBpassport from './InsMgmt/editInstructor/InsUpdateDBpassport';
import InsResetAccPassword from './InsMgmt/editInstructor/resetAccPassword';
import InsProfilePicUpdate from './InsMgmt/editInstructor/InsProfilePicUpdate';
import InsUpdateDBpic from './InsMgmt/editInstructor/InsUpdateDBpic';
import InsUpdateSignatureQ from './InsMgmt/editInstructor/InsUpdateSignatureQ';
import InsSignatureEditCapture from './InsMgmt/editInstructor/InsSignatureEditCapture';
import InsSignatureEditConfirm from './InsMgmt/editInstructor/InsSignatureEditConfirm';
import InsPasswordDBupdate from './InsMgmt/editInstructor/PasswordDBupdate';
import InsSignatureDBupdate from './InsMgmt/editInstructor/InsSignatureDBupdate';
import Typography from '@material-ui/core/Typography';
import PasswordHash from 'password-hash';
import RaisedButton from 'material-ui/RaisedButton';


import SelectStudentFormPriceEdit from './fleetManagement/setPricePoints/SelectStudentForm';
import SelectWingsFormPriceEdit from './fleetManagement/setPricePoints/SelectWingsForm';
import SetPrice from './fleetManagement/setPricePoints/setPricePoint';
import ConfirmationIndividualPrices from './fleetManagement/setPricePoints/confirmation';
import Checkbox from '@material-ui/core/Checkbox';

import SelectWingsFormPreFlight from './preFlightAuth/SelectWingsForm';
import FlightType from './preFlightAuth/flightType';
import FlightAuthStudentSelect from './preFlightAuth/SelectStudentForm';
import PreFlightInfoForm from './preFlightAuth/PreFlightInfoForm';
import FlightAuthSelectDate from './preFlightAuth/SelectDate';
import PendingPreFlightAuth from './preFlightAuth/PendingPreFlightAuth';
import PendingCharterPreFlightAuth from './preFlightAuth/PendingCharterPreFlightAuth'
import DisplayPreFlightAuths from './preFlightAuth/DisplayPreFlightAuthsDaily'

import EffectsOfControls from './trainingMgmt/addrecord/forms/01_EffectsOfControls';
import Template from './passport template.png';
import SuccessPreFlight from './preFlightAuth/Success1';
import TrainingMatrixExport from './trainingMgmt/matrix/trainingMatrixExport';


import sjcl from 'sjcl';

const TOKEN_KEY=process.env.REACT_APP_TOKEN_KEY;
const SERVER_URL=process.env.REACT_APP_SERVER_URL;

var CryptoJS = require("crypto-js");
var encKey = "secret key 123";


let ts = Date.now();

let date_ob = new Date(ts);
let date = date_ob.getDate();
let month = date_ob.getMonth() + 1;
let year = date_ob.getFullYear();
let hour = date_ob.getHours();
let mins = date_ob.getMinutes();
let secs = date_ob.getSeconds();

// prints date & time in YYYY-MM-DD format
let current_time =  month + "/" + date + "/" + year;


const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: green,
  },
  status: {
    danger: 'orange',
  },
});



export class WelcomeSplash extends Component{
state ={
  lessonCost:'',
  costPerHour:'',
  step: 1001,
  userName:'',
  uniquePass:'',
  profilePic:"https://res.cloudinary.com/donas/image/upload/v1589353044/gbooij0ehivnf5hqzlez.png",
  firstName:'',
  stu_name:'',
  stu_arn:'',
  ins_fname:'',
  ins_mNames:'',
  ins_lname:'',
  ins_btype:'',
  ins_mAddress:'',
  ins_rAddress:'',
  ins_tpNo:'',
  ins_medicalExpiry:'',
  ins_email:'',
  ins_nextKin:'',
  ins_nextKinPhone:'',
  ins_cWeight:'',
  ins_dob:'',
  identName:'',
  ins_PassPhoto:'',
  ins_profPhoto:"https://res.cloudinary.com/donas/image/upload/v1589353044/gbooij0ehivnf5hqzlez.png",
  ins_arn:'',
  ins_notes:'',
  ins_id:'',
  wing_id:'',
  rego:'',
  wing_type:'',
  wing_counter:'',
  wingLastService:'',
  wingLastServiceNotes:'',
  fl_date:'',
  fl_time:'',
  fl_clock:'',
  current_date:'',
  pfk_bool:'',
  nextLesson_bool:'',
  commts:'',
  open: false,
  formId:'',
  formRating:'',
  fName:'',
  mNames:'',
  lName:'',
  dob:'',
  email:'',
  mAddress:'',
  rAddress:'',
  phone:'',
  nextKin:'',
  nextKinPhone:'',
  cWeight:'',
  LicType:'',
  accNotes:'',
  bloodType:'',
  passportPhoto:'',
  suggestionsStu:'',
  searchJson:'',
  returnedAccountInfo:'',
  searchKeyword:{stu_id:' ',fname:' ', lname:' '},
  tillBalance:'',
  stu_id_edit:'',
  suggestionsWings:'',
  suggestionsIns:'',
  amountPaid:'0.0',
  transId:'',
  transTimeStamp:Date.now(),
  transNotes:'',
  newBalance:'',
  loggedInUser:{email:' ',keypass:' ', accessLevel:'i',fName:' ', lName:' ',profilePic:' ', isTemp:' '},
  transactions:'',
  loading:'false',
  signatureUpload:'null',
  ins_Signature:'null',
  stu_Signature:'null',
  psp1:'',
  accessLevel:'',
  kiHash:'',
  xs_level:'',
  passTemp:'',
  isTemp:'',
  soloGH:'0',
  soloNav:'0',
  dualGH:'0',
  dualNav:'0',
  flightRecords:[{takeOffDateTime: "",recordId: "",formId: "",ins_id: " ",soloGhrs: "",soloNavHrs: "",dualGhrs: "",dualNavHrs: "",flightTime: "",costOfLesson: "",comments: ""}],
  day14History:[{soloGhrs:'',soloNavHrs:'',dualGhrs:'',dualNavHrs:''}],
  day30History:[{soloGhrs:'',soloNavHrs:'',dualGhrs:'',dualNavHrs:''}],
  day90History:[{soloGhrs:'',soloNavHrs:'',dualGhrs:'',dualNavHrs:''}],
  lessonNo:'',
  recordId:'',
  appBarTitle:'JEFF',
  flightType:'',
  fuelStart:'0',
  dualtotal:'0',
  picTotal:'0',
  medical1:moment().format('MM/DD/YYYY'),
  medical2:moment().format('MM/DD/YYYY'),
  medical3:moment().format('MM/DD/YYYY'),
  medical1Checked:false,
  medical2Checked:false,
  medical3Checked:false,
  creditCardName: "",
  creditCardNumber: "",
  creditCardExpiry:"",
  creditCardCVC:"",
  ccNameEnc   :"",
  ccNumberEnc   :"",
  ccExpEnc    :"",
  ccCVCEnc    :"",
  passReset   :"",
  pax1name    :"",
  pax1W       :"",
  pax2name    :"",
  pax2W       :"",
  pax3name    :"",
  pax3W       :"",
  pax4name    :"",
  pax4W       :"",
  pax5name    :"",
  pax5W       :"",
  pax6name    :"",
  pax6W       :"",
  status      :"TP",
  preflightAuthId:"",
  CLicType:"",
  banner:"",
  pilotName:"",
  emailChanged   : false,
  oldEmail: "",
  costOfLesson:0,
  lessonsProgress:[],
  token:''







}


clearState = () =>{
  this.setState({
    lessonCost:'0.0',
    costPerHour:'',
    stu_name:'',
    stu_arn:'',
    profilePic:'null',
    ins_fname:'',
    ins_mNames:'',
    ins_lname:'',
    ins_btype:'',
    ins_mAddress:'',
    ins_rAddress:'',
    ins_tpNo:'',
    ins_email:'',
    ins_nextKin:'',
    ins_nextKinPhone:'',
    ins_cWeight:'',
    ins_medicalExpiry:moment().toDate(),
    ins_dob:'Mon Jan 01 1990 00:00:00 GMT+1100 (Australian Eastern Daylight Time)',
    identName:'',
    ins_PassPhoto:'',
    ins_profPhoto:'',
    ins_arn:'',
    ins_id:'',
    ins_notes:'',
    wing_id:'',
    rego:'',
    wing_type:'',
    wing_counter:'',
    wingLastService:current_time,
    wingLastServiceNotes:'',
    fl_date:moment().toDate(),
    fl_time:moment().toDate(),
    fl_clock:'',
    current_date:'',
    pfk_bool:'YES',
    nextLesson_bool:'YES',
    commts:'',
    open: false,
    formId:'',
    formRating:[{cid: '', rate: '', condition: ''}],
    fName:'',
    mNames:'',
    lName:'',
    dob:'Mon Jan 01 1990 00:00:00 GMT+1100 (Australian Eastern Daylight Time)',
    email:'',
    mAddress:'',
    rAddress:'',
    phone:'',
    nextKin:'',
    nextKinPhone:'',
    cWeight:'',
    LicType:'',
    accNotes:'',
    bloodType:'',
    passportPhoto:Template,
    suggestionsStu:'',
    searchJson:'',
    returnedAccountInfo:'',
    searchKeyword:{stu_id:' ',fname:' ', lname:' '},
    tillBalance:'',
    stu_id_edit:'',
    suggestionsWings:'',
    suggestionsIns:'',
    amountPaid:'0.0',
    transId:'',
    transTimeStamp:Date.now(),
    transNotes:'',
    newBalance:'',
    transactions:[{transId: '', type: '', amount: '', entryTimeStamp: '', paymentTimeStamp: '', accId: '', accName: '', transDesc: '', processedBy: '', eftposId: '', balanceAfter: ''}],
    loading:'false',
    signatureUpload:'null',
    ins_Signature:'null',
    stu_Signature:'null',
    psp1:'',
    kiHash:'',
    xs_level:'',
    passTemp:'',
    isTemp:'',
    soloGH:'0',
    soloNav:'0',
    dualGH:'0',
    dualNav:'0',
    flightRecords:[{takeOffDateTime: "",recordId: "",formId: "",ins_id: " ",soloGhrs: "",soloNavHrs: "",dualGhrs: "",dualNavHrs: "",flightTime: "",costOfLesson: "",comments: ""}],
    day14History:[{soloGhrs:'',soloNavHrs:'',dualGhrs:'',dualNavHrs:''}],
    day30History:[{soloGhrs:'',soloNavHrs:'',dualGhrs:'',dualNavHrs:''}],
    day90History:[{soloGhrs:'',soloNavHrs:'',dualGhrs:'',dualNavHrs:''}],
    lessonNo:'',
    recordId:'',
    appBarTitle:'JEFF',
    flightType:'',
    fuelStart:'0',
    dualtotal:'0',
    picTotal:'0',
    medical1:moment().format('MM/DD/YYYY'),
    medical2:moment().format('MM/DD/YYYY'),
    medical3:moment().format('MM/DD/YYYY'),
    medical1Checked:false,
    medical2Checked:false,
    medical3Checked:false,
    creditCardName: "",
    creditCardNumber: "",
    creditCardExpiry:"",
    creditCardCVC:"",
    ccNameEnc:"",
    ccNumberEnc:"",
    ccExpEnc:"",
    ccCVCEnc:"",
    passReset   :"",
    pax1name    :"",
    pax1W       :"",
    pax2name    :"",
    pax2W       :"",
    pax3name    :"",
    pax3W       :"",
    pax4name    :"",
    pax4W       :"",
    pax5name    :"",
    pax5W       :"",
    pax6name    :"",
    pax6W       :"",
    status      :"TP",
    preflightAuthId:"",
    CLicType:"",
    banner:"",
    pilotName:"",
    emailChanged   : false,
    oldEmail: "",
    costOfLesson:0,
    lessonsProgress:[],





  })
}
//proceed to next step
nextStep = () => {
  const {step} = this.state;
  this.setState({
    step: step + 1
  });
}
//back to previous step
prevStep = () => {
  const {step} = this.state;
  this.setState({
    step: step - 1
  });
}

startAgain = () => {
  const {step} = this.state;
  this.setState({
    step: 1004
  });
  this.clearState();
}
startAgainFleetMgmt = () => {
  const {step} = this.state;
  this.setState({
    step: 5000
  });
  this.clearState();
}
startAgainAcctMgmt = () => {
  const {step} = this.state;
  this.setState({
    step: 2000
  });
  this.clearState();
}
startAgainFinanceMgmt = () => {
  const {step} = this.state;
  this.setState({
    step: 6000
  });
  this.clearState();
}

startAgainInsMgmt = () => {
  const {step} = this.state;
  this.setState({
    step: 4000
  });
  this.clearState();
}
startAgaintrainingMgmt= () => {
  const {step} = this.state;
  this.setState({
    step: 2999
  });
  this.clearState();
}

setFormId= (id) => {
  const {formId} = this.state;
  this.setState({
    formId: id})
  }

setRadio= (id) => {
  const {formRating,fName} = this.state;
  this.setState({loading:"true"});
  fetch(`${process.env.REACT_APP_SERVER_URL}/getLessonCondsDB?formId=${id}`,{
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{console.log(response.data);
                  this.setState({formRatingCOND:response.data})
                })
  .catch(err=>console.error(err))
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getLessonCondsDBRatingOnly?formId=${id}`,{
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{console.log(response.data);
                  this.setState({formRating:response.data,loading:"false"})
                })
  .catch(err=>console.error(err)))

}
changeButton = (cid)=> e =>{
  e.preventDefault();
  this.handleChangeRadio(cid,e.target.value);


}
createRadioGroups = ()=>{
  console.log("createRadioGroups is called")
  const {formRatingCOND} = this.state;

  if(formRatingCOND.length>1){
  return(formRatingCOND.map(
    item =>
    <Grid container>
          <Grid item xs={2} style={{marginTop:20, marginRight:0}}>{item.cid} </Grid>

          <Grid item xs={6} style={{marginTop:20}}>{item.condition} </Grid>
            <Grid item xs={4} style={{marginTop:10}}>
              <RadioGroup defaultValue={item.rate} name={item.cid}  onChange={this.changeButton(item.cid)} style={{display: 'flex', flexDirection: 'row'}}>
                <FormControlLabel value="3" control={<Radio color="primary" />} label=' ' labelPlacement="top"/>
                <FormControlLabel value="2" control={<Radio color="primary" />}label=' ' labelPlacement="top"/>
                <FormControlLabel value="1" control={<Radio color="primary" />}label=' ' labelPlacement="top"/>
                <FormControlLabel value="0" control={<Radio color="primary" />}label=' ' labelPlacement="top"/>
              </RadioGroup>
            </Grid>

        </Grid>

  ))} else {

      this.setStep(3007);

  }
};

createRadioGroupsEdit = ()=>{
  console.log("createRadioGroups is called")
  const {formRatingCOND} = this.state;

  if(formRatingCOND.length>1){
  return(formRatingCOND.map(
    item =>
    <Grid container>
          <Grid item xs={2} style={{marginTop:20, marginRight:0}}>{item.cid} </Grid>

          <Grid item xs={6} style={{marginTop:20}}>{item.condition} </Grid>
            <Grid item xs={4} style={{marginTop:10}}>
              <RadioGroup defaultValue={item.rate} name={item.cid}  onChange={this.changeButton(item.cid)} style={{display: 'flex', flexDirection: 'row'}}>
                <FormControlLabel value="3" control={<Radio color="primary" />} label=' ' labelPlacement="top"/>
                <FormControlLabel value="2" control={<Radio color="primary" />}label=' ' labelPlacement="top"/>
                <FormControlLabel value="1" control={<Radio color="primary" />}label=' ' labelPlacement="top"/>
                <FormControlLabel value="0" control={<Radio color="primary" />}label=' ' labelPlacement="top"/>
              </RadioGroup>
            </Grid>

        </Grid>

  ))} else {

      this.setStep(3209);

  }
};


displayTransactions = ()=>{
  console.log("displayTransactions is called")
  const {transactions} = this.state;
  console.log(transactions)

return ( transactions.map(
    (item, ndx) => {
      console.log(ndx);
      const date=moment(parseInt(item.paymentTimeStamp)).format("DD/MM/YYYY");
      const dateStamp=date;
      let nextBalance;
      let displayBalance;

      if(item.type=="Debit"){
        if (ndx==0) {
          item.balanceCarrying=parseFloat(this.state.tillBalance).toFixed(2);

        }

          nextBalance= parseFloat(item.balanceCarrying) + parseFloat(item.amount );
          transactions.map((x,y)=>{if(y==ndx+1){ x.balanceCarrying = parseFloat(nextBalance).toFixed(2) }});



      return(
    <Grid container>


          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.transId}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.eftposId}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{dateStamp}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.type}</Typography></Grid>
          <Grid item xs={3} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.transDesc}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">$ {parseFloat(item.amount).toFixed(2)}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">$ {item.balanceCarrying}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">
          <FormControlLabel
            control={
              <Checkbox style={{display:this.state.loggedInUser.accessLevel== "sa" ? "":"none"}} disabled={this.state.loggedInUser.accessLevel== "sa" ? false:true} checked={item.invoiced == "true" ? true:false} onChange={this.handleChangeInvoiced(item.transId)}  />
            }

            /></Typography></Grid>


        </Grid>)
      }else{
        if (ndx==0) {
          item.balanceCarrying=parseFloat(this.state.tillBalance).toFixed(2);

        }

        nextBalance= parseFloat(item.balanceCarrying) - parseFloat(item.amount );
        transactions.map((x,y)=>{if(y==ndx+1){ x.balanceCarrying = parseFloat(nextBalance).toFixed(2) }});


        return(

        <Grid container>


              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">{item.transId}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">{item.eftposId}</Typography></Grid>
              <Grid item xs={2} style={{marginTop:20}}><Typography variant="caption">{dateStamp}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">{item.type}</Typography></Grid>
              <Grid item xs={3} style={{marginTop:20}}><Typography variant="caption">{item.transDesc}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">$ {parseFloat(item.amount).toFixed(2)}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">$ {item.balanceCarrying}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">
              <FormControlLabel
                control={
                  <Checkbox style={{display:this.state.loggedInUser.accessLevel== "sa" ? "":"none"}} disabled={this.state.loggedInUser.accessLevel== "sa" ? false:true} checked={item.invoiced == "true" ? true:false} onChange={this.handleChangeInvoiced(item.transId)}  />
                }

                /></Typography></Grid>

            </Grid>)



      }}

  ))
  };

  handleDoNotShowAgain=(email)=>{
    console.log(email);
    fetch(`${process.env.REACT_APP_SERVER_URL}/updateBanner?email=${email}`,{
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(console.log("success"))

  }
updateInvoicedFlights = (transId)=> e =>{
  if(e.target.checked==true){
  const valueChecked="true";
  console.log(transId,valueChecked);
  fetch(`${process.env.REACT_APP_SERVER_URL}/updateInvoicedFlights?invoiced=${valueChecked}&transId=${transId}`,{
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(
    this.setState(prevState => ({

    flightRecords: prevState.flightRecords.map(
      el => el.recordId === transId? { ...el, invoiced: valueChecked }: el)


  })))

}else{
    const valueChecked="";
    console.log(transId,valueChecked);
    fetch(`${process.env.REACT_APP_SERVER_URL}/updateInvoicedFlights?invoiced=${valueChecked}&transId=${transId}`,{
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(
      this.setState(prevState => ({

      flightRecords: prevState.flightRecords.map(
        el => el.recordId === transId? { ...el, invoiced: valueChecked }: el)


    })))
  }

}




  handleChangeInvoiced = (transId)=> e =>{
    if(e.target.checked==true){
    const valueChecked="true";
    console.log(transId,valueChecked);
    fetch(`${process.env.REACT_APP_SERVER_URL}/updateInvoiced?invoiced=${valueChecked}&transId=${transId}`,{
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(
      this.setState(prevState => ({

      transactions: prevState.transactions.map(
        el => el.transId === transId? { ...el, invoiced: valueChecked }: el)


    })))

  }else{
      const valueChecked="";
      console.log(transId,valueChecked);
      fetch(`${process.env.REACT_APP_SERVER_URL}/updateInvoiced?invoiced=${valueChecked}&transId=${transId}`,{
        headers: {
        'x-access-token': this.state.token
      }})
      .then(response => response.json())
      .then(
        this.setState(prevState => ({

        transactions: prevState.transactions.map(
          el => el.transId === transId? { ...el, invoiced: valueChecked }: el)


      })))
    }

  }

  handleChangeChecked =(transId,valueChecked) =>{


  }

displayAllTransactions = ()=>{
  console.log("displayAllTransactions is called")
  const {transactions} = this.state;
  console.log(transactions)

  return(transactions.map(
    item => {
      const date=moment(parseInt(item.paymentTimeStamp)).format("DD/MM/YYYY");
      const dateStamp=date.toLocaleString();

      if(item.type=="Debit"){

      return(
    <Grid container>


          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.transId}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.accName}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.eftposId}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{dateStamp}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.type}</Typography></Grid>
          <Grid item xs={3} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">{item.transDesc}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:20}}><Typography style={{color:"red"}} variant="caption">$ {item.amount}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <RaisedButton onClick={this.handleEditTransaction(item.transId,item.accName,item.eftposId,dateStamp,item.type,item.transDesc,item.amount)} id={item.transId} label="Edit"/></Grid>


        </Grid>)
      }else{

        return(

        <Grid container>


              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">{item.transId}</Typography></Grid>
              <Grid item xs={2} style={{marginTop:20}}><Typography variant="caption">{item.accName}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">{item.eftposId}</Typography></Grid>
              <Grid item xs={2} style={{marginTop:20}}><Typography variant="caption">{dateStamp}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">{item.type}</Typography></Grid>
              <Grid item xs={3} style={{marginTop:20}}><Typography variant="caption">{item.transDesc}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:20}}><Typography variant="caption">$ {item.amount}</Typography></Grid>
              <Grid item xs={1} style={{marginTop:10}}> <RaisedButton onClick={this.handleEditTransaction(item.transId,item.accName,item.eftposId,dateStamp,item.type,item.transDesc,item.amount)} id={item.transId} label="Edit"/></Grid>


            </Grid>)



      }}

  ))




};
displayFlightHistory2= (dateFrom,dateTo)=>{
  console.log("displayFlightHistory is called")
  const {flightRecords} = this.state;
  console.log(flightRecords)
  let count = 0;

  return(flightRecords.map(
    item => {
      count = count+1;


      const date=new Date(parseInt(item.takeOffDateTime));

      const dateStamp=date.toLocaleString();
      const dateFromInt=parseInt(dateFrom);
      const dateToInt = parseInt(dateTo)
      const dateOfFlight = parseInt(item.takeOffDateTime);




      if (dateFromInt<=dateOfFlight && dateOfFlight<=dateToInt) {




return(

    <Grid container style={{backgroundColor:count%2==1?"#e0e0de":"none"}}>


          <Grid item xs={1} style={{marginLeft:10}}><Typography style={{color:"red"}} variant="caption">
          <FormControlLabel
            control={
              <Checkbox style={{display:this.state.loggedInUser.accessLevel== "sa" ? "":"none"}} disabled={this.state.loggedInUser.accessLevel== "sa" ? false:true} checked={item.invoiced == "true" ? true:false} onChange={this.updateInvoicedFlights(item.recordId)}  />
            }

            /></Typography> <Typography variant="caption">{item.recordId}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.stu_id}<br></br>{item.accName}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{dateStamp}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.formId}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.rego} <br></br>{item.ins_name}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.soloGhrs}  SGH &emsp;{item.soloNavHrs}  SN <br></br>{item.dualGhrs}  DGH &emsp;{item.dualNavHrs}  DN</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.flightTime}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">$ {item.costOfLesson}</Typography></Grid>
          <Grid item xs={3} style={{marginTop:10, marginBottom:10, marginRight:10}}> <Typography variant="caption">{item.comments}</Typography></Grid>


        </Grid>)}



      }))

};


displayFlightHistory = ()=>{
  console.log("displayFlightHistory is called")
  const {flightRecords} = this.state;
  console.log(flightRecords)
  let count = 0;

  return(flightRecords.map(
    item => {

      count = count+1;


      const date=new Date(parseInt(item.takeOffDateTime));
      const dateStamp=date.toLocaleString();

return(
  <Grid container style={{backgroundColor:count%2==1?"#e0e0de":"none"}}>


    <Grid item xs={1} style={{marginLeft:10}}><Typography style={{color:"red"}} variant="caption">
    <FormControlLabel
      control={
        <Checkbox style={{display:this.state.loggedInUser.accessLevel== "sa" ? "":"none"}} disabled={this.state.loggedInUser.accessLevel== "sa" ? false:true} checked={item.invoiced == "true" ? true:false} onChange={this.updateInvoicedFlights(item.recordId)}  />
      }

      /></Typography> <Typography variant="caption">{item.recordId}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{dateStamp}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.formId}.{item.lessonNo}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.rego} <br></br> {item.ins_name}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.soloGhrs}  SGH &emsp;{item.soloNavHrs}  SN <br></br>{item.dualGhrs}  DGH &emsp;{item.dualNavHrs}  DN</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.flightTime}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">$ {item.costOfLesson}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:10, marginBottom:10}}> <Typography variant="caption">{item.comments}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, marginBottom:10,marginLeft:10}}> <RaisedButton onClick={this.handleViewRecord(item.recordId,item.stu_id,item.formId,item.lessonNo,item.AccName,item.takeOffDateTime,item.ins_id,item.rego,item.dualGhrs,item.soloGhrs,item.dualNavHrs,item.soloNavHrs,item.flightTime,item.dateOfRecord,item.ratings,item.costOfLesson,item.preflightBool,item.nextLessonBool,item.comments,item.preflightAuthId)} id={item.recordId} label="Open"/></Grid>

        </Grid>)}))

};
displayPendingPreFlightAuths = ()=>{
  const {flightRecords} = this.state;
  if (flightRecords.length>0) {



  return(flightRecords.map(
    item => {

      const date=new Date(parseInt(item.timeStamp));
      const dateStamp=date.toLocaleString();

return(
    <Grid container >


          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.id}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{moment(item.authTime,'MM/DD/YYYY').format('DD/MM/YYYY')}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.name}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.authByname}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.fuelAtStart}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.dualHrs}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.picHrs}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.rego}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.description}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <RaisedButton primary onClick={this.handleSelectPreFlight(item.id,item.email,item.authEmail,item.rego,item.status,item.fType,item.authTime,item.pilotName,item.fuelAtStart)} id={item.id} label="Select"/></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <RaisedButton primary onClick={this.handleSelectPreFlightChangeStatus(item.id,item.name,item.email,item.authByname,item.authEmail,item.fuelAtStart,item.dualHrs,item.picHrs,item.rego,item.description,item.rAddress,item.authTime,item.manifest,item.status,item.fType,item.pilotName)} id={item.id} label="Finalized"/></Grid>

        </Grid>)}))}else {
          return(
            <Grid container >
            <Grid item xs={12} style={{marginTop:10}}> <Typography variant="caption">Nothing to Display</Typography></Grid>

            </Grid>
          )
        }

};

displayPendingCharterPreFlightAuths = ()=>{
  const {flightRecords} = this.state;
  if (flightRecords.length>0) {



  return(flightRecords.map(
    item => {
      if (item.status=="P") {



      const date=new Date(parseInt(item.timeStamp));
      const dateStamp=date.toLocaleString();

return(
    <Grid container >


          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.id}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{moment(item.authTime,'MM/DD/YYYY').format('DD/MM/YYYY')}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.name}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.authByname}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.fuelAtStart}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.dualHrs}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.picHrs}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.rego}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.description}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <RaisedButton primary onClick={this.handleEditCharterPreFlight(item.id,item.name,item.email,item.authByname,item.authEmail,item.fuelAtStart,item.dualHrs,item.picHrs,item.rego,item.description,item.rAddress,item.authTime,item.manifest,item.status,item.fType,item.pilotName)} id={item.id} label="Finalize/Edit"/></Grid>

        </Grid>)}}))}else {
          return(
            <Grid container >
            <Grid item xs={12} style={{marginTop:10}}> <Typography variant="caption">Nothing to Display</Typography></Grid>

            </Grid>
          )
        }

};

displayDailyPreFlightAuths = ()=>{
  const {flightRecords} = this.state;
  if (flightRecords.length>0) {



  return(flightRecords.map(
    item => {




      const date=new Date(parseInt(item.timeStamp));
      const dateStamp=date.toLocaleString();

return(
    <Grid container >


          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.id}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{moment(item.authTime,'MM/DD/YYYY').format('DD/MM/YYYY')}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.name}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10}}> <Typography variant="caption">{item.authByname}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.fuelAtStart}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.dualHrs}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.picHrs}</Typography></Grid>
          <Grid item xs={1} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.rego}</Typography></Grid>
          <Grid item xs={2} style={{marginTop:10, paddingLeft:10}}> <Typography variant="caption">{item.description}</Typography></Grid>

          <Grid item xs={1} style={{marginTop:10}}> <RaisedButton primary onClick={this.handleEditCharterPreFlight(item.id,item.name,item.email,item.authByname,item.authEmail,item.fuelAtStart,item.dualHrs,item.picHrs,item.rego,item.description,item.rAddress,item.authTime,item.manifest,item.status,item.fType,item.pilotName)} id={item.id} label="Edit"/></Grid>

        </Grid>)}))}else {
          return(
            <Grid container >
            <Grid item xs={12} style={{marginTop:10}}> <Typography variant="caption">Nothing to Display</Typography></Grid>

            </Grid>
          )
        }

};


getSoloGHTots=e => {
  const {flightRecords} = this.state;
  return(flightRecords.reduce(function(tot,arr){
    return tot+(Math.round(arr.soloGhrs*10))/10;
  },0))}

getSoloNavTots=e => {
  const {flightRecords} = this.state;
  return(flightRecords.reduce(function(tot,arr){
    return tot+(Math.round(arr.soloNavHrs*10))/10;
  },0))}


getDualGHTots=e => {
  const {flightRecords} = this.state;
  return(flightRecords.reduce(function(tot,arr){
    return tot+(Math.round(arr.dualGhrs*10))/10;
  },0))}


getDualNavTots=e => {
  const {flightRecords} = this.state;
  return(flightRecords.reduce(function(tot,arr){
    return tot+(Math.round(arr.dualNavHrs*10))/10;
  },0))}

getFlightTimeTots=e => {
  const {flightRecords} = this.state;
  return(flightRecords.reduce(function(tot,arr){
    return tot+(Math.round(arr.flightTime*10))/10;
  },0))}


getCostTots=e => {
  const {flightRecords} = this.state;
  return(flightRecords.reduce(function(tot,arr){
    return tot+arr.costOfLesson;
  },0))}



//------------------------------------

  get14SoloGHTots=e => {
    const {day14History} = this.state;
    return(day14History.reduce(function(tot,arr){
      return tot+(Math.round(arr.soloGhrs*10))/10;
    },0))}

  get14SoloNavTots=e => {
    const {day14History} = this.state;
    return(day14History.reduce(function(tot,arr){
      return tot+(Math.round(arr.soloNavHrs*10))/10;
    },0))}


  get14DualGHTots=e => {
    const {day14History} = this.state;
    return(day14History.reduce(function(tot,arr){
      return tot+(Math.round(arr.dualGhrs*10))/10;
    },0))}


  get14DualNavTots=e => {
    const {day14History} = this.state;
    return(day14History.reduce(function(tot,arr){
      return tot+(Math.round(arr.dualNavHrs*10))/10;
    },0))}

  get14FlightTimeTots=e => {
    const {day14History} = this.state;
    return(day14History.reduce(function(tot,arr){
      return tot+(Math.round(arr.flightTime*10))/10;
    },0))}
//------------------------------------



  get30SoloGHTots=e => {
    const {day30History} = this.state;
    return(day30History.reduce(function(tot,arr){
      return tot+(Math.round(arr.soloGhrs*10))/10;
    },0))}

  get30SoloNavTots=e => {
    const {day30History} = this.state;
    return(day30History.reduce(function(tot,arr){
      return tot+(Math.round(arr.soloNavHrs*10))/10;
    },0))}


  get30DualGHTots=e => {
    const {day30History} = this.state;
    return(day30History.reduce(function(tot,arr){
      return tot+(Math.round(arr.dualGhrs*10))/10;
    },0))}


  get30DualNavTots=e => {
    const {day30History} = this.state;
    return(day30History.reduce(function(tot,arr){
      return tot+(Math.round(arr.dualNavHrs*10))/10;
    },0))}

  get30FlightTimeTots=e => {
    const {day30History} = this.state;
    return(day30History.reduce(function(tot,arr){
      return tot+(Math.round(arr.flightTime*10))/10;
    },0))}




    get90SoloGHTots=e => {
      const {day90History} = this.state;
      return(day90History.reduce(function(tot,arr){
        return tot+(Math.round(arr.soloGhrs*10))/10;
      },0))}

    get90SoloNavTots=e => {
      const {day90History} = this.state;
      return(day90History.reduce(function(tot,arr){
        return tot+(Math.round(arr.soloNavHrs*10))/10;
      },0))}


    get90DualGHTots=e => {
      const {day90History} = this.state;
      return(day90History.reduce(function(tot,arr){
        return tot+(Math.round(arr.dualGhrs*10))/10;
      },0))}


    get90DualNavTots=e => {
      const {day90History} = this.state;
      return(day90History.reduce(function(tot,arr){
        return tot+(Math.round(arr.dualNavHrs*10))/10;
      },0))}

    get90FlightTimeTots=e => {
      const {day90History} = this.state;
      return(day90History.reduce(function(tot,arr){
        return tot+(Math.round(arr.flightTime*10))/10;
      },0))}

handlePicChangeIns =(files) => {

    this.setState({ ins_profPhoto: files })
    console.log(files)
  }


handlePicChange =(files) => {

    this.setState({ profilePic: files })
    console.log(files)
  }

uploadProfilePicIns = (step)=> {

    this.setState({step:0})

    const files = Array.from(this.state.ins_profPhoto)
    const formData = new FormData()
    files.forEach((file, i) => {
      formData.append(i, file)})

      fetch(`${process.env.REACT_APP_SERVER_URL}/image-upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'x-access-token': this.state.token
        }
      })
      .then(res => res.json())
      .then(images => {
        this.setState({ins_profPhoto: images[0].secure_url,
                      step:step  })
      })
    }


uploadProfilePic = (step)=> {

  this.setState({step:0})

  const files = Array.from(this.state.profilePic)
  const formData = new FormData()
  files.forEach((file, i) => {
    formData.append(i, file)})

    fetch(`${process.env.REACT_APP_SERVER_URL}/image-upload`, {
      method: 'POST',
      body: formData,
      headers: {
        'x-access-token': this.state.token
      }
    })
    .then(res => res.json())
    .then(images => {
      this.setState({profilePic: images[0].secure_url,
                    step:step  })
    })
  }


  uploadPassportImageIns= (step) => {

    this.setState({step:0})

    const files = Array.of(this.state.ins_PassPhoto)
    const formData = new FormData()
    files.forEach((file, i) => {
    formData.append(i, file)})

    fetch(`${process.env.REACT_APP_SERVER_URL}/imageUploadPassport`, {
          method: 'POST',
          body: formData,
          headers: {
            'x-access-token': this.state.token
          }
        })
        .then(res => res.json())
        .then(images => {
          this.setState({ins_PassPhoto: images[0].secure_url,
                        step:step  })
        })
      }

uploadPassportImage= (step) => {

  this.setState({step:0})

  const files = Array.of(this.state.passportPhoto)
  const formData = new FormData()
  files.forEach((file, i) => {
  formData.append(i, file)})

  fetch(`${process.env.REACT_APP_SERVER_URL}/imageUploadPassport`, {
        method: 'POST',
        body: formData,
        headers: {
          'x-access-token': this.state.token
        }
      })
      .then(res => res.json())
      .then(images => {
        this.setState({passportPhoto: images[0].secure_url,
                      step:step  })
      })
    }



  uploadSignatureImage = (step) => {
    this.setState({step:0})

      const files = Array.of(this.state.signatureUpload)
      const formData = new FormData()
      files.forEach((file, i) => {
      formData.append(i, file)})

      fetch(`${process.env.REACT_APP_SERVER_URL}/imageUploadPassport`, {
            method: 'POST',
            body: formData,
            headers: {
              'x-access-token': this.state.token
            }
          })
          .then(res => res.json())
          .then(images => {
            this.setState({signatureUpload: images[0].secure_url,
                          step:step  })
          })
        }


addStudent= e => {
      this.setState({step:0})

      fetch(`${process.env.REACT_APP_SERVER_URL}/addStudent?stuId=${this.state.accNotes}&arn=${this.state.stu_arn}&fname=${this.state.fName}&lname=${this.state.lName}&othname=${this.state.mNames}&dob=${this.state.dob}&mAddress=${this.state.mAddress}&rAddress=${this.state.rAddress}&tpNo=${this.state.phone}&email=${this.state.email}&bloodType=${this.state.bloodType}&nextOfKin=${this.state.nextKin}&nextOfKinContact=${this.state.nextKinPhone}&currentWeight=${this.state.cWeight}&licType=${this.state.LicType}&accountNotes=${this.state.accNotes}&profPicURL=${this.state.profilePic}&passportURL=${this.state.passportPhoto}&signatureURL=${this.state.signatureUpload}&medical1=${this.state.medical1}&medical2=${this.state.medical2}&medical3=${this.state.medical3}&cLicType=${this.state.CLicType}`,{
        headers: {
        'x-access-token': this.state.token
      }})


      .then(res=>res.json())
      .then(res=>{console.log(res.data);fetch(`${process.env.REACT_APP_SERVER_URL}/addAccess?email=${this.state.email}&id=${res.data}&kiHash=${this.state.kiHash}&xsLevel=${this.state.xs_level}&fname=${this.state.fName}&lname=${this.state.lName}&profPicURL=${this.state.profilePic}&isTemp=${this.state.isTemp}`,{
        headers: {
        'x-access-token': this.state.token
      }})})
      .then(fetch(`${process.env.REACT_APP_SERVER_URL}/addCCinfo?email=${this.state.email}&name=${this.state.ccNameEnc}&number=${this.state.ccNumberEnc}&expiry=${this.state.ccExpEnc}&cvc=${this.state.ccCVCEnc}`,{
        headers: {
        'x-access-token': this.state.token
      }}))
      .catch(err=>console.error(err))



      .then(this.setState({step:2009  }))




    }
updateCardinfo=step=>{
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/updateCCinfo?email=${this.state.email}&name=${this.state.ccNameEnc}&number=${this.state.ccNumberEnc}&expiry=${this.state.ccExpEnc}&cvc=${this.state.ccCVCEnc}`,{
    headers: {
    'x-access-token': this.state.token
  }})
  .catch(err=>console.log(err))
  .then(this.setState({step:step}))

}


cCEncrpt=e=>{

var nameCipher1=sjcl.encrypt(encKey, this.state.creditCardName);
console.log("object: ",nameCipher1);
var stringified =JSON.stringify(nameCipher1);
var jsonCypher = JSON.parse(stringified);

var plaintextCypher= sjcl.decrypt(encKey, jsonCypher);
console.log(plaintextCypher);


var nameCipher = sjcl.encrypt(encKey, this.state.creditCardName);
var numberCipher = sjcl.encrypt(encKey, this.state.creditCardNumber);
var expiryCipher = sjcl.encrypt(encKey, this.state.creditCardExpiry);
var cvcCipher = sjcl.encrypt(encKey, this.state.creditCardCVC);
console.log(nameCipher);
console.log(numberCipher);
console.log(expiryCipher);
console.log(cvcCipher);

this.setState({ ccNameEnc   : encodeURIComponent(nameCipher),
                ccNumberEnc : encodeURIComponent(numberCipher),
                ccExpEnc    : encodeURIComponent(expiryCipher),
                ccCVCEnc    : encodeURIComponent(cvcCipher),

                })
}




getCCInfo=(step)=> {
  console.log("Fetching CC info")

  const { values,searchKeyword,searchJson} = this.state;

  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getCC?usi=${this.state.email}`,{
    headers: {
    'x-access-token': this.state.token
  }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(response=>{

    const ccNameEnc = response.data[0].name;
    const ccNumberEnc = response.data[0].num;
    const ccExpEnc = response.data[0].exp;
    const ccCVCEnc = response.data[0].verification;

    const ccName = sjcl.decrypt(encKey, ccNameEnc);
    const ccNumber = sjcl.decrypt(encKey, ccNumberEnc);
    const ccExp = sjcl.decrypt(encKey, ccExpEnc);
    const ccCVC = sjcl.decrypt(encKey, ccCVCEnc);

    this.setState({
      creditCardName   :ccName,
      creditCardNumber :ccNumber,
      creditCardExpiry :ccExp.replace(" / ",""),
      creditCardCVC    :ccCVC,


    })

  })
  .then(this.setState({step:step}))



}


getCCExpiry=(step)=> {
  console.log("Fetching CC info")

  const { values,searchKeyword,searchJson} = this.state;

  fetch(`${process.env.REACT_APP_SERVER_URL}/getCCExp?usi=${searchKeyword.email}`,{
    headers: {
    'x-access-token': this.state.token
  }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(response=>{

    const ccExpEnc = response.data[0].exp;

    const ccExp = sjcl.decrypt(encKey, ccExpEnc);
    const mmyy=ccExp.split(" / ");
    const mm=mmyy[0];
    const yyyy="20"+mmyy[1];
    const dd="01"
    const expdate=dd+"/"+mm+"/"+yyyy;
    const expdateUnix=moment(expdate, 'DD.MM.YYYY').unix();
    console.log(expdateUnix)

    this.setState({
      creditCardExpiry :expdateUnix,
      step:step,


    })

  })



}



cCDecrpt=e=>{
console.log("decrypting...")


  const nameCipher    =this.state.ccNameEnc;
  const numberCipher  =this.state.ccNumberEnc;
  const expiryCipher  =this.state.ccExpEnc;
  const cvcCipher     =this.state.ccCVCEnc;

  console.log(this.state.ccNameEnc);
  console.log(this.state.ccNumberEnc);
  console.log(this.state.ccExpEnc);
  console.log(this.state.ccCVCEnc);


var namebytes  = CryptoJS.AES.decrypt(nameCipher.toString(), 'secretkey123');
var numberbytes  = CryptoJS.AES.decrypt(numberCipher.toString(), 'secretkey123');
var expirybytes  = CryptoJS.AES.decrypt(expiryCipher.toString(), 'secretkey123');
var cvcbytes  = CryptoJS.AES.decrypt(cvcCipher.toString(), 'secretkey123');

console.log(namebytes);
console.log(numberbytes);
console.log(expirybytes);
console.log(cvcbytes);

var plaintextnamebytes = namebytes.toString(CryptoJS.enc.Utf8);
var plaintextnumberbytes = numberbytes.toString(CryptoJS.enc.Utf8);
var plaintextexpirybytes = expirybytes.toString(CryptoJS.enc.Utf8);
var plaintextcvcbytes = cvcbytes.toString(CryptoJS.enc.Utf8);

console.log(plaintextnamebytes);
console.log(plaintextnumberbytes);
console.log(plaintextexpirybytes);
console.log(plaintextcvcbytes);

this.setState({ creditCardName:plaintextnamebytes,
                creditCardNumber:plaintextnumberbytes,
                creditCardExpiry:plaintextexpirybytes.replace(" / ",""),
                creditCardCVC:plaintextcvcbytes,})

}


handleChangeRadio =(cid,newRate) =>{
  this.setState(prevState => ({

  formRating: prevState.formRating.map(
    el => el.cid === cid? { ...el, rate: newRate }: el)


}));
this.setState(prevState => ({

formRatingCOND: prevState.formRatingCOND.map(
  el => el.cid === cid? { ...el, rate: newRate }: el)


}))


}


setStep = (stepNo)=>{
  const {step} = this.state;
  this.setState({step:stepNo});
}
//handle change
handleChange = input => e => {
  console.log(e)
  this.setState({[input]: e.target.value});
}

handleChangeInput = (input,value) => {
  console.log(input,value)
  this.setState({[input]: value});
}

handleViewRecord=(id,stuId,formId,lessonNo,accName,takeOffDateTime,insId,rego,dualGH,soloGH,dualNav,soloNav,flightTime,dateOfRecord,ratings,costOfLesson,preflightBool,nextLessonBool,comments,preflightAuthId) =>e => {
  e.preventDefault();
  this.setState({step:0});
  fetch(`${process.env.REACT_APP_SERVER_URL}/getLessonCondsDB?formId=${formId}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response =>this.setState({ formRatingCOND  : response.data }))
  .then(()=>{
  this.getInsAccountInfo2(insId);
  this.getAccountInfo5(stuId);
  this.getWingInfo2(rego);
  this.setState({ searchKeyword   : id,
                  recordId        : id,
                  formId          : formId,
                  lessonNo        : lessonNo,
                  stu_id_edit     : stuId,
                  fl_time         : takeOffDateTime,
                  ins_id          : insId,
                  rego            : rego,
                  fl_clock        : flightTime,
                  transTimeStamp  : dateOfRecord,
                  formRating : JSON.parse(ratings),
                  dualGH          : dualGH,
                  soloGH          : soloGH,
                  dualNav         : dualNav,
                  soloNav         : soloNav,
                  costOfLesson    : costOfLesson,
                  pfk_bool        : preflightBool,
                  nextLesson_bool : nextLessonBool,
                  commts          : comments,
                  preflightAuthId : preflightAuthId
                }) })

    .then(()=>{

        this.state.formRatingCOND.map(
            i=>{this.setState(prevState =>({
              formRating: prevState.formRating.map(
                el => el.cid === i.cid? { ...el, rate: i.rate }: el)

            }))}
          )
        })
    .then(()=>{this.setState({step:3202})})
    }

handleEditTransaction=(transId,accName,eftposId,dateStamp,type,transDesc,amount) =>e => {
  e.preventDefault();
  this.setState({step:0});


  this.setState({ amountPaid   : amount,
                  recordId     : transId,
                  transId        : eftposId,
                  transTimeStamp : dateStamp,
                  transNotes        : transDesc,
                  step:6401,
                })


    }

handleEditCharterPreFlight=(id,name,email,authByName,authEmail,fuelAtStart,dualHrs,picHrs,rego,description,rAddress,authTime,manifest,status,fType,pilotName) =>e => {
  e.preventDefault();
  const mani=JSON.parse(manifest)
  if (this.state.step==7006) {
    this.setState({
                      preflightAuthId:id,
                      searchJson   : name,
                      fName        : name,
                      email        : email,
                      ins_fname    : authByName,
                      ins_email    : authEmail,
                      fuelStart    : fuelAtStart,
                      dualtotal    : dualHrs,
                      picTotal     : picHrs,
                      rego         : rego,
                      rAddress     : rAddress,
                      commts       : description,
                      fl_time      : authTime,
                      step         : 7007,
                      pax1name         :[mani[0][0]],
                      pax2name         :mani[1][0],
                      pax3name         :mani[2][0],
                      pax4name         :mani[3][0],
                      pax5name         :mani[4][0],
                      pax6name         :mani[5][0],
                      pax1W         :mani[0][1],
                      pax2W         :mani[1][1],
                      pax3W         :mani[2][1],
                      pax4W         :mani[3][1],
                      pax5W         :mani[4][1],
                      pax6W         :mani[5][1],
                      status        : status,
                      flightType    : fType,
                      pilotName     :pilotName,
                      status        : status=="TF"||status=="TP"?"TF":"F"



                    });

  }else{
  this.setState({
                    preflightAuthId:id,
                    searchJson   : name,
                    fName        : name,
                    email        : email,
                    fl_time      : authTime,
                    ins_fname    : authByName,
                    ins_email    : authEmail,
                    fuelStart    : fuelAtStart,
                    dualtotal    : dualHrs,
                    picTotal     : picHrs,
                    rego         : rego,
                    rAddress     : rAddress,
                    commts       : description,
                    step         : 7050,
                    pax1name     : mani[0][0],
                    pax2name     : mani[1][0],
                    pax3name     : mani[2][0],
                    pax4name     : mani[3][0],
                    pax5name     : mani[4][0],
                    pax6name     : mani[5][0],
                    pax1W         :mani[0][1],
                    pax2W         :mani[1][1],
                    pax3W         :mani[2][1],
                    pax4W         :mani[3][1],
                    pax5W         :mani[4][1],
                    pax6W         :mani[5][1],
                    status        : status,
                    flightType    : fType,
                    pilotName     :pilotName,
                    status        : "F"



                  });}

  }



handleSelectPreFlightChangeStatus=(id,name,email,authByName,authEmail,fuelAtStart,dualHrs,picHrs,rego,description,rAddress,authTime,manifest,status,fType,pilotName) =>e => {
  e.preventDefault();
  const mani=JSON.parse(manifest)

this.setState({
                                    preflightAuthId:id,
                                    searchJson   : name,
                                    fName        : name,
                                    email        : email,
                                    ins_fname    : authByName,
                                    ins_email    : authEmail,
                                    fuelStart    : fuelAtStart,
                                    dualtotal    : dualHrs,
                                    picTotal     : picHrs,
                                    rego         : rego,
                                    rAddress     : rAddress,
                                    commts       : description,
                                    fl_time      : authTime,
                                    step         : 7050,
                                    pax1name         :[mani[0][0]],
                                    pax2name         :mani[1][0],
                                    pax3name         :mani[2][0],
                                    pax4name         :mani[3][0],
                                    pax5name         :mani[4][0],
                                    pax6name         :mani[5][0],
                                    pax1W         :mani[0][1],
                                    pax2W         :mani[1][1],
                                    pax3W         :mani[2][1],
                                    pax4W         :mani[3][1],
                                    pax5W         :mani[4][1],
                                    pax6W         :mani[5][1],
                                    status        : status,
                                    flightType    : fType,
                                    pilotName     :pilotName,
                                    status        : "TF",



                                  });



    };


handleSelectPreFlight=(id,stuEmail,insEmail,rego,status,fType,authTime,pilotName,fuelAtStart) =>e => {
  e.preventDefault();
  const names=pilotName.split(" ");
  const fname=names[0];
  const lname=names[1];
  console.log(fname);
  this.setState({ step:0,
                  preflightAuthId:id,
                  flightType    : fType,
                  fl_time       : moment(authTime,"MM/DD/YYYY"),
                  pilotName     : pilotName,
                  fuelStart   : fuelAtStart,
                  status:"TF"});
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfoByEmail?email=${stuEmail}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,



                  });


  })
  .then(

  fetch(`${process.env.REACT_APP_SERVER_URL}/getInsAccountInfoByname?fname=${fname}&lname=${lname}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ ins_fname:     response.data[0].fname,
                    ins_mNames:    response.data[0].other_name,
                    ins_lname:     response.data[0].lname,
                    ins_btype:     response.data[0].bloodtype,
                    ins_mAddress:  response.data[0].m_address,
                    ins_rAddress:  response.data[0].r_address,
                    ins_tpNo:      response.data[0].tp_no,
                    ins_dob:       response.data[0].dob,
                    identName:     response.data[0].identName,
                    ins_PassPhoto: response.data[0].passportPhoto,
                    ins_profPhoto: response.data[0].profilePhoto,
                    ins_Signature: response.data[0].sigId,
                    ins_arn:       response.data[0].arn,
                    ins_email:     response.data[0].email,
                    ins_id:        response.data[0].insId,
                    ins_Signature: response.data[0].sigId,
                    ins_nextKin:   response.data[0].NextOfKin,
                    ins_nextKinPhone:   response.data[0].nextOfKinContact,
                    ins_cWeight:   response.data[0].currentWeight,
                    ins_notes: response.data[0].accNotes,
                    xs_level:response.data[0].xsLevel

});


  })
  .then(
    fetch(`${process.env.REACT_APP_SERVER_URL}/getWingInfo?rego=${rego}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.setState({ rego                  : response.data[0].rego,
                      wing_id               : response.data[0].indexId,
                      wing_type             : response.data[0].type,
                      wing_counter          : response.data[0].counter,
                      wingLastService       : response.data[0].lastServicedOn,
                      costPerHour           : response.data[0].costPerHour,
                      wingLastServiceNotes  : response.data[0].lastServiceNotes,
                      step                  : 3000});

      console.log("Server Response Completed")

    })
  )
)
.catch(err=>console.error(err))
  }





retrieveViewTR= e=>{



}

handleChangeBalance = (value,input) => {
  this.setState({[input]: value});
}
setTotalBalance= e=>{

  const {newBalance,amountPaid,tillBalance}=this.state;
  let addAmount = parseFloat(amountPaid);
  const totalBalance=addAmount+tillBalance;

  this.setState({newBalance:totalBalance})
}

onTagsChange = (event, values) => {
  const {searchKeyword} = this.state;
  this.setState({
    searchKeyword: values
  }, () => {
    // This will output an array of objects
    // given by Autocompelte options property.
    console.log(this.state.tags);
  });
}


dateChange=(date)=>{
    this.setState({dob: moment(date).format('MM/DD/YYYY')})
}
dateChangeMedical1=(date)=>{
    this.setState({medical1: moment(date).format('MM/DD/YYYY')})
}
dateChangeMedical2=(date)=>{
    this.setState({medical2: moment(date).format('MM/DD/YYYY')})
}
dateChangeMedical3=(date)=>{
    this.setState({medical3: moment(date).format('MM/DD/YYYY')})
}
dateChangeIns=(date)=>{
    this.setState({ins_dob: moment(date).format('MM/DD/YYYY')})
}
dateChangeMedicalIns=( date)=>{
    this.setState({ins_medicalExpiry: moment(date).format('MM/DD/YYYY')})
}
logServiceDate=(event, date)=>{
    this.setState({wingLastService: moment(date).format('MM/DD/YYYY')})
}
logtransTimeStamp=(event, date)=>{
    this.setState({transTimeStamp: moment(date).valueOf()})
}

dateChangeFlDate=(event, date)=>{
    this.setState({fl_date: moment(date).valueOf()})
}
FlTimeChange=(date)=>{
    this.setState({fl_time: moment(date).valueOf()})
}

setPassportPhoto=(imageSrc)=>{
    this.setState({passportPhoto: imageSrc})
}
setPassportPhotoIns=(imageSrc)=>{
    this.setState({ins_PassPhoto: imageSrc})
}
setSignatureUpload=(imageSrc)=>{
    this.setState({signatureUpload: imageSrc})
}




getStudentList= e => {
  const { suggestionsStu} = this.state;
  this.setState({loading:"true"})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountNames`,{ headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{this.setState({suggestionsStu:response.data,loading:"false"})})
  .catch(err=>console.error(err))

}

getWingsList= e => {
  const { suggestionsWings} = this.state;
  this.setState({loading:"true"})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getWings`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{this.setState({suggestionsWings:response.data,loading:"false"})})
  .catch(err=>console.error(err))

}
getInstructors= e => {
  const { suggestionsStu} = this.state;
  this.setState({loading:"true"})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getInstructors`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{this.setState({suggestionsIns:response.data,loading:"false"})})
  .catch(err=>console.error(err))

}

getDebitsOnly= e => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAllTransactionsDebit`, {
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ transactions:     response.data,
                    step        :     6400          });
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}
getCreditsOnly= e => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAllTransactionsCredit`, {
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ transactions:     response.data,
                    step        :     6400          });
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}

getAllTransactions= e => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAllTransactions`, {
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ transactions:     response.data,
                    step        :     6400          });
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}


getAccountTransactions= e => {
  const { values,searchKeyword,searchJson} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,


                  });
    console.log("Server Response Completed")


  })
  .then(

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountTransactions?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ transactions:     response.data});
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}

getLoggedInUserTransactions=(step)=> {
  const { loggedInUser} = this.state;

  this.setState({step:0});
  fetch(
    `${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${loggedInUser.id}`,
    {
      headers: {
        "x-access-token": this.state.token,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      console.log(response.data[0]);
      this.setState({
        fName: response.data[0].fname,
        mNames: response.data[0].othname,
        LicType: response.data[0].LicType,
        nextKin: response.data[0].NextOfKin,
        accNotes: response.data[0].accountNotes,
        stu_arn: response.data[0].arn,
        bloodType: response.data[0].bloodtype,
        cWeight: response.data[0].currentWeight,
        dob: response.data[0].dob,
        email: response.data[0].email,
        lName: response.data[0].lname,
        mAddress: response.data[0].m_address,
        nextKin: response.data[0].NextOfKin,
        nextKinPhone: response.data[0].nextOfKinContact,
        passportPhoto: response.data[0].passportPhoto,
        rAddress: response.data[0].r_address,
        stu_id_edit: response.data[0].stu_id,
        tillBalance: response.data[0].till_balance,
        phone: response.data[0].tp_no,
        stu_Signature: response.data[0].signature,
        passportPhoto: response.data[0].passportPhoto,
        profilePic: response.data[0].profilePhoto,
        medical1: response.data[0].medical1,
        medical2: response.data[0].medical2,
        medical3: response.data[0].medical3,
        medical1Checked:
          response.data[0].medical1Checked == "true" ? true : false,
        medical2Checked:
          response.data[0].medical2Checked == "true" ? true : false,
        medical3Checked:
          response.data[0].medical3Checked == "true" ? true : false,
        CLicType: response.data[0].CLicType,
      });
      console.log("Server Response Completed");
    })
    .then(
      fetch(
        `${process.env.REACT_APP_SERVER_URL}/getAccountTransactions?stuId=${loggedInUser.id}`,
        {
          headers: {
            "x-access-token": this.state.token,
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          this.setState({ transactions: response.data, step: step });
          console.log("Server Response Completed");
        })
    )
    .catch((err) => console.error(err));

}

getAllTrainingHistory= e => {
  this.setState({step:0})
  const { values,searchKeyword,searchJson} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAllTrainingHistory`, {
    headers: {
    'x-access-token': this.state.token
  }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ flightRecords:     response.data,
                    step         :     3400});
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}

getFlightHistoryforStudent= e => {
 const dateB430=moment().add(-30,'days')
 const dateB490=moment().add(-90,'days')
 const dateB414=moment().add(-14,'days')

 const dateB414value=moment(dateB414).valueOf();
 const dateB430value=moment(dateB430).valueOf();
 const dateB490value=moment(dateB490).valueOf();

  const { values,searchKeyword,searchJson,step} = this.state;
  const lessons = [
    {id:"01_105CPL_H",name:"Effects Of Controls"},
    {id:"02_105CPL_H",name:"Coordination"},
    {id:"03_105CPL_H",name:"Advanced Coordination"},
    {id:"04_105CPL_H",name:"Hover"},
    {id:"05_105CPL_H",name:"Hover Turns And Hover Taxi"},
    {id:"06_105CPL_H",name:"Landing and Lift-offs"},
    {id:"07_105CPL_H",name:"Transitions"},
    {id:"08_105CPL_H",name:"Circuits"},
    {id:"09_105CPL_H",name:"Max.Performance/Steep Approaches"},
    {id:"10_105CPL_H",name:"Limited Power Operations"},
    {id:"11_105CPL_H",name:"Basic Autorotation"},
    {id:"12_105CPL_H",name:"Engine Failure(Hover)"},
    {id:"13_105CPL_H",name:"Practice Forced Landings"},
    {id:"14_105CPL_H",name:"Tail Rotor Failures"},
    {id:"15_105CPL_H",name:"Jammed Flight Controls"},
    {id:"16_105CPL_H",name:"Advanced Emergencies"},
    {id:"17_105CPL_H",name:"Circuits (Pre-Solo)"},
    {id:"18_105CPL_H",name:"Solo Circuits #1"},
    {id:"19_105CPL_H",name:"Circuit Consolidation #1"},
    {id:"20_105CPL_H",name:"Solo Circuits #2"},
    {id:"21_105CPL_H",name:"Circuit Consolidation #2"},
    {id:"22_105CPL_H",name:"Solo Circuits #3"},
    {id:"23_105CPL_H",name:"Circuit Consolidation #3"},
    {id:"24_105CPL_H",name:"lo Circuits #4"},
    {id:"25_105CPL_H",name:"Sloped Landings"},
    {id:"26_105CPL_H",name:"Confined Areas"},
    {id:"27_105CPL_H",name:"Steep Turns"},
    {id:"28_105CPL_H",name:"Pre-training Area Solo"},
    {id:"29_105CPL_H",name:"Training Area Solo #1"},
    {id:"30_105CPL_H",name:"Conf. Areas Consolidation"},
    {id:"31_105CPL_H",name:"Training Area Solo #2"},
    {id:"32_105CPL_H",name:"Emergencies Consolidation"},
    {id:"33_105CPL_H",name:"Training Areas Solo #3"},
    {id:"34_105CPL_H",name:"Navigation: Lane Of Entry 1"},
    {id:"35_105CPL_H",name:"Navigation: Lane Of Entry 2"},
    {id:"36_105CPL_H",name:"Navigation: Lane Of Entry 3"},
    {id:"37_105CPL_H",name:"Navigation: Lane Of Entry (1st Solo Nav)"},
    {id:"38_105CPL_H",name:"Navigation: 12 D.M.E Step"},
    {id:"39_105CPL_H",name:"Navigation: 12 D.M.E Step (Solo)"},
    {id:"40_105CPL_H",name:"Navigation: R555"},
    {id:"41_105CPL_H",name:"Navigation: R555 (Solo)"},
    {id:"42_105CPL_H",name:"Navigation: YSHL - YMIG"},
    {id:"43_105CPL_H",name:"Navigation: YSHL - YMIG (Solo)"},
    {id:"44_105CPL_H",name:"Navigation: Other (Class C)"},
    {id:"45_105CPL_H",name:"Navigation: Other (Solo)"},
    {id:"46_105CPL_H",name:"Navigation: YBTH - YSCN"},
    {id:"47_105CPL_H",name:"Navigation: YBTH - YSCN (Solo)"},
    {id:"48_105CPL_H",name:"Dual Consolidation"},
    {id:"49_105CPL_H",name:"Solo Consolidation"},
    {id:"50_105CPL_H",name:"Pre-CPL"},
    {id:"51_105CPL_H",name:"Accelerated competency form"},
    {id:"60_105CPL_HGS",name:"Radio & Transponder"},
    {id:"61_105CPL_HGS",name:"Weight & Balance"},
    {id:"62_105CPL_HGS",name:"Radio Failure"},
    {id:"63_105CPL_HGS",name:"Decoding TAFs"},
    {id:"64_105CPL_HGS",name:"Daily inspection"},
    {id:"65_105CPL_HGS",name:"Refueling"},
    {id:"66_105CPL_HGS",name:"Logbook"},
    {id:"67_105CPL_HGS",name:"Student File"},
    {id:"68_105CPL_HGS",name:"Approach / Vacate HGSelipad"},
    {id:"68_105CPL_HGS",name:"Taxiways & Tower"},
    {id:"68_105CPL_HGS",name:"Give Way"},
    {id:"68_105CPL_HGS",name:"Hold Short Helipad"},
]
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,


                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getFlightHistoryforStudent?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ flightRecords:    response.data,});

    console.log("Server Response Completed")})
    .then(fetch(`${process.env.REACT_APP_SERVER_URL}/getLast30daysHistory?stuId=${searchKeyword.stu_id}&date=${dateB414value}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.setState({ day14History:    response.data});
      console.log("Server Response Completed")


  }))
    .then(fetch(`${process.env.REACT_APP_SERVER_URL}/getLast30daysHistory?stuId=${searchKeyword.stu_id}&date=${dateB430value}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.setState({ day30History:    response.data});
      console.log("Server Response Completed")


  }))
  .then(
    step==3200 && lessons.map(i=>
 fetch(`${process.env.REACT_APP_SERVER_URL}/getLessonNo?stuId=${searchKeyword.stu_id}&lessonID=${i.id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
 .then(response => response.json())
 .then(response=>{
     i.status = response.data[0].count ;


 })
)
)
.then(  step==3200 &&  this.setState({lessonsProgress:lessons}))
.then(fetch(`${process.env.REACT_APP_SERVER_URL}/getLast30daysHistory?stuId=${searchKeyword.stu_id}&date=${dateB490value}`, {
      headers: {
      'x-access-token': this.state.token
    }})
.then(response => response.json())
.then(response=>{
  if (step==3200) {
    this.setState({ day90History:    response.data,
                    step        :    step+1});
  }else {
    this.setState({ day90History:    response.data});
  }

  console.log("Server Response Completed")


}))

)
  .catch(err=>console.error(err))

}

getLoggedInUserTraining= (step) => {
  this.setState({step:0});
 const dateB430=moment().add(-30,'days')
 const dateB490=moment().add(-90,'days')
 const dateB414=moment().add(-14,'days')

 const dateB414value=moment(dateB414).valueOf();
 const dateB430value=moment(dateB430).valueOf();
 const dateB490value=moment(dateB490).valueOf();
  const { values,searchKeyword,searchJson} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/getFlightHistoryforStudent?stuId=${this.state.loggedInUser.id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ flightRecords:    response.data,
                    fName        :    searchKeyword.fname,
                    lName        :    searchKeyword.lname,
                    stu_id_edit  :    searchKeyword.stu_id
    });
    console.log("Server Response Completed")})
    .then(fetch(`${process.env.REACT_APP_SERVER_URL}/getLast30daysHistory?stuId=${this.state.loggedInUser.id}&date=${dateB414value}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.setState({ day14History:    response.data});
      console.log("Server Response Completed")


  }))
    .then(fetch(`${process.env.REACT_APP_SERVER_URL}/getLast30daysHistory?stuId=${this.state.loggedInUser.id}&date=${dateB430value}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.setState({ day30History:    response.data});
      console.log("Server Response Completed")


  }))
  .then(fetch(`${process.env.REACT_APP_SERVER_URL}/getLast30daysHistory?stuId=${this.state.loggedInUser.id}&date=${dateB490value}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ day90History:    response.data,
                    step        :    step });
    console.log("Server Response Completed")


}))
  .catch(err=>console.error(err))

}




assignToMainState= (searchJson) => {
  console.log(searchJson.fname);


}

editStudent= (step)=> {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editStudent?arn=${this.state.stu_arn}&profilePic=${this.state.profilePic}&passportPhoto=${this.state.passportPhoto}&signature=${this.state.signatureUpload}&fname=${this.state.fName}&lname=${this.state.lName}&othname=${this.state.mNames}&dob=${this.state.dob}&mAddress=${this.state.mAddress}&rAddress=${this.state.rAddress}&tpNo=${this.state.phone}&email=${this.state.email}&bloodType=${this.state.bloodType}&nextOfKin=${this.state.nextKin}&nextOfKinContact=${this.state.nextKinPhone}&currentWeight=${this.state.cWeight}&licType=${this.state.LicType}&accountNotes=${this.state.accNotes}&stu_id=${this.state.stu_id_edit}&medical1=${this.state.medical1}&medical2=${this.state.medical2}&medical3=${this.state.medical3}&medical1Checked=${this.state.medical1Checked}&medical2Checked=${this.state.medical2Checked}&medical3Checked=${this.state.medical3Checked}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))

  .then(
    e=>{if (this.state.emailChanged==true) {

      this.editAccessInfoEmail(step);
    console.log("changing access email");
    console.log(this.state.emailChanged);

    } else {
      this.editStuPasswordInfo(step);
      console.log("not changing access email");
      console.log(this.state.emailChanged);



    }}

    )
}

editStudent2= (step)=> {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editStudent?arn=${this.state.stu_arn}&profilePic=${this.state.profilePic}&passportPhoto=${this.state.passportPhoto}&signature=${this.state.signatureUpload}&fname=${this.state.fName}&lname=${this.state.lName}&othname=${this.state.mNames}&dob=${this.state.dob}&mAddress=${this.state.mAddress}&rAddress=${this.state.rAddress}&tpNo=${this.state.phone}&email=${this.state.email}&bloodType=${this.state.bloodType}&nextOfKin=${this.state.nextKin}&nextOfKinContact=${this.state.nextKinPhone}&currentWeight=${this.state.cWeight}&licType=${this.state.LicType}&accountNotes=${this.state.accNotes}&stu_id=${this.state.stu_id_edit}&medical1=${this.state.medical1}&medical2=${this.state.medical2}&medical3=${this.state.medical3}&medical1Checked=${this.state.medical1Checked}&medical2Checked=${this.state.medical2Checked}&medical3Checked=${this.state.medical3Checked}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))

  .then(
    e=>{if (this.state.emailChanged==true) {

      this.editAccessInfoEmail(step);
    console.log("changing access email");
    console.log(this.state.emailChanged);


    } else {
      this.editStuPasswordInfo(step);
      console.log("not changing access email");
      console.log(this.state.emailChanged);



    }}

    )
}

editAirCraft= e => {
  fetch(`${process.env.REACT_APP_SERVER_URL}/editAirCraft?indexId=${this.state.wing_id}&rego=${this.state.rego}&type=${this.state.wing_type}&counter=${this.state.wing_counter}&wingLastService=${this.state.wingLastService}&wingLastServiceNotes=${this.state.wingLastServiceNotes}&costPerHour=${this.state.costPerHour}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
}

editInstructor= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editInstructor?insId=${this.state.ins_id}&xsLevel=${this.state.xs_level}&arn=${this.state.ins_arn}&fname=${this.state.ins_fname}&lname=${this.state.ins_lname}&bloodType=${this.state.ins_btype}&othname=${this.state.ins_mNames}&dob=${this.state.ins_dob}&mAddress=${this.state.ins_mAddress}&rAddress=${this.state.ins_rAddress}&tpNo=${this.state.ins_tpNo}&email=${this.state.ins_email}&nextOfKin=${this.state.ins_nextKin}&nextOfKinContact=${this.state.ins_nextKinPhone}&currentWeight=${this.state.ins_cWeight}&medicalExpiry=${this.state.ins_medicalExpiry}&accountNotes=${this.state.ins_notes}&passportPhoto=${this.state.ins_PassPhoto}&profilePhoto=${this.state.ins_profPhoto}&signature=${this.state.signatureUpload}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))

  .then(this.editInsPasswordInfo(step))

}

editInsPassword= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editPassword?email=${this.state.ins_email}&kiHash=${this.state.kiHash}&lName=${this.state.ins_lname}&fName=${this.state.ins_fname}&profilePic=${this.state.ins_profPhoto}&isTemp=${this.state.isTemp}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(this.setState({step:step}))
}
editInsPasswordInfo= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editPasswordInfo?email=${this.state.ins_email}&lName=${this.state.ins_lname}&fName=${this.state.ins_fname}&profilePic=${this.state.ins_profPhoto}&xsLevel=${this.state.xs_level}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(this.setState({step:step}))
}

editStuPassword= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editPassword?email=${this.state.email}&kiHash=${this.state.kiHash}&lName=${this.state.lName}&fName=${this.state.fName}&profilePic=${this.state.profilePic}&isTemp=${this.state.isTemp}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(this.setState({step:step}))
}



editStuPasswordInfo= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editPasswordInfo?email=${this.state.email}&lName=${this.state.lName}&fName=${this.state.fName}&profilePic=${this.state.profilePic}&xsLevel=${this.state.xs_level}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(this.setState({step:step}))
}

editAccessInfoEmail= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/editPasswordInfoEmail?oldEmail=${this.state.oldEmail}&newEmail=${this.state.email}&lName=${this.state.lName}&fName=${this.state.fName}&profilePic=${this.state.profilePic}&xsLevel=${this.state.xs_level}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(this.setState({step:step}))
}

deleteAirCraft= e => {
  fetch(`${process.env.REACT_APP_SERVER_URL}/deleteAirCraft?indexId=${this.state.wing_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
}


getInsAccountInfo= e => {
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.insId!=" "){
  fetch(`${process.env.REACT_APP_SERVER_URL}/getInsAccountInfo?insId=${searchKeyword.insId}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ ins_fname:     response.data[0].fname,
                    ins_mNames:    response.data[0].other_name,
                    ins_lname:     response.data[0].lname,
                    ins_btype:     response.data[0].bloodtype,
                    ins_mAddress:  response.data[0].m_address,
                    ins_rAddress:  response.data[0].r_address,
                    ins_tpNo:      response.data[0].tp_no,
                    ins_dob:       response.data[0].dob,
                    identName:     response.data[0].identName,
                    ins_PassPhoto: response.data[0].passportPhoto,
                    ins_profPhoto: response.data[0].profilePhoto,
                    ins_Signature: response.data[0].sigId,
                    ins_arn:       response.data[0].arn,
                    ins_email:     response.data[0].email,
                    oldEmail     : response.data[0].email,
                    ins_id:        response.data[0].insId,
                    ins_Signature: response.data[0].sigId,
                    ins_nextKin:   response.data[0].NextOfKin,
                    ins_nextKinPhone:   response.data[0].nextOfKinContact,
                    ins_cWeight:   response.data[0].currentWeight,
                    ins_notes: response.data[0].accNotes,
                    xs_level:response.data[0].xsLevel

});
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}}
getInsAccountInfo2=(insId) => {
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.insId!=" "){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getInsAccountInfo?insId=${insId}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ ins_fname:     response.data[0].fname,
                    ins_mNames:    response.data[0].other_name,
                    ins_lname:     response.data[0].lname,
                    ins_btype:     response.data[0].bloodtype,
                    ins_mAddress:  response.data[0].m_address,
                    ins_rAddress:  response.data[0].r_address,
                    ins_tpNo:      response.data[0].tp_no,
                    ins_dob:       response.data[0].dob,
                    identName:     response.data[0].identName,
                    ins_PassPhoto: response.data[0].passportPhoto,
                    ins_profPhoto: response.data[0].profilePhoto,
                    ins_Signature: response.data[0].sigId,
                    ins_arn:       response.data[0].arn,
                    ins_email:     response.data[0].email,
                    oldEmail     : response.data[0].email,
                    ins_id:        response.data[0].insId,
                    ins_Signature: response.data[0].sigId,
                    ins_nextKin:   response.data[0].NextOfKin,
                    ins_nextKinPhone:   response.data[0].nextOfKinContact,
                    ins_cWeight:   response.data[0].currentWeight,
                    ins_notes: response.data[0].accNotes,
                    xs_level : response.data[0].xsLevel

});
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}}

getAccountInfo= (step) => {
  this.setState({step:0})
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.stu_id!=" "){
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,


                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,

                    step: step,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}}

getAccountInfo2= () => {
  this.setState({step:0})
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.stu_id!=" "){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,

                  });

    console.log("Server Response Completed")


  })
  .then(
                fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
                .then(response => response.json())
                .then(response=>{console.log(response.data[0])
                  this.setState({ xs_level        : response.data[0].accessLevel,

                                  step: 2501,


                                });
                  console.log("Server Response Completed")


                }))
  .catch(err=>console.error(err))

}}

getAccountInfo3= () => {
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.stu_id!=" "){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,


                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}}

getAccountInfo4= (step) => {
  const { values,searchKeyword,searchJson} = this.state;
  this.setState({step:0});
  console.log(searchKeyword);

if (searchKeyword.hasOwnProperty("stu_id")) {


  if(searchKeyword.stu_id!=" "){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,

                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,
                    step            : step,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}else {
  this.setState({ step            : step,


                });
}}else {
  this.setState({ step            : step,


                });
}}

getAccountInfo7= (x) => {
  const { values,searchKeyword,searchJson,step} = this.state;
  this.setState({step:0});



  if(searchKeyword.stu_id!=" "){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,

                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,
                    step            : step+1,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}else {
  this.setState({ step            : step+1,


                });
}}

getAccountInfo5= (stuId) => {
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.stu_id!=" "){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${stuId}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,


                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${searchKeyword.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}}

getAccountInfo6= (step) => {
  this.setState({step:0})
  const { values,searchKeyword,searchJson} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountInfo?stuId=${this.state.loggedInUser.id}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ fName        : response.data[0].fname,
                    mNames       : response.data[0].othname,
                    LicType      : response.data[0].LicType,
                    nextKin      : response.data[0].NextOfKin,
                    accNotes     : response.data[0].accountNotes,
                    stu_arn      : response.data[0].arn,
                    bloodType    : response.data[0].bloodtype,
                    cWeight      : response.data[0].currentWeight,
                    dob          : response.data[0].dob,
                    email        : response.data[0].email,
                    oldEmail     : response.data[0].email,
                    lName        : response.data[0].lname,
                    mAddress     : response.data[0].m_address,
                    nextKin      : response.data[0].NextOfKin,
                    nextKinPhone : response.data[0].nextOfKinContact,
                    passportPhoto: response.data[0].passportPhoto,
                    rAddress     : response.data[0].r_address,
                    stu_id_edit  : response.data[0].stu_id,
                    tillBalance  : response.data[0].till_balance,
                    phone        : response.data[0].tp_no,
                    stu_Signature: response.data[0].signature,
                    passportPhoto: response.data[0].passportPhoto,
                    profilePic   : response.data[0].profilePhoto,
                    medical1     : response.data[0].medical1,
                    medical2     : response.data[0].medical2,
                    medical3     : response.data[0].medical3,
                    medical1Checked:response.data[0].medical1Checked=="true"? true:false,
                    medical2Checked:response.data[0].medical2Checked=="true"? true:false,
                    medical3Checked:response.data[0].medical3Checked=="true"? true:false,
                    CLicType      :response.data[0].CLicType,
                  });
    console.log("Server Response Completed")


  })
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${this.state.loggedInUser.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{console.log(response.data[0])
    this.setState({ xs_level        : response.data[0].accessLevel,

                    step: step,


                  });
    console.log("Server Response Completed")


  }))
  .catch(err=>console.error(err))

}


getLoggedInUserInfo= (step,email) => {
  this.setState({step:0})
  const { values,searchKeyword,searchJson} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/getXsInfo?email=${email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{if(response.data.length>0){
    this.setState({ loggedInUser          : response.data[0],
                    step                  : step,
                    banner                : response.data[0].banner
                    });
    console.log("Server Response Completed")
  }else{this.setState({step:1005})}


  })
  .catch(err=>console.log(err))

}

authenticate= (step,password) => {
  this.setState({step:0})
  const {loggedInUser} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/authKi?email=${loggedInUser.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{if(response.data.length>0){
    const hashed= response.data[0].keypass;
    const isTemp= response.data[0].isTemp;
    const token= response.data[0].token;

    this.setState({ 
      loggedInUserSecure    : PasswordHash.verify(password,hashed),
      token : token
    });

    if(PasswordHash.verify(password,hashed)==true && isTemp=="true"){
      console.log("must reset")
      this.setState({step:1000})
    }else if(PasswordHash.verify(password,hashed)==true && isTemp=="false"){
      console.log("access granted")
      this.setState({step:1004})}

  else{console.log("incorrect password");this.setState({step:1006})}


}})
  .catch(err=>console.log(err))

}



getLessonNumber= (step) => {
  this.setState({step:0})
  const {stu_id_edit,formId} = this.state;
  fetch(`${process.env.REACT_APP_SERVER_URL}/getLessonNo?stuId=${stu_id_edit}&lessonID=${formId}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ lessonNo          : response.data[0].count+1,
                    step                  : step,
                    });
    console.log("Server Response Completed")


  })
  .catch(err=>console.log(err))

}



getWingInfo= e => {
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.rego!=""){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getWingInfo?rego=${searchKeyword.rego}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ rego                  : response.data[0].rego,
                    wing_id               : response.data[0].indexId,
                    wing_type             : response.data[0].type,
                    wing_counter          : response.data[0].counter,
                    wingLastService       : response.data[0].lastServicedOn,
                    costPerHour           : response.data[0].costPerHour,
                    wingLastServiceNotes  : response.data[0].lastServiceNotes});
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}}
getWingInfo2=(rego)=> {
  const { values,searchKeyword,searchJson} = this.state;
  if(searchKeyword.rego!=""){

  fetch(`${process.env.REACT_APP_SERVER_URL}/getWingInfo?rego=${rego}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(response => response.json())
  .then(response=>{
    this.setState({ rego                  : response.data[0].rego,
                    wing_id               : response.data[0].indexId,
                    wing_type             : response.data[0].type,
                    wing_counter          : response.data[0].counter,
                    wingLastService       : response.data[0].lastServicedOn,
                    costPerHour           : response.data[0].costPerHour,
                    wingLastServiceNotes  : response.data[0].lastServiceNotes});
    console.log("Server Response Completed")


  })
  .catch(err=>console.error(err))

}}

addHours= (step) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/addHours?soloGH=${this.state.soloGH}&stuId=${this.state.stu_id_edit}&soloNav=${this.state.soloNav}&dualGH=${this.state.dualGH}&dualNav=${this.state.dualNav}&flightTime=${this.state.fl_clock}&comments=${this.state.commts}&date=${Date.now()}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(this.setState({step:step}))
  }

enterPreFlightAuth= (step) => {
  const manifest=[[this.state.pax1name,this.state.pax1W],[this.state.pax2name,this.state.pax2W],[this.state.pax3name,this.state.pax3W],[this.state.pax4name,this.state.pax4W],[this.state.pax5name,this.state.pax5W],[this.state.pax6name,this.state.pax6W]]
  const manifestS=JSON.stringify(manifest);
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/enterPreFlightAuth?name=${this.state.searchJson+this.state.fName+" "+this.state.lName}&email=${this.state.email}&fuel=${this.state.fuelStart}&dualGH=${this.state.dualtotal}&picHrs=${this.state.picTotal}&authbyemail=${this.state.loggedInUser.email}&authbyname=${this.state.loggedInUser.fName+" "+this.state.loggedInUser.lName}&comments=${this.state.commts}&date=${moment(this.state.fl_time).format('MM/DD/YYYY')}&timeStamp=${moment().unix()}&rego=${this.state.rego}&fType=${this.state.flightType}&manifest=${manifestS}&status=${this.state.status}&rAddress=${this.state.rAddress}&hirerSig=${this.state.stu_Signature}&insSig=${this.state.ins_Signature}&pilotName=${this.state.pilotName}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(this.setState({step:step}))
  }




enterTrainingRecord=e=>{
  const ratings=JSON.stringify(this.state.formRating);
  console.log(ratings)
  fetch(`${process.env.REACT_APP_SERVER_URL}/addTR?stuId=${this.state.stu_id_edit}&accName=${this.state.fName+" "+this.state.lName}&insName=${this.state.ins_fname+" "+this.state.ins_lname}&lessonNo=${this.state.lessonNo}&insId=${this.state.ins_id}&rego=${this.state.rego}&takeOffDateTime=${moment(this.state.fl_time).valueOf()}&signTimeStamp=${this.state.transTimeStamp}&flightTime=${this.state.fl_clock}&comments=${this.state.commts}&ratings=${ratings}&nextLessonBool=${this.state.nextLesson_bool}&preflightBool=${this.state.pfk_bool}&formId=${this.state.formId}&costOfLessson=${this.state.lessonCost}&soloGH=${this.state.soloGH}&soloNav=${this.state.soloNav}&dualGH=${this.state.dualGH}&dualNav=${this.state.dualNav}&preflightAuthId=${this.state.preflightAuthId}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(
    fetch(`${process.env.REACT_APP_SERVER_URL}/editpreflightAuth?id=${this.state.preflightAuthId}&hirerName=${this.state.fName+" "+this.state.lName}&hirerEmail=${this.state.email}&authByName=${this.state.ins_fname+" "+this.state.ins_lname}&authByEmail=${this.state.ins_email}&dualHrs=${(parseFloat(this.state.dualGH)+parseFloat(this.state.dualNav)).toFixed(1)}&picHrs=${(parseFloat(this.state.soloGH)+parseFloat(this.state.soloNav)).toFixed(1)}&rego=${this.state.rego}&status=TF&date=${moment(this.state.fl_time).format('MM/DD/YYYY')}&pilotName=${this.state.pilotName}&fuel=${this.state.fuelStart}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .catch(err=>console.error(err))
    .then(response => response.json())
  )

  .catch(err=>console.error(err))

  }

editTrainingRecord=e=>{
  const ratings=JSON.stringify(this.state.formRating);
  console.log(ratings)
  fetch(`${process.env.REACT_APP_SERVER_URL}/editTRplus?recordId=${this.state.recordId}&ratings=${ratings}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .then(
  fetch(`${process.env.REACT_APP_SERVER_URL}/editTR?recordId=${this.state.recordId}&stuId=${this.state.stu_id_edit}&accName=${this.state.fName+" "+this.state.lName}&insName=${this.state.ins_fname+" "+this.state.ins_lname}&lessonNo=${this.state.lessonNo}&insId=${this.state.ins_id}&rego=${this.state.rego}&takeOffDateTime=${moment(this.state.fl_time).valueOf()}&signTimeStamp=${this.state.transTimeStamp}&flightTime=${this.state.fl_clock}&comments=${this.state.commts}&ratings=${ratings}&nextLessonBool=${this.state.nextLesson_bool}&preflightBool=${this.state.pfk_bool}&formId=${this.state.formId}&costOfLessson=${this.state.lessonCost}&soloGH=${this.state.soloGH}&soloNav=${this.state.soloNav}&dualGH=${this.state.dualGH}&dualNav=${this.state.dualNav}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())

  .catch(err=>console.error(err))
  .then(
    fetch(`${process.env.REACT_APP_SERVER_URL}/editpreflightAuth?id=${this.state.preflightAuthId}&hirerName=${this.state.fName+" "+this.state.lName}&hirerEmail=${this.state.email}&authByName=${this.state.ins_fname+" "+this.state.ins_lname}&authByEmail=${this.state.ins_email}&dualHrs=${(parseFloat(this.state.dualGH)+parseFloat(this.state.dualNav)).toFixed(1)}&picHrs=${(parseFloat(this.state.soloGH)+parseFloat(this.state.soloNav)).toFixed(1)}&rego=${this.state.rego}&status=TF&date=${moment(this.state.fl_time).format('MM/DD/YYYY')}&pilotName=${this.state.pilotName}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .catch(err=>console.error(err))
    .then(response => response.json())
  ))

  }
editpreflightAuth=(step)=>{
  fetch(`${process.env.REACT_APP_SERVER_URL}/editpreflightAuth?id=${this.state.preflightAuthId}&hirerName=${this.state.fName+" "+this.state.lName}&hirerEmail=${this.state.email}&authByName=${this.state.ins_fname+" "+this.state.ins_lname}&authByEmail=${this.state.ins_email}&dualHrs=${parseFloat(this.state.dualtotal).toFixed(1)}&picHrs=${parseFloat(this.state.picTotal).toFixed(1)}&rego=${this.state.rego}&status=${this.state.status}&rAddress=${this.state.rAddress}&comments=${this.state.commts}&date=${this.state.fl_time}&fType=${this.state.flightType}&fuel=${this.state.fuelStart}&pilotName=${this.state.pilotName}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(this.setState({step:step}))

}

editTransaction=(step)=>{
  fetch(`${process.env.REACT_APP_SERVER_URL}/editTransaction?transId=${this.state.recordId}&eftposId=${this.state.transId}&transDesc=${this.state.transNotes}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(this.setState({step:6402}))

}

  addInstructor= (step) => {
    this.setStep({step:0})
    fetch(`${process.env.REACT_APP_SERVER_URL}/addInstructor?arn=${this.state.ins_arn}&fname=${this.state.ins_fname}&lname=${this.state.ins_lname}&othname=${this.state.ins_mNames}&dob=${this.state.ins_dob}&mAddress=${this.state.ins_mAddress}&rAddress=${this.state.ins_rAddress}&tpNo=${this.state.ins_tpNo}&email=${this.state.ins_email}&nextOfKin=${this.state.ins_nextKin}&nextOfKinContact=${this.state.ins_nextKinPhone}&currentWeight=${this.state.ins_cWeight}&medicalExpiry=${this.state.ins_medicalExpiry}&accountNotes=${this.state.ins_notes}&passportPhoto=${this.state.ins_PassPhoto}&profilePhoto=${this.state.ins_profPhoto}&signature=${this.state.signatureUpload}&bloodType=${this.state.ins_btype}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .catch(err=>console.error(err))
    .then(response => response.json())
    .then(
      fetch(`${process.env.REACT_APP_SERVER_URL}/addAccess?email=${this.state.ins_email}&kiHash=${this.state.kiHash}&xsLevel=${this.state.xs_level}&fname=${this.state.ins_fname}&lname=${this.state.ins_lname}&profPicURL=${this.state.ins_profPhoto}&isTemp=${this.state.isTemp}`, {
      headers: {
      'x-access-token': this.state.token
    }}))
      .catch(err=>console.error(err))

    .catch(err=>console.error(err))
    .then(this.setStep({step:step}))
    }

  addAirCraft= e => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/addAircraft?rego=${this.state.rego}&type=${this.state.wing_type}&counter=${this.state.wing_counter}&lastServiceDate=${this.state.wingLastService}&serviceNotes=${this.state.wingLastServiceNotes}&cost=${this.state.costPerHour}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .catch(err=>console.error(err))
    .then(response => response.json())

    .catch(err=>console.error(err))

    }
validateEmail=e=>{
  fetch(`${process.env.REACT_APP_SERVER_URL}/validateEmail?email=${this.state.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(response=>{if(response.data.count>0){
    return "true"
  }else {
    return "false"
  }
  })

  .catch(err=>console.error(err))
}

setCustomPrice= (current_time) => {
  this.setState({step:0})
  fetch(`${process.env.REACT_APP_SERVER_URL}/setCustomPrice?rego=${this.state.rego}&stuId=${this.state.stu_id_edit}&cost=${this.state.costPerHour}&date=${current_time}&notes=${this.state.transNotes}`, {
      headers: {
      'x-access-token': this.state.token
    }})
  .catch(err=>console.error(err))
  .then(response => response.json())
  .then(this.setState({step:5304}))

  .catch(err=>console.error(err))

  }

  getCustomPrice= e => {
    const { values,searchKeyword,searchJson} = this.state;
    fetch(`${process.env.REACT_APP_SERVER_URL}/getCustomPrice?rego=${this.state.step == 3207 ? this.state.rego : this.state.searchKeyword.rego}&stuId=${this.state.stu_id_edit}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .then(response => response.json())
    .then(response=>{if(response.data.length>0){
      this.setState({ costPerHour  : response.data[0].costPerHour })  }
    })
    .catch(err=>console.error(err))

  }


  addAccess= e => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/addAccess?email=${this.state.email}&kiHash=${this.state.kiHash}&xsLevel=${this.state.xs_level}&fname=${this.state.fName}&lname=${this.state.lName}&profPicURL=${this.state.profilePic}&isTemp=${this.state.passTemp}`, {
      headers: {
      'x-access-token': this.state.token
    }})
    .catch(err=>console.error(err))
    .then(response => response.json())


    }

    addCredit= e => {
      const {newBalance,amountPaid,tillBalance}=this.state;

      let addAmount = parseFloat(amountPaid);
      const totalBalance1=addAmount+tillBalance;
      let totalBalance= parseFloat(totalBalance1).toFixed(2);

      console.log(totalBalance);
      fetch(`${process.env.REACT_APP_SERVER_URL}/addCredit?type=Credit&amount=${addAmount}&paymentTimeStamp=${this.state.transTimeStamp}&accId=${this.state.stu_id_edit}&accName=${this.state.fName+" "+this.state.lName}&transDesc=${this.state.transNotes}&processedBy=${this.state.loggedInUser}&eftposId=${this.state.transId}&balanceAfter=${totalBalance}&stuId=${this.state.stu_id_edit}&email=${this.state.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
      .catch(err=>console.error(err))
      .then(response => response.json())

      .catch(err=>console.error(err))


      fetch(`${process.env.REACT_APP_SERVER_URL}/changeStudentTillBalance?balanceAfter=${totalBalance}&stuId=${this.state.stu_id_edit}`, {
      headers: {
      'x-access-token': this.state.token
    }})
      .catch(err=>console.error(err))
      .then(response => response.json())

      .catch(err=>console.error(err))
      }



addDebit= e => {
        const {amountPaid,costPerHour,fl_clock,newBalance,lessonCost,tillBalance,email,dualGH,dualNav,soloGH,soloNav}=this.state;

        const totalcost=(parseFloat(amountPaid)+parseFloat(lessonCost));
        console.log(amountPaid,lessonCost,totalcost);


        let debitAmount = parseFloat(totalcost);
        const totalBalance1=tillBalance-debitAmount;
        let totalBalance= parseFloat(totalBalance1).toFixed(2);

        const dualTime=(parseFloat(dualGH)+parseFloat(dualNav)).toFixed(1);
        const soloTime=(parseFloat(soloGH)+parseFloat(soloNav)).toFixed(1)
        const timeString=dualTime>0.0||soloTime>0.0?soloTime+" S "+dualTime+" D, Total "+fl_clock+" hours @ $"+costPerHour+"/hr ":" "



        console.log(timeString);
        fetch(`${process.env.REACT_APP_SERVER_URL}/addDebit?type=Debit&amount=${debitAmount}&paymentTimeStamp=${this.state.transTimeStamp}&accId=${this.state.stu_id_edit}&accName=${this.state.fName+" "+this.state.lName}&transDesc=${this.state.formId+" "+this.state.transNotes+" "+timeString+" "+this.state.rego+" "+moment(moment(this.state.fl_time).valueOf()).format("L LT")}&processedBy=${this.state.loggedInUser}&eftposId=${this.state.transId}&balanceAfter=${totalBalance}&stuId=${this.state.stu_id_edit}&email=${this.state.email}`, {
      headers: {
      'x-access-token': this.state.token
    }})
        .catch(err=>console.error(err))
        .then(response => response.json())

        .catch(err=>console.error(err))


        fetch(`${process.env.REACT_APP_SERVER_URL}/changeStudentTillBalance?balanceAfter=${totalBalance}&stuId=${this.state.stu_id_edit}`, {
      headers: {
      'x-access-token': this.state.token
    }})
        .catch(err=>console.error(err))
        .then(response => response.json())

        .catch(err=>console.error(err))
        }



        deleteRecord= (tableName,recordId,idName)=> {
        fetch(`${process.env.REACT_APP_SERVER_URL}/deleteRecord?tableName=${tableName}&recordId=${recordId}&idName=${idName}`, {
      headers: {
      'x-access-token': this.state.token
    }})
        .catch(err=>{console.error(err);
                    this.setState({err:true,
                                  errinfo:err  })} )

        .then(
          e=>{return "success" }
        )

      }


  render(){
    const {
      costOfLesson,
      lessonCost,
      costPerHour,
      ins_fname,
    ins_mNames,
    ins_lname,
    ins_btype,
    ins_mAddress,
    ins_rAddress,
    ins_tpNo,
    ins_email,
    ins_medicalExpiry,
    ins_nextKin,
    ins_nextKinPhone,
    ins_cWeight,
    ins_dob,
    identName,
    ins_PassPhoto,
    ins_profPhoto,
    ins_arn,
    ins_id,
    ins_notes,
    suggestionsIns,
    suggestionsWings,
    stu_id_edit,
    returnedAccountInfo,
    searchJson,
    searchKeyword,
    suggestionsStu,
    passportPhoto,
    bloodType,
    fName,
    mNames,
    lName,
    dob,
    email,
    mAddress,
    rAddress,
    phone,
    nextKin,
    nextKinPhone,
    cWeight,
    LicType,
    accNotes,
    step,
    userName,
    uniquePass,
    open,
    formId,
    formRating,
    stu_name,
    stu_arn,
    fl_date,
    fl_time,
    fl_clock,
    commts,
    current_date,
    pfk_bool,
    nextLesson_bool,
    rego,
    wing_type,
    wing_counter,
    wingLastService,
    wingLastServiceNotes,
    amountPaid,
    transId,
    transTimeStamp,
    transNotes,
    tillBalance,
    newBalance,
    loggedInUser,
    transactions,
    loading,
    signatureUpload,
    ins_Signature,
    stu_Signature,
    profilePic,
    psp1,
    accessLevel,
    kiHash,
    xs_level,
    passTemp,
    isTemp,
    soloGH,
    soloNav,
    dualGH,
    dualNav,
    flightRecords,
    day14History,
    day30History,
    day90History,
    lessonNo,
    recordId,
    appBarTitle,
    flightType,
    fuelStart,
    dualtotal,
    picTotal,
    medical1,
  medical2,
  medical3,
  medical1Checked,
medical2Checked,
medical3Checked,
creditCardName,
creditCardNumber,
creditCardExpiry,
creditCardCVC,
ccNameEnc,
ccNumberEnc,
ccExpEnc,
ccCVCEnc,
passReset,
pax1name,
pax1W,
pax2name,
pax2W,
pax3name,
pax3W,
pax4name,
pax4W,
pax5name,
pax5W,
pax6name,
pax6W,
status,
preflightAuthId,
CLicType,
banner,
pilotName,
emailChanged,
oldEmail,
lessonsProgress,
token,





        } = this.state;

  const values = {
    costOfLesson,
    lessonCost,
    costPerHour,
    ins_fname,
    ins_mNames,
    ins_lname,
    ins_btype,
    ins_mAddress,
    ins_rAddress,
    ins_tpNo,
    ins_email,
    ins_nextKin,
    ins_nextKinPhone,
    ins_cWeight,
    ins_dob,
    ins_medicalExpiry,
    identName,
    ins_PassPhoto,
    ins_profPhoto,
    ins_arn,
    ins_id,
    ins_notes,
    suggestionsIns,
    suggestionsWings,
    stu_id_edit,
    returnedAccountInfo,
    searchJson,
    searchKeyword,
    suggestionsStu,
    passportPhoto,
    bloodType,
    fName,
    mNames,
    lName,
    dob,
    email,
    mAddress,
    rAddress,
    phone,
    nextKin,
    nextKinPhone,
    cWeight,
    LicType,
    accNotes,
    step,
    userName,
    uniquePass,
    open,
    formId,
    formRating,
    stu_name,
    stu_arn,
    fl_date,
    fl_time,
    fl_clock,
    commts,
    current_date,
    pfk_bool,
    nextLesson_bool,
    rego,
    wing_type,
    wing_counter,
    wingLastService,
    wingLastServiceNotes,
    amountPaid,
    transId,
    transTimeStamp,
    transNotes,
    tillBalance,
    newBalance,
    loggedInUser,
    transactions,
    loading,
    signatureUpload,
    ins_Signature,
    stu_Signature,
    profilePic,
    psp1,
    accessLevel,
    kiHash,
    xs_level,
    passTemp,
    isTemp,
    soloGH,
    soloNav,
    dualGH,
    dualNav,
    flightRecords,
    day14History,
    day30History,
    day90History,
    lessonNo,
    recordId,
    appBarTitle,
    flightType,
    fuelStart,
    picTotal,
    dualtotal,
    medical1,
  medical2,
  medical3,
  medical1Checked,
medical2Checked,
medical3Checked,
creditCardName,
creditCardNumber,
creditCardExpiry,
creditCardCVC,
ccNameEnc,
ccNumberEnc,
ccExpEnc,
ccCVCEnc,
passReset,
pax1name,
pax1W,
pax2name,
pax2W,
pax3name,
pax3W,
pax4name,
pax4W,
pax5name,
pax5W,
pax6name,
pax6W,
status,
preflightAuthId,
CLicType,
banner,
pilotName,
emailChanged,
oldEmail,
lessonsProgress,
token,


};

    switch (step) {
      case 0:
        return (
          <Loading
          />
        )

        case 1000:
          return (
            <PasswordReset
            clearState={this.clearState}
            setStep={this.setStep}
            handleChange={this.handleChange}
            values={values}
            />
          )
      case 1001:
        return (
          <WelcomePage
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          values={values}
          />
        )
        case 1002:
          return (
            <LoginPage
            nextStep={this.nextStep}
            setStep={this.setStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            getLoggedInUserInfo={this.getLoggedInUserInfo}
            values={values}
            />
          )
          case 1003:
            return (
              <LoginPagePassword
              nextStep={this.nextStep}
              setStep={this.setStep}
              prevStep={this.prevStep}
              authenticate={this.authenticate}
              handleChange={this.handleChange}
              values={values}
        />
            )
            case 1004:
              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                <HomePage
                nextStep={this.nextStep}
                getAccountInfo5={this.getAccountInfo5}
                prevStep={this.prevStep}
                setStep={this.setStep}
                handleChange={this.handleChange}
                handleChangeInput={this.handleChangeInput}
                handleDoNotShowAgain={this.handleDoNotShowAgain}
                getAccountInfo={this.getAccountInfo6}
                clear={this.clearState}
                values={values}
                /></div>
              )

              case 1005:
                return (
                  <LoginPageAgain
                  nextStep={this.nextStep}
                  setStep={this.setStep}
                  prevStep={this.setStep}
                  handleChange={this.handleChange}
                  getLoggedInUserInfo={this.getLoggedInUserInfo}
                  values={values}
                  />
                )
              case 1006:
              return (
                <LoginPagePasswordAgain
                nextStep={this.nextStep}
                setStep={this.setStep}
                prevStep={this.prevStep}
                authenticate={this.authenticate}
                handleChange={this.handleChange}
                values={values}
                />
              )




              case 2000:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <AccountManagement
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  setStep={this.setStep}
                  startAgain={this.startAgain}
                  handleChange={this.handleChange}
                  clear={this.clearState}
                  values={values}
                  /></div>
                )

              case 2001:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <CreateStudentForm
                  nextStep={this.nextStep}
                  prevStep={this.startAgainAcctMgmt}
                  setStep={this.setStep}
                  handleChange={this.handleChange}
                  handleChangeInput={this.handleChangeInput}
                  log={this.dateChange}
                  log1={this.dateChangeMedical1}
                  log2={this.dateChangeMedical2}
                  log3={this.dateChangeMedical3}
                  values={values}
                  handlePicChange={this.handlePicChange}
                  validateEmail={this.validateEmail}
                  /></div>
                )
                case 2002:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <ProfilePicUpload
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    handleChangeInput={this.handleChangeInput}
                    log={this.dateChange}
                    setStep={this.setStep}
                    values={values}
                    handlePicChange={this.handlePicChange}
                    uploadProfilePic={this.uploadProfilePic}
                    /></div>
                  )


              case 2003:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <SetAccPassword
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChange}
                  handleChangeInput={this.handleChangeInput}
                  log={this.dateChange}
                  values={values}
                  /></div>)

              case 2004:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <PassportPicCapture
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  setPassportPhoto={this.setPassportPhoto}
                  handleChangeInput={this.handleChangeInput}
                  setStep={this.setStep}
                  handleChange={this.handleChange}
                  log={this.dateChange}
                  values={values}
                  /></div>)
                  case 2005:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <PassportPicConfirm
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setPassportPhoto={this.setPassportPhoto}
                      handleChangeInput={this.handleChangeInput}
                      setStep={this.setStep}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      uploadPassportImage={this.uploadPassportImage}
                      /></div>)

                      case 2006:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <SignatureCapture
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          setStep={this.setStep}
                          setSignatureUpload={this.setSignatureUpload}
                          handleChange={this.handleChange}
                          log={this.dateChange}
                          values={values}
                          /></div>)

                          case 2007:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                              <SignatureConfirm
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              uploadSignatureImage={this.uploadSignatureImage}
                              handleChange={this.handleChange}
                              log={this.dateChange}
                              values={values}
                              /></div>)
              case 2008:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <VerifyNewStudentDetails
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChange}
                  log={this.dateChange}
                  addStudent={this.addStudent}
                  values={values}




                  /></div>)
            case 2009:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <Success1
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            startAgain={this.startAgainAcctMgmt}
                            handleChange={this.handleChange}
                            values={values}
                            clearState={this.clearState}
                            /></div>
                          )
              case 2010:
              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                <CreditCardInfo
                nextStep={this.nextStep}
                setStep={this.setStep}
                cCEncrpt={this.cCEncrpt}
                prevStep={this.prevStep}
                startAgain={this.startAgainAcctMgmt}
                handleChange={this.handleChange}
                values={values}
                handleChangeInput={this.handleChangeInput}
                clearState={this.clearState}
                /></div>
              )


              case 2500:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <SearchStudent
                  nextStep={this.nextStep}
                  startAgain={this.startAgain}
                  prevStep={this.startAgainAcctMgmt}
                  handleChange={this.handleChangeInput}
                  getStudentList={this.getStudentList}
                  log={this.dateChange}
                  getAccountInfo={this.getAccountInfo}
                  onTagsChange={this.onTagsChange}
                  values={values}
                  /></div>
                )
                case 2501:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>

                    <EditStudentAccount
                    getCCInfo={this.getCCInfo}
                    setStep={this.setStep}
                    prevStep={this.prevStep}
                    clearState={this.clearState}
                    handleChange={this.handleChange}
                    handleChangeInput={this.handleChangeInput}
                    log={this.dateChange}
                    log1={this.dateChangeMedical1}
                    log2={this.dateChangeMedical2}
                    log3={this.dateChangeMedical3}
                    getAccountInfo={this.getAccountInfo2}
                    values={values}
                    /></div>
                  )
                  case 2502:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <PassportPicEditQ
                      setStep={this.setStep}
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setPassportPhoto={this.setPassportPhoto}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      getAccountInfo={this.getAccountInfo}
                      values={values}
                      /></div>)
                  case 2503:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <PassportPicEditCapture
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setPassportPhoto={this.setPassportPhoto}
                      handleChange={this.handleChange}
                      handleChangeInput={this.handleChangeInput}
                      setStep={this.setStep}
                      log={this.dateChange}
                      values={values}
                      /></div>)
                  case 2504:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <PassportPicEditConfirm
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setPassportPhoto={this.setPassportPhoto}
                      uploadPassportImage={this.uploadPassportImage}
                      handleChangeInput={this.handleChangeInput}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      setStep={this.setStep}

                      /></div>)
                  case 2505:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <VerifyEditStudentDetails
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      startAgain={this.startAgainAcctMgmt}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      editStudent={this.editStudent2}
                      values={values}
                      /></div>)
                  case 2506:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <Success1
                            startAgain={this.startAgainAcctMgmt}

                            clearState={this.clearState}
                            /></div>
                          )
                  case 2507:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <UpdateDBpassport
                            editStudent={this.editStudent}
                            values={values}

                            clearState={this.editStudent}
                            /></div>
                          )

                  case 2600:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <ResetAccPassword
                      prevStep={this.prevStep}
                      handleChangeInput={this.handleChangeInput}
                      setStep={this.setStep}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      /></div>
                    )
                  case 2601:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <PasswordDBupdate
                        setStep={this.setStep}
                        handleChange={this.handleChange}
                        editStudent={this.editStuPassword}
                        log={this.dateChange}
                        values={values}
                        /></div>
)



                  case 2700:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <ProfilePicUpdate
                      setStep={this.setStep}
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      handlePicChange={this.handlePicChange}
                      handleChangeInput={this.handleChangeInput}
                      updateProfilePic={this.uploadProfilePic}
                      setPassportPhoto={this.setPassportPhoto}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      /></div>
                    )
                  case 2701:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <UpdateDBpic
                      setStep={this.setStep}
                      editStudent={this.editStudent}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      /></div>
                    )

                  case 2800:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <UpdateSignatureQ
                      setStep={this.setStep}
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setPassportPhoto={this.setPassportPhoto}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      /></div>
                    )
                  case 2801:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <SignatureEditCapture
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setStep={this.setStep}
                      setSignatureUpload={this.setSignatureUpload}
                      handleChange={this.handleChange}
                      log={this.dateChange}
                      values={values}
                      /></div>
                    )

                  case 2802:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SignatureEditConfirm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                        uploadSignatureImage={this.uploadSignatureImage}
                        handleChange={this.handleChange}
                        log={this.dateChange}
                        values={values}
                        /></div>
                        )
                    case 2803:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <SignatureDBupdate
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          editStudent={this.editStudent}
                          handleChange={this.handleChange}
                          log={this.dateChange}
                          values={values}
                          /></div>
                        )
                        case 2850:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <CreditCardInfoView
                          nextStep={this.nextStep}
                          getCCInfo={this.getCCInfo}
                          setStep={this.setStep}
                          cCEncrpt={this.cCEncrpt}
                          cCDecrpt={this.cCDecrpt}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainAcctMgmt}
                          handleChange={this.handleChange}
                          values={values}
                          handleChangeInput={this.handleChangeInput}
                          clearState={this.clearState}
                          /></div>
                        )
                        case 2851:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <CreditCardInfoEdit
                          nextStep={this.nextStep}
                          getCCInfo={this.getCCInfo}
                          setStep={this.setStep}
                          addCardinfo={this.addCardinfo}
                          cCEncrpt={this.cCEncrpt}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainAcctMgmt}
                          handleChange={this.handleChange}
                          values={values}
                          handleChangeInput={this.handleChangeInput}
                          clearState={this.clearState}
                          /></div>
                        )
                        case 2852:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <CreditCardInfoVerify
                          nextStep={this.nextStep}
                          getCCInfo={this.getCCInfo}
                          setStep={this.setStep}
                          updateCardinfo={this.updateCardinfo}
                          cCEncrpt={this.cCEncrpt}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainAcctMgmt}
                          handleChange={this.handleChange}
                          values={values}
                          handleChangeInput={this.handleChangeInput}
                          clearState={this.clearState}
                          /></div>
                        )
                        case 2853:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <Success2
                          nextStep={this.nextStep}
                          getCCInfo={this.getCCInfo}
                          setStep={this.setStep}
                          updateCardinfo={this.updateCardinfo}
                          cCEncrpt={this.cCEncrpt}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainAcctMgmt}
                          handleChange={this.handleChange}
                          values={values}
                          handleChangeInput={this.handleChangeInput}
                          clearState={this.clearState}
                          /></div>
                        )


                    case 2900:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <SelectStudentForm
                    nextStep={this.nextStep}
                    prevStep={this.startAgain}
                    handleChange={this.handleChangeInput}
                    getStudentList={this.getStudentList}
                    getAccountInfo={this.getAccountInfo7}
                    onTagsChange={this.onTagsChange}
                    values={values}
                    /></div>
                  )
                  case 2901:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>

                    <EditStudentAccount
                    setStep={this.setStep}
                    prevStep={this.prevStep}
                    clearState={this.clearState}
                    handleChange={this.handleChange}
                    handleChangeInput={this.handleChangeInput}
                    log={this.dateChange}
                    getAccountInfo={this.getAccountInfo2}
                    values={values}
                    /></div>
                  )




              case 2999:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <TrainingManagement
                  nextStep={this.nextStep}
                  prevStep={this.startAgain}
                  setStep={this.setStep}
                  startAgain={this.startAgain}
                  handleChange={this.handleChange}
                  clear={this.clearState}
                  values={values}
                  getAllTrainingHistory={this.getAllTrainingHistory}
                  getLoggedInUserTraining={this.getLoggedInUserTraining}
                  /></div>
                )
                case 2998:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <PendingPreFlightAuth
                    nextStep={this.nextStep}
                    prevStep={this.startAgain}
                    setStep={this.setStep}
                    startAgain={this.startAgain}
                    handleChange={this.handleChange}
                    handleChangeInput={this.handleChangeInput}
                    createRadioGroups={this.displayPendingPreFlightAuths}
                    clear={this.clearState}
                    values={values}
                    getAllTrainingHistory={this.getAllTrainingHistory}
                    getLoggedInUserTraining={this.getLoggedInUserTraining}
                    /></div>
                  )



            case 3000:
              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
              <SelectForm
              nextStep={this.nextStep}
              prevStep={this.startAgaintrainingMgmt}
              handleChange={this.handleChange}
              values={values}
              setRadio={this.setRadio} //*set default values of the form to the state array object*//
              setFormId={this.setFormId}
              /></div>
            )
              case 3001:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <SelectStudentForm
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  handleChange={this.handleChangeInput}
                  getStudentList={this.getStudentList}
                  log={this.dateChange}
                  getAccountInfo={this.getAccountInfo4}
                  onTagsChange={this.onTagsChange}
                  values={values}
                  getWingsList={this.getWingsList}
                  /></div>
                )
                case 3002:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <SelectInstructorForm
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChangeInput}
                    getStudentList={this.getInstructors}
                    log={this.dateChange}
                    onTagsChange={this.onTagsChange}
                    getAccountInfo={this.getInsAccountInfo}
                    values={values}
                    getWingsList={this.getWingsList}
                    /></div>
                  )
                  case 3003:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <SelectWingsForm
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      handleChange={this.handleChangeInput}
                      log={this.dateChange}
                      onTagsChange={this.onTagsChange}
                      getAccountInfo={this.getWingInfo}
                      values={values}
                      getStudentList={this.getWingsList}
                      /></div>
                    )
                    case 3004:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectDate
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChangeInput}
                        getStudentList={this.getStudentList}
                        //log={this.dateChangeFlDate}
                        log={this.FlTimeChange}
                        getAccountInfo={this.getAccountInfo}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        /></div>
                      )


                case 3005:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <SelectEngineHours
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    getCustomPrice={this.getCustomPrice}
                    startAgain={this.startAgain}
                    handleChange={this.handleChange}
                    handleChangeInput={this.handleChangeInput}
                    values={values}
                    setRadio={this.setRadio} //*set default values of the form to the state array object*//
                    setFormId={this.setFormId}
                    /></div>
                  )


                case 3006:
                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <LessonCond
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgain}
                  setRadio={this.setRadio} //*set default values of the form to the state array object*//
                  defaultRadio={this.defaultRadio}
                  handleChange={this.handleChange}
                  handleChangeRadio={this.handleChangeRadio}
                  createRadioGroups={this.createRadioGroups}
                  values={values}

                  /></div>
                )


                case 3007:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <AddComments
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgain}
                  handleChange={this.handleChange}
                  values={values}
                  /></div>
                )
                case 3008:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <SignIns
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgain}
                  handleChange={this.handleChange}
                  values={values}
                  /></div>
                )
                case 3009:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <SignStu
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    setStep={this.setStep}
                    startAgain={this.startAgain}
                    handleChange={this.handleChange}
                    values={values}
                    getLessonNumber={this.getLessonNumber}
                    handleChangeInput={this.handleChangeInput}

                    /></div>
                  )
                case 3010:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <FormReview
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      startAgain={this.startAgain}
                      handleChange={this.handleChange}
                      enterTrainingRecord={this.enterTrainingRecord}
                      addDebit={this.addDebit}
                      values={values}
                      getLessonNumber={this.getLessonNumber}
                      /></div>
                    )
                case 3011:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <Success
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgaintrainingMgmt={this.startAgaintrainingMgmt}
                          startAgain={this.startAgain}
                          handleChange={this.handleChange}
                          values={values}
                          clearState={this.clearState}
                          /></div>
                        )

              case 3100:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <SelectStudentForm
                  nextStep={this.nextStep}
                  prevStep={this.startAgaintrainingMgmt}
                  handleChange={this.handleChangeInput}
                  getStudentList={this.getStudentList}
                  log={this.dateChange}
                  getAccountInfo={this.getAccountInfo7}
                  onTagsChange={this.onTagsChange}
                  values={values}
                  getWingsList={this.getWingsList}
                  /></div>
                )


                case 3101:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <AddHours
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      startAgain={this.startAgaintrainingMgmt}
                      handleChange={this.handleChange}
                      handleChangeInput={this.handleChangeInput}
                      values={values}
                      /></div>
                    )

                case 3102:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <ReviewAddHours
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      startAgain={this.startAgaintrainingMgmt}
                      addHours={this.addHours}
                      values={values}
                      /></div>
                    )
                case 3103:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <Success1
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgaintrainingMgmt}
                          handleChange={this.handleChange}
                          values={values}
                          clearState={this.clearState}
                          /></div>
                        )

                case 3200:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <SelectStudentForm
                      nextStep={this.nextStep}
                      prevStep={this.startAgaintrainingMgmt}
                      handleChange={this.handleChangeInput}
                      getStudentList={this.getStudentList}
                      getAccountInfo={this.getFlightHistoryforStudent}
                      onTagsChange={this.onTagsChange}
                      values={values}
                      />
                      </div>
                    )
                case 3201:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <FlightHistoryTable
                      prevStep={this.prevStep}
                      setStep={this.setStep}
                      createRadioGroups={this.displayFlightHistory}
                      getSoloGHTots={this.getSoloGHTots}
                      getSoloNavTots={this.getSoloNavTots}
                      getDualGHTots={this.getDualGHTots}
                      getDualNavTots={this.getDualNavTots}
                      getFlightTimeTots={this.getFlightTimeTots}
                      getCostTots={this.getCostTots}
                      values={values}
                      get14SoloGHTots={this.get14SoloGHTots}
                      get14SoloNavTots={this.get14SoloNavTots}
                      get14DualGHTots={this.get14DualGHTots}
                      get14DualNavTots={this.get14DualNavTots}
                      get14FlightTimeTots={this.get14FlightTimeTots}
                      get30SoloGHTots={this.get30SoloGHTots}
                      get30SoloNavTots={this.get30SoloNavTots}
                      get30DualGHTots={this.get30DualGHTots}
                      get30DualNavTots={this.get30DualNavTots}
                      get30FlightTimeTots={this.get30FlightTimeTots}
                      get90SoloGHTots={this.get90SoloGHTots}
                      get90SoloNavTots={this.get90SoloNavTots}
                      get90DualGHTots={this.get90DualGHTots}
                      get90DualNavTots={this.get90DualNavTots}
                      get90FlightTimeTots={this.get90FlightTimeTots}
                      startAgain={this.startAgaintrainingMgmt}
                      getLoggedInUserTraining={this.getLoggedInUserTraining}


                  /></div>)
                case 3202:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <FormView
                        nextStep={this.nextStep}
                        setStep={this.setStep}
                        setRadio={this.setRadio}
                        prevStep={this.prevStep}
                        startAgain={this.startAgain}
                        handleChangeInput={this.handleChangeInput}
                        values={values}
                        /></div>
                      )
                      case 3203:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <EditSelectStudentForm
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChange={this.handleChangeInput}
                          getStudentList={this.getStudentList}
                          log={this.dateChange}
                          getAccountInfo={this.getAccountInfo4}
                          onTagsChange={this.onTagsChange}
                          values={values}
                          getWingsList={this.getWingsList}

                          /></div>
                        )
                        case 3204:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <EditSelectInstructorForm
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            setStep={this.setStep}
                            handleChange={this.handleChangeInput}
                            getStudentList={this.getInstructors}
                            log={this.dateChange}
                            onTagsChange={this.onTagsChange}
                            getAccountInfo={this.getInsAccountInfo}
                            values={values}
                            getWingsList={this.getWingsList}
                            /></div>
                          )
                          case 3205:
                              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                              <EditSelectWingsForm
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              handleChange={this.handleChangeInput}
                              log={this.dateChange}
                              onTagsChange={this.onTagsChange}
                              getAccountInfo={this.getWingInfo}
                              values={values}
                              getStudentList={this.getWingsList}
                              /></div>
                            )
                            case 3206:
                                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                                <EditSelectDate
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                handleChange={this.handleChangeInput}
                                getStudentList={this.getStudentList}
                                //log={this.dateChangeFlDate}
                                log={this.FlTimeChange}
                                getAccountInfo={this.getAccountInfo}
                                onTagsChange={this.onTagsChange}
                                values={values}
                                getWingsList={this.getWingsList}
                                /></div>
                              )


                        case 3207:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <EditSelectEngineHours
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            getCustomPrice={this.getCustomPrice}
                            startAgain={this.startAgain}
                            handleChange={this.handleChange}
                            handleChangeInput={this.handleChangeInput}
                            values={values}
                            setFormId={this.setFormId}
                            /></div>
                          )


                        case 3208:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <EditLessonCond
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgain}
                          setRadio={this.setRadio} //*set default values of the form to the state array object*//
                          defaultRadio={this.defaultRadio}
                          handleChange={this.handleChange}
                          handleChangeRadio={this.handleChangeRadio}
                          createRadioGroups={this.createRadioGroupsEdit}
                          values={values}

                          /></div>
                        )


                        case 3209:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <EditAddComments
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgain}
                          handleChange={this.handleChange}
                          values={values}
                          /></div>
                        )
                        case 3210:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <EditSignIns
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgain}
                          handleChange={this.handleChange}
                          values={values}
                          /></div>
                        )
                        case 3211:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <EditSignStu
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            startAgain={this.startAgain}
                            handleChange={this.handleChange}
                            values={values}
                            getLessonNumber={this.getLessonNumber}
                            handleChangeInput={this.handleChangeInput}

                            /></div>
                          )
                        case 3212:
                              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                              <EditFormReview
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              startAgain={this.startAgain}
                              enterTrainingRecord={this.editTrainingRecord}
                              values={values}
                              /></div>
                            )
                        case 3213:
                                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                                  <EditSuccess
                                  nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  startAgain={this.startAgain}
                                  startAgaintrainingMgmt={this.startAgaintrainingMgmt}
                                  handleChange={this.handleChange}
                                  values={values}
                                  clearState={this.clearState}
                                  /></div>
                                )

                case 3400:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <FlightHistoryTable2
                      createRadioGroups={this.displayFlightHistory2}
                      getSoloGHTots={this.getSoloGHTots}
                      getSoloNavTots={this.getSoloNavTots}
                      getDualGHTots={this.getDualGHTots}
                      getDualNavTots={this.getDualNavTots}
                      getFlightTimeTots={this.getFlightTimeTots}
                      getCostTots={this.getCostTots}
                      values={values}
                      get14SoloGHTots={this.get14SoloGHTots}
                      get14SoloNavTots={this.get14SoloNavTots}
                      get14DualGHTots={this.get14DualGHTots}
                      get14DualNavTots={this.get14DualNavTots}
                      get14FlightTimeTots={this.get14FlightTimeTots}
                      get30SoloGHTots={this.get30SoloGHTots}
                      get30SoloNavTots={this.get30SoloNavTots}
                      get30DualGHTots={this.get30DualGHTots}
                      get30DualNavTots={this.get30DualNavTots}
                      get30FlightTimeTots={this.get30FlightTimeTots}
                      get90SoloGHTots={this.get90SoloGHTots}
                      get90SoloNavTots={this.get90SoloNavTots}
                      get90DualGHTots={this.get90DualGHTots}
                      get90DualNavTots={this.get90DualNavTots}
                      get90FlightTimeTots={this.get90FlightTimeTots}
                      startAgain={this.startAgaintrainingMgmt}
                      /></div>
                    )

                case 4000:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <InsManagement
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      setStep={this.setStep}
                      startAgain={this.startAgain}
                      handleChange={this.handleChange}
                      clear={this.clearState}
                      values={values}
                      /></div>
                    )

                case 4001:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <CreateInstructorForm
                        nextStep={this.nextStep}
                        prevStep={this.startAgainInsMgmt}
                        handleChangeInput={this.handleChangeInput}
                        handleChange={this.handleChange}
                        setStep={this.setStep}
                        log={this.dateChangeIns}
                        logMedical={this.dateChangeMedicalIns}
                        values={values}
                        /></div>
                      )



               case 4002:
                   return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                   <InsProfilePicUpload
                   nextStep={this.nextStep}
                   prevStep={this.prevStep}
                   setStep={this.setStep}
                   handleChangeInput={this.handleChangeInput}
                   log={this.dateChange}
                   values={values}
                   handlePicChangeIns={this.handlePicChangeIns}
                   uploadProfilePic={this.uploadProfilePicIns}
                   /></div>
                 )
                 case 4003:
                     return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                         <SetAccPasswordIns
                         nextStep={this.nextStep}
                         prevStep={this.prevStep}
                         handleChange={this.handleChange}
                         handleChangeInput={this.handleChangeInput}
                         log={this.dateChange}
                         values={values}
                                 /></div>)
              case 4004:
                 return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                 <InsPassportPicCapture
                 nextStep={this.nextStep}
                 prevStep={this.prevStep}
                 setPassportPhoto={this.setPassportPhotoIns}
                 handleChangeInput={this.handleChangeInput}
                 handleChange={this.handleChange}
                 log={this.dateChange}
                 values={values}
                 /></div>)
            case 4005:
                     return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                     <InsPassportPicConfirm
                     nextStep={this.nextStep}
                     prevStep={this.prevStep}
                     setPassportPhoto={this.setPassportPhotoIns}
                     handleChange={this.handleChange}
                     log={this.dateChange}
                     values={values}
                     handleChangeInput={this.handleChangeInput}
                     setStep={this.setStep}
                     uploadPassportImage={this.uploadPassportImageIns}
                     /></div>)

              case 4006:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                         <InsSignatureCapture
                         nextStep={this.nextStep}
                         prevStep={this.prevStep}
                         setStep={this.setStep}
                         setSignatureUpload={this.setSignatureUpload}
                         handleChange={this.handleChange}
                         log={this.dateChange}
                         values={values}
                         /></div>)

                         case 4007:
                             return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                             <InsSignatureConfirm
                             nextStep={this.nextStep}
                             prevStep={this.prevStep}
                             uploadSignatureImage={this.uploadSignatureImage}
                             handleChange={this.handleChange}
                             log={this.dateChange}
                             values={values}
                             /></div>)

                case 4008:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <VerifyNewInstructorDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        log={this.dateChange}
                        values={values}
                        addInstructor={this.addInstructor}
                        /></div>)
                case 4009:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <Success1
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgainInsMgmt}
                  handleChange={this.handleChange}
                  values={values}
                  clearState={this.clearState}
                  /></div>
                )

                case 4100:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <SelectInstructorForm
                    nextStep={this.nextStep}
                    prevStep={this.startAgainInsMgmt}
                    handleChange={this.handleChangeInput}
                    getStudentList={this.getInstructors}
                    log={this.dateChange}
                    onTagsChange={this.onTagsChange}
                    getAccountInfo={this.getInsAccountInfo}
                    values={values}
                    getWingsList={this.getWingsList}
                    /></div>
                  )

                case 4101:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <EditInstructorForm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setStep={this.setStep}
                        handleChange={this.handleChange}
                        handleChangeInput={this.handleChangeInput}
                        log={this.dateChangeIns}
                        logMedical={this.dateChangeMedicalIns}
                        values={values}
                        /></div>
                      )
                      case 4200:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsPassportPicEditQ
                          setStep={this.setStep}
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          values={values}
                          /></div>)
                      case 4201:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsPassportPicEditCapture
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          setPassportPhoto={this.setPassportPhotoIns}
                          handleChange={this.handleChange}
                          log={this.dateChange}
                          values={values}
                          /></div>)
                      case 4202:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsPassportPicEditConfirm
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChangeInput={this.handleChangeInput}
                          setStep={this.setStep}
                          uploadPassportImage={this.uploadPassportImageIns}
                          handleChange={this.handleChange}
                          log={this.dateChange}
                          values={values}
                          />
                          </div>)


                      case 4203:
                                return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                                <InsUpdateDBpassport
                                editInstructor={this.editInstructor}
                                values={values}

                                clearState={this.editInstructor}
                                /></div>
                              )

                      case 4300:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsResetAccPassword
                          prevStep={this.prevStep}
                          handleChangeInput={this.handleChangeInput}
                          setStep={this.setStep}
                          handleChange={this.handleChange}
                          log={this.dateChange}
                          values={values}
                          /></div>)

                      case 4301:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsPasswordDBupdate
                          setStep={this.setStep}
                          handleChange={this.handleChange}
                          editInstructor={this.editInsPassword}
                          log={this.dateChange}
                          values={values}
                          /></div>)

                      case 4400:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsProfilePicUpdate
                          setStep={this.setStep}
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChangeInput={this.handleChangeInput}
                          handlePicChange={this.handlePicChangeIns}
                          updateProfilePic={this.uploadProfilePicIns}
                          values={values}
                          /></div>)

                      case 4401:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsUpdateDBpic
                          setStep={this.setStep}
                          editInstructor={this.editInstructor}
                          values={values}
                          /></div>)

                      case 4500:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsUpdateSignatureQ
                          setStep={this.setStep}
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          values={values}
                          /></div>)
                      case 4501:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <InsSignatureEditCapture
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          setStep={this.setStep}
                          setSignatureUpload={this.setSignatureUpload}
                          handleChange={this.handleChange}
                          log={this.dateChange}
                          values={values}
                          /></div>)

                      case 4502:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <InsSignatureEditConfirm
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            setStep={this.setStep}
                            uploadSignatureImage={this.uploadSignatureImage}
                            handleChange={this.handleChange}
                            log={this.dateChange}
                            values={values}
                            /></div>)
                        case 4503:
                              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                              <InsSignatureDBupdate
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              editInstructor={this.editInstructor}
                              handleChange={this.handleChange}
                              log={this.dateChange}
                              values={values}
                              /></div>)

                        case 4600:
                              return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                              <SelectInstructorForm
                              nextStep={this.nextStep}
                              prevStep={this.startAgainInsMgmt}
                              handleChange={this.handleChangeInput}
                              getStudentList={this.getInstructors}
                              log={this.dateChange}
                              onTagsChange={this.onTagsChange}
                              getAccountInfo={this.getInsAccountInfo}
                              values={values}
                              getWingsList={this.getWingsList}
                              /></div>
                            )

                        case 4601:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                                <EditInstructorForm
                                nextStep={this.nextStep}
                                prevStep={this.prevStep}
                                setStep={this.setStep}
                                handleChange={this.handleChange}
                                log={this.dateChangeIns}
                                logMedical={this.dateChangeMedicalIns}
                                values={values}
                                /></div>
                              )

                case 4102:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <VerifyEditInstructorDetails
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        log={this.dateChange}
                        values={values}
                        updateInstructor={this.editInstructor}
                        /></div>)
                case 4103:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <Success1
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgainInsMgmt}
                  handleChange={this.handleChange}
                  values={values}
                  clearState={this.clearState}
                  /></div>
                )







                case 5000:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <FleetManagement
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    setStep={this.setStep}
                    startAgain={this.startAgain}
                    handleChange={this.handleChange}
                    clear={this.clearState}
                    values={values}
                    /></div>
                  )

                case 5001:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <AddAircraft
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgain}
                  logServiceDate={this.logServiceDate}
                  handleChange={this.handleChange}
                  values={values}
                  addAirCraft={this.addAirCraft}
                  clearState={this.clearState}
                  /></div>
                )
                case 5002:
                  return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                  <Success1
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgainFleetMgmt}
                  handleChange={this.handleChange}
                  values={values}
                  clearState={this.clearState}
                  /></div>
                )
                case 5100:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <SelectWingsForm
                    nextStep={this.nextStep}
                    prevStep={this.startAgainFleetMgmt}
                    handleChange={this.handleChangeInput}
                    log={this.dateChange}
                    onTagsChange={this.onTagsChange}
                    getAccountInfo={this.getWingInfo}
                    values={values}
                    getStudentList={this.getWingsList}
                    /></div>
                  )
                  case 5101:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                    <EditAircraft
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    startAgain={this.startAgain}
                    logServiceDate={this.logServiceDate}
                    handleChange={this.handleChange}
                    values={values}
                    editAirCraft={this.editAirCraft}
                    clearState={this.clearState}
                    /></div>)
                    case 5102:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <Success1
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      startAgain={this.startAgainFleetMgmt}
                      handleChange={this.handleChange}
                      values={values}
                      clearState={this.clearState}
                      /></div>
                    )
                    case 5200:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectWingsForm
                        nextStep={this.nextStep}
                        prevStep={this.startAgainFleetMgmt}
                        handleChange={this.handleChangeInput}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        getAccountInfo={this.getWingInfo}
                        values={values}
                        getStudentList={this.getWingsList}
                        /></div>
                      )
                      case 5201:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <DeleteAircraft
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        startAgain={this.startAgain}
                        values={values}
                        deleteAirCraft={this.deleteAirCraft}
                        clearState={this.clearState}
                        /></div>)
                        case 5202:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <Success1
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainFleetMgmt}
                          handleChange={this.handleChange}
                          values={values}
                          clearState={this.clearState}
                          /></div>
                        )
                  case 5300:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectStudentFormPriceEdit
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChangeInput}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        getAccountInfo={this.getAccountInfo4}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        /></div>
                      )
                      case 5301:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <SelectWingsFormPriceEdit
                          nextStep={this.nextStep}
                          prevStep={this.startAgainFleetMgmt}
                          handleChange={this.handleChangeInput}
                          log={this.dateChange}
                          onTagsChange={this.onTagsChange}
                          getAccountInfo={this.getWingInfo}
                          values={values}
                          getStudentList={this.getWingsList}
                          /></div>
                        )

                        case 5302:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <SetPrice
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            handleChangeInput={this.handleChangeInput}
                            values={values}
                            /></div>
                          )
                        case 5303:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <ConfirmationIndividualPrices
                            nextStep={this.nextStep}
                            prevStep={this.startAgainFleetMgmt}
                            setCustomPrice={this.setCustomPrice}
                            values={values}

                            /></div>
                          )
                        case 5304:
                            return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                            <Success1
                            nextStep={this.nextStep}
                            startAgain={this.startAgainFleetMgmt}
                            clearState={this.clearState}

                            /></div>
                          )


                  case 6000:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <FinanceManagement
                      nextStep={this.nextStep}
                      prevStep={this.startAgain}
                      setStep={this.setStep}
                      startAgain={this.startAgain}
                      handleChange={this.handleChange}
                      clear={this.clearState}
                      values={values}
                      getAllTransactions={this.getAllTransactions}
                      getLoggedInUserTransactions={this.getLoggedInUserTransactions}
                      /></div>
                    )
                    case 6100:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <SelectStudentFormAddPayment
                      nextStep={this.nextStep}
                      prevStep={this.startAgainFinanceMgmt}
                      handleChange={this.handleChangeInput}
                      log={this.dateChange}
                      onTagsChange={this.onTagsChange}
                      getAccountInfo={this.getAccountInfo3}
                      values={values}
                      getStudentList={this.getStudentList}
                      /></div>
                    )
                    case 6101:
                      return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <SelectAmount
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      startAgain={this.startAgain}
                      log={this.logtransTimeStamp}
                      getAccountInfo={this.getAccountInfo}
                      handleChange={this.handleChange}
                      values={values}
                      editAirCraft={this.editAirCraft}
                      clearState={this.clearState}
                      /></div>)

                      case 6102:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <Confirmation
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        startAgain={this.startAgain}
                        log={this.logtransTimeStamp}
                        setTotalBalance={this.setTotalBalance}
                        handleChange={this.handleChange}
                        values={values}
                        addCredit={this.addCredit}
                        clearState={this.clearState}
                        /></div>)
                      case 6103:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <Success1
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        startAgain={this.startAgainFinanceMgmt}
                        handleChange={this.handleChangeBalance}
                        values={values}
                        clearState={this.clearState}
                        /></div>
                      )
                      case 6200:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectStudentFormAddExpense
                        nextStep={this.nextStep}
                        prevStep={this.startAgainFinanceMgmt}
                        handleChange={this.handleChangeInput}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        getAccountInfo={this.getAccountInfo3}
                        values={values}
                        getStudentList={this.getStudentList}
                        /></div>
                      )
                      case 6201:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectAmount
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        startAgain={this.startAgain}
                        log={this.logtransTimeStamp}
                        getAccountInfo={this.getAccountInfo}
                        handleChange={this.handleChange}
                        values={values}
                        clearState={this.clearState}
                        /></div>)

                        case 6202:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <ConfirmationDebit
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgain}
                          log={this.logtransTimeStamp}
                          setTotalBalance={this.setTotalBalance}
                          handleChange={this.handleChange}
                          values={values}
                          addDebit={this.addDebit}
                          clearState={this.clearState}
                          /></div>)
                        case 6203:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <Success1
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainFinanceMgmt}
                          handleChange={this.handleChangeBalance}
                          values={values}
                          clearState={this.clearState}
                          /></div>
                        )
                        case 6300:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <SelectStudentFormAccHistory
                          nextStep={this.nextStep}
                          prevStep={this.startAgainFinanceMgmt}
                          handleChange={this.handleChangeInput}
                          log={this.dateChange}
                          onTagsChange={this.onTagsChange}
                          getAccountInfo={this.getAccountTransactions}
                          values={values}
                          getStudentList={this.getStudentList}
                          /></div>
                        )
                        case 6301:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <HistoryTable
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgain}
                          setRadio={this.setRadio} //*set default values of the form to the state array object*//
                          defaultRadio={this.defaultRadio}
                          handleChangeInput={this.handleChangeInput}
                          handleChange={this.handleChange}
                          handleChangeRadio={this.handleChangeRadio}
                          createRadioGroups={this.displayTransactions}
                          values={values}
                          setStep={this.setStep}

                          /></div>)

                      case 6400:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <AllHistoryTable
                        nextStep={this.nextStep}
                        prevStep={this.startAgainFinanceMgmt}
                        startAgain={this.startAgain}
                        createRadioGroups={this.displayAllTransactions}
                        getDebitsOnly={this.getDebitsOnly}
                        getCreditsOnly={this.getCreditsOnly}
                        getAllTransactions={this.getAllTransactions}
                        values={values}
                        /></div>
                      )
                      case 6401:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectAmount
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        startAgain={this.startAgain}
                        log={this.logtransTimeStamp}
                        getAccountInfo={this.getAccountInfo}
                        handleChange={this.handleChange}
                        values={values}
                        clearState={this.clearState}
                        editTransaction={this.editTransaction}
                        /></div>)


                        case 6402:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <Success1
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          startAgain={this.startAgainFinanceMgmt}
                          handleChange={this.handleChangeBalance}
                          values={values}
                          clearState={this.clearState}
                          /></div>
                        )

                      case 7000:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <FlightType
                        nextStep={this.nextStep}
                        setStep={this.setStep}
                        handleChangeInput={this.handleChangeInput}
                        startAgain={this.startAgain}
                        values={values}
                        /></div>
                      )
                      case 7001:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <FlightAuthStudentSelect
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        getCCExpiry={this.getCCExpiry}
                        handleChange={this.handleChangeInput}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        getAccountInfo={this.getAccountInfo7}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        handleChangeInput={this.handleChangeInput}
                        /></div>
                      )
                      case 10002:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SelectWingsFormPreFlight
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        handleChange={this.handleChangeInput}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        getAccountInfo={this.getWingInfo}
                        values={values}
                        getStudentList={this.getWingsList}
                        /></div>
                      )
                      case 7002:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <PreFlightInfoForm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        setStep={this.setStep}
                        handleChange={this.handleChangeInput}
                        getAccountInfo={this.getAccountInfo4}
                        getFlightHistoryforStudent={this.getFlightHistoryforStudent}
                        handleChangeInput={this.handleChangeInput}
                        getCCExpiry={this.getCCExpiry}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        enterPreFlightAuth={this.enterPreFlightAuth}
                        /></div>
                      )
                      case 7003:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <SuccessPreFlight
                        clearState={this.clearState}
                        setStep={this.setStep}
                        /></div>
                      )

                      case 7005:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <FlightAuthSelectDate
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        setStep={this.setStep}
                        handleChange={this.handleChangeInput}
                        getAccountInfo={this.getAccountInfo4}
                        handleChangeInput={this.handleChangeInput}
                        getCCExpiry={this.getCCExpiry}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        enterPreFlightAuth={this.enterPreFlightAuth}
                        /></div>
                      )
                      case 7006:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <DisplayPreFlightAuths
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        setStep={this.setStep}
                        handleChange={this.handleChangeInput}
                        getAccountInfo={this.getAccountInfo4}
                        handleChangeInput={this.handleChangeInput}
                        getCCExpiry={this.getCCExpiry}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        createRadioGroups={this.displayDailyPreFlightAuths}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        enterPreFlightAuth={this.enterPreFlightAuth}
                        /></div>
                      )
                      case 7007:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <PreFlightInfoForm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        setStep={this.setStep}
                        handleChange={this.handleChangeInput}
                        getAccountInfo={this.getAccountInfo4}
                        getFlightHistoryforStudent={this.getFlightHistoryforStudent}
                        handleChangeInput={this.handleChangeInput}
                        getCCExpiry={this.getCCExpiry}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        enterPreFlightAuth={this.enterPreFlightAuth}
                        editpreflightAuth={this.editpreflightAuth}
                        /></div>
                      )


                  case 7049:
                    return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                      <PendingCharterPreFlightAuth
                      nextStep={this.nextStep}
                      prevStep={this.startAgain}
                      setStep={this.setStep}
                      startAgain={this.startAgain}
                      handleChange={this.handleChange}
                      handleChangeInput={this.handleChangeInput}
                      createRadioGroups={this.displayPendingCharterPreFlightAuths}
                      clear={this.clearState}
                      values={values}
                      getAllTrainingHistory={this.getAllTrainingHistory}
                      getLoggedInUserTraining={this.getLoggedInUserTraining}
                      /></div>
                    )
                      case 7050:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <PreFlightInfoForm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        setStep={this.setStep}
                        handleChange={this.handleChangeInput}
                        getAccountInfo={this.getAccountInfo4}
                        getFlightHistoryforStudent={this.getFlightHistoryforStudent}
                        handleChangeInput={this.handleChangeInput}
                        getCCExpiry={this.getCCExpiry}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        enterPreFlightAuth={this.enterPreFlightAuth}
                        editpreflightAuth={this.editpreflightAuth}
                        /></div>
                      )
                      case 7060:
                          return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                          <FlightHistoryTable
                            prevStep={this.prevStep}
                            setStep={this.setStep}
                            createRadioGroups={this.displayFlightHistory}
                            getSoloGHTots={this.getSoloGHTots}
                            getSoloNavTots={this.getSoloNavTots}
                            getDualGHTots={this.getDualGHTots}
                            getDualNavTots={this.getDualNavTots}
                            getFlightTimeTots={this.getFlightTimeTots}
                            getCostTots={this.getCostTots}
                            values={values}
                            get14SoloGHTots={this.get14SoloGHTots}
                            get14SoloNavTots={this.get14SoloNavTots}
                            get14DualGHTots={this.get14DualGHTots}
                            get14DualNavTots={this.get14DualNavTots}
                            get14FlightTimeTots={this.get14FlightTimeTots}
                            get30SoloGHTots={this.get30SoloGHTots}
                            get30SoloNavTots={this.get30SoloNavTots}
                            get30DualGHTots={this.get30DualGHTots}
                            get30DualNavTots={this.get30DualNavTots}
                            get30FlightTimeTots={this.get30FlightTimeTots}
                            get90SoloGHTots={this.get90SoloGHTots}
                            get90SoloNavTots={this.get90SoloNavTots}
                            get90DualGHTots={this.get90DualGHTots}
                            get90DualNavTots={this.get90DualNavTots}
                            get90FlightTimeTots={this.get90FlightTimeTots}
                            startAgain={this.startAgaintrainingMgmt}
                            getLoggedInUserTraining={this.getLoggedInUserTraining}


                        /></div>)

                      case 8000:
                        return (<div><MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/> </React.Fragment></MuiThemeProvider>
                        <TrainingMatrixExport
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        clearState={this.clearState}
                        setStep={this.setStep}
                        handleChange={this.handleChangeInput}
                        getAccountInfo={this.getAccountInfo4}
                        handleChangeInput={this.handleChangeInput}
                        getCCExpiry={this.getCCExpiry}
                        getStudentList={this.getStudentList}
                        log={this.dateChange}
                        onTagsChange={this.onTagsChange}
                        values={values}
                        getWingsList={this.getWingsList}
                        enterPreFlightAuth={this.enterPreFlightAuth}
                        /></div>
                      )


        default:
          return (<MuiThemeProvider theme={theme}> <React.Fragment> <AppBarr values={values} setStep={this.setStep}/>

                <GridList style={{height: 450,maxWidth:'90%', borderStyle:'solid',borderColor:'grey',borderWidth:1, marginRight:'10%', marginLeft:'10%', marginTop:35}}>
                <h1>ERROR 404: Page not available  </h1></GridList>
                <Grid item xs={10} style={{marginLeft:'10%',marginRight:'10%',marginTop:20}}>
                <ButtonGroup variant="contained"  style={{marginTop:20}} fullWidth >
                <Button onClick={this.startAgain}>Go back home</Button>
                </ButtonGroup>
                </Grid>
            </React.Fragment>
          </MuiThemeProvider>
    )
  }
}
}

export default WelcomeSplash;
