import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {DropzoneArea} from 'material-ui-dropzone'
import ImageUploader from 'react-images-upload';
import Image from 'react-bootstrap/Image';


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const handleDateChange = e => {
   console.log(e);

 };

export class ProfilePicUpdate extends Component{

  constructor(props) {
    super(props);
    this.state = {
      files: []
    };

  }


  continue = e => {
    e.preventDefault();
    this.props.updateProfilePic(2701);
  }
  back = e => {
    e.preventDefault();
    this.props.setStep(2501);
  }


showWidget = (widget) =>{
  widget.open()
}

checkUploadResult = (resultEvent,widget) =>{
  if (resultEvent.event === 'success'){
    widget.close();
    this.props.handleChangeInput('profilePic',resultEvent.info.secure_url)
    this.props.setStep(2701)
  }

}

  render() {
    const { values, handleChange, log, handlePicChange} = this.props;
    let widget = window.cloudinary.createUploadWidget({
      cloudName:"donas",
      uploadPreset:"eg8ivjdr"
    },(error,result)=>{this.checkUploadResult(result,widget) })

    return(

      <MuiThemeProvider>
        <React.Fragment >




          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Upload a Profile Picture below</h3>

              <p> Don't forget to hit Submit once you return to the profile page to finalise profile picture change</p>
              </Grid>





                    <Grid item xs={12}>
                    <RaisedButton fullWidth style={{height:60}} label="Upload" primary={true} onClick={e=>{e.preventDefault();this.showWidget(widget)}}/>
                      </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default ProfilePicUpdate
