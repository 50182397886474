import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';



export class SelectAmount extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    if(this.props.values.step==6401){
    this.props.editTransaction();}else{
    this.props.nextStep();
}
  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }





  render() {
    const { values, handleChange, log} = this.props;
    var date1 = new Date(values.transTimeStamp);
    var transStamp = date1.getTime();
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Enter Transaction Details</h3>
              </Grid>
              <Grid item xs={12}>

              <p2> Existing Account Balance: $ {parseFloat(values.tillBalance).toFixed(2)}</p2>
              </Grid>
              <Grid item xs={4}>
              <OutlinedInput
                  disabled={values.step==6401?true:false}
                  id="amountPaid"
                  type="number"
                  autoFocus="true"
                  inputProps={{ step: "5.00", min:"0.00" }}
                  fullWidth="true"
                  style={{marginTop:'4.5%',marginBottom:'2%'}}
                  value={values.amountPaid}
                  onChange={handleChange('amountPaid')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  aria-describedby="Amount Paid"

                  labelWidth={0}
                  />
              </Grid>


              <Grid item xs={4}>
              <TextField
                  id="transId"
                  label="Transaction ID"
                  type="text"
                  name="transId"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={handleChange('transId')}
                  value={values.transId}
                  /> </Grid>

                  <Grid item xs={4}>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>



                      <KeyboardDateTimePicker
                      disabled={values.step==6401?true:false}

                      InputLabelProps={{ shrink: true }}
                      autoOk={true}
                      variant="inline"
                      inputVariant="outlined"
                      ampm={false}
                      label="Date and Time of Transaction"
                      value={transStamp}
                      onChange={log}
                      onError={console.log}
                      style={{marginTop:'4.5%',marginBottom:'2%'}}
                      fullWidth="true"
                      disableFuture
                      format="yyyy/MM/dd HH:mm"
                        />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                  <TextField
                      id="transNotes"
                      label="Transaction Notes(if any)"
                      type="text"
                      name="transNotes"
                      fullWidth
                      margin="normal"
                      variant="outlined" style={{width:'100%'}}
                      onChange={handleChange('transNotes')}
                      value={values.transNotes}
                      /> </Grid>




                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label={values.step==6401?"Submit Changes":"Next"} primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SelectAmount
