import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Devon from './Devon1.jpg'
import Ferrari from './ferrarilarge.jpg'
import PasswordHash from 'password-hash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { validate } from 'indicative/validator';



const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class PasswordReset extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0,
                  currentPassword:'',
                  password:'',
                  password_confirmation:'',
                  errors:'',
                  success:''
                };
  }


  continue = e => {
    e.preventDefault();
    const{values}=this.props;
    fetch(`${process.env.REACT_APP_SERVER_URL}/authKi?email=${values.loggedInUser.email}`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{if(response.data.length>0){
      const hashed= response.data[0].keypass;

      if(PasswordHash.verify(this.state.currentPassword,hashed)==true){

        const data = this.state;
        const rules= {password:'required|string|min:8|max:20|confirmed|alpha_numeric|different:currentPassword'}
        const messages={
          required              : 'Password cannot be empty',
          min                   : 'Requires at least 8 characters',
          max                   : 'Password too long',
          alpha_numeric         : 'Illegal characters in the password',
          different             : 'Cannot use the previous password',
          'password.confirmed'  : 'The password confirmation does not match the entered password',
                }

        validate(data, rules, messages)
        .then(()=>{
          const hashedPassword = PasswordHash.generate(this.state.password,{saltLength:10})
          console.log(PasswordHash.verify(this.state.password, hashedPassword))
          this.setState({errors:''})

            fetch(`${process.env.REACT_APP_SERVER_URL}/resetPassword?email=${values.loggedInUser.email}&kiHash=${hashedPassword}&isTemp=false`, {
              headers: {
              'x-access-token': values.token
            }})
            .catch(err=>console.error(err))
            .then(this.setState({success:true}))



        })

        .catch(errors =>{
          console.log(errors);

          const formattedErrors = {}
          errors.forEach( error => formattedErrors[error.field] = error.message)
          this.setState({errors: formattedErrors})
        })


      }

    else{this.setState({errors:{password:"Current Password is Incorrect"}})}


  }})}



  passwordSet=(field) => e => {
    e.preventDefault();
    this.setState({[field]:e.target.value, errors:''})
  }

  render() {
    const { values} = this.props;

    return(

      <MuiThemeProvider>
        <React.Fragment>


        <div >
      <Grid container style={{paddingTop:"9%"}} >
          <Grid item xs={12}>


          <Paper style={{opacity:0.89,padding:"2%"}}>


          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs style={{textAlign:'center', paddingLeft:"20%",paddingRight:"20%"}}>

              <h2>
                Password Reset
              </h2>
              <Typography alignJustify variant="body2" color="textSecondary">
                You must reset your password before you continue
              </Typography>


                <TextField
                  autoFocus
                  margin="dense"
                  id="currentPass"
                  hintText="Current Password"
                  type="password"
                  onChange={this.passwordSet("currentPassword")}
                  value={this.state.currentPassword}
                  fullWidth
                  />
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    hintText="New Password"
                    type="password"
                    onChange={this.passwordSet("password")}
                    value={this.state.password}
                    fullWidth
                    />
                <TextField
                      autoFocus
                      margin="dense"
                      id="password_confirmation"
                      hintText="Confirm New Password"
                      type="password"
                      onChange={this.passwordSet("password_confirmation")}
                      value={this.state.password_confirmation}
                      fullWidth
                      />
          <Grid item>
          <Grid item xs={12}>
          <p style={{color:"red"}}>
          {this.state.errors.password}
          </p>
          </Grid>
          <RaisedButton label="Save" primary={true} onClick={this.continue} fullWidth='true' style={{marginTop:20}}/>

          </Grid>
              </Grid>

            </Grid>
          </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}/>



        </Grid>
        <Dialog
        style={{textAlign:'center'}}
        backdrop
        aria-labelledby="customized-dialog-title"
        open={this.state.success}>
        <DialogTitle id="customized-dialog-title">
        Password Reset
        </DialogTitle>
        <DialogContent dividers>
        <Typography gutterBottom>
        Password Change was Successful!
        <br></br>
        Please log in again
        </Typography>
        </DialogContent>
        <DialogActions>
        <Button onClick={e=>{e.preventDefault();this.props.clearState();this.props.setStep(1002)}} color="secondary">
        Ok
        </Button>

        </DialogActions>
        </Dialog>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default PasswordReset
