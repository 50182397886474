import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { validations, validateAll } from 'indicative/validator';
import CircularProgress from '@material-ui/core/CircularProgress';




export class FlightAuthStudentSelect extends Component{

  constructor(props) {
    super(props);
    this.state = {loading:true,value:0,notInDatabase:"",checkbox:false, inDatabase:"none",searchJson: "",};
    const { values,handleChange} = this.props;


  }



  back = e => {
    e.preventDefault();
    this.props.clearState();
    this.props.prevStep();
  }

  continue= e => {
   e.preventDefault();
   this.props.getCCExpiry(7002);
   this.props.getAccountInfo(0);
 }


onTagsChange = (event, value) => {
    const { values,handleChange} = this.props;
    handleChange('searchKeyword',value);
  }

customName= field => e => {
  console.log(e.target.value)
  const { values,handleChange, } = this.props;

e.preventDefault();
this.setState({[field]:''})
handleChange(field,e.target.value);
}

checkDatabase=e=>{
  if (e.target.checked==true) {
    this.setState({checkbox:e.target.checked})
    this.setState({notInDatabase:"None"})
    this.setState({inDatabase:""})


  }else {
    this.props.handleChange('searchJson',"");
    this.setState({checkbox:e.target.checked})
    this.setState({notInDatabase:""})
    this.setState({inDatabase:"None"})


  }

}



  componentDidMount(){
    const {values}=this.props;
    fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountNames`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{this.props.handleChange('suggestionsStu',response.data)})
    .then(
    fetch(`${process.env.REACT_APP_SERVER_URL}/getInsAccountInfoByEmail?email=${values.loggedInUser.email}`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.props.handleChangeInput("ins_Signature",response.data[0].sigId);
      this.setState({ loading: false});


    })

  )
    .catch(err=>console.error(err))

  }



  validate=e=>{
    e.preventDefault();
     this.validateEmail()

  }
  validateEmail=e=>{
    const { values} = this.props;
    const data = values
    const rules= {searchJson  : 'string|required',

  }
    const messages={
      alpha                 : 'Alphabetical characters only!',
      date1                 : 'Invalid Date',
      number                : 'Numbers Only',
      float                 : "That's invalid",
      required              : 'This Cannot be empty',
      min                   : "That's too short!",
      max                   : "That's too long!",
      email                 : 'Invalid Email',
      alpha_numeric         : 'Illegal Characters in the password',
      'password.confirmed'  : 'The password confirmation does not match the entered password',
            }

    validateAll(data, rules, messages)
    .catch(errors =>{console.log(errors);

      const formattedErrors = {}
      errors.forEach(error =>this.setState({[error.field]:error.message})
      )})
    .then(i=> {if (this.state.searchJson=="" ) {
        this.props.nextStep();
      } else{console.log(this.state.searchJson)}})}




  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Hirer Selection</h3>
              </Grid>
              <Grid item xs={12}
              style={{marginTop:'5%'}}
              >

              <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.checkbox} onChange={this.checkDatabase} value="notInDatabase" />
                }
                label="Hirer Not in Database"
                />
               </Grid>

               <div style={{display:this.state.notInDatabase}}>


              <Autocomplete
                required
                disableClearable='true'
                disableOpenOnFocus="true"
                options={values.suggestionsStu}
                getOptionLabel={option => option.stu_id+' '+option.fname+' '+option.lname}
                onChange={this.onTagsChange}
                renderTags={() => {}}

                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    value={values.searchJson}
                    label="Search by Student Name or ID "
                    autoFocus
                    margin="normal"
                    fullWidth
                  />
                )}
                />

                <Grid item xs={12} style={{ marginTop: 10}}>


                <RaisedButton
                    fullWidth
                    label="Continue"
                    primary={true}
                    onClick={this.continue}
                    style={{marginTop:'2%'}}/>
                  </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>


                    <RaisedButton
                        fullWidth
                        label="Back"
                        secondary={true}
                        onClick={this.back}
                        style={{marginTop:'5%'}}/>
                      </Grid>
                      </div>

                      <div style={{display:this.state.inDatabase}}>
                      <TextField
                          required
                          id="HirerName"
                          label="Hirer Full Name"
                          type="name"
                          margin="normal"
                          style={{width:'100%'}}
                          onChange={this.customName('searchJson')}
                          value={values.searchJson}
                          error={this.state.searchJson!=""}
                          helperText={this.state.searchJson != "" ? this.state.searchJson : ''}

                          />

                          <Grid item xs={12} style={{ marginTop: 10}}>
                          <RaisedButton
                              fullWidth
                              label="Continue"
                              primary={true}
                              onClick={this.validate}
                              style={{marginTop:'5%'}}/>

                          <RaisedButton
                              fullWidth
                              label="Back"
                              secondary={true}
                              onClick={this.back}
                              style={{marginTop:'1%'}}/>
                            </Grid>
                      </div>

                      </Grid>

</Grid>
<Dialog style={{padding:"10%"}}
backdrop
open={this.state.loading}>
<DialogContent dividers>
<CircularProgress />
</DialogContent>


</Dialog>
              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default FlightAuthStudentSelect
