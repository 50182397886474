import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactExport from 'react-data-export';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export class TrainingMatrixExport extends Component{



    constructor(props) {
      super(props);
      this.state={value:0}
    }

  /*method to select what conditions are displayed depending on formID selection from the previous page*/



    continue = e => {
      e.preventDefault();
      this.props.nextStep();
    }

    back = e => {
      const {values}=this.props;
      switch (values.loggedInUser.accessLevel) {
        case "s":
        e.preventDefault();
        this.props.setStep(2999)

          break;
        default:
        e.preventDefault();
        this.props.prevStep();

      }
    }

changeRate=(cid,newRate,lessonID)=>e=>{
  e.preventDefault();
  console.log("prevstate");
  this.setState(prevState => ({

  ["formRating"+lessonID]: prevState["formRating"+lessonID].map(
    el => el.cid === cid? { ...el, rate: newRate }: el)


}))

}






      render() {
        const{values}=this.props;

        const multiDataSet = [
            {
                columns: [
                    {title: "", width: {wpx: 80}},//pixels width
                    {title: "", width: {wpx: 350}},//pixels width
                    {title: "1", width: {wpx: 20}},//pixels width
                    {title: "2", width: {wpx: 20}},//pixels width
                    {title: "3", width: {wpx: 20}},//pixels width
                    {title: "4", width: {wpx: 20}},//pixels width
                    {title: "5", width: {wpx: 20}},//pixels width
                    {title: "6", width: {wpx: 20}},//pixels width
                    {title: "7", width: {wpx: 20}},//pixels width
                    {title: "8", width: {wpx: 20}},//pixels width
                    {title: "9", width: {wpx: 20}},//pixels width
                    {title: "10", width: {wpx: 20}},//pixels width
                    {title: "11", width: {wpx: 20}},//pixels width
                    {title: "12", width: {wpx: 20}},//pixels width
                    {title: "13", width: {wpx: 20}},//pixels width
                    {title: "14", width: {wpx: 20}},//pixels width
                    {title: "15", width: {wpx: 20}},//pixels width
                    {title: "16", width: {wpx: 20}},//pixels width
                    {title: "17", width: {wpx: 20}},//pixels width
                    {title: "18", width: {wpx: 20}},//pixels width
                    {title: "19", width: {wpx: 20}},//pixels width
                    {title: "20", width: {wpx: 20}},//pixels width
                    {title: "21", width: {wpx: 20}},//pixels width
                    {title: "22", width: {wpx: 20}},//pixels width
                    {title: "23", width: {wpx: 20}},//pixels width
                    {title: "24", width: {wpx: 20}},//pixels width
                    {title: "25", width: {wpx: 20}},//pixels width
                    {title: "26", width: {wpx: 20}},//pixels width
                    {title: "27", width: {wpx: 20}},//pixels width
                    {title: "28", width: {wpx: 20}},//pixels width
                    {title: "29", width: {wpx: 20}},//pixels width
                    {title: "30", width: {wpx: 20}},//pixels width
                    {title: "31", width: {wpx: 20}},//pixels width
                    {title: "32", width: {wpx: 20}},//pixels width
                    {title: "33", width: {wpx: 20}},//pixels width
                    {title: "35", width: {wpx: 20}},//pixels width
                    {title: "36", width: {wpx: 20}},//pixels width
                    {title: "38", width: {wpx: 20}},//pixels width
                    {title: "39", width: {wpx: 20}},//pixels width
                    {title: "40", width: {wpx: 20}},//pixels width
                    {title: "41", width: {wpx: 20}},//pixels width
                    {title: "43", width: {wpx: 20}},//pixels width
                    {title: "44", width: {wpx: 20}},//pixels width
                    {title: "46", width: {wpx: 20}},//pixels width
                    {title: "47", width: {wpx: 20}},//pixels width
                    {title: "48", width: {wpx: 20}},//pixels width
                    {title: "49", width: {wpx: 20}},//pixels width
                    {title: "51", width: {wpx: 20}},//pixels width
                    {title: "52", width: {wpx: 20}},//pixels width
                    {title: "53", width: {wpx: 20}},//pixels width
                    {title: "54", width: {wpx: 20}},//pixels width
                    {title: "", width: {wpx: 20}},//pixels width
                    {title: "", width: {wpx: 20}},//pixels width
                    {title: "55", width: {wpx: 20}},//pixels width
                    {title: "",style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},

                ],
                data: [
                    [
                      {value: "", width: {wpx: 80}},//pixels width
                      {value: "", width: {wpx: 280}},//pixels width
                      {value: "Effects of Controls", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Coordination", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Advanced Coordination", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Hover", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Hover Turns and Hover Taxi", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Landings and Lift Offs", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Transitions", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Circuits", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Max Perf / Steep Approach", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Limited  Power Operations", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Basic Autorotations", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Engine Fail in Hov and Hov Taxi", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Practice Forced Landings", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Tail Rotor  Failures", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Jammed Flight Controls", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Advanced Emergencie", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Pre-solo Circuits", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "First Solo Circuit", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Circuit Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Solo Circuits",style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Circuit Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Solo Circuits",style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Circuit Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Solo Circuits",style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Slope Landings", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Confined Area", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Steep Turns", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Pre-Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "First Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Confined Areas Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Emergencies Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Navigation  Exercise 1", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 1", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 3", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 4 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Navigation  Exercise 5", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 6 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Navigation  Exercise 7", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 8 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Navigation  Exercise 9", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 10 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Navigation  Exercise 11", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 12 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Navigation  Exercise 13", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Navigation  Exercise 14 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                      {value: "Dual Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                      {value: "Solo Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                      {value: "Synthetic Trainer ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                      {value: "Hours Brought Forward ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                      {value: "Pre-CPL(H)", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                      {value: "Total Hours", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                    ],
                    [
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "Dual", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH01_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH02_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH03_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH04_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH05_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH06_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH07_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH08_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH09_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH10_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH11_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH12_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH13_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH14_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH15_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH16_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH17_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH19_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH21_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH23_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH25_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH26_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH27_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH28_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH30_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH32_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav34_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav35_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav36_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav38_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav40_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav42_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav44_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav46_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualNav48_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.dualGH50_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                      {value: values.totalDualGHNav,style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                    ],
                ]
            }
        ];

        console.log(values.multiDataSet);


          return (
            <MuiThemeProvider>
              <React.Fragment>
              <div>
              Download Ready! Click below to download...

                  <ExcelFile  element={<RaisedButton>Download Data With Styles</RaisedButton>}>
                      <ExcelSheet dataSet={multiDataSet} name="Organization"/>
                  </ExcelFile>
                  </div>

                    </React.Fragment>
                    </MuiThemeProvider>
          );
      }
  }
export default TrainingMatrixExport
//02
