import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { validations, validateAll } from 'indicative/validator';




export class EditInstructorForm extends Component{

  constructor(props) {
    super(props);
    const{values}=this.props;
    if(values.loggedInUser.accessLevel=="sa"){
    this.state = {value:0,
                  editenabled:false,
                  edit:"",
                  ins_fnameerr     :"",
                  ins_mNameserr  :"",
                  ins_lnameerr  :"",
                  ins_doberr     :"",
                  ins_medicalExpiryerr  :"",
                  ins_emailerr  :"",
                  ins_tpNoerr  :"",
                  ins_btypeerr  :"",
                  ins_rAddresserr  :"",
                  ins_mAddresserr  :"",
                  ins_nextKinerr  :"",
                  ins_nextKinPhoneerr  :"",
                  ins_arnerr       :"",
                  ins_cWeighterr  :"",
                  ins_noteserr  :"",
                  xs_levelerr   :"",
};
                }else{
                    this.state = {value:0,
                                  editenabled:true,
                                  edit:"none",
                                  ins_fnameerr     :"",
                                  ins_mNameserr  :"",
                                  ins_lnameerr  :"",
                                  ins_doberr     :"",
                                  ins_medicalExpiryerr  :"",
                                  ins_emailerr  :"",
                                  ins_tpNoerr  :"",
                                  ins_btypeerr  :"",
                                  ins_rAddresserr  :"",
                                  ins_mAddresserr  :"",
                                  ins_nextKinerr  :"",
                                  ins_nextKinPhoneerr  :"",
                                  ins_arnerr       :"",
                                  ins_cWeighterr  :"",
                                  ins_noteserr  :"",
                                  xs_levelerr   :""
};
                  }
  }


  continue = e => {
    if (this.state.ins_fnameerr=="" && this.state.ins_mNameserr=="" && this.state.ins_lnameerr=="" && this.state.ins_doberr=="" && this.state.ins_medicalExpiryerr=="" && this.state.ins_emailerr=="" && this.state.ins_tpNoerr=="" && this.state.ins_btypeerr=="" && this.state.ins_rAddresserr=="" && this.state.ins_mAddresserr=="" && this.state.ins_nextKinerr=="" && this.state.ins_nextKinPhoneerr=="" && this.state.ins_arnerr=="" && this.state.ins_cWeighterr=="" && this.state.ins_noteserr=="" && this.state.xs_levelerr=="") {
      this.props.setStep(4102);
    } else {
        console.log('err');
    }

  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }


  handleChange = field => e => {
    const { values, log,handleChangeInput, handlePicChange} = this.props;

  e.preventDefault();
  this.setState({[field+"err"]:''})
  handleChangeInput(field,e.target.value);
  /// Do something here
}


  retakePassport = (e) => {
    e.preventDefault()
    this.props.setStep(4200);
  }


  resetPassword = (e) => {
  e.preventDefault()
  this.props.setStep(4300);
  }

  profPic = (e) => {
  e.preventDefault()
  this.props.setStep(4400);
  }

  redoSignature = (e) => {
  e.preventDefault()
  this.props.setStep(4500);
  }


    validate=e=>{
      e.preventDefault();
       this.validateEmail()

    }

    validateEmail=e=>{
      const { values} = this.props;
      const data = values
      const rules= {
        ins_fname         :'required|max:50',
        ins_mNames        :'max:50',
        ins_lname         :'required|max:50',
        ins_dob         :'required|date',
        ins_email         :'required|email',
        ins_tpNo        :'required|number',
        ins_rAddress        :'required|max:500',
        ins_mAddress        :'max:500',
        ins_nextKin         :'required|max:100',
        ins_nextKinPhone        :'required|number',
        ins_arn         :'required|number',
        ins_cWeight         :'required|number',
        ins_notes         :'max:500',



    }
      const messages={
        alpha                 : 'Alphabetical characters only!',
        date                 : 'Invalid Date',
        number                : 'Numbers Only',
        float                 : "That's invalid",
        required              : 'This Cannot be empty',
        min                   : "That's too short!",
        max                   : "That's too long!",
        email                 : 'Invalid Email',
        alpha_numeric         : 'Illegal Characters in the password',
              }

      validateAll(data, rules, messages)
      .catch(errors =>{
        console.log(errors);

        const formattedErrors = {}
        errors.forEach(error =>this.setState({[error.field+"err"]:error.message})
        )



      })
      .then(this.continue)

      }

      logMedical=e=>{
        this.setState({ins_medicalExpiryerr:''});
        this.props.logMedical(e);

      }

      log=e=>{
        this.setState({ins_doberr:''});
        this.props.log(e);

      }





  render() {
    const { values, handleChange} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >


          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Instructor Details</h3>
              </Grid>
              <Grid item xs={12} style={{textAlign:'center', padding:'2%'}}>
              <Avatar src={values.ins_profPhoto} size={200} />
              </Grid>
              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editenabled}
                  id="First_Name"
                  label="First Name"
                  type="fname"
                  name="First Name"
                  autoComplete="fname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_fname')}
                  value={values.ins_fname}
                  error={this.state.ins_fnameerr!=""}
                  helperText={this.state.ins_fnameerr != "" ? this.state.ins_fnameerr : ''}
                  /></Grid>


              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editenabled}
                  id="Middle_Names"
                  label="Middle Names"
                  type="mname"
                  name="Middle Name"
                  autoComplete="mname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_mNames')}
                  value={values.ins_mNames}
                  error={this.state.ins_mNameserr!=""}
                  helperText={this.state.ins_mNameserr != "" ? this.state.ins_mNameserr : ''}
                  /></Grid>
              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editenabled}
                  id="Last_Name"
                  label="Last Name"
                  type="lname"
                  name="Last Name"
                  autoComplete="lname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_lname')}
                  value={values.ins_lname}
                  error={this.state.ins_lnameerr!=""}
                  helperText={this.state.ins_lnameerr != "" ? this.state.ins_lnameerr : ''}
                  /></Grid>


              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                  disabled={this.state.editenabled}
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Date Of Birth"
                onChange={this.log}
                value={values.ins_dob}
                error={this.state.ins_doberr!=""}
                helperText={this.state.ins_doberr != "" ? this.state.ins_doberr : ''}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
                <KeyboardDatePicker
                    disabled={this.state.editenabled}
                  disablePast
                  autoOk={true}
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="medicalExpiry_picker"
                  label="Medical Expiry"
                  onChange={this.logMedical}
                  value={values.ins_medicalExpiry}
                  error={this.state.ins_medicalExpiryerr!=""}
                  helperText={this.state.ins_medicalExpiryerr != "" ? this.state.ins_medicalExpiryerr : ''}
                  style={{width:'100%' ,marginTop:'4.5%'}}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4}>
              <TextField
                  disabled
                  id="Email"
                  label="E-mail"
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_email}
                  /></Grid>








              <Grid item xs={2}>
              <TextField
                  disabled={this.state.editenabled}
                  id="phone"
                  label="phone"
                  type="phone"
                  name="phone"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_tpNo')}
                  value={values.ins_tpNo}
                  error={this.state.ins_tpNoerr!=""}
                  helperText={this.state.ins_tpNoerr != "" ? this.state.ins_tpNoerr : ''}
                  /></Grid>

              <Grid item xs={2}>
              <FormControl variant="outlined" style={{width:'100%',marginTop:'9%'}}

              >
                      <InputLabel  htmlFor="outlined-age-simple">
                        Blood Type
                        </InputLabel>


                      <Select
                          disabled={this.state.editenabled}
                      onChange={this.handleChange('ins_btype')}
                      value={values.ins_btype}
                      error={this.state.ins_btypeerr!=""}
                      helperText={this.state.ins_btypeerr != "" ? this.state.ins_btypeerr : ''}

                    inputProps={{
                      name: 'bloodtype',
                      id: 'bloodtype',
                    }}
                        >
                        <MenuItem value={"x"}>Unsure</MenuItem>
                        <MenuItem value={"A1"}>A+</MenuItem>
                        <MenuItem value={"A0"}>A-</MenuItem>
                        <MenuItem value={"B1"}>B+</MenuItem>
                        <MenuItem value={"B0"}>B-</MenuItem>
                        <MenuItem value={"O1"}>O+</MenuItem>
                        <MenuItem value={"O0"}>O-</MenuItem>
                        <MenuItem value={"AB1"}>AB+</MenuItem>
                        <MenuItem value={"AB0"}>AB-</MenuItem>
                        </Select>

                        </FormControl></Grid>

              <Grid item xs={12}>
              <TextField
                  disabled={this.state.editenabled}
                  id="res_address"
                  label="Residential Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_rAddress')}
                  value={values.ins_rAddress}
                  error={this.state.ins_rAddresserr!=""}
                  helperText={this.state.ins_rAddresserr != "" ? this.state.ins_rAddresserr : ''}
                  /></Grid>

              <Grid item xs={12}>
              <TextField
                  disabled={this.state.editenabled}
                  id="m_address"
                  label="Mailing Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_mAddress')}
                  value={values.ins_mAddress}
                  error={this.state.ins_mAddresserr!=""}
                  helperText={this.state.ins_mAddresserr != "" ? this.state.ins_mAddresserr : ''}
                  /></Grid>

              <Grid item xs={8}>
              <TextField
                  disabled={this.state.editenabled}
                  id="Next_of_Kin"
                  label="Next of Kin"
                  autoComplete="name"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_nextKin')}
                  value={values.ins_nextKin}
                  error={this.state.ins_nextKinerr!=""}
                  helperText={this.state.ins_nextKinerr != "" ? this.state.ins_nextKinerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editenabled}
                  id="Next_of_Kin_phone"
                  label="Next of Kin Contact"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_nextKinPhone')}
                  value={values.ins_nextKinPhone}
                  error={this.state.ins_nextKinPhoneerr!=""}
                  helperText={this.state.ins_nextKinPhoneerr != "" ? this.state.ins_nextKinPhoneerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editenabled}
                  id="ARN"
                  label="ARN"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_arn')}
                  value={values.ins_arn}
                  error={this.state.ins_arnerr!=""}
                  helperText={this.state.ins_arnerr != "" ? this.state.ins_arnerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <OutlinedInput
                  disabled={this.state.editenabled}
                  id="cWeight"
                  type="number"
                  inputProps={{ step: "5.00", min:"0.00" }}
                  fullWidth="true"
                  style={{marginTop:'4.5%',marginBottom:'2%'}}
                  onChange={this.handleChange('ins_cWeight')}
                  value={values.ins_cWeight}
                  error={this.state.ins_cWeighterr!=""}
                  helperText={this.state.ins_cWeighterr != "" ? this.state.ins_cWeighterr : ''}
                  endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                  aria-describedby="Weight"

                  labelWidth={0}
                  /></Grid>


                  <Grid item xs={4} style={{display:this.state.editenabled===true?"none":""}}>
                  <FormControl
                    required='true'
                    variant="outlined"
                    style={{width:'100%',marginTop:'4.5%',marginBottom:'2%'}}>

                        <InputLabel  htmlFor="outlined-age-simple">
                            Role
                            </InputLabel>


                          <Select
                          disabled={this.state.editenabled}
                          onChange={this.props.handleChange("xs_level")}
                          value={values.xs_level}
                          error={this.state.xs_levelerr!=""}
                          helperText={this.state.xs_levelerr != "" ? this.state.xs_levelerr : ''}

                        inputProps={{
                          name: 'role',
                          id: 'role',
                        }}
                            >

                            <MenuItem value={"sa"}>Super User    (Full System Admin Access)</MenuItem>
                            <MenuItem value={"i"} >Instructor    (Academic Admin Access)</MenuItem>
                            <MenuItem value={"p"}> Charter Pilot (Pre-Flight auth & View Access) </MenuItem>

                            </Select>

                            </FormControl></Grid>



              <Grid item xs={12}>
              <TextField
                  disabled={this.state.editenabled}
                  id="Notes"
                  label="Special Notes"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('ins_notes')}
                  value={values.ins_notes}
                  error={this.state.ins_noteserr!=""}
                  helperText={this.state.ins_noteserr != "" ? this.state.ins_noteserr : ''}
                  /></Grid>

                  <Grid item xs={3} style={{ marginTop: "1%",display:this.state.edit}}>
                  <RaisedButton fullWidth label="Reset Password" secondary={true} onClick={this.resetPassword}/>
                    </Grid>
                    <Grid item xs={3} style={{ marginTop: "1%",display:this.state.edit}}>
                    <RaisedButton fullWidth label="Change Profile Picture" secondary={true} onClick={this.profPic}/>
                      </Grid>
                      <Grid item xs={3} style={{ marginTop: "1%",display:this.state.edit}}>
                      <RaisedButton fullWidth label="Retake Passport Photo" secondary={true} onClick={this.retakePassport}/>
                        </Grid>
                        <Grid item xs={3} style={{ marginTop: "1%",display:this.state.edit}}>
                        <RaisedButton fullWidth label="Change Signature" secondary={true} onClick={this.redoSignature}/>
                          </Grid>


                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60,display:this.state.edit}} label="Submit" primary={true} onClick={this.validate}/>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default EditInstructorForm
