import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactExport from 'react-data-export';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export class Generate extends Component{



    constructor(props) {
      super(props);
      this.state={value:0}
    }

  /*method to select what conditions are displayed depending on formID selection from the previous page*/











multiDataSet=(e)=>{
  const{values}=this.props;
  const multiDataSet = [
      {
          columns: [
              {title: "", width: {wpx: 80}},//pixels width
              {title: "", width: {wpx: 350}},//pixels width
              {title: "1", width: {wpx: 20}},//pixels width
              {title: "2", width: {wpx: 20}},//pixels width
              {title: "3", width: {wpx: 20}},//pixels width
              {title: "4", width: {wpx: 20}},//pixels width
              {title: "5", width: {wpx: 20}},//pixels width
              {title: "6", width: {wpx: 20}},//pixels width
              {title: "7", width: {wpx: 20}},//pixels width
              {title: "8", width: {wpx: 20}},//pixels width
              {title: "9", width: {wpx: 20}},//pixels width
              {title: "10", width: {wpx: 20}},//pixels width
              {title: "11", width: {wpx: 20}},//pixels width
              {title: "12", width: {wpx: 20}},//pixels width
              {title: "13", width: {wpx: 20}},//pixels width
              {title: "14", width: {wpx: 20}},//pixels width
              {title: "15", width: {wpx: 20}},//pixels width
              {title: "16", width: {wpx: 20}},//pixels width
              {title: "17", width: {wpx: 20}},//pixels width
              {title: "18", width: {wpx: 20}},//pixels width
              {title: "19", width: {wpx: 20}},//pixels width
              {title: "20", width: {wpx: 20}},//pixels width
              {title: "21", width: {wpx: 20}},//pixels width
              {title: "22", width: {wpx: 20}},//pixels width
              {title: "23", width: {wpx: 20}},//pixels width
              {title: "24", width: {wpx: 20}},//pixels width
              {title: "25", width: {wpx: 20}},//pixels width
              {title: "26", width: {wpx: 20}},//pixels width
              {title: "27", width: {wpx: 20}},//pixels width
              {title: "28", width: {wpx: 20}},//pixels width
              {title: "29", width: {wpx: 20}},//pixels width
              {title: "30", width: {wpx: 20}},//pixels width
              {title: "31", width: {wpx: 20}},//pixels width
              {title: "32", width: {wpx: 20}},//pixels width
              {title: "33", width: {wpx: 20}},//pixels width
              {title: "35", width: {wpx: 20}},//pixels width
              {title: "36", width: {wpx: 20}},//pixels width
              {title: "38", width: {wpx: 20}},//pixels width
              {title: "39", width: {wpx: 20}},//pixels width
              {title: "40", width: {wpx: 20}},//pixels width
              {title: "41", width: {wpx: 20}},//pixels width
              {title: "43", width: {wpx: 20}},//pixels width
              {title: "44", width: {wpx: 20}},//pixels width
              {title: "46", width: {wpx: 20}},//pixels width
              {title: "47", width: {wpx: 20}},//pixels width
              {title: "48", width: {wpx: 20}},//pixels width
              {title: "49", width: {wpx: 20}},//pixels width
              {title: "51", width: {wpx: 20}},//pixels width
              {title: "52", width: {wpx: 20}},//pixels width
              {title: "53", width: {wpx: 20}},//pixels width
              {title: "54", width: {wpx: 20}},//pixels width
              {title: "", width: {wpx: 20}},//pixels width
              {title: "", width: {wpx: 20}},//pixels width
              {title: "55", width: {wpx: 20}},//pixels width
              {title: "",style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},

          ],
          data: [
              [
                {value: "", width: {wpx: 80}},//pixels width
                {value: "", width: {wpx: 280}},//pixels width
                {value: "Effects of Controls", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Coordination", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Advanced Coordination", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Hover", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Hover Turns and Hover Taxi", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Landings and Lift Offs", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Transitions", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Circuits", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Max Perf / Steep Approach", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Limited  Power Operations", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Basic Autorotations", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Engine Fail in Hov and Hov Taxi", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Practice Forced Landings", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Tail Rotor  Failures", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Jammed Flight Controls", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Advanced Emergencie", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Pre-solo Circuits", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "First Solo Circuit", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Circuit Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Solo Circuits",style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Circuit Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Solo Circuits",style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Circuit Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Solo Circuits",style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Slope Landings", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Confined Area", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Steep Turns", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Pre-Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "First Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Confined Areas Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Emergencies Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Training Area Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Navigation  Exercise 1", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 1", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 3", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 4 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Navigation  Exercise 5", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 6 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Navigation  Exercise 7", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 8 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Navigation  Exercise 9", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 10 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Navigation  Exercise 11", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 12 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Navigation  Exercise 13", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Navigation  Exercise 14 - Solo", style: {fill: {patternType: "solid", fgColor: {rgb: "ccffff"}}}},
                {value: "Dual Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                {value: "Solo Consolidation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                {value: "Synthetic Trainer ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                {value: "Hours Brought Forward ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb3ff"}}}},
                {value: "Pre-CPL(H)", style: {fill: {patternType: "solid", fgColor: {rgb: "daffb3"}}}},
                {value: "Total Hours", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "Dual", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH01_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH02_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH03_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH04_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH05_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH06_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH07_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH08_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH09_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH10_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH11_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH12_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH13_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH14_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH15_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH16_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH17_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH19_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH21_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH23_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH25_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH26_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH27_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH28_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH30_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH32_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav34_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav35_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav36_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav38_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav40_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav42_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav44_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav46_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualNav48_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.dualGH50_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.totalDualGHNav,style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "Solo - General Handling", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH18_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH20_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH22_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH24_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH29_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH31_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH33_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloGH49_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.totalSoloGH,style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "Solo - Navigation", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloNav37_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloNav39_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloNav41_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloNav43_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloNav45_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.soloNav47_105CPL_H, style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: this.state.totalSoloNav,style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "Aeronautical knowledge examinations", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "P", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "R", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "E", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "-", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "S", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "O", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "L", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "O", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}, border: {right: {style: "thick", color: "00000000"}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "e6e6e6"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "Units, Elements and Performance Criteria", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "ffb366"}}}},
              ],
              [
                {value: "C1", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "Communicating in the aviation environment", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "ffcc99"}}}},
              ],
              [
                {value: "C1.1", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "Communicating face-to-face", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "ffe7cf"}}}},
              ]
              ,
              [
                {value: "(a)"},
                {value: "pronounces words clearly, using an accent that does not cause difficulties in understanding", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: values.formRating01_105CPL_H[0].rate[0]},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ],
              [
                {value: "(b)"},
                {value: "conveys information in clearly structured sentences without confusion or ambiguity", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: ""},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ],
              [
                {value: "(c)"},
                {value: "uses an extensive vocabulary to accurately communicate on general and technical topics, without excessive use of jargon, slang or colloquial language", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: ""},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ],
              [
                {value: "(d)"},
                {value: "speaks fluently without long pauses, repetition or excessive false starts", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: ""},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ],
              [
                {value: "(e)"},
                {value: "responds to communications with actions that demonstrate that the information has been received and understood", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: ""},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ],
              [
                {value: "(f)"},
                {value: "exchanges information clearly in a variety of situations with both expert and non-expert English speakers while giving and receiving timely and appropriate responses", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: ""},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ],
              [
                {value: "(g)"},
                {value: "uses appropriate techniques to validate communications", style:{font: {sz: "8"}} },
                {value: ""},//01
                {value: ""},//02
                {value: ""},//03
                {value: ""},//04
                {value: ""},//05
                {value: ""},//06
                {value: ""},//07
                {value: ""},//08
                {value: ""},//09
                {value: ""},//10
                {value: ""},//11
                {value: ""},//12
                {value: ""},//13
                {value: ""},//14
                {value: ""},//15
                {value: ""},//16
                {value: ""},//17
                {value: ""},//18
                {value: ""},//19
                {value: ""},//20
                {value: ""},//21
                {value: ""},//22
                {value: ""},//23
                {value: ""},//24
                {value: ""},//25
                {value: ""},//26
                {value: ""},//27
                {value: ""},//28
                {value: ""},//29
                {value: ""},//30
                {value: ""},//31
                {value: ""},//32
                {value: ""},//33
                {value: ""},//34
                {value: ""},//35
                {value: ""},//36
                {value: ""},//37
                {value: ""},//38
                {value: ""},//39
                {value: ""},//40
                {value: ""},//41
                {value: ""},//42
                {value: ""},//43
                {value: ""},//44
                {value: ""},//45
                {value: ""},//46
                {value: ""},//47
                {value: ""},//48
                {value: ""},//49
                {value: ""},
                {value: ""},
                {value: ""},//50
                {value: ""},//02
              ]




          ]
      }
  ];
  console.log(multiDataSet);
  this.props.handleData(multiDataSet);



}



      render() {




          return (
            <MuiThemeProvider>
              <React.Fragment>
              <div>
              <RaisedButton style={{margin:'5%'}}fullWidth label="Generate Data" secondary={true} onClick={e=>{e.preventDefault();this.multiDataSet();}}/>


                  </div>

                    </React.Fragment>
                    </MuiThemeProvider>
          );
      }
  }
export default Generate
//02
