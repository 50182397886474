import React from 'react';
import {WelcomeSplash} from "./components/mainFlow/WelcomeSplash";

function App() {
  return (
    <div className="App">
      <WelcomeSplash />
    </div>
  );
}

export default App;
