import React, { Component } from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AppBar from "material-ui/AppBar/AppBar";
import TextField from "material-ui/TextField/TextField";
import Toggle from "material-ui/Toggle";
import DatePicker from "material-ui/DatePicker";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import AutoComplete from "material-ui/AutoComplete";
import TimePicker from "material-ui/TimePicker";
import SelectField from "material-ui/SelectField";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "material-ui/Avatar";
import Fab from "@material-ui/core/Fab";
import RaisedButton from "material-ui/RaisedButton";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import * as moment from "moment";

import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import ActionFavorite from "material-ui/svg-icons/action/favorite";
import ActionFavoriteBorder from "material-ui/svg-icons/action/favorite-border";

export class StudentFlightHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      progressDialog: false,
      loading: false,
      lessonStatus: [],
    };
  }

  /*method to select what conditions are displayed depending on formID selection from the previous page*/

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    const { values } = this.props;
    if (values.step == 7060) {
      e.preventDefault();
      this.props.setStep(7002);
    } else {
      switch (values.loggedInUser.accessLevel) {
        case "s":
          e.preventDefault();
          this.props.setStep(2999);
          break;
        default:
          if (values.flightType != "") {
            e.preventDefault();
            this.props.setStep(7002);
          } else {
            e.preventDefault();
            this.props.prevStep();
          }
      }
    }
  };

  render() {
    const {
      values,
      handleChange,
      defaultButton,
      formRating,
      createRadioGroups,
    } = this.props;
    const ratingState = values.formRating;

    return (
      <MuiThemeProvider>
        <React.Fragment>
          <div>
            <Grid item xs={12} style={{ marginLeft: "5%" }}>
              <h1>
                Training History for {values.fName} {values.lName}
              </h1>
            </Grid>
            <Grid item xs={6} style={{ marginLeft: "5%" }}>
              <p1>Currently held License:{values.CLicType}</p1>
            </Grid>
            <Grid item xs={6} style={{ marginLeft: "5%", marginBottom: "2%" }}>
              <p1>Training for :{values.LicType}</p1>
            </Grid>
            <Paper
              style={{
                marginLeft: "2%",
                marginRight: "2%",
                paddingLeft: "2%",
                paddingRight: "2%",
              }}
            >
              <Grid container spacing={1}>
                {moment(values.medical2, "MM/DD/YYYY").unix() <=
                  moment().unix() &&
                  moment(values.medical1, "MM/DD/YYYY").unix() <=
                    moment().unix() && (
                    <Grid
                      item
                      xs={4}
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      <h2>Check Medical</h2>
                    </Grid>
                  )}

                {values.tillBalance < 1000 && (
                  <Grid
                    item
                    xs={4}
                    style={{
                      backgroundColor:
                        values.tillBalance < 1000 ? "red" : "white",
                      color: values.tillBalance < 1000 ? "white" : "black",
                    }}
                  >
                    <h2>Account Balance Low</h2>
                  </Grid>
                )}

                <Grid
                  item
                  xs={4}
                  style={{
                    backgroundColor:
                      values.tillBalance < 1000 ? "red" : "white",
                    color: values.tillBalance < 1000 ? "white" : "black",
                  }}
                >
                  <h2>Account Balance : ${values.tillBalance}</h2>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Record ID</Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Date & Time</Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Lesson ID</Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Rego & Instructor</Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Hours</Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Total Time</Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Cost</Typography>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <Typography variant="subtitle2">Comments</Typography>
                </Grid>
              </Grid>

              <GridList style={{ height: 400 }}>
                <Grid item xs={12}>
                  {createRadioGroups()}
                </Grid>
              </GridList>
              <Grid container spacing={1} style={{ paddingTop: "2%" }}>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">
                    <b>History Totals</b>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <h3></h3>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    <b>S G H</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    <b>S N</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    <b>D G H</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    <b>D N</b>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    <b>Total</b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ paddingTop: "2%" }}>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">14 Days</Typography>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <h3></h3>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get14SoloGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get14SoloNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get14DualGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get14DualNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {" "}
                    {this.props.get14FlightTimeTots().toFixed(1)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">30 Days</Typography>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <h3></h3>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get30SoloGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get30SoloNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get30DualGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get30DualNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {" "}
                    {this.props.get30FlightTimeTots().toFixed(1)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">90 Days</Typography>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <h3></h3>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get90SoloGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get90SoloNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get90DualGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.get90DualNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {" "}
                    {this.props.get90FlightTimeTots().toFixed(1)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ paddingTop: "1%" }}>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="subtitle2">Total</Typography>
                </Grid>
                <Grid item xs={3}>
                  {" "}
                  <h3></h3>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.getSoloGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.getSoloNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.getDualGHTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.getDualNavTots().toFixed(1)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  {" "}
                  <Typography variant="body2">
                    {this.props.getFlightTimeTots().toFixed(1)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            {values.loggedInUser.accessLevel != "s" && (
              <>
                {" "}
                <Grid item xs={12} style={{ display: "", margin: "2%" }}>
                  <RaisedButton
                    fullWidth
                    label="View Training Progress"
                    secondary={true}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ progressDialog: true });
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: "", margin: "2%" }}>
                  <RaisedButton
                    fullWidth
                    label="Generate Training Matrix"
                    secondary={true}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.setStep(8000);
                    }}
                  />
                </Grid>{" "}
              </>
            )}
            <Grid item xs={12} style={{ margin: "2%" }}>
              <RaisedButton
                fullWidth
                label="Back"
                primary={true}
                onClick={this.back}
              />
            </Grid>
            <Dialog
              fullWidth="true"
              fullHeight="true"
              maxWidth="xl"
              aria-labelledby="customized-dialog-title"
              open={this.state.progressDialog}
            >
              <DialogContent
                style={{ textAlign: "center" }}
                id="customized-dialog-title"
              >
                {this.props.values.lessonsProgress.length > 1 && (
                  <Grid container spacing={1} style={{ paddingTop: "2%" }}>
                    <Grid item xs={1}>
                      {" "}
                      <Typography variant="subtitle2">
                        <b>Lesson ID</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {" "}
                      <Typography variant="body2">
                        <b>Name</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      {" "}
                      <Typography variant="body2">
                        <b>Status</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      {" "}
                      <Typography variant="body2">
                        <b>No. of Lessons</b>
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {this.props.values.lessonsProgress.map((x) => {
                  return (
                    <>
                      <Grid container spacing={1}>
                        <Grid item xs={1}>
                          {" "}
                          <Typography variant="body">{x.id}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          {" "}
                          <Typography variant="body2">{x.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {" "}
                          <Typography variant="body2">
                            {x.status > 0 ? "✅" : "❌ "}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          {" "}
                          <Typography variant="body2">{x.status}</Typography>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}

                <Typography style={{ padding: "2%" }} variant="h5">
                  {" "}
                </Typography>
              </DialogContent>
              <DialogActions>
                <RaisedButton
                  fullWidth
                  label="Close"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ progressDialog: false });
                  }}
                  secondary={true}
                />
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default StudentFlightHistory;
