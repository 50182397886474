import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import RaisedButton from 'material-ui/RaisedButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SignaturePad from 'react-signature-pad-wrapper'


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const videoConstraints = {
  width: 151.181102362,
  height: 188.976377953,
  facingMode: "user"
};





export class SignatureEditCapture extends Component{

  setRef = sigCanvas => {
    this.sigCanvas = sigCanvas;
  };

  capture = () => {
    const imageSrc = this.sigCanvas.toDataURL();
    this.props.setSignatureUpload(imageSrc);
    this.props.setStep(2802)

  };
  clear = () => {
    this.sigCanvas.clear();

  };

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    this.capture();

  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  handleChange = (event, index, value) => this.setState({value});





  render() {
    const {values, setPassportPhoto,passportPhoto} = this.props;

    return(

      <MuiThemeProvider>
        <React.Fragment>

      
        <div>
        <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
        <Grid container spacing={1}>


        <Grid item xs={12}>
        <h3>Enter New Account Details</h3>
        </Grid>
        <Grid container style={{backgroundColor:'grey', padding:'2%'}}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} style={{padding:'1%',backgroundColor:'white'}}>

            <SignaturePad
              ref={this.setRef}
              penColor='black'
              canvasProps={{border:1, width: "535%", height: "200%"}} />
            </Grid>
        <Grid item xs={3}></Grid>
        </Grid>


        </Grid>

                <RaisedButton label="Capture Signature" primary={true} onClick={this.continue} fullWidth='true' style={{marginTop:20}}/>
                <RaisedButton label="Clear" secondary={true} onClick={this.clear} fullWidth='true' style={{marginTop:20}}/>
                <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back  </Button>

            </Paper>
            </div>


        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default SignatureEditCapture
