import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AvatarPic from './avatar.png'
import Webcam from 'react-webcam';
import ModelPhoto from './modelPhoto.jpg'


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const videoConstraints = {
  width: 151.181102362,
  height: 188.976377953,
  facingMode: "user"
};





export class PassportPicEditCapture extends Component{



  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  handleChange = (event, index, value) => this.setState({value});





  render() {
    const {values, setPassportPhoto,passportPhoto} = this.props;

    return(

      <MuiThemeProvider>
        <React.Fragment>


          <div>
          <Grid container spacing={2} style={{padding:'10%',paddingTop:'2%'}}>
          <Grid item xs={12}>
          <Paper style={{opacity:0.89}}>
          <h3>Upload an appropriate passport photo for official documentation purposes</h3>
          <p>Photo must be similar to the example shown below and must meet the following guideline </p>
          <p>Clear, focused image with no marks or 'red eye'</p>
          <p>Plain white or light grey background that contrasts with your face</p>
          <p>Uniform lighting (no shadows or reflections) with appropriate brightness and contrast to show natural skin tone</p>
          <p>Face looking directly at the camera and not tilted in any direction</p>
          <p>Hair off the face so that the edges of the face are visible</p>
          <p>Eyes open, mouth closed</p>
          <p>Neutral expression (not smiling, laughing or frowning), which is the easiest way for our systems to match you to your image. </p>

            <Grid style={{padding:'2%'}}>
            <Grid style={{margin:'35%',marginTop:"2%",marginBottom:'2%',height:'30%',width:'30%'}}>
            <img src={ModelPhoto}/>
                      </Grid>
                      </Grid>

              <Grid item>
                <RaisedButton label="CAPTURE" primary={true} onClick={this.continue} fullWidth='true' style={{marginTop:20}}/>
                <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back  </Button>
              </Grid>

            </Paper>
            </Grid>




</Grid>
            </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default PassportPicEditCapture
