import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';





export class BasicAutorotation extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }

  render() {
    const {values} = this.props;
    const ct = new Date(values.transTimeStamp);

    var date1 = new Date(values.fl_time);
    var flightDate = date1.getDate() + '/' + (date1.getMonth()+1) + '/' + date1.getFullYear();
    let current_time =  ct.getDate() + '/' + (ct.getMonth()+1) + '/' + ct.getFullYear();
    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>


          <Grid item xs={12} sm container style={{padding:50}}>


              <table style={{textAlign:'center',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#BFBFBE',width:'42%'}}>
              <b>HELICOPTER TRAINING AND TRANSPORT <br></br>
              Commercial Pilot Licence – Helicopter Category Rating</b>
              </td>
              </tr>
              </table>


              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 11: BASIC AUTOROTATION</b>
              </td>
              </tr>
              </table>



              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse', backgroundColor:'#EEECDF'}}>Flight no: </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> 105CPL(H)11.{values.lessonNo}    </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Trainee name <br></br> &ARN: </th>
              <td style={{padding:10, columnSpan:'all',borderBottomStyle:'solid',borderWidth:1}}> {values.fName} {values.lName}<br></br> {values.stu_arn}  </td>
              </tr>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Date </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> {flightDate}     </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Instructor: </th>
              <td style={{padding:10, columnSpan:'all'}}>{values.ins_fname} {values.ins_lname}  </td>

              </tr>
              </table>
              <table style={{textAlign:'left',borderStyle:'none', width:'100%', borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft<br></br>registration: </th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> {values.rego}   </td>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft Type:</th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{values.wing_type}     </td>
              <th style={{width:'10%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Flight Time:</th>
              <td style={{borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{(parseFloat(values.soloGH)+parseFloat(values.soloNav)).toFixed(1)} S <br></br>{(parseFloat(values.dualGH)+parseFloat(values.dualNav)).toFixed(1)} D</td>
              </tr>

              </table>

              <Grid item xs style={{textAlign:'left', marginTop:20, paddingLeft:10, paddingTop:5,borderWidth:1, borderStyle:'solid'}}>
              <b>Lesson Overview</b>

              <ul>
              <li> Conduct autorotative entry, descent, and flare </li>
              <li>Conduct turns during an autorotative descent</li>
              <li>Extend range during descent</li>
              <li>Extend endurance during descent</li>
              <li>Conduct go-around at 500 feet AGL</li>
              <li>Conduct power recovery to the hover</li>
              <li>Conduct autorotative landing</li>
              </ul>

              </Grid>

              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <th style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#BFBFBE',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              PRE-FLIGHT KNOWLEDGE<br></br>
              Long Briefing: 1.0 hour    Pre-flight Briefing: 0.3 hour<br></br>
              Underpinning knowledge: as required
              </th>
              </tr>
              <tr>
              <td style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#EEECDF',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}> Content
              </td>
              </tr>
              <tr><td style={{ borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Long briefing </b>– Basic Autorotation  <br></br>
              <ul>
              <li>AFM Emergency section</li>
              <li>AFM Limitations</li>
              <li>Aerodynamics of Autorotation (Vector Diagram)</li>
              <li>Techniques associated with autorotative flight.</li>

              </ul>
              </td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Underpinning knowledge</b> (relevant to the stage of training):<br></br>
              <ul>
              <li>Review/expand previously introduced knowledge as required</li>
              <li>Typical hazards and risks associated with conducting advanced manoeuvres and identify precautions and procedures to control the risks [H6(e)]</li>
              <li>Instructions, procedures and information that is relevant to executing advanced manoeuvres [H6(f)]</li>
              <li>Emergency procedures [H7(a)] </li>
              <li>Applicable system malfunctions [H7(b)]</li>
              <li>Managing system malfunctions [H7(c)]</li>
              <li>Prioritising tasks when managing malfunctions [H7(d)]</li>

              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>HF & NTS</b><br></br>
              <ul>
              <li>Hand over/take over technique </li>
              <li>Control technique (Emphasis on Rotor RPM and throttle management)</li>
              <li>Careful assessment of conditions for approach and landing</li>
              <li>HASEL checks (Emphasis on L for lookout and listen out)</li>
              <li>No aft cyclic after ground contact</li>
              <li>RHC POH safety notice SN-38, if applicable.</li>
              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%', borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Pre-flight briefing</b><br></br>
              <ul>
              <li>Review flight sequences, what to expect, see & do</li>
              <li>Check essential knowledge</li>
              <li>Reinforce threat & error management</li>
              <li>Reinforce significant airmanship points </li>
              </ul></td>
              </tr>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',width:'42%',paddingLeft:10,paddingBottom:40, borderRightStyle:'solid', borderWidth:1}}>
              <b>Pre-flight knowledge components complete: <br></br></b>{values.pfk_bool}</td>
              <td style={{paddingLeft:10}}><b>Instructor’s signature & date</b><br></br>
              <img style={{maxWidth:'60%'}} src={values.ins_Signature}/>{current_time}</td>
              </tr>
              </table>



              <hr style={{marginTop:30, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 11  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 1</p2></td>

              </tr>

              </table>
              </Grid>


          <Grid item xs={12} sm container style={{padding:50, paddingTop:65}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 11: BASIC AUTOROTATION</b>
              </td>
              </tr>
              </table>

              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr style={{textAlign:'center',backgroundColor:'#BFBFBE',width:'100%',paddingLeft:10,paddingBottom:40}}>
              <b>Performance Standard</b>
              </tr>
              </table>

              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>3</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>2</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>1</b></td>
              </tr>
              <tr>
              <td style={{ width: '33.35%',borderStyle:'solid',borderWidth:1, padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Has received training in the element, however is not able to consistently demonstrate competency to the standard required for qualification issue</td>
              <td style={{ width: '33.34%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Demonstrates a developing level of proficiency, and is deemed safe to conduct solo practice under direct supervision</td>
              <td style={{ width: '33%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Achieves competency to the standard required for qualification issue</td>
              </tr>

              </table>


              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>FLIGHT TRAINING<br></br>
              Suggested flight time:  1.0 hour dual</b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
              </tr>

              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> C3.1  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Operate radio equipment   </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>confirm serviceability of radio equipment </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[0].rate}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b) </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>conduct transmission and receipt of radio communications using appropriate procedures and phraseology </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[1].rate}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c) </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain a listening watch and respond appropriately to applicable transmissions</td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[2].rate}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d) </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>conduct appropriate emergency and urgency transmissions </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[3].rate}</td>
                </tr>




              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H1.1   </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Start engine and rotor  </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b) </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> wind conditions are assessed for start</td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[4].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c) </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform pre-start checklists actions </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[5].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d) </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform engine start and rotor engagement </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[6].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>rotor disc position is controlled during start </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[7].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>engine is operated within limits </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[8].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergencies are managed </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[9].rate}</td>
              </tr>





              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H1.2  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Stop engine and rotor  </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> wind conditions are assessed and appropriate allowances made</td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[10].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is positioned with a view to safety and rotor clearance when stopping engine and rotors </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[11].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c) </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform engine shutdown and rotor stop </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[12].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>rotor disc position is controlled during shutdown  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[13].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>engine and transmission system indications are monitored and managed </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[14].rate}</td>
              </tr>




              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H1.3  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Control main rotor disc and anti-torque system   </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a) </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>maintain the main rotor disc attitude during all RRPM operations </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[15].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> set anti-torque pedal position to compensate for main rotor torque</td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[16].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c)  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>rotor disc attitude and RRPM are managed while performing other tasks or actions </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>2  </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[17].rate}</td>
              </tr>





              </table>

              <hr style={{marginTop:460, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 11  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 2</p2></td>

              </tr>

              </table>


              </Grid>

              <Grid item xs={12} sm container style={{paddingTop:140, padding:50}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 11: BASIC AUTOROTATION</b>
              </td>
              </tr>
              </table>


              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>FLIGHT TRAINING<br></br>
              Suggested flight time:  1.0 hour dual</b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
              </tr>

              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H6.2  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform autorotative flight  </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a) </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>an appropriate action plan including task priorities is formulated that ensures the safe completion of autorotative manoeuvres </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[18].rate}</td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b) </td>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>autorotative flight is entered and maintained at a nominated speed and heading in balanced flight </td>
                      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[19].rate}</td>
                      </tr>
                      <tr>
                        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c)  </td>
                        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> autorotative flight is performed at the optimum range and minimum descent rate speeds</td>
                        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[20].rate}</td>
                        </tr>
                        <tr>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d)  </td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>heading is altered through 180° and 360° with the helicopter in balanced flight at a nominated speed </td>
                          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[21].rate}</td>
                          </tr>
                          <tr>
                            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e)  </td>
                            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is recovered  to normal flight from autorotative flight using power to a climb at nominated heading and speed </td>
                            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[22].rate}</td>
                            </tr>
                            <tr>
                              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f)  </td>
                              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is recovered to a power termination into wind, using appropriate  control inputs, the helicopter is flared at the appropriate height to reduce groundspeed and reduce rate of descent, control RRPM in limits, the helicopter is levelled and power is used to reduce rate of descent and establish a hover or hover taxi, control yaw throughout </td>
                              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[23].rate}</td>
                              </tr>
                              <tr>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g)  </td>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter autorotative landing to touchdown is performed into wind, using appropriate control inputs, the helicopter is flared at the appropriate height to reduce groundspeed and reduce rate of descent, the helicopter is levelled prior to touchdown and collective is used to reduce rate of descent and cushion the landing, control yaw throughout. The landing is completed in accordance with the procedures in the aircraft flight manual </td>
                                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[24].rate}</td>
                                </tr>
                                <tr>
                                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(h)  </td>
                                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility and terrain </td>
                                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[25].rate}</td>
                                  </tr>
                                  <tr>
                                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(i)  </td>
                                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>situational awareness is maintained at all times during autorotative flight </td>
                                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>3  </td>
                                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[26].rate}</td>
                                    </tr>
                                    </table>



              <hr style={{marginTop:820, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 11  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 3</p2></td>

              </tr>
              </table>





              <table style={{marginTop:100,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 11: BASIC AUTOROTATION</b>
              </td>
              </tr>
              </table>


              <p><i><b>*Enter the performance standard achieved if it is different to that required</b><br></br>
              Where it has not been possible to introduce performance criteria or the trainee has not achieved the
              required standard, the performance criteria must be covered during the next lesson. Enter these performance
              criteria in the lesson record for the subsequent lesson.</i></p>

              <table style={{borderStyle:'solid', width:'100%', marginTop:30, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>CONSOLIDATION AND/OR REMEDIAL TRAINING<br></br></b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>

              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              </table>



              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, borderStyle:'solid',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>DEBRIEFING</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,borderStyle:'solid', backgroundColor:'#EEECDF',width:'100%'}}>
              Content
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <ul>
              <li>Training review and outcomes achieved against lesson objectives and the Part 61 MOS competency standards</li>
              <li>Recommendations for next lesson (including any carryover/remedial training)</li>
              <li>Trainee preparation for next lesson</li>
              <li>Training record completion and sign off</li>
              </ul>
              </td>
              </tr>
              </table>


              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>COMMENTS AND OUTCOME</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderStyle:'solid', borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <br></br>
              {values.commts}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>


              </td>
              </tr>
              </table>
              <table style={{textAlign:'left',borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderBottomStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'80%'}}>
              <b>Proceed to next training session?</b>
              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>
              <b>{values.nextLesson_bool}</b>
              </td>
              <td style={{ borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>

              </td>
              </tr>

              </table>

              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Instructor’s signature & date</b>
              </td>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Trainee’s signature & date</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.ins_Signature}/>{flightDate}

              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.stu_Signature}/>{flightDate}
              </td>

              </tr>

              </table>

              <hr style={{marginTop:245, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 11  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 4</p2></td>

              </tr>

              </table>
              </Grid>


          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default BasicAutorotation
