import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';
import Typography from '@material-ui/core/Typography';

export class DeleteButton extends Component{

  constructor(props) {
    super(props);

    this.state = {value:0,
                  disabled:this.props.disabled,
                  tableName:this.props.tableName,
                  recordId:this.props.recordId,
                  idName: this.props.idName,
                  err:false,
                  errinfo:"",
                    };

  }


  deleteRecord= e=>(step)=> {
    const{values}=this.props;

    e.preventDefault();
  fetch(`${process.env.REACT_APP_SERVER_URL}/deleteRecord?tableName=${this.state.tableName}&recordId=${this.state.recordId}&idName=${this.state.idName}`, {
      headers: {
      'x-access-token': values.token
    }})
  .catch(err=>{console.error(err);
              this.setState({err:true,
                            errinfo:err  })})

  .then(
    e=>{this.setState({disabled:true});
    this.props.setStep(step)}
  )

}
render() {
  if (this.state.err==true) {
    return(

            <MuiThemeProvider>
              <React.Fragment>
    <Typography  style={{padding:'2%'}}variant="h5">  Deletion Failed!</Typography>
    <Typography style={{padding:'2%'}} variant="body2">  {this.state.errinfo}</Typography>

    </React.Fragment>
    </MuiThemeProvider>

  )

  } else {
    return (

            <MuiThemeProvider>
              <React.Fragment>
        <RaisedButton fullWidth label="Delete" disabled={this.state.disabled} onClick={this.deleteRecord()}/>

        </React.Fragment>
        </MuiThemeProvider>

    )

  }

    }
}
export default DeleteButton;
