import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';





export class AdvancedEmergencies extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }

  render() {
    const {values} = this.props;
    const ct = new Date(values.transTimeStamp);

    var date1 = new Date(values.fl_time);
    var flightDate = date1.getDate() + '/' + (date1.getMonth()+1) + '/' + date1.getFullYear();
    let current_time =  ct.getDate() + '/' + (ct.getMonth()+1) + '/' + ct.getFullYear();
    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>


          <Grid item xs={12} sm container style={{padding:50}}>


              <table style={{textAlign:'center',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#BFBFBE',width:'42%'}}>
              <b>HELICOPTER TRAINING AND TRANSPORT <br></br>
              Commercial Pilot Licence – Helicopter Category Rating</b>
              </td>
              </tr>
              </table>


              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 16: ADVANCED EMERGENCIES</b>
              </td>
              </tr>
              </table>



              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse', backgroundColor:'#EEECDF'}}>Flight no: </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> 105CPL(H) 16.{values.lessonNo}    </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Trainee name <br></br> & ARN: </th>
              <td style={{padding:10, columnSpan:'all',borderBottomStyle:'solid',borderWidth:1}}> {values.fName} {values.lName}<br></br> {values.stu_arn}  </td>
              </tr>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Date </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>  {flightDate}     </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Instructor: </th>
              <td style={{padding:10, columnSpan:'all'}}>{values.ins_fname} {values.ins_lname}  </td>

              </tr>
              </table>
              <table style={{textAlign:'left',borderStyle:'none', width:'100%', borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft<br></br>registration: </th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> {values.rego}   </td>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft Type:</th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{values.wing_type}     </td>
              <th style={{width:'10%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Flight Time:</th>
              <td style={{borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{(parseFloat(values.soloGH)+parseFloat(values.soloNav)).toFixed(1)} S <br></br>{(parseFloat(values.dualGH)+parseFloat(values.dualNav)).toFixed(1)} D</td>
              </tr>

              </table>

              <Grid item xs style={{textAlign:'left', marginTop:20, paddingLeft:10, paddingTop:5,borderWidth:1, borderStyle:'solid'}}>
              <b>Lesson Overview</b>

              <ul>
              <li> Conduct and /or simulate flight system failures</li>
              <li>Conduct and/or simulate warning light actions</li>
              <li>Conduct and/or simulate radio failure procedures</li>
              <li>Consolidate aerodrome Circuit procedures (Class D CTR)</li>
              </ul>

              </Grid>

              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <th style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#BFBFBE',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              PRE-FLIGHT KNOWLEDGE<br></br>
              Long Briefing: 1.0 hour    Pre-flight Briefing: 0.3 hour<br></br>
              Underpinning knowledge: as required
              </th>
              </tr>
              <tr>
              <td style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#EEECDF',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}> Content
              </td>
              </tr>
              <tr><td style={{ borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Long briefing </b>– Advanced Emergencies  <br></br>
              <ul>
              <li>Warning light panel actions</li>
              <li>Hydraulic failure (if applicable)</li>
              <li>Clutch actuator failure (if applicable)</li>
              <li>Governor failure</li>
              <li>Electrical systems failure</li>
              <li>Response to fire in flight</li>
              <li>Radio failure</li>
              <li>Transponder settings (7500, 7600, 7700)</li>

              </ul>
              </td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Underpinning knowledge</b> (relevant to the stage of training):<br></br>
              <ul>
              <li>Review/expand previously introduced knowledge as required</li>
              <li>Undesired aircraft states, including prevention, identifying and controlling [NTS2(e)]</li>
              <li>How an undesired aircraft state can develop from an unmanaged threat or error [NTS2(f)]</li>

              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>HF & NTS</b><br></br>
              <ul>
              <li>Look out/ listen out</li>
              <li>Carry out recurring scan of local environment for traffic prior to beginning each new exercise</li>
              <li>Hand over / take over  technique</li>
              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%', borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Pre-flight briefing</b><br></br>
              <ul>
              <li>Review flight sequences, what to expect, see & do</li>
              <li>Check essential knowledge</li>
              <li>Reinforce threat & error management</li>
              <li>Reinforce significant airmanship points </li>
              </ul></td>
              </tr>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',width:'42%',paddingLeft:10,paddingBottom:40, borderRightStyle:'solid', borderWidth:1}}>
              <b>Pre-flight knowledge components complete: <br></br></b>{values.pfk_bool}</td>
              <td style={{paddingLeft:10}}><b>Instructor’s signature & date</b><br></br>
              <img style={{maxWidth:'60%'}} src={values.ins_Signature}/>{current_time}</td>
              </tr>
              </table>



              <hr style={{marginTop:150, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 1</p2></td>

              </tr>

              </table>
              </Grid>


          <Grid item xs={12} sm container style={{padding:50, paddingTop:80}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 16: ADVANCED EMERGENCIES</b>
              </td>
              </tr>
              </table>

              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr style={{textAlign:'center',backgroundColor:'#BFBFBE',width:'100%',paddingLeft:10,paddingBottom:40}}>
              <b>Performance Standard</b>
              </tr>
              </table>

              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>3</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>2</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>1</b></td>
              </tr>
              <tr>
              <td style={{ width: '33.35%',borderStyle:'solid',borderWidth:1, padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Has received training in the element, however is not able to consistently demonstrate competency to the standard required for qualification issue</td>
              <td style={{ width: '33.34%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Demonstrates a developing level of proficiency, and is deemed safe to conduct solo practice under direct supervision</td>
              <td style={{ width: '33%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Achieves competency to the standard required for qualification issue</td>
              </tr>

              </table>


              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>FLIGHT TRAINING<br></br>
              Suggested flight time:  1.0 hour dual</b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
              </tr>

              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>C2.1   </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Pre-flight actions and procedures   </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify special aerodrome procedures  </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[0].rate}</td>
                    </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> determine the suitability of the current and forecast weather conditions for the proposed flight  </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[1].rate}</td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (g) </td>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> determine whether the aircraft is serviceable for the proposed flight  </td>
                      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[2].rate}</td>
                      </tr>

              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> C2.2  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform pre-flight inspection  </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify and secure equipment and documentation that is required for the flight </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[3].rate}</td>
                    </tr>
                    <tr>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> </td>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>complete an internal and external check of the aircraft </td>
                      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[4].rate}</td>
                      </tr><tr>
                        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> </td>
                        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify all defects or damage to the aircraft </td>
                        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[5].rate}</td>
                        </tr><tr>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e)  </td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>ensure all aircraft locking and securing devices, covers and bungs are removed and stowed securely</td>
                          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[6].rate}</td>
                          </tr>

              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> C3.2  </b></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>  Manage R/T equipment malfunctions </b></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
                  <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform radio failure procedures </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[7].rate}</td>
                    </tr>
                        <tr>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>  </td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>use fault finding procedures and perform corrective actions   </td>
                          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[8].rate}</td>
                          </tr>

        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> C3.3  </b></td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Operate Transponder  </b></td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
        </tr>
            <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> operate a transponder during normal, abnormal and emergency operations  </td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[9].rate}</td>
              </tr>
              <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> recall transponder emergency codes  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[10].rate}</td>
                </tr>

        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> NTS1.2  </b></td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Maintain situational awareness  </b></td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
        </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> monitor all aircraft systems using a systematic scan technique  </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[11].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> collect information to facilitate ongoing system management  </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[12].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> monitor flight environment for deviations from planned operations  </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[13].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> collect flight environment information to update planned operations  </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[14].rate}</td>
            </tr>



        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> NTS1.3  </b></td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Assess situations and make decisions   </b></td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
        </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify problems  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[15].rate}</td>
                </tr>

                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> analyse problems  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[16].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify solutions  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[17].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>assess solutions and risks   </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[18].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>decide on a course of action   </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[19].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>communicate plans of action (if appropriate)   </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[20].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>allocate tasks for action (if appropriate)   </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[21].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> take actions to achieve optimum outcomes for the operation  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[22].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> monitor progress against plan  </td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[23].rate}</td>
                </tr>
                <tr>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> re-evaluate plan to achieve optimum outcomes</td>
                <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[24].rate}</td>
                </tr>




          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> NTS1.4  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Set priorities and manage tasks   </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> organise workload and priorities to ensure optimum outcome of the flight  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[25].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> plan events and tasks to occur sequentially  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[26].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> anticipate events and tasks to ensure sufficient opportunity for completion  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[27].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> use technology to reduce workload and improve cognitive and manipulative activities  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[28].rate}</td>
                  </tr>

                  </table>



                  <hr style={{marginTop:50, width:'100%'}}></hr>
                  <table style={{width:'100%'}}>
                  <tr>
                  <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
                  <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
                  <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 2</p2></td>

                  </tr>

                  </table>
                  </Grid>


                  <Grid item xs={12} sm container style={{padding:50, paddingTop:80}}>



                  <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>LESSON PLAN AND TRAINING RECORD <br></br>
                  105CPL(H) 16: ADVANCED EMERGENCIES</b>
                  </td>
                  </tr>
                  </table>




                  <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>FLIGHT TRAINING<br></br>
                  Suggested flight time:  1.0 hour dual</b>
                  </td>
                  </table>
                  <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

                  </tr>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
                  </tr>





          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> NTS2.1  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Recognise and manage threats  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify relevant environmental or operational threats that are likely to affect the safety of the flight   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[29].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify when competing priorities and demands may represent a threat to the safety of the flight   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[30].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> develop and implement countermeasures to manage threats  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[31].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> monitor and assess flight progress to ensure a safe outcome, or modify actions when a safe outcome is not assured  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[32].rate}</td>
                  </tr>

          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> NTS2.2  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Recognise and manage errors  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>apply checklists and standard operating procedures to prevent aircraft handling, procedural or communication errors   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[33].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify committed errors before safety is affected or the aircraft enters an undesired state  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[34].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> implement countermeasures to prevent errors or take action in the time available to correct errors before the aircraft enters an undesired state	  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[35].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>NTS2.3   </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Recognise and manage undesired aircraft state  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> recognise an undesired aircraft state  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[36].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> prioritise tasks to ensure an undesired aircraft state is managed effectively  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[37].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  apply corrective actions to recover an undesired aircraft state in a safe and timely manner </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[38].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H1.1  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Start engine and rotor  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is positioned with a view to safety and rotor clearance when starting engine and rotors  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[39].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> wind conditions are assessed for start  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[40].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> perform pre-start checklists actions  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[41].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> perform engine start and rotor engagement  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[42].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> rotor disc position is controlled during start  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[43].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> engine is operated within limits  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[44].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergencies are managed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[45].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H1.2   </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Stop engine and rotor  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> wind conditions are assessed and appropriate allowances made  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[46].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is positioned with a view to safety and rotor clearance when stopping engine and rotors   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[47].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> perform engine shutdown and rotor stop  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[48].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> rotor disc position is controlled during shutdown   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[49].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> engine and transmission system indications are monitored and managed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[50].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H1.3  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Control main rotor disc and anti-torque system  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain the main rotor disc attitude during all RRPM operations  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[51].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> set anti-torque pedal position to compensate for main rotor torque  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[52].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> rotor disc attitude and RRPM are managed while performing other tasks or actions  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 1</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[53].rate}</td>
                  </tr>

                  </table>



                  <hr style={{marginTop:300, width:'100%'}}></hr>
                  <table style={{width:'100%'}}>
                  <tr>
                  <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
                  <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
                  <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 3</p2></td>

                  </tr>

                  </table>
                  </Grid>


                  <Grid item xs={12} sm container style={{padding:50, paddingTop:70}}>



                  <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>LESSON PLAN AND TRAINING RECORD <br></br>
                  105CPL(H) 16: ADVANCED EMERGENCIES</b>
                  </td>
                  </tr>
                  </table>




                  <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>FLIGHT TRAINING<br></br>
                  Suggested flight time:  1.0 hour dual</b>
                  </td>
                  </table>
                  <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

                  </tr>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
                  </tr>


          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H2.1  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Lift off to hover and perform hover checks  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> aircraft performance is calculated for the flight  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[54].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> pre-take-off checks are performed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[55].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> flight controls are set to prepare for lift-off to the hover  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[56].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>flight and power controls are used to lift helicopter off the surface to a stable hover at the appropriate height for the helicopter while controlling heading   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[57].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> wind effect is anticipated and accounted for with appropriate control inputs to maintain position over nominated hover point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[58].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> awareness of adverse effects of rotor downwash on surrounding aircraft, people, objects and environment is demonstrated  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[59].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> perform hover checks  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[60].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> flight control functions, centre of gravity and hover power requirements are checked  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[61].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> at a constant and safe hover height, commence, maintain and stop a hover taxi manoeuvre while maintaining power and RRPM within the limits  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[62].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>coordinated corrective action is used to counter the effects of wind   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[63].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> implications of environmental conditions are assessed and appropriate compensation is made  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[64].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is maintained clear of obstructions   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[65].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[66].rate}</td>
                  </tr>

          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H2.2  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Hover helicopter in crosswind and tailwind  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is maintained in hovering flight remaining over a nominated hover point at a nominated height and heading in cross and tail winds  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[67].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> coordinated corrective action is used to maintain a constant rate of turn and counter the effects of wind  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[68].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H2.3   </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform turns around the mast  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is turned around the mast while maintaining a constant height  at a specified rate of turn  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[69].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  turn is completed on a nominated heading </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[70].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  controlled corrective action is used to control the effects of wind </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[71].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  helicopter is maintained clear of obstructions </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[72].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility, obstructions and terrain </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[73].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  RPM is managed within limits during the turn </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[74].rate}</td>
                  </tr>

          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H2.4   </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform turns around nose and tail  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is turned around a nominated point on or forward of the nose while maintaining a constant height and specified rate of movement around that point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[75].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is turned around a nominated point on or aft of the tail while maintaining a constant height and specified rate of movement around that point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[76].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>controlled corrective action is taken to counter the effects of wind   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[77].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is maintained clear of obstructions during turning manoeuvres  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[78].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility, obstructions and terrain   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[79].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> turns in a specified direction are commenced and stopped at a specified heading  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[80].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> RPM is managed within limits during the turn  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[81].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain ground track at a constant distance from the nominated point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[82].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> anti-torque pedals are used to ensure helicopter nose or tail is pointed at the nominated turning point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[83].rate}</td>
                  </tr>
                  </table>



                  <hr style={{marginTop:110, width:'100%'}}></hr>
                  <table style={{width:'100%'}}>
                  <tr>
                  <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
                  <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
                  <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 4</p2></td>

                  </tr>

                  </table>
                  </Grid>


                  <Grid item xs={12} sm container style={{padding:50, paddingTop:80}}>



                  <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>LESSON PLAN AND TRAINING RECORD <br></br>
                  105CPL(H) 16: ADVANCED EMERGENCIES</b>
                  </td>
                  </tr>
                  </table>




                  <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>FLIGHT TRAINING<br></br>
                  Suggested flight time:  1.0 hour dual</b>
                  </td>
                  </table>
                  <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

                  </tr>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
                  </tr>



          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H2.5   </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform sideways and backwards flight  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is transitioned from static hover to sideways and rearward flight   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[84].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> lookout is maintained in direction of flight using a systematic scan technique at a rate determined by traffic density, visibility, obstructions and terrain  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[85].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> rearward movement is only conducted after visually checking behind helicopter, and height is adjusted as required  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[86].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter directional control is maintained and manoeuvred clear of obstructions during sidewards and backwards flight manoeuvres  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[87].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> RPM is managed within limits during the turn  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[88].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain rate of movement of helicopter at a safe speed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[89].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> sideways and rearward flight is terminated over a nominated hover point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[90].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H2.6   </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Land from the hover  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> complete pre-landing checks (if applicable)  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[91].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is lowered on to a nominated point from hovering flight using a controlled rate of descent, without adverse longitudinal, lateral, yawing or rolling movements  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[92].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> ensure helicopter is stable on its landing gear prior to fully lowering collective  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[93].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> after-landing checks are performed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[94].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H2.7  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Manage a mishandled landing </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify when an adverse landing situation is developing  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[95].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> appropriate action is taken to discontinue the landing and return to a safe hover  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[96].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H2.8 </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Manage a mishandled lift off </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify when an adverse lift off situation is developing  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[97].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> appropriate action is taken to discontinue the lift off and return to the ground safely  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[98].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H3.2  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Perform air taxiing manoeuvres  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is manoeuvred over the ground on a prescribed track at constant height associated with ground effect and speed adjusted to suit helicopter type, surface conditions, congestion, maintenance of control and to avoid collision with obstacles or other aircraft  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[99].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> as far as operational limitations allow, the landing gear is aligned with the direction of travel  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[100].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>awareness of adverse effects of rotor downwash on surrounding aircraft, people, objects and environment is demonstrated   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[101].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> RPM is managed within normal operating limits  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[102].rate}</td>
                  </tr>

          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H4.4 </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform go-around procedure </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> critical situations are recognised and timely decisions are made to go-around in circumstances that require discontinuing a circuit or approach   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[103].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> initiate the go-around   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[104].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> set power and attitude to initiate safe climb at appropriate IAS from any position in the circuit  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[105].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> obstructions and traffic are appropriately avoided during the climb following a decision to go-around  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[106].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>  lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[107].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> situational awareness of circuit traffic is maintained throughout go-round procedure  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[108].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>after take-off checks are completed in accordance with approved checklist (as required)   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[109].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> local and published noise abatement requirements and curfews are observed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[110].rate}</td>
                  </tr>

                  </table>



                  <hr style={{marginTop:160, width:'100%'}}></hr>
                  <table style={{width:'100%'}}>
                  <tr>
                  <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
                  <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
                  <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 5</p2></td>

                  </tr>

                  </table>
                  </Grid>


                  <Grid item xs={12} sm container style={{padding:50, paddingTop:100}}>



                  <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>LESSON PLAN AND TRAINING RECORD <br></br>
                  105CPL(H) 16: ADVANCED EMERGENCIES</b>
                  </td>
                  </tr>
                  </table>




                  <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>FLIGHT TRAINING<br></br>
                  Suggested flight time:  1.0 hour dual</b>
                  </td>
                  </table>
                  <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

                  </tr>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
                  </tr>

          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>H5.6 </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform circuits and approaches </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> circuits are joined and conducted in accordance with AIP and or local procedures at normal and low altitude appropriate to the helicopter type  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[111].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> due allowance is made for the wind   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[112].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> all appropriate checklist items are completed when performing circuits and approaches  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[113].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> radiotelephone procedures are followed during circuit operations  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[114].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> the approach path applicable to the helicopter type is intercepted and maintained whilst remaining clear of other traffic  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[115].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter approach is conducted to establish hover or conduct a landing at the nominated termination point  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[116].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (g)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> lookout is maintained during circuits and approaches using a systematic scan technique at a rate determined by traffic density, visibility and terrain  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[117].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (h)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> conflicting traffic is recognised and appropriate responses are made  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[118].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(i)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> right of way rules are applied and compliance with the rules is maintained  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[119].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(j)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> weather conditions are monitored and appropriate responses are made  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[120].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(k)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> fuel status is monitored and appropriate responses are made  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[121].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H5.7</b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Comply with airspace requirements </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> suitable aeronautical charts are interpreted and used to maintain airspace compliance requirements  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[122].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>circuit departure is performed   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[123].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is maintained within a specified area and/or track while complying with air traffic requirements, controlled or restricted airspace conditions or limitations and reacting to factors that affect the safe progress of a flight  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[124].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> orientation is maintained to geographical features with the aid of suitable charts and maps  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[125].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> circuit join is conducted  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[126].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H6.2 </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>  Perform autorotative flight</b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> an appropriate action plan including task priorities is formulated that ensures the safe completion of autorotative manoeuvres  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[127].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> autorotative flight is entered and maintained at a nominated speed and heading in balanced flight  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[128].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> autorotative flight is performed at the optimum range and minimum descent rate speeds  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[129].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> heading is altered through 180° and 360° with the helicopter in balanced flight at a nominated speed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[130].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is recovered  to normal flight from autorotative flight using power to a climb at nominated heading and speed   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[131].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>helicopter is recovered to a power termination into wind, using appropriate  control inputs, the helicopter is flared at the appropriate height to reduce groundspeed and reduce rate of descent, control RRPM in limits, the helicopter is levelled and power is used to reduce rate of descent and establish a hover or hover taxi, control yaw throughout   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[132].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (h)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility and terrain  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[133].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (i)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> situational awareness is maintained at all times during autorotative flight  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[134].rate}</td>
                  </tr>
                  </table>



                  <hr style={{marginTop:250, width:'100%'}}></hr>
                  <table style={{width:'100%'}}>
                  <tr>
                  <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
                  <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
                  <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 6</p2></td>

                  </tr>

                  </table>
                  </Grid>


                  <Grid item xs={12} sm container style={{padding:50, paddingTop:80}}>



                  <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>LESSON PLAN AND TRAINING RECORD <br></br>
                  105CPL(H) 16: ADVANCED EMERGENCIES</b>
                  </td>
                  </tr>
                  </table>




                  <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
                  <b>FLIGHT TRAINING<br></br>
                  Suggested flight time:  1.0 hour dual</b>
                  </td>
                  </table>
                  <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
                  <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

                  </tr>
                  <tr>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
                  <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
                  </tr>




          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H7.1</b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Manage a forced landing from level flight, after take-off and on approach </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergency situation requiring a forced landing is correctly identified  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[135].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> autorotative flight is entered and established at nominated speed and heading in balanced flight  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[136].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> immediate actions are performed  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[137].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> control RRPM within limitations  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[138].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> a landing area within autorotative distance is selected and an appropriate action plan is formulated to ensure safety of the helicopter  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[139].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (f)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergency procedures are implemented and task priorities are allocated to all actions to ensure aircraft, flight crew and passenger safety  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[140].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g)   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergency radio message of intentions are transmitted  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[141].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (h)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> helicopter is aligned with prevailing wind direction when possible with as slow as practical ground speed while maintaining control of the helicopter; situational awareness is maintained at all times during forced landing manoeuvres  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[142].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b> H7.2</b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Manage an engine failure at the hover or during taxi </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> hover heights and taxi surfaces are selected to maximise options in the event of an engine failure  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[143].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> emergency situation involving an engine failure is correctly identified  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[144].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> immediate actions are performed in accordance with the aircraft flight manual  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[145].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform a controlled touchdown   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[146].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>      H7.6  </b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Manage upset recovery (this element is included to cover situations where the pilot has to recover the aircraft to a safe and stable flight condition in visual conditions)  </b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> apply correct techniques for upset recovery in various configurations as follows:  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> (i) recognise upset condition  </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[147].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>(ii) maintain references by visual cues   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[148].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>   </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>(iii) recover to level flight condition; configure aircraft appropriately   </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[149].rate}</td>
                  </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>CTR.1</b></td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Controlled aerodrome pre-flight preparation</b></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
          </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>using a current ERSA and NOTAM, for the controlled aerodrome, extract all the relevant operational information </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[150].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> interpret the extracted information</td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[151].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify all special aerodrome procedures </td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[152].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> check current weather forecast and local observations</td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[153].rate}</td>
                  </tr>
                  <tr>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)  </td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify all relevant radio and navigation aid frequencies</td>
                  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                  <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[154].rate}</td>
                  </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>CTR.2</b></td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Taxi aircraft at a controlled aerodrome</b></td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
            </tr>
                    <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>obtain and comply with ATC clearances </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[155].rate}</td>
                    </tr>
                    <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> manoeuvre aircraft to holding point as instructed and take appropriate action to avoid other aircraft and obstructions</td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[156].rate}</td>
                    </tr>
                    <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>recognise ground markings during taxi and take appropriate action </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[157].rate}</td>
                    </tr>
                    <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>recognise lighting signals and take appropriate action </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[158].rate}</td>
                    </tr>
                    <tr>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)  </td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify airport runway incursion hotspots </td>
                    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2</td>
                    <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[159].rate}</td>
                    </tr>



              </table>

              <hr style={{marginTop:240, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 7</p2></td>

              </tr>

              </table>


              </Grid>

              <Grid item xs={12} sm container style={{paddingTop:100, padding:50}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 16: ADVANCED EMERGENCIES</b>
              </td>
              </tr>
              </table>
              <p><i><b>*Enter the performance standard achieved if it is different to that required</b><br></br>
              Where it has not been possible to introduce performance criteria or the trainee has not achieved the
              required standard, the performance criteria must be covered during the next lesson. Enter these performance
              criteria in the lesson record for the subsequent lesson.</i></p>

              <table style={{borderStyle:'solid', width:'100%', marginTop:30, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>CONSOLIDATION AND/OR REMEDIAL TRAINING<br></br></b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>

              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              </table>



              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, borderStyle:'solid',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>DEBRIEFING</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,borderStyle:'solid', backgroundColor:'#EEECDF',width:'100%'}}>
              Content
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <ul>
              <li>Training review and outcomes achieved against lesson objectives and the Part 61 MOS competency standards</li>
              <li>Recommendations for next lesson (including any carryover/remedial training)</li>
              <li>Trainee preparation for next lesson</li>
              <li>Training record completion and sign off</li>
              </ul>
              </td>
              </tr>
              </table>


              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>COMMENTS AND OUTCOME</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderStyle:'solid', borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <br></br>
              {values.commts}
              <br></br>
              <br></br>
              <br></br>
              <br></br>


              </td>
              </tr>
              </table>
              <table style={{textAlign:'left',borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderBottomStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'80%'}}>
              <b>Proceed to next training session?</b>
              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>
              <b>{values.nextLesson_bool}</b>
              </td>
              <td style={{ borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>

              </td>
              </tr>

              </table>

              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Instructor’s signature & date</b>
              </td>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Trainee’s signature & date</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.ins_Signature}/>{flightDate}

              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.stu_Signature}/>{flightDate}
              </td>

              </tr>

              </table>

              <hr style={{marginTop:245, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 16  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 8</p2></td>

              </tr>

              </table>
              </Grid>


          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default AdvancedEmergencies
