import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { validations, validateAll } from 'indicative/validator';
import PasswordHash from 'password-hash';
import * as moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const handleDateChange = e => {
   console.log(e);

 };

export class PreFlightInfoForm extends Component{

  constructor(props) {
    super(props);
    const {values}=this.props;
    const current_date=moment().format('MM/DD/YYYY');
    console.log(values.medical2);
    console.log(moment().unix());

    if (values.searchJson=="") {
      this.state = {value         : 0,
                    loading       :true,
                    checkbox      :values.status=="TP"||values.status=="TF",
                    fuelStart         : "",
                    dualtotal        : "",
                    hirerNDBSigPopup : "",
                    hirerSigPopup    : "",
                    hirerauthorized  : "",
                    insAuthorized    : "",
                    insSigPopup      : "",
                    picTotal         : "",
                    commts      : "",
                    rego        :"",
                    divOff      : "",
                    ccErr       : values.creditCardExpiry<moment(current_date).unix(),
                    medErr      : moment(values.medical2,'MM/DD/YYYY').unix()<=moment().unix(),
                    hirerPasserr     : "none",
                    insPassErr       : "none",
                    passHirer        : "",
                    passIns          : "",
                    fuelStarterr     : "",
                    dualtotalerr     : "",
                    picTotalerr      : "",
                    commtserr        : "",
                    regoerr          :  "",
                    noFlyPopUp       : "",
                    emailerr       :  "",
                    rAddresserr      :  "",
                    summaryDateerr   :"",
                    hirerLabel       : "Student/Hirer Authorization",
                    fl_time           : moment(values.fl_time).format('MM/DD/YYYY'),
                    flightTypeerr     :"",
                    pilotNameerr      :"",
                    pilotName         :"N/A",

          };

    }else {
      this.state = {value         : 0,
                    loading       :true,
                    checkbox      :values.status=="TP"||values.status=="TF",
                    fuelStart         : "",
                    dualtotal        : "",
                    picTotal         : "",
                    commts      : "",
                    regos       :"",
                    divOff      : "none",
                    ccErr       : values.creditCardExpiry<moment(current_date).unix(),
                    medErr      :  moment(values.medical2,'MM/DD/YYYY').unix()<=moment().unix(),
                    hirerNDBSigPopup : "",
                    hirerSigPopup    : "",
                    hirerauthorized  : "",
                    insAuthorized    : "",
                    insSigPopup      : "",
                    hirerPasserr     : "none",
                    insPassErr       : "none",
                    passHirer        : "",
                    passIns          : "",
                    fuelStarterr     : "",
                    dualtotalerr     : "",
                    picTotalerr      : "",
                    commtserr        : "",
                    regoerr          :  "",
                    noFlyPopUp       : "",
                    emailerr       :  "",
                    rAddresserr      :  "",
                    hirerLabel       : "Hirer Authorization",
                    fl_time           : moment(values.fl_time).format('MM/DD/YYYY'),
                    flightTypeerr     :"",
                    pilotNameerr      :"",
                    pilotName         :"N/A",

          };

    }




  }


  continue = e =>{
    if (this.state.fuelStart=="" && this.state.dualtotal=="" && this.state.picTotal=="" && this.state.commts=="") {
      this.props.nextStep();
    } else {
        console.log('err');
    }

  }
  back = e => {
    const {values}=this.props;
    e.preventDefault();
    this.props.handleChange("searchJson","");
    if (values.step!=7007) {
      this.props.clearState();

    }
    this.props.prevStep();
  }

  validate=e=>{
    e.preventDefault();
     this.validateEmail()

  }
jumpTraining= e => {
  const {values}=this.props;
  e.preventDefault();
  this.props.handleChange("searchJson","");

  this.props.setStep(2999);
}

hirerauthorize=e=>{
  const{values}=this.props;
  e.preventDefault();
  fetch(this.validate())
  .then(
  e=>{if (this.state.flightTypeerr=="" && this.state.regoerr=="" && this.state.fuelStarterr=="" && this.state.dualtotalerr=="" && this.state.picTotalerr=="" && this.state.commtserr=="") {

    if (this.state.ccErr==true && values.tillBalance<1000) {
      this.setState({noFlyPopUp:true})

}else {
  this.setState({hirerSigPopup:true});
}


  } else {
      console.log('err');
  }})


}

hirerauthorizeNDB=e=>{
  e.preventDefault();
  fetch(this.validate())
  .then(
  e=>{if (this.state.flightTypeerr=="" && this.state.regoerr=="" &&this.state.fuelStarterr=="" && this.state.dualtotalerr=="" && this.state.picTotalerr=="" && this.state.commtserr=="" && this.state.emailerr=="" && this.state.rAddresserr=="") {
    this.setState({hirerNDBSigPopup:true});
  } else {
      console.log('err');
  }})


}

insauthorize=e=>{
  e.preventDefault();
  this.setState({insSigPopup:true});
}

passwordSet= field => e =>{
    e.preventDefault();
    this.setState({[field]:e.target.value});


  }

  onTagsChange = (event, value) => {
      const { values,handleChange} = this.props;
      handleChange('rego',value.rego)
      this.setState({regoerr:""})


    }

    onTagsChangeIns = (event, value) => {
        const { values,handleChange} = this.props;
        handleChange('pilotName',value.fname+' '+value.lname)
        this.setState({pilotNameerr:""})


      }

  handleChange = field => e => {
    const { values, log,handleChangeInput, handlePicChange} = this.props;

  e.preventDefault();
  this.setState({[field+"err"]:''})
  handleChangeInput(field,e.target.value);
  /// Do something here
}

log =  e => {
  console.log(e)
  const { log} = this.props;

this.setState({dob:''})
log(e);
/// Do something here
}



  validate=e=>{
    const { values} = this.props;
    const data = values
    const rules= {fuelStart   : 'required|max:6',
                  dualtotal   : 'max:5',
                  picTotal    : 'max:5',
                  commts      : 'max:5000',
                  rego        : 'required',
                  email       : 'required|email',
                  rAddress    : 'required|max:900',
                  flightType  : 'required'
  }
    const messages={
      alpha                 : 'Alphabetical characters only!',
      date1                 : 'Invalid Date',
      number                : 'Numbers Only',
      float                 : "That's invalid",
      required              : 'This cannot be empty',
      min                   : "That's too short!",
      max                   : "That's too long!",
      email                 : 'Invalid Email',
      alpha_numeric         : 'Illegal Characters in the password',
      'password.confirmed'  : 'The password confirmation does not match the entered password',
            }

    validateAll(data, rules, messages)
    .catch(errors =>{
      console.log(errors);

      const formattedErrors = {}
      errors.forEach(error =>this.setState({[error.field+"err"]:error.message})
      )



    })


  }
validatePassHirerNDB= e => {
  const { values} = this.props;
  const passHirerLowerStr=(this.state.passHirer).toLowerCase();
  const searchJsonLowerStr=(values.searchJson).toLowerCase();

  if (passHirerLowerStr==searchJsonLowerStr) {
    this.setState({ hirerNDBSigPopup :false,
                    hirerLabel    :"Hirer/Student Authorized",

    })

  }else{this.setState({hirerPasserr:""})}


}

validatePassHirerAbsent= e => {
  const { values} = this.props;


      this.setState({ hirerSigPopup :false,
                      hirerLabel    :"Hirer/Student Authorized",

      })

}


  validatePassHirer= e => {
    const { values} = this.props;

    fetch(`${process.env.REACT_APP_SERVER_URL}/authKi?email=${values.email}`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{if(response.data.length>0){
      const hashed= response.data[0].keypass;
      const password=this.state.passHirer;
      const authSuccess= PasswordHash.verify(password,hashed);
      console.log(authSuccess)

      if(authSuccess==true){
        this.setState({ hirerSigPopup :false,
                        hirerLabel    :"Hirer/Student Authorized",

        })

      }else{this.setState({hirerPasserr:""})}

    }else{this.setState({hirerPasserr:""})}


    })
    .catch(err=>this.setState({hirerPasserr:""}))

  }


validatePassIns= e => {
  const { values} = this.props;
  this.props.handleChangeInput('fl_time',moment(this.state.fl_time).format('MM/DD/YYYY'))

  if (this.state.flightTypeerr=="" && this.state.regoerr=="" &&this.state.fuelStarterr=="" && this.state.dualtotalerr=="" && this.state.picTotalerr=="" && this.state.commtserr=="" && this.state.emailerr=="" && this.state.rAddresserr=="") {
    this.setState({hirerNDBSigPopup:true});
    if (values.step!=7050 && values.step!=7007) {


    this.props.enterPreFlightAuth(7003);}
    else {

      this.props.editpreflightAuth(7003);
    }

  } else {
      console.log('err');
  }



      //enter DB entry




}

openRecentHistory=e=>{
  this.props.getFlightHistoryforStudent();
  this.props.setStep(7060);

}

updateStatus=e=>{
  if (e.target.checked==true) {
    this.props.handleChangeInput('status',"TP");
    this.setState({checkbox:e.target.checked})



  }else {
    this.props.handleChangeInput('status',"P");
    this.setState({checkbox:e.target.checked})


  }
}

dateChange=(date)=>{
    this.setState({summaryDateerr:"",fl_time:date})
    console.log(date);

    this.props.handleChangeInput('fl_time',moment(date).format('MM/DD/YYYY'))
}


componentDidMount(){
  const { values} = this.props;

  fetch(`${process.env.REACT_APP_SERVER_URL}/getWings`, {
    headers: {
    'x-access-token': values.token
  }})
  .then(response => response.json())
  .then(response=>{this.props.handleChange('suggestionsWings',response.data);})
  .then(
    fetch(`${process.env.REACT_APP_SERVER_URL}/getInstructors`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{this.props.handleChange('suggestionsIns',response.data);this.setState({loading:false})})


  )
  .catch(err=>console.error(err))
}


render() {
    const { values, log, handlePicChange} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >


          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1} style={{display:this.state.divOff, marginTop:"5%", marginBottom:"2%"}}>

              <Grid item xs={12}>
              <h3>{values.fName} {values.lName}</h3>
              </Grid>
              <Grid item xs={4} style={{display:this.state.medErr==true?"":"none",backgroundColor:this.state.medErr!=false?"red":"",color:this.state.medErr!=false?"white":""}}>
              <h2>Medical Expired</h2>
              </Grid>
              <Grid item xs={4} style={{display:this.state.ccErr==true?"":"none",backgroundColor:"red",color:"white"}}>
              <h2>Payment Info Error/Expired</h2>
              </Grid>
              <Grid item xs={4} style={{backgroundColor:values.tillBalance<1000?"red":"",color:values.tillBalance<1000?"white":""}}>
              <h2>Account Balance : {parseFloat(values.tillBalance).toFixed(2)}</h2>
              </Grid>
              <Grid xs={12}/>
              <Grid item xs={3} style={{display:values.searchJson==""?"flex":"none"}}>
              <RaisedButton style={{height:60}} label={"View Recent Flight History"} primary={true} onClick={e=>{e.preventDefault();this.openRecentHistory()}}/>
                </Grid>


              </Grid>
              <Grid container spacing={1}>

              <Grid item xs={12}>

              <h3>Fill in the information below</h3>
              <Grid item xs={12}>
              <Grid item xs={3}>
              <FormControl variant="outlined" style={{width:'100%',marginTop:'9%'}}

              >
                      <InputLabel  htmlFor="outlined-age-simple">
                        Flight Type
                        </InputLabel>


                      <Select
                      onChange={this.handleChange('flightType')}
                      value={values.flightType}
                      error={this.state.flightTypeerr!=""}
                      helperText={this.state.flightTypeerr != "" ? this.state.flightTypeerr : ''}

                    inputProps={{
                      name: 'flightType',
                      id: 'flightType',
                    }}
                        >
                        <MenuItem value={"P"}>P - Private</MenuItem>
                        <MenuItem value={"A"}>A - Airwork</MenuItem>
                        <MenuItem value={"C"}>C - Charter</MenuItem>
                        <MenuItem value={"X"}>X - Cross Hire</MenuItem>
                        </Select>

                        </FormControl></Grid>
              <FormControlLabel
              style={{marginTop:'20px'}}
                control={
                  <Checkbox  checked={this.state.checkbox} onChange={this.updateStatus} value="trainingFlight" />
                }
                label="Training Flight"
                />

               </Grid>
              </Grid>
              <Grid xs={12}>
              <p>Current Rego : {values.rego}<br></br> Current Flight Date: {moment(values.fl_time).format("DD/MM/YYYY")}<br></br> Current Pilot/Instructor: {values.pilotName} </p>
              </Grid>
              <Grid xs={3}>
              <Autocomplete
                disableClearable='true'
                disableOpenOnFocus="true"
                options={values.suggestionsWings}
                getOptionLabel={option => option.rego}
                onChange={this.onTagsChange}
                renderTags={() => {}}

                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    fullWidth
                    value={this.state.rego}
                    label="Change Rego"
                    error={this.state.regoerr!=""}
                    helperText={this.state.regoerr != "" ? this.state.regoerr : ''}
                    style={{paddingTop:'1%'}}
                  />
                )}
                />
              </Grid>
              <Grid xs={3}>
              <Autocomplete
                disableClearable='true'
                disableOpenOnFocus="true"
                options={values.suggestionsIns}
                getOptionLabel={option => option.fname+' '+option.lname}
                onChange={this.onTagsChangeIns}
                renderTags={() => {}}

                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    fullWidth
                    value={this.state.pilotName}
                    label="Change Pilot/Instructor"
                    error={this.state.pilotNameerr!=""}
                    helperText={this.state.pilotNameerr != "" ? this.state.pilotNameerr : ''}
                    style={{paddingTop:'1%'}}
                  />
                )}
                />
              </Grid>
              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                  disabled={this.state.editenabled}
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date_picker"
                label="Change Date (Date will be changed if selected only)"
                onChange={this.dateChange}
                value={this.state.fl_time}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
                </MuiPickersUtilsProvider>
            </Grid>
              <Grid item xs={3}>
              <TextField
                  required
                  id="fuel"
                  label="Fuel at Start(L)"
                  type="number"
                  name="fuel"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('fuelStart')}
                  value={values.fuelStart}
                  error={this.state.fuelStarterr!=""}
                  helperText={this.state.fuelStarterr != "" ? this.state.fuelStarterr : ''}

                  /></Grid>


              <Grid item xs={3}>
              <TextField
                  id="dualtotal"
                  label="Total Dual Hrs"
                  type="number"
                  name="dualtotal"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('dualtotal')}
                  value={values.dualtotal}
                  error={this.state.dualtotalerr!=""}
                  helperText={this.state.dualtotalerr != "" ? this.state.dualtotalerr : ''}

                  /></Grid>
              <Grid item xs={3}>
              <TextField
                  required
                  id="picTotal"
                  label="PIC Hrs"
                  type="number"
                  name="picTotal"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('picTotal')}
                  value={values.picTotal}
                  error={this.state.picTotalerr!=""}
                  helperText={this.state.picTotalerr != "" ? this.state.picTotalerr : ''}

                  /></Grid>


              <Grid item xs={12}>
              <TextField
                  id="Comments"
                  label="Route/Flight Details"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('commts')}
                  value={values.commts}
                  error={this.state.commtserr!=""}
                  helperText={this.state.commtserr != "" ? this.state.commtserr : ''}
                  />
                  </Grid>
                  <Grid item xs={6} style={{display:this.state.divOff=="none"?"":"none"}}>
                  <TextField
                      id="email"
                      type="email"
                      label="Hirer Email"
                      margin="normal"
                      variant="outlined" style={{width:'100%'}}
                      onChange={this.handleChange('email')}
                      value={values.email}
                      error={this.state.emailerr!=""}
                      helperText={this.state.emailerr != "" ? this.state.emailerr : ''}

                      />

                    </Grid>
                    <Grid item xs={6} style={{display:this.state.divOff=="none"?"":"none"}}>

                    <TextField
                        id="pax1"
                        label="Hirer Address"
                        margin="normal"
                        variant="outlined" style={{width:'100%'}}
                        onChange={this.handleChange('rAddress')}
                        value={values.rAddress}
                        error={this.state.rAddresserr!=""}
                        helperText={this.state.rAddresserr != "" ? this.state.rAddresserr : ''}

                        />
                      </Grid>
                  <Grid container spacing={1} >
                  <Grid xs={12} style={{display:values.step==7050||values.step==7007?"none":""}}><p> Following Information CANNOT be altered later</p></Grid>
                  <Grid item xs={6}>

                  <TextField
                      id="pax1"
                      disabled={values.step==7050||values.step==7007?true:false}
                      label="Pax 1 Name"
                      margin="normal"
                      variant="outlined" style={{width:'100%'}}
                      onChange={this.handleChange('pax1name')}
                      value={values.pax1name}

                      />

                    </Grid>

                    <Grid item xs={6}>
                    <TextField
                    disabled={values.step==7050||values.step==7007?true:false}
                        id="pax1W"
                        label="Pax 1 Weight"
                        margin="normal"
                        variant="outlined" style={{width:'100%'}}
                        onChange={this.handleChange('pax1W')}
                        value={values.pax1W}
                      />

                      </Grid>
                      <Grid item xs={6}>
                      <TextField
                      disabled={values.step==7050||values.step==7007?true:false}
                          id="pax2"
                          label="Pax 2 Name"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          onChange={this.handleChange('pax2name')}
                          value={values.pax2name}

                          />

                        </Grid>
                      <Grid item xs={6}>
                      <TextField
                      disabled={values.step==7050||values.step==7007?true:false}
                          id="pax2W"
                          label="Pax 2 Weight"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          onChange={this.handleChange('pax2W')}
                          value={values.pax2W}

                          />

                        </Grid>
                        <Grid item xs={6}>
                        <TextField
                        disabled={values.step==7050||values.step==7007?true:false}
                            id="pax3"
                            label="Pax 3 Name"
                            margin="normal"
                            variant="outlined" style={{width:'100%'}}
                            onChange={this.handleChange('pax3name')}
                            value={values.pax3name}

                            />

                          </Grid>
                        <Grid item xs={6}>
                        <TextField
                        disabled={values.step==7050||values.step==7007?true:false}
                            id="pax3W"
                            label="Pax 3 Weight"
                            margin="normal"
                            variant="outlined" style={{width:'100%'}}
                            onChange={this.handleChange('pax3W')}
                            value={values.pax3W}

                            />

                          </Grid>
                          <Grid item xs={6}>
                          <TextField
                          disabled={values.step==7050||values.step==7007?true:false}
                              id="pax4"
                              label="Pax 4 Name"
                              margin="normal"
                              variant="outlined" style={{width:'100%'}}
                              onChange={this.handleChange('pax4name')}
                              value={values.pax4name}

                              />

                            </Grid>
                          <Grid item xs={6}>
                          <TextField
                          disabled={values.step==7050||values.step==7007?true:false}
                              id="pax4W"
                              label="Pax 4 Weight"
                              margin="normal"
                              variant="outlined" style={{width:'100%'}}
                              onChange={this.handleChange('pax4W')}
                              value={values.pax4W}

                              />

                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                            disabled={values.step==7050||values.step==7007?true:false}
                                id="pax5"
                                label="Pax 5 Name"
                                margin="normal"
                                variant="outlined" style={{width:'100%'}}
                                onChange={this.handleChange('pax5name')}
                                value={values.pax5name}

                                />

                              </Grid>
                            <Grid item xs={6}>
                            <TextField
                            disabled={values.step==7050||values.step==7007?true:false}
                                id="pax5W"
                                label="Pax 5 Weight"
                                margin="normal"
                                variant="outlined" style={{width:'100%'}}
                                onChange={this.handleChange('pax5W')}
                                value={values.pax5W}

                                />

                              </Grid>
                              <Grid item xs={6}>
                              <TextField
                              disabled={values.step==7050||values.step==7007?true:false}
                                  id="pax6"
                                  label="Pax 6 Name"
                                  margin="normal"
                                  variant="outlined" style={{width:'100%'}}
                                  onChange={this.handleChange('pax6name')}
                                  value={values.pax6name}

                                  />

                                </Grid>
                              <Grid item xs={6}>
                              <TextField
                              disabled={values.step==7050||values.step==7007?true:false}
                                  id="pax6W"
                                  label="Pax 6 Weight"
                                  margin="normal"
                                  variant="outlined" style={{width:'100%'}}
                                  onChange={this.handleChange('pax6W')}
                                  value={values.pax6W}

                                  />

                                </Grid>


                                  </Grid>


                    <Grid container spacing={1} style={{display:values.step==7050||values.step==7007?"none":""}}>

                  <Grid item xs={12} style={{display:values.searchJson==""?"":"none"}}>
                  <RaisedButton fullWidth disabled={this.state.hirerLabel=="Student/Hirer Authorization"?false:true} style={{height:60}} label={this.state.hirerLabel} secondary={true} onClick={this.hirerauthorize}/>
                    </Grid>

                    <Grid item xs={12} style={{display:values.searchJson!=""?"":"none"}}>
                    <RaisedButton fullWidth disabled={this.state.hirerLabel=="Hirer Authorization"?false:true} style={{height:60}} label={this.state.hirerLabel} secondary={true} onClick={this.hirerauthorizeNDB}/>
                      </Grid>


                <Grid item xs={12}>
                <RaisedButton fullWidth disabled={this.state.hirerLabel!="Hirer/Student Authorized"?true:false}  style={{height:60}} label="Authorize Flight" primary={true} onClick={this.insauthorize}/>
                  </Grid>
                  </Grid>

                  <Grid item xs={12} style={{display:values.step==7050||values.step==7007?"":"none"}}>
                  <RaisedButton fullWidth style={{height:60}} label="Authorize Flight" primary={true} onClick={this.insauthorize}/>
                    </Grid>


                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 10}}>
                      <RaisedButton fullWidth label="Jump to Training Portal" secondary={true} onClick={this.jumpTraining}/>
                        </Grid>

                      </Grid>
                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      style={{textAlign:'center'}}
                      open={this.state.hirerSigPopup}>
                      <DialogTitle id="customized-dialog-title">
                      DECLARATION
                      </DialogTitle>
                      <DialogContent dividers>
                      <Avatar style={{ margin: 20 }} src={values.profilePic} size={150}/>
                      <Typography gutterBottom>
                      I {values.fName} {values.lName}, hereby certify that I agree to the below conditions;
                      <br></br>  <br></br>
                      Immediate payment is required for all flights prior to departure for any student or hirer with insufficient credit without valid credit card details
                      <br></br><br></br>
                      That any refunds of pre-paid amounts, less the amount already used calculated at the casual training rate will be made within 30 days(Booking cancellation fees may apply).
                      <br></br><br></br>
                      I authorize my Training Provider to charge the amounts owing to my nominated credit/debit card, if I fail to reconsile an overdue account within 90 days.
                      <br></br><br></br>
                      I understand and will comply with the conditions of training and hire set out by the provider and will adhere to all neccessary guidelines.
                      <br></br><br></br>
                      Confirm your password below to sign here
                      </Typography>
                      <Typography style={{display:this.state.hirerPasserr,color:"red"}}gutterBottom>
                       Incorrect Password. Try again!
                       </Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="Pass"
                        hintText="Enter Your Password to Sign"
                        type="password"
                        onChange={this.passwordSet("passHirer")}
                        value={this.state.passHirer}
                        fullWidth
                        />
                      </DialogContent>
                      <DialogActions>
                      <Button onClick={this.validatePassHirer} color="secondary">
                      Authorize
                      </Button>
                      <Button onClick={this.validatePassHirerAbsent} color="secondary">
                      Hirer Absent
                      </Button>
                      <Button onClick={e=>{e.preventDefault(); this.setState({hirerSigPopup:false, passHirer:""})}} color="primary">
                      I Disagree
                      </Button>
                      </DialogActions>
                      </Dialog>





                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      style={{textAlign:'center'}}
                      open={this.state.hirerNDBSigPopup}>
                      <DialogTitle id="customized-dialog-title">
                      DECLARATION BY THE HIRER
                      </DialogTitle>
                      <DialogContent dividers>
                      <Typography gutterBottom>
                      I {values.searchJson}, hereby certify that I agree to the below conditions;
                      <br></br>  <br></br>
                      Immediate payment is required for all flights prior to departure for any hirer with insufficient credit
                      <br></br><br></br>
                      That any refunds of pre-paid amounts, less the amount already used calculated at the casual hourly rate will be made within 30 days(Booking cancellation fees may apply).
                      <br></br><br></br>
                      I understand and will comply with the conditions of training and hire set out by the provider and will adhere to all neccessary guidelines.
                      <br></br><br></br>
                      Type in your name below to accept above terms
                      </Typography>
                      <Typography style={{display:this.state.hirerPasserr,color:"red"}}gutterBottom>
                       That is not a match, Try again!
                       </Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="Pass"
                        hintText="Enter Your Password to Sign"
                        type="name"
                        onChange={this.passwordSet("passHirer")}
                        value={this.state.passHirer}
                        fullWidth
                        />
                      </DialogContent>
                      <DialogActions>
                      <Button onClick={this.validatePassHirerNDB} color="secondary">
                      Authorize
                      </Button>
                      <Button onClick={e=>{e.preventDefault(); this.setState({hirerNDBSigPopup:false, passHirer:""})}} color="primary">
                      Cancel
                      </Button>
                      </DialogActions>
                      </Dialog>





                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      style={{textAlign:'center'}}
                      open={this.state.insSigPopup}>
                      <DialogTitle id="customized-dialog-title">
                      DECLARATION
                      </DialogTitle>
                      <DialogContent dividers>
                      <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
                      <Typography gutterBottom>
                      I {values.loggedInUser.fName} {values.loggedInUser.lName}, hereby certify that;
                      <br></br>  <br></br>
                      The hirer or student has been briefed on conditions of hire and training policies.
                      <br></br><br></br>
                      The hirer or student is briefed and has completed and acquired pre-flight knowledge required for this flight  .
                      <br></br><br></br>
                      A valid flight plan has been completed and lodged for this flight.
                      <br></br><br></br>
                      I have checked the account balance and available payment methods and have since cleared and authorized this flight.
                      <br></br><br></br>

                      </Typography>
                      <Typography style={{display:this.state.insPassErr,color:"red"}}gutterBottom>
                       Incorrect Password. Try again!
                       </Typography>

                      </DialogContent>
                      <DialogActions>
                      <Button onClick={this.validatePassIns} color="secondary">
                      Authorize
                      </Button>
                      <Button onClick={e=>{e.preventDefault(); this.setState({insSigPopup:false, passIns:""})}} color="primary">
                      I Disagree
                      </Button>
                      </DialogActions>
                      </Dialog>

                      <Dialog style={{padding:"10%"}}
                      backdrop
                      open={this.state.loading}>
                      <DialogContent dividers>
                      <CircularProgress />
                      </DialogContent>


                      </Dialog>



                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      style={{textAlign:'center'}}
                      open={this.state.noFlyPopUp}>
                      <DialogTitle id="customized-dialog-title">
                      WARNING!
                      </DialogTitle>
                      <DialogContent dividers>
                      <Avatar style={{ margin: 20 }} src={values.profilePic} size={150}/>
                      <Typography gutterBottom>
                      {values.fName} {values.lName}
                      <br></br>  <br></br>
                      This account holder does not have sufficient credit in their account or up-to-date payment information.
                      <br></br><br></br>
                      Please add funds to your account before attempting to authorize this flight again.
                      <br></br><br></br>

                      </Typography>

                      </DialogContent>
                      <DialogActions>

                      <Button onClick={e=>{e.preventDefault(); this.props.clearState();this.props.setStep(1004)}} color="primary">
                      OK
                      </Button>
                      </DialogActions>
                      </Dialog>

              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default PreFlightInfoForm
