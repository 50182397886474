import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';



export class Confirmation extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    this.props.setTotalBalance();
    this.props.addCredit();
    this.props.nextStep();
  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }





  render() {
    const { values, handleChange, log} = this.props;


    let date_ob = new Date(values.transTimeStamp);

    // prints date & time in YYYY-MM-DD format
    let current_time =  date_ob.toLocaleString();

    let addAmount = parseFloat(values.amountPaid);
    let totalBalance= addAmount+values.tillBalance;




    return(

      <MuiThemeProvider>
        <React.Fragment >


          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Confirm Transaction details below</h3>
              </Grid>

              <Grid item xs={10}>
                <h1>{values.fName} {values.lName}</h1>

              </Grid>
              <Grid item xs={2}>{current_time} </Grid>
              <Grid item xs={2}> <p2> Existing Account Balance</p2></Grid><Grid item xs={10}><p2>: $ {values.tillBalance.toFixed(2)}</p2></Grid>
              <Grid item xs={12}><h1>+$ {parseFloat(values.amountPaid).toFixed(2)} </h1></Grid>
              <Grid item xs={2}><h4> Transaction ID</h4></Grid><Grid item xs={10}><h4>: {values.transId} </h4> </Grid>
              <Grid item xs={2}><p1> Transaction Time Stamp</p1></Grid><Grid item xs={10}><p1>: {current_time} </p1> </Grid>
              <Grid item xs={2}><p1> Notes</p1></Grid><Grid item xs={10}><p1>: {values.transNotes} </p1> </Grid>
              <Grid item xs={7}/><Grid item xs={2}><p2> New Account Balance   :</p2></Grid><Grid item xs={3}><h1>$ {totalBalance.toFixed(2)} </h1></Grid>

              <Grid></Grid>
              <Grid></Grid>





                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Confirm" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default Confirmation
