import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';





export class NavEx5 extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }

  render() {
    const {values} = this.props;
    const ct = new Date(values.transTimeStamp);

    var date1 = new Date(values.fl_time);
    var flightDate = date1.getDate() + '/' + (date1.getMonth()+1) + '/' + date1.getFullYear();
    let current_time =  ct.getDate() + '/' + (ct.getMonth()+1) + '/' + ct.getFullYear();

    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>


          <Grid item xs={12} sm container style={{padding:50}}>


              <table style={{textAlign:'center',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#BFBFBE',width:'42%'}}>
              <b>HELICOPTER TRAINING AND TRANSPORT <br></br>
              Commercial Pilot Licence – Helicopter Category Rating</b>
              </td>
              </tr>
              </table>


              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H)  38: NAVIGATION: 12 D.M.E STEP</b>
              </td>
              </tr>
              </table>



              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse', backgroundColor:'#EEECDF'}}>Flight no: </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> 105CPL(H) 38.{values.lessonNo}    </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Trainee name <br></br> & ARN: </th>
              <td style={{padding:10, columnSpan:'all',borderBottomStyle:'solid',borderWidth:1}}> {values.fName} {values.lName}<br></br> {values.stu_arn}  </td>              </tr>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Date </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>  {flightDate}     </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Instructor: </th>
              <td style={{padding:10, columnSpan:'all'}}>{values.ins_fname} {values.ins_lname}  </td>
              </tr>
              </table>
              <table style={{textAlign:'left',borderStyle:'none', width:'100%', borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft<br></br>registration: </th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> {values.rego}   </td>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft Type:</th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{values.wing_type}     </td>
              <th style={{width:'10%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Flight Time:</th>
              <td style={{borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{(parseFloat(values.soloGH)+parseFloat(values.soloNav)).toFixed(1)} S <br></br>{(parseFloat(values.dualGH)+parseFloat(values.dualNav)).toFixed(1)} D</td>
              </tr>

              </table>

              <Grid item xs style={{textAlign:'left', marginTop:20, paddingLeft:10, paddingTop:5,borderWidth:1, borderStyle:'solid'}}>
              <b>Lesson Overview</b>

              <ul>
              <li>Navigation route: [see comments]</li>
              <li>Introduction to controlled airspace and controlled aerodromes</li>
              <li><b>Assess</b> competency to conduct next solo navigation exercise</li>
              </ul>

              </Grid>

              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <th style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#BFBFBE',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              PRE-FLIGHT KNOWLEDGE<br></br>
              Long Briefing: As required.     Pre-flight Briefing: 0.3 hour<br></br>
              Underpinning knowledge: as required
              </th>
              </tr>
              <tr>
              <td style={{borderStyle:'solid',borderWidth:1,backgroundColor:'#EEECDF',width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}> Content
              </td>
              </tr>
              <tr><td style={{ borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Long briefing </b>– Preparation for and overview of navigation exercise #5<br></br>
              <ul>
              <li>Other revision as required</li>
              <li>Radio Phraseology applicable to CTA and CTR </li>
              <li>Readback requirements </li>
              <li>VMC requirements in Class D and Class C airspace</li>
              <li>Separation procedures in controlled airspace</li>
              <li>Relevant AIP instructions</li>

              </ul>
              </td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Underpinning knowledge</b> <br></br>
              <ul>
              <li>Review/expand previously introduced knowledge as required</li>
              <li>Assess underpinning knowledge as required for the conduct of the next solo navigation exercise</li>
              <li>Operate at a controlled aerodrome (all) [CTR(a)-(e)]. NOTAMS, markings and lighting, RT phraseology, ERSA COM failure, Transponder codes 7500,7600,7700</li>
              <li>Operate in controlled airspace (all) [CTA(a)-(e)] NOTAMS, markings and lighting, RT phraseology, ERSA COM failure, Transponder codes 7500,7600,7700</li>

              </ul></td>
              </tr>
              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>HF & NTS</b><br></br>
              <ul>
              <li>Review as required</li>
              <li>Sterile cockpit technique during landing and takeoff</li>
              <li>The importance of clear communication with ATC (if in doubt: ask)</li>
              </ul></td>
              </tr>

              <tr><td style={{paddingLeft:10, borderStyle:'solid',borderWidth:1,width:'100%', borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Pre-flight briefing</b><br></br>
              <ul>
              <li>Review flight sequences, what to expect, see & do</li>
              <li>Check essential knowledge</li>
              <li>Reinforce threat & error management</li>
              <li>Reinforce significant airmanship points </li>
              </ul></td>
              </tr>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',width:'42%',paddingLeft:10,paddingBottom:40, borderRightStyle:'solid', borderWidth:1}}>
              <b>Pre-flight knowledge components complete: <br></br></b>{values.pfk_bool}</td>
              <td style={{paddingLeft:10}}><b>Instructor’s signature & date</b><br></br>
              <img style={{maxWidth:'60%'}} src={values.ins_Signature}/>{current_time}</td>
              </tr>
              </table>



              <hr style={{marginTop:160, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 38  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 1</p2></td>

              </tr>

              </table>
              </Grid>


              <Grid item xs={12} sm container style={{padding:50, paddingTop:40}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H)  38: NAVIGATION: 12 D.M.E STEP</b>
              </td>
              </tr>
              </table>


              <table style={{borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr style={{textAlign:'center',backgroundColor:'#BFBFBE',width:'100%',paddingLeft:10,paddingBottom:40}}>
              <b>Performance Standard</b>
              </tr>
              </table>

              <table style={{borderStyle:'solid',borderTopStyle:'none', width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>3</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>2</b></td>
              <td style={{borderStyle:'solid',borderTopStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}><b>1</b></td>
              </tr>
              <tr>
              <td style={{ width: '33.35%',borderStyle:'solid',borderWidth:1, padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Has received training in the element, however is not able to consistently demonstrate competency to the standard required for qualification issue</td>
              <td style={{ width: '33.34%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Demonstrates a developing level of proficiency, and is deemed safe to conduct solo practice under direct supervision</td>
              <td style={{ width: '33%',borderStyle:'solid',borderWidth:1,padding:10, borderCollapse: 'collapse',textAlign:'left' }}>
              Achieves competency to the standard required for qualification issue</td>
              </tr>

              </table>


              <table style={{borderStyle:'solid', width:'100%', marginTop:13, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>FLIGHT TRAINING<br></br>
              Suggested flight time:  2.0 hour dual</b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
              </tr>

      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>    NAV.5   </b></td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Navigate at low level and in reduced visibility   </b></td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
      </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a) </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>configure the aircraft as required for the following environmental and operational conditions:    </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}>  </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>(i) reduced visibility  </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[0].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> (ii) low cloud base </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[1].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> navigate aeroplane at minimum heights (not below 500 ft AGL, clear of built-up areas) and remain in VMC </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[2].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain separation from terrain, obstacles, allowing for wind and turbulence at low level </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[3].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> avoid noise sensitive areas </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[4].rate}</td>
            </tr>
            <tr>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)</td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> operate appropriately in the vicinity of aerodromes and landing areas </td>
            <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
            <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[5].rate}</td>
            </tr>
    <tr>
    <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>    NAV.6     </b></td>
    <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>  Perform lost procedure   </b></td>
    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
    <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
    </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a) </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>acknowledge positional uncertainty in a timely manner  </td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[6].rate}</td>
          </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)</td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> configure aircraft for range and endurance as required</td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[7].rate}</td>
          </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)</td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>apply recognised method to re-establish aircraft position </td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[8].rate}</td>
          </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)</td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>fix position </td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[9].rate}</td>
          </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)</td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>use radio to request assistance, if applicable </td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[10].rate}</td>
          </tr>
          <tr>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (f)</td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>plan a timely precautionary search and landing if unable to complete flight safely to suitable aerodrome </td>
          <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
          <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[11].rate}</td>
          </tr>
  <tr>
  <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>    NAV.7    </b></td>
  <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Perform diversion procedure     </b></td>
  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
  <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
  </tr>
        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(a) </td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>make timely decision to divert  </td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[12].rate}</td>
        </tr>
        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (b)</td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify an acceptable alternate aerodrome  </td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[13].rate}</td>
        </tr>
        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (c)</td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>select a suitable route and cruising level   </td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[14].rate}</td>
        </tr>
        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (d)</td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> revise flight plan considering weather, terrain, airspace and fuel available  </td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[15].rate}</td>
        </tr>
        <tr>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (e)</td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>advise ATS of an intention to divert   </td>
        <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 2 </td>
        <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[16].rate}</td>
        </tr>

<tr>
<td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>   CTR.1 </b></td>
<td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Controlled aerodrome pre-flight preparation  </b></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
</tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)</td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> using a current ERSA and NOTAM, for the controlled aerodrome, extract all the relevant operational information </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[17].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>interpret the extracted information  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[18].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify all special aerodrome procedures  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[19].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> check current weather forecast and local observations </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[20].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>identify all relevant radio and navigation aid frequencies  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[21].rate}</td>
      </tr>
<tr>
<td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>    CTR.2</b></td>
<td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Taxi aircraft at a controlled aerodrome </b></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
</tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)</td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>obtain and comply with ATC clearances  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[22].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>manoeuvre aircraft to holding point as instructed and take appropriate action to avoid other aircraft and obstructions  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[23].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>recognise ground markings during taxi and take appropriate action  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[24].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>recognise lighting signals and take appropriate action  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[25].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> identify airport runway incursion hotspots </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[26].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>manoeuvre aircraft to avoid jet blast hazard  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[27].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> request taxi guidance if unsure of position </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[28].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(h) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>use strobes when crossing any runway  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[29].rate}</td>
      </tr>

      </table>



      <hr style={{marginTop:10, width:'100%'}}></hr>
      <table style={{width:'100%'}}>
      <tr>
      <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 38  v1.1</p2></td>
      <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
      <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 2</p2></td>

      </tr>

      </table>
      </Grid>


      <Grid item xs={12} sm container style={{padding:50, paddingTop:50}}>



      <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
      <tr>
      <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
      <b>LESSON PLAN AND TRAINING RECORD <br></br>
      105CPL(H)  38: NAVIGATION: 12 D.M.E STEP</b>
      </td>
      </tr>
      </table>



      <table style={{borderStyle:'solid', width:'100%', marginTop:13, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
      <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
      <b>FLIGHT TRAINING<br></br>
      Suggested flight time:  2.0 hour dual</b>
      </td>
      </table>
      <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
      <tr>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
      <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
      <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

      </tr>
      <tr>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
      </tr>


<tr>
<td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>    CTR.3</b></td>
<td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Perform departure from controlled aerodrome </b></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
</tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)</td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> receive and correctly read back an airways clearance </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[30].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> check and ensure runway approach is clear prior to entering a runway </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[31].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>correctly set transponder code and mode prior to entering runway for take-off  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[32].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>comply with ATC departure instructions  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[33].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>advise ATC as soon as possible if unable to comply with clearance  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[34].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> contact approach with airborne report or give departure call to tower </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[35].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain lookout </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[36].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(h) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>avoid wake turbulence  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[37].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(i) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> comply with airways clearances within tracking and altitude tolerances and maintain traffic lookout until clear of the aerodrome control zone </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[38].rate}</td>
      </tr>
<tr>
<td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>   CTR.4 </b></td>
<td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b>Perform arrival and landing at controlled aerodrome  </b></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
</tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)</td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>check ERSA and NOTAM prior to entering control area and extract required operational information  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[39].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>receive ATIS and correctly set the appropriate QNH  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[40].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>request and receive ATC clearance and set correct transponder code prior to entering control area  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[41].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> advise ATC as soon as possible if unable to comply with clearance </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[42].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>maintain lookout at all times  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[43].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>update QNH as required  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[44].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>maintain tracking tolerances  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[45].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(h) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> establish aircraft on the correct leg of the circuit in preparation for landing and maintain separation from traffic </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[46].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(i) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>confirm clearance to land  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[47].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(j) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>vacate runway and obtain taxi clearance  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[48].rate}</td>
      </tr>
      </table>



      <hr style={{marginTop:650, width:'100%'}}></hr>
      <table style={{width:'100%'}}>
      <tr>
      <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 38  v1.1</p2></td>
      <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
      <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 3</p2></td>

      </tr>

      </table>
      </Grid>


      <Grid item xs={12} sm container style={{padding:50, paddingTop:100}}>



      <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
      <tr>
      <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
      <b>LESSON PLAN AND TRAINING RECORD <br></br>
      105CPL(H)  38: NAVIGATION: 12 D.M.E STEP</b>
      </td>
      </tr>
      </table>



      <table style={{borderStyle:'solid', width:'100%', marginTop:13, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
      <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
      <b>FLIGHT TRAINING<br></br>
      Suggested flight time:  2.0 hour dual</b>
      </td>
      </table>
      <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
      <tr>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
      <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
      <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

      </tr>
      <tr>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
      <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>
      </tr>



<tr>
<td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none',padding:5 }}><b>   CTA.1 </b></td>
<td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderLeftStyle:'none',padding:5}}><b> Operate aircraft in controlled airspace </b></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
<td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
</tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}> (a)</td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>comply with airways clearance requirements for operating in all classes of airspace, including lead time required for flight plan submission, contents, ‘clearance void time’, and ‘readback’ requirement  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[49].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(b) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> apply airways clearance requirements for entering, operating in and departing from CTA and CTR, including details that need to be provided to ATC, and what details to expect from ATC </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[50].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(c) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>maintain control area protection tolerances  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[51].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(d) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> maintain tracking and altitude tolerances when operating on an airways clearance </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[52].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(e) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>reconfirm any clearance items when doubt exists  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[53].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(f) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>advise ATC as soon as possible if unable to maintain clearance due to adverse weather conditions  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[54].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(g) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>follow ATC requirements for a change of level in CTA, including in an emergency situation  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[55].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(h) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>comply with departure, climb, transition to cruise (levelling out), cruise, change of levels, descent and visual approach procedures in CTA and CTR instructions  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[56].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(i) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>apply separation standards between IFR flights, and IFR and VFR flights in the various classes of CTA  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[57].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(j) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform appropriate actions in the event of the loss of radio communication in CTA and CTR  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[58].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(k) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> perform appropriate actions in the event of abnormal operations and emergency procedures in CTA and CTR </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[59].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(l) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> operate under radar vectoring procedures, including radio procedures and phraseologies </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[60].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(m) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>maximum permissible time interval between ATC transmissions during radar vectoring are not exceeded  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[61].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(n) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}>perform appropriate actions in the event of abnormal operations and emergencies  </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[62].rate}</td>
      </tr>
      <tr>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}>(o) </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}> recall transponder emergency code and communication failure code </td>
      <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> 3 </td>
      <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>{values.formRating[63].rate}</td>
      </tr>



              </table>

              <hr style={{marginTop:700, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 38  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 4</p2></td>

              </tr>

              </table>


              </Grid>

              <Grid item xs={12} sm container style={{paddingTop:100, padding:50}}>



              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 35: NAVIGATION EXERCISE 2</b>
              </td>
              </tr>
              </table>
              <p><i><b>*Enter the performance standard achieved if it is different to that required</b><br></br>
              Where it has not been possible to introduce performance criteria or the trainee has not achieved the
              required standard, the performance criteria must be covered during the next lesson. Enter these performance
              criteria in the lesson record for the subsequent lesson.</i></p>

              <table style={{borderStyle:'solid', width:'100%', marginTop:30, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <td style={{paddingLeft:5, textAlign:'left',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>CONSOLIDATION AND/OR REMEDIAL TRAINING<br></br></b>
              </td>
              </table>
              <table style={{borderStyle:'solid',borderTopStyle:'none',borderBottomStyle:'none', width:'100%', marginTop:0, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>MOS<br></br>Reference</td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'85%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderRightStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Performance </td>
              <td style={{backgroundColor:'#EEECDF',borderStyle:'solid',width:'5%',borderTopStyle:'none',borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'center' }}>Standard</td>

              </tr>
              <tr>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'10%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'80%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Lesson Content <b>(Elements & Performance Criteria)</b></td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Required</td>
              <td style={{backgroundColor:'#EEECDF',paddingLeft:5,borderStyle:'solid',width:'5%',borderTopStyle:'none',borderBottomStyle:'none',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}>Achieved</td>

              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              <tr>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'10%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', borderRightStyle:'none' }}></td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'80%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left', padding:5}}></td>
              <td style={{backgroundColor:'#f0f0f0',paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'center',padding:5 }}> </td>
              <td style={{paddingLeft:5,borderStyle:'solid',width:'5%',borderWidth:1, borderCollapse: 'collapse',textAlign:'left' }}></td>
              </tr>
              </table>



              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, borderStyle:'solid',backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>DEBRIEFING</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,borderStyle:'solid', backgroundColor:'#EEECDF',width:'100%'}}>
              Content
              </td>
              </tr>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <ul>
              <li>Training review and outcomes achieved against lesson objectives and the Part 61 MOS competency standards</li>
              <li>Recommendations for next lesson (including any carryover/remedial training)</li>
              <li>Trainee preparation for next lesson</li>
              <li>Training record completion and sign off</li>
              </ul>
              </td>
              </tr>
              </table>


              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>COMMENTS AND OUTCOME</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderStyle:'solid', borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <br></br>
              {values.commts}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>


              </td>
              </tr>
              </table>
              <table style={{textAlign:'left',borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderBottomStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'80%'}}>
              <b>Proceed to next training session?</b>
              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>
              <b>{values.nextLesson_bool}</b>
              </td>
              <td style={{ borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>

              </td>
              </tr>

              </table>

              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Instructor’s signature & date</b>
              </td>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Trainee’s signature & date</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.ins_Signature}/>{flightDate}

              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.stu_Signature}/>{flightDate}
              </td>

              </tr>

              </table>

              <hr style={{marginTop:245, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 38  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 5</p2></td>

              </tr>

              </table>
              </Grid>


          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default NavEx5
