import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { validations, validateAll } from 'indicative/validator';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as moment from 'moment';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import CreditCardInput from 'react-credit-card-input';
import Slide from '@material-ui/core/Slide';
import PasswordHash from 'password-hash';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';




const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const handleDateChange = e => {
   console.log(e);

 };

export class CreditCardInfoView extends Component{

  constructor(props) {
    super(props);
    this.state = {value         : 0,
                  disclaimer    : false,
                  authenticate  : true,
                  backdrop      : "none",
                  authenticateStatus:'Authenticate',
                  pass          : '',
                  passerr       : 'none',
                  cvc           : '',
                  expiry        : '',
                  focus         : '',
                  name          : '',
                  number        : '',
                  creditCardCVCerr        : '',
                  creditCardExpiryerr     : '',
                  focuserr      : '',
                  creditCardNameerr       : '',
                  creditCardNumbererr     : '',
                    };

  }


  continue = e =>{
    this.props.nextStep();
  }


  back=e=>{
    e.preventDefault();
    this.props.setStep(2501);
  }


  handleInputFocus = (field)=>e => {
    e.preventDefault();
     this.setState({ focus: field });
   }

   handleInputChange = (e) => {
     const { name, value } = e.target;
     this.props.handleChangeInput(name,value)
     this.setState({creditCardNameerr:''})
     this.setState({ [name]: value });
   }



handleCardNumberChange= (e) => {
  const { value } = e.target;
  this.props.handleChangeInput('creditCardNumber',value)
  this.setState({creditCardNumbererr:''})
  this.setState({ number: value });
}



handleCardExpiryChange= (e) => {
  const { value } = e.target;
  this.props.handleChangeInput('creditCardExpiry',value)
  this.setState({creditCardExpiryerr:''})
  this.setState({ expiry: value });
  }




handleCardCVCChange= (e) => {
  const { value } = e.target;
  this.props.handleChangeInput('creditCardCVC',value)

  this.setState({creditCardCVCerr:''})
  this.setState({ cvc: value });
}


handleErrNumber = (e) => {
   this.setState({creditCardNumbererr:e})
 };
 handleErrExpiry = (e) => {
    this.setState({creditCardExpiryerr:e})
  };
  handleErrCvc = (e) => {
     this.setState({creditCardCVCerr:e})
   };

   validate=e=>{
     const {values} = this.props;
     const data = values;
     const rules= {
                   creditCardName      : 'string|required|max:25|min:1',
                   creditCardNumber    :  'required|max:25',
                   creditCardExpiry    :  'required',
                   creditCardCVC       :  'required'


   }
     const messages={
       alpha                 : 'Alphabetical characters only!',
       date                 : 'Invalid Date',
       number                : 'Numbers Only',
       float                 : "That's invalid",
       required              : 'This Cannot be empty',
       min                   : "That's too short!",
       max                   : "That's too long!",
             }

     validateAll(data, rules, messages)
     .catch(errors =>{
       console.log(errors);

       const formattedErrors = {}
       errors.forEach(error =>this.setState({[error.field+"err"]:error.message})
       )



     })
     .then(this.continue)
   }
   passwordSet=e=>{
     e.preventDefault();
     this.setState({pass:e.target.value});
   }
    reauth=e=>{
      e.preventDefault();
      this.setState({backdrop:"",authenticateStatus:"Authenticating "})
      const {values} = this.props;

      fetch(`${process.env.REACT_APP_SERVER_URL}/authKi?email=${values.loggedInUser.email}`, {
      headers: {
      'x-access-token': values.token
    }})
      .then(response => response.json())
      .then(response=>{if(response.data.length>0){
        const hashed= response.data[0].keypass;
        const isTemp= response.data[0].isTemp;

        this.setState({ passSecure    : PasswordHash.verify(this.state.pass,hashed)});

        if(this.state.passSecure==true && isTemp=="true"){
          this.props.setStep(1000)
        }else if(this.state.passSecure==true && isTemp=="false"){
          console.log("access granted")
          this.setState({backdrop:"none",authenticate:false,pass:"",passerr:"none",authenticateStatus:"Authenticate"})}

      else{this.setState({backdrop:"none",authenticate:true,passerr:"",authenticateStatus:"Authenticate"})}


    }})
      .catch(err=>console.log(err))
    }
    logout=e=> {
      e.preventDefault();
      this.props.setStep(1002);


    }

  render() {
    const { values, log, handlePicChange} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >


          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Credit Card Details</h3>
              </Grid>
              <Grid item xs={6}>

                  <Cards
                    cvc={values.creditCardCVC}
                    expiry={values.creditCardExpiry}
                    focused='name'
                    name={values.creditCardName}
                    number={values.creditCardNumber}
                      />
                      </Grid>
                      <Grid item xs={6}>

                          <Cards
                            cvc={values.creditCardCVC}
                            expiry={values.creditCardExpiry}
                            focused='cvc'
                            name={values.creditCardName}
                            number={values.creditCardNumber}
                              />
                              </Grid>



                  </Grid>





                  <Grid item xs={12} style={{ marginTop: 50}}>
                  <RaisedButton fullWidth style={{height:60}} label="Edit" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>


                      <Dialog
                      backdrop
                      aria-labelledby="customized-dialog-title"
                      open={this.state.disclaimer}>
                      <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                      You must provide details of a valid Credit/Debit Card
                      </DialogTitle>
                      <DialogContent dividers>
                      <Typography gutterBottom>
                      Immediate payment is required for all flights prior to departure for any student or hirer with insufficient credit without valid credit card details
                      <br></br><br></br>
                      Flights maybe granted to a student or hirer with insufficient credit at provider's discretion if credit card details are provided.
                      <br></br><br></br>
                      By clicking "I Agree" below, I authorize my Training Provider to charge the amounts owing to my nominated credit/debit card, if I (student/hirer/Account holder) fail to reconsile an overdue account within 90 days.
                      </Typography>
                      </DialogContent>
                      <DialogActions>
                      <Button onClick={e=>this.setState({disclaimer:false})} color="secondary">
                      I AGREE
                      </Button>
                      <Button onClick={this.back} color="primary">
                      I Disagree
                      </Button>
                      </DialogActions>
                      </Dialog>


                      <Dialog
                        fullScreen
                        backdropDisabled
                        TransitionComponent={Slide}
                        open={this.state.authenticate}>
                      <div style={{textAlign:'center',paddingTop:50, paddingLeft:300, paddingRight:300}}>

                      <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                      Authentication Required!
                      </DialogTitle>
                      <DialogContent dividers>
                      <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
                      <Typography gutterBottom variant="subtitle1">
                        {values.loggedInUser.fName} {values.loggedInUser.lName}
                      </Typography>
                     <Typography gutterBottom>
                      Your must authorize your session before viewing this information. Please enter your password below to re-authenticate
                      </Typography>
                      <Typography style={{display:this.state.passerr,color:"red"}}gutterBottom>
                       Incorrect Password. Try again!
                       </Typography>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="Pass"
                        hintText="Enter Your Password"
                        type="password"
                        onChange={this.passwordSet}
                        value={this.state.pass}
                        fullWidth
                        />
                      </DialogContent>

                      <DialogActions>
                      <Button onClick={this.reauth} color="primary">
                      {this.state.authenticateStatus} <CircularProgress style={{display:this.state.backdrop}}/>
                      </Button>
                      <Button onClick={this.logout}color="primary">
                      Logout
                      </Button>
                      </DialogActions>
                      </div>
                      </Dialog>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default CreditCardInfoView
