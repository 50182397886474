import React, { Component } from 'react';
import { useRef } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridList from '@material-ui/core/GridList';
import Pdf from 'html-pdf';
import Fs from 'file-system';
import ReactToPrint from 'react-to-print';


import EffectsOfControls from '../addrecord/forms/01_EffectsOfControls';
import Coordination from '../addrecord/forms/02_Coordination';
import AdvancedCoordination from '../addrecord/forms/03_AdvancedCoordination';
import Hover from '../addrecord/forms/04_Hover';
import HoverTurnsAndHoverTaxi from '../addrecord/forms/05_HoverTurnsAndHoverTaxi';
import LandingsAndLiftOffs from '../addrecord/forms/06_LandingsAndLiftOffs';
import Transitions from '../addrecord/forms/07_Transitions';
import Circuits from '../addrecord/forms/08_Circuits';
import MaximumPerformanceTakeOffsAndSteepApproaches from '../addrecord/forms/09_MaximumPerformanceTakeOffsAndSteepApproaches';
import LimitedPowerOperations from '../addrecord/forms/10_LimitedPowerOperations';
import BasicAutorotation from '../addrecord/forms/11_BasicAutorotation';
import EngineFailureInTheHoverAndHoverTaxi from '../addrecord/forms/12_EngineFailureInTheHoverAndHoverTaxi';
import PracticeForcedLandings from '../addrecord/forms/13_PracticeForcedLandings';
import TailRotorFailures from '../addrecord/forms/14_TailRotorFailures';
import JammedFlightControls from '../addrecord/forms/15_JammedFlightControls';
import AdvancedEmergencies from '../addrecord/forms/16_AdvancedEmergencies';
import CircuitsPreSolo from '../addrecord/forms/17_CircuitsPreSolo';
import FirstSoloCircuit from '../addrecord/forms/18_FirstSoloCircuit';
import CircuitConsolidation from '../addrecord/forms/19_CircuitConsolidation';
import SoloCircuits from '../addrecord/forms/20_SoloCircuits';
import CircuitConsolidation2 from '../addrecord/forms/21_CircuitConsolidation2';
import SoloCircuits2 from '../addrecord/forms/22_SoloCircuits2';
import CircuitConsolidation3 from '../addrecord/forms/23_CircuitConsolidation3';
import SoloCircuits3 from '../addrecord/forms/24_SoloCircuits3';
import SlopeLandings from '../addrecord/forms/25_SlopeLandings';
import ConfinedAreas from '../addrecord/forms/26_ConfinedAreas';
import SteepTurns from '../addrecord/forms/27_SteepTurns';
import PreTrainingAreaSolo from '../addrecord/forms/28_PreTrainingAreaSolo';
import FirstTrainingAreaSolo from '../addrecord/forms/29_FirstTrainingAreaSolo';
import ConfinedAreasConsolidation from '../addrecord/forms/30_ConfinedAreasConsolidation';
import TrainingAreaSolo from '../addrecord/forms/31_TrainingAreaSolo';
import EmergenciesConsolidation from '../addrecord/forms/32_EmergenciesConsolidation';
import TrainingAreaSolo2 from '../addrecord/forms/33_TrainingAreaSolo2';
import NavEx1 from '../addrecord/forms/34_NavEx1';
import NavEx2 from '../addrecord/forms/35_NavEx2';
import NavEx3 from '../addrecord/forms/36_NavEx3';
import NavEx4FirstSolo from '../addrecord/forms/37_NavEx4FirstSolo';
import NavEx5 from '../addrecord/forms/38_NavEx5';
import NavEx6SoloNav12DME from '../addrecord/forms/39_NavEx6SoloNav12DME';
import NavEx7 from '../addrecord/forms/40_NavEx7';
import NavEx8SoloNavR555 from '../addrecord/forms/41_NavEx8SoloNavR555';
import NavEx9 from '../addrecord/forms/42_NavEx9';
import NavEx10SoloNav from '../addrecord/forms/43_NavEx10SoloNav';
import NavEx11 from '../addrecord/forms/44_NavEx11';
import NavEx12SoloNav from '../addrecord/forms/45_NavEx12SoloNav';
import NavEx13 from '../addrecord/forms/46_NavEx13';
import NavEx14SoloNav from '../addrecord/forms/47_NavEx14SoloNav';
import DualConsolidation from '../addrecord/forms/48_DualConsolidation';
import SoloConsolidation from '../addrecord/forms/49_SoloConsolidation';
import PreCPL from '../addrecord/forms/50_PreCPL';
import AccCompetency from '../addrecord/forms/51_AccCompetency';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



export class Success extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0,error:false,err:'',success:false};

  }


  continue = e => {

          this.props.nextStep();

  }



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }
  repeat = e => {
    e.preventDefault();
    this.props.startAgain();
  }
  handleCloseError= () => {
      this.setState({
        error: false,
        err:''
      });
    };

    handleCloseSuccess= () => {
        this.setState({
          success: false,
        });
      };

    updateSuccess=()=>{
      this.setState({success:true})
    }
    updateError=(e)=>{
      this.setState({error:true,err:e})
    }



renderForm=()=>{
      const {values} = this.props;
      const formId=values.formId;

    switch (formId) {
      case "01_105CPL_H":
      return(<EffectsOfControls ref={el => (this.componentRef = el)} values={values}/>)
      case "02_105CPL_H":
      return(<Coordination ref={el => (this.componentRef = el)} values={values}/>)
      case "03_105CPL_H":
      return(<AdvancedCoordination ref={el => (this.componentRef = el)} values={values}/>)
      case "04_105CPL_H":
      return(<Hover ref={el => (this.componentRef = el)} values={values}/>)
      case "05_105CPL_H":
      return(<HoverTurnsAndHoverTaxi ref={el => (this.componentRef = el)} values={values}/>)
      case "06_105CPL_H":
      return(<LandingsAndLiftOffs ref={el => (this.componentRef = el)} values={values}/>)
      case "07_105CPL_H":
      return(<Transitions ref={el => (this.componentRef = el)} values={values}/>)
      case "08_105CPL_H":
      return(<Circuits ref={el => (this.componentRef = el)} values={values}/>)
      case "09_105CPL_H":
      return(<MaximumPerformanceTakeOffsAndSteepApproaches ref={el => (this.componentRef = el)} values={values}/>)
      case "10_105CPL_H":
      return(<LimitedPowerOperations ref={el => (this.componentRef = el)} values={values}/>)
      case "11_105CPL_H":
      return(<BasicAutorotation ref={el => (this.componentRef = el)} values={values}/>)
      case "12_105CPL_H":
      return(<EngineFailureInTheHoverAndHoverTaxi ref={el => (this.componentRef = el)} values={values}/>)
      case "13_105CPL_H":
      return(<PracticeForcedLandings ref={el => (this.componentRef = el)} values={values}/>)
      case "14_105CPL_H":
      return(<TailRotorFailures ref={el => (this.componentRef = el)} values={values}/>)
      case "15_105CPL_H":
      return(<JammedFlightControls ref={el => (this.componentRef = el)} values={values}/>)
      case "16_105CPL_H":
      return(<AdvancedEmergencies ref={el => (this.componentRef = el)} values={values}/>)
      case "17_105CPL_H":
      return(<CircuitsPreSolo ref={el => (this.componentRef = el)} values={values}/>)
      case "18_105CPL_H":
      return(<FirstSoloCircuit ref={el => (this.componentRef = el)} values={values}/>)
      case "19_105CPL_H":
      return(<CircuitConsolidation ref={el => (this.componentRef = el)} values={values}/>)
      case "20_105CPL_H":
      return(<SoloCircuits ref={el => (this.componentRef = el)} values={values}/>)
      case "21_105CPL_H":
      return(<CircuitConsolidation2 ref={el => (this.componentRef = el)} values={values}/>)
      case "22_105CPL_H":
      return(<SoloCircuits2 ref={el => (this.componentRef = el)} values={values}/>)
      case "23_105CPL_H":
      return(<CircuitConsolidation3 ref={el => (this.componentRef = el)} values={values}/>)
      case "24_105CPL_H":
      return(<SoloCircuits3 ref={el => (this.componentRef = el)} values={values}/>)
      case "25_105CPL_H":
      return(<SlopeLandings ref={el => (this.componentRef = el)} values={values}/>)
      case "26_105CPL_H":
      return(<ConfinedAreas ref={el => (this.componentRef = el)} values={values}/>)
      case "27_105CPL_H":
      return(<SteepTurns ref={el => (this.componentRef = el)} values={values}/>)
      case "28_105CPL_H":
      return(<PreTrainingAreaSolo ref={el => (this.componentRef = el)} values={values}/>)
      case "29_105CPL_H":
      return(<FirstTrainingAreaSolo ref={el => (this.componentRef = el)} values={values}/>)
      case "30_105CPL_H":
      return(<ConfinedAreasConsolidation ref={el => (this.componentRef = el)} values={values}/>)
      case "31_105CPL_H":
      return(<TrainingAreaSolo ref={el => (this.componentRef = el)} values={values}/>)
      case "32_105CPL_H":
      return(<EmergenciesConsolidation ref={el => (this.componentRef = el)} values={values}/>)
      case "33_105CPL_H":
      return(<TrainingAreaSolo2 ref={el => (this.componentRef = el)} values={values}/>)
      case "34_105CPL_H":
      return(<NavEx1 ref={el => (this.componentRef = el)} values={values}/>)
      case "35_105CPL_H":
      return(<NavEx2 ref={el => (this.componentRef = el)} values={values}/>)
      case "36_105CPL_H":
      return(<NavEx3 ref={el => (this.componentRef = el)} values={values}/>)
      case "37_105CPL_H":
      return(<NavEx4FirstSolo ref={el => (this.componentRef = el)} values={values}/>)
      case "38_105CPL_H":
      return(<NavEx5 ref={el => (this.componentRef = el)} values={values}/>)
      case "39_105CPL_H":
      return(<NavEx6SoloNav12DME ref={el => (this.componentRef = el)} values={values}/>)
      case "40_105CPL_H":
      return(<NavEx7 ref={el => (this.componentRef = el)} values={values}/>)
      case "41_105CPL_H":
      return(<NavEx8SoloNavR555 ref={el => (this.componentRef = el)} values={values}/>)
      case "42_105CPL_H":
      return(<NavEx9 ref={el => (this.componentRef = el)} values={values}/>)
      case "43_105CPL_H":
      return(<NavEx10SoloNav ref={el => (this.componentRef = el)} values={values}/>)
      case "44_105CPL_H":
      return(<NavEx11 ref={el => (this.componentRef = el)} values={values}/>)
      case "45_105CPL_H":
      return(<NavEx12SoloNav ref={el => (this.componentRef = el)} values={values}/>)
      case "46_105CPL_H":
      return(<NavEx13 ref={el => (this.componentRef = el)} values={values}/>)
      case "47_105CPL_H":
      return(<NavEx14SoloNav ref={el => (this.componentRef = el)} values={values}/>)
      case "48_105CPL_H":
      return(<DualConsolidation ref={el => (this.componentRef = el)} values={values}/>)
      case "49_105CPL_H":
      return(<SoloConsolidation ref={el => (this.componentRef = el)} values={values}/>)
      case "50_105CPL_H":
      return(<PreCPL ref={el => (this.componentRef = el)} values={values}/>)
      case "51_105CPL_H":
      return(<AccCompetency ref={el => (this.componentRef = el)} values={values}/>)


      default:
      return(<AccCompetency ref={el => (this.componentRef = el)} values={values}/>)

    }

    }



  render() {
    const {values} = this.props;
    const formId=values.formId;
return(


          <MuiThemeProvider>
            <React.Fragment>

              <div>
              <Grid container spacing={2} style={{padding:50}}>
              <Grid item xs={2}/>
              <Grid item xs={8}>


              <Paper style={{marginTop:"15%"}}>


              <Grid item xs={12} sm container>
                <Grid item xs container direction="column">
                  <Grid item xs style={{textAlign:'center', padding:20}}>
                  <Typography variant="h6">Success!</Typography>
                <div style={{display: "none"}}>

                  {this.renderForm()}
                </div>
                <Grid item xs={10} style={{marginLeft:'10%',marginRight:'10%',marginTop:"1%"}}>

                <RaisedButton primary fullWidth='true' label="Email Summary" style={{color:"white",marginTop:20, height:50}}/>
                <ReactToPrint onAfterPrint={this.updateSuccess} onPrintError={this.updateError}

                trigger={() =>  <RaisedButton secondary fullWidth='true' style={{color:"white",marginTop:10, height:30}}><Typography variant="caption">Print Training Record</Typography></RaisedButton>}
                 content={() => this.componentRef}
                />

                <RaisedButton secondary onClick={this.props.startAgaintrainingMgmt} fullWidth='true' style={{color:"white",marginTop:10, height:50}}><Typography variant="caption">Return To Training Management</Typography></RaisedButton>

                </Grid>
                </Grid>
                </Grid>
                </Grid>
                </Paper>
                <Dialog
                  fullWidth="true"
                  maxWidth="md"
                  onClose={this.handleCloseSuccess}
                  open={this.state.success}
                  >
                  <DialogContent style={{textAlign:'center'}}id="customized-dialog-title" onClose={this.handleCloseIncorrectPassword}>
                    <Typography  style={{padding:'2%'}}variant="h5"> Request Completed</Typography>
                    <Typography style={{padding:'2%'}} variant="body2">  Action Completed Successfully</Typography>
                        </DialogContent>
                <DialogActions>
                  <RaisedButton fullWidth style={{marginBottom:'2%'}} onClick={this.handleCloseSuccess} secondary={true} >
                    Close
                  </RaisedButton>

                </DialogActions>
                </Dialog>
                <Dialog
                  fullWidth="true"
                  maxWidth="md"
                  onClose={this.handleCloseIncorrectPassword}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.error}
                  >
                  <DialogContent style={{textAlign:'center'}}id="customized-dialog-title" onClose={this.handleCloseIncorrectPassword}>
                    <Typography  style={{padding:'2%'}}variant="h5">  Unexpected Issue has occured</Typography>
                    <Typography style={{padding:'2%'}} variant="body2">  There was an issue processing this request. Please contact us or try again</Typography>
                        </DialogContent>
                <DialogActions>
                  <RaisedButton fullWidth style={{marginBottom:'2%'}} onClick={this.handleCloseError} secondary={true} >
                    Try again
                  </RaisedButton>

                </DialogActions>
                </Dialog>
                </Grid>


                <Grid item xs={2}/>
                </Grid>







                </div>
            </React.Fragment>
          </MuiThemeProvider>






)
}
}
export default Success
