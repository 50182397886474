import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));





export class SelectDate extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
    const { values,handleChange} = this.props;

    console.log(values.suggestionsStu.stu_id)

  }



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }



  render() {
    const { values, handleChange, log, logTime, handleChangeInput} = this.props;

    var date2 = new Date(values.fl_time);
    var flightTime = date2.getTime();
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Select Flight Date & Time of Take-off</h3>
              </Grid>
              <Grid item xs={12}>
              <p4>Simply click on the calender icon to select date and time(MILITARY TIME/24HR) </p4>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>



                      <KeyboardDateTimePicker
                      InputLabelProps={{ shrink: true }}
                      autoOk={true}
                      autoFocus="true"
                      variant="inline"
                      inputVariant="outlined"
                      ampm={false}
                      label="Date & Time of Take-Off"
                      value={flightTime}
                      onChange={this.props.log}
                      onError={console.log}
                      style={{marginTop:'2%',marginBottom:'2%'}}
                      fullWidth="true"
                      disableFuture
                      format="dd/MM/yyyy HH:mm"
                        />
                    </MuiPickersUtilsProvider></Grid>


                    <Grid item xs={12}>
                    <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.props.nextStep}/>
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 10}}>
                      <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                        </Grid>


</Grid>
              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SelectDate
