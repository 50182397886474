import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';





export class NavEx12SoloNav extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }

  render() {
    const {values} = this.props;
        const ct = new Date(values.transTimeStamp);

        var date1 = new Date(values.fl_time);
        var flightDate = date1.getDate() + '/' + (date1.getMonth()+1) + '/' + date1.getFullYear();
        let current_time =  ct.getDate() + '/' + (ct.getMonth()+1) + '/' + ct.getFullYear();
    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>


          <Grid item xs={12} sm container style={{padding:50}}>


              <table style={{textAlign:'center',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{backgroundColor:'#BFBFBE',width:'42%'}}>
              <b>HELICOPTER TRAINING AND TRANSPORT <br></br>
              Commercial Pilot Licence – Helicopter Category Rating</b>
              </td>
              </tr>
              </table>


              <table style={{textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>LESSON PLAN AND TRAINING RECORD <br></br>
              105CPL(H) 45: NAVIGATION: OTHER (SOLO)</b>
              </td>
              </tr>
              </table>



              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse', backgroundColor:'#EEECDF'}}>Flight no: </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> 105CPL(H) 45.{values.lessonNo}    </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Trainee name <br></br> & ARN: </th>
              <td style={{padding:10, columnSpan:'all',borderBottomStyle:'solid',borderWidth:1}}> {values.fName} {values.lName}<br></br> {values.stu_arn}  </td>              </tr>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Date </th>
              <td style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>  {flightDate}     </td>
              <th style={{width:'20%',borderStyle:'solid',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Instructor: </th>
              <td style={{padding:10, columnSpan:'all'}}>{values.ins_fname} {values.ins_lname}  </td>

              </tr>
              </table>
              <table style={{textAlign:'left',borderStyle:'none', width:'100%', borderWidth:1, borderColor:'black', borderCollapse: 'collapse'}}>
              <tr>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft<br></br>registration: </th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}> {values.rego}   </td>
              <th style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Aircraft Type:</th>
              <td style={{width:'20%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{values.wing_type}     </td>
              <th style={{width:'10%',borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse',backgroundColor:'#EEECDF'}}>Flight Time:</th>
              <td style={{borderStyle:'solid',borderTopStyle:'none',padding:10,borderWidth:1, borderCollapse: 'collapse'}}>{(parseFloat(values.soloGH)+parseFloat(values.soloNav)).toFixed(1)} S <br></br>{(parseFloat(values.dualGH)+parseFloat(values.dualNav)).toFixed(1)} D</td>
              </tr>

              </table>


              <table style={{textAlign:'left',borderStyle:'solid', width:'100%', marginTop:20, borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Lesson Overview</b>

              <ul>
              <li>Solo navigation exercise – outside controlled airspace</li>
              <li>Navigation route: [see comments]</li>
              <li><b>Suggested flight time: 2.0 hour</b></li>
              </ul>
              </td>
              </tr>
              <tr>
              <th style={{borderStyle:'solid',borderWidth:1,width:'100%',padding:10, borderCollapse: 'collapse', borderColor: 'black'}}>
              <b>Operational Limitations:</b>
              <p></p>
              <p style={{marginTop:160}}>
              <p><i><b>During solo flight, trainees must only be authorised to practise sequences that have been assessed to performance standard 2 or 1, on a minimum of two separate flights.

              <br></br>Except in emergency or urgency situations, or in the interests of maintaining safety, the trainee must not operate contrary to the limitations and guidelines specified by the authorising flight instructor.</b></i></p>
              </p>


              </th>

              </tr>
              </table>
















              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderWidth:1, borderCollapse: 'collapse',paddingLeft:10, backgroundColor:'#BFBFBE',width:'100%'}}>
              <b>COMMENTS AND OUTCOME</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderStyle:'solid', borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'100%'}}>
              <br></br>
              {values.commts}
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              </td>
              </tr>
              </table>
              <table style={{textAlign:'left',borderTopStyle:'none',borderLeftStyle:'solid',borderRightStyle:'solid', borderBottomStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ borderStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'80%'}}>
              <b>Proceed to next training session?</b>
              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>
              <b>{values.nextLesson_bool}</b>
              </td>
              <td style={{ borderLeftStyle:'none',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'10%'}}>

              </td>
              </tr>

              </table>

              <table style={{marginTop:20,textAlign:'left',borderStyle:'solid',paddingLeft:10, width:'100%', borderWidth:1, borderCollapse: 'collapse', borderColor: 'black'}}>
              <tr>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Instructor’s signature & date</b>
              </td>
              <td style={{ backgroundColor:'#BFBFBE',borderStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <b>Trainee’s signature & date</b>
              </td>
              </tr>
              <tr>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.ins_Signature}/>{flightDate}

              </td>
              <td style={{ borderLeftStyle:'solid',borderWidth:1, borderCollapse: 'collapse',paddingLeft:10,width:'50%'}}>
              <img style={{maxWidth:'50%'}} src={values.stu_Signature}/>{flightDate}
              </td>

              </tr>

              </table>

              <hr style={{marginTop:180, width:'100%'}}></hr>
              <table style={{width:'100%'}}>
              <tr>
              <td style={{fontSize:'small',textAlign:'left',width:'33.3%'}}><p2>105CPL(H) 45  v1.1</p2></td>
              <td style={{fontSize:'small',textAlign:'center',width:'33.3%'}}><p2>APRIL 2017 </p2></td>
              <td style={{fontSize:'small',textAlign:'right', width:'33.3%'}}><p2>Page 1</p2></td>

              </tr>

              </table>
              </Grid>


          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default NavEx12SoloNav
