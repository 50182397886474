import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Devon from './Devon1.jpg'
import Ferrari from './ferrarilarge.jpg'



const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class LoginPagePassword extends Component{

    constructor(props) {
      super(props);
      this.state = {value:0,
                    password:''
                  };
    }


    continue = e => {
      this.props.authenticate(1004,this.state.password);
    }

    back = e => {
      e.preventDefault();
      this.props.setStep(1002);
    }

    passwordSet = e => {
      e.preventDefault();
      this.setState({password:e.target.value})
      console.log(this.state.username);
    }
    keyPress=(e)=>{
          if(e.keyCode == 13){
             this.continue();
             // put the login here
          }
       }

    render() {
      const { values} = this.props;

      return(

        <MuiThemeProvider>
          <React.Fragment>


          <div style={{height:'100vh',flex:'auto',backgroundImage: `url(${Ferrari})`}}>
          <Grid container style={{paddingTop:"7%"}} >
            <Grid item xs={12}>


            <Paper style={{opacity:0.89,padding:"2%"}}>


            <Grid item xs={12} sm container>
              <Grid item xs container direction="column">
                <Grid item xs style={{textAlign:'center', paddingLeft:"20%",paddingRight:"20%"}}>

                <h1>
                  Oops!
                </h1>
                <h3 style={{color:"red"}}>Incorrect Password</h3>
                <Typography alignJustify variant="body2" color="textSecondary">
                  It is an offence to use someone else's credentials for access. If your passphrase security is at risk, please contact Support as soon as Possible!
                </Typography>
                <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>

                  <Typography gutterBottom variant="subtitle1">
                    {values.loggedInUser.fName} {values.loggedInUser.lName}
                  </Typography>


                  <TextField
              autoFocus
              margin="dense"
              id="Pass"
              hintText="Enter Your Password again"
              type="password"
              onKeyDown={this.keyPress}
              onChange={this.passwordSet}
              value={this.state.password}
              fullWidth
            />
            <Grid item>
            <RaisedButton label="Sign-in" primary={true} onClick={e=>{e.preventDefault();this.continue()}} fullWidth='true' style={{marginTop:20}}/>
            <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back  </Button>

            </Grid>
                </Grid>

              </Grid>
            </Grid>
              </Paper>
            </Grid>

            <Grid item xs={2}/>



          </Grid>



            </div>
          </React.Fragment>
        </MuiThemeProvider>
      )
    }
  }


export default LoginPagePassword
