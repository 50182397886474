import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Toggle from 'material-ui/Toggle';
import DatePicker from 'material-ui/DatePicker';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ActionFavorite from 'material-ui/svg-icons/action/favorite';
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border';








export class LessonOne extends Component{



  constructor(props) {
    super(props);
    this.state={value:0}
  }

/*method to select what conditions are displayed depending on formID selection from the previous page*/



  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }









  render() {
    const { values, handleChange, defaultButton, formRating,createRadioGroups} = this.props;
    const ratingState= values.formRating;



    return(



      <MuiThemeProvider>
        <React.Fragment>




          <div style={{ padding: 10}}>

          <Grid container>
            <Grid item xs={5} style={{marginLeft:'5%'}}><h1>Transaction History</h1></Grid>
            <Grid item xs={2} style={{padding:'1%'}}>
                    <Button fullWidth variant="outlined" onClick={this.props.getAllTransactions}>
                          Show All
                          </Button>
                          </Grid>
            <Grid item xs={2}style={{padding:'1%'}}>
                    <Button fullWidth variant="outlined" color="secondary" onClick={this.props.getDebitsOnly}>
                          Debits Only
                          </Button>
                          </Grid>
            <Grid item xs={2}style={{padding:'1%'}}>
                    <Button fullWidth variant="outlined" color="primary" onClick={this.props.getCreditsOnly}>
                        Credits Only
                        </Button></Grid></Grid>
            <Paper style={{margin:'2%',padding:'2%'}}>
            <Grid container  spacing={1}>


            <Grid item xs={1}> <h4>#</h4></Grid>
            <Grid item xs={2}> <h4>Account</h4></Grid>
            <Grid item xs={1}> <h4>EFTPOS</h4></Grid>
            <Grid item xs={2}> <h4>Payment Date & Time</h4></Grid>
            <Grid item xs={1}> <h4>Type</h4></Grid>
            <Grid item xs={3}> <h4>Transaction Info</h4></Grid>
            <Grid item xs={1}> <h4>Amount</h4></Grid>
            </Grid>

          <GridList style={{height:400}}>
              <Grid item xs={12}>{createRadioGroups()}</Grid>
              </GridList>
              </Paper>
                <Grid item xs={12} style={{ marginTop: "2%"}}>
                <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>

                </Grid>

          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default LessonOne
