import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WelcomeImage from './WelcomeImage.jpeg'
import CircularProgress from '@material-ui/core/CircularProgress';
import HeliLoading from './zResourcesimg/heliLoading.gif';



const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class Loading extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }




  handleChange = (event, index, value) => this.setState({value});


  handleClickOpen = () => {
      this.setState({
        open: true,
      });
    };

  handleClose = () => {
      this.setState({
        open: false
      });
    };



  render() {



    return(

      <MuiThemeProvider>
        <React.Fragment>
  

            <div>
          <Grid container style={{paddingTop:'10%', paddingBottom:'23%'}}>
          <Grid item xs={2}/>
          <Grid item xs={8}>


          <Paper style={{opacity:0.89, padding:"10%"}}>


          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs style={{textAlign:'center'}}>


              <Typography alignJustify variant="body2" color="textSecondary">
                Please Wait
              </Typography>


                <CircularProgress style={{padding:'5%'}} />




              </Grid>

            </Grid>
          </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}/>



        </Grid>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default Loading
