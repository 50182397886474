import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Banner from './zResourcesimg/banner.png';

import HeliFleet from './zResourcesimg/helicopter.png';
import InsIcon from './zResourcesimg/pilot.png';
import AccIcon from './zResourcesimg/helmet.png';
import FinIcon from './zResourcesimg/profit.png';
import TrIcon from './zResourcesimg/steering-wheel.png';
import Auth from './zResourcesimg/tick.png';





export class HomePage extends Component{

  constructor(props) {
    super(props);


    this.state = {value:0,banner:false};
  }


  continue =(stepNo)=> e => {
    e.preventDefault();
    this.props.clear();
    this.props.setStep(stepNo);
  }
  formId = id => {
    this.props.setFormId(id);

  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

viewMyAccount=e=>{
  this.props.getAccountInfo(2501)
}

handleDoNotShowAgain=e=>{
  const {values}=this.props;
  e.preventDefault();
  this.props.handleDoNotShowAgain(values.loggedInUser.email);
  this.props.handleChangeInput("banner","false");


}


handleClose=e=>{
  e.preventDefault();
  this.props.handleChangeInput('banner',"false");


}
componentDidMount(){
  const {values}=this.props;
  this.setState({banner:values.loggedInUser.banner})
}


  render() {
    const {values, setPassportPhoto,passportPhoto} = this.props;



      switch (values.loggedInUser.accessLevel) {
        case "sa":
          return(
            <MuiThemeProvider>
              <React.Fragment>

                <div>
                <Grid container spacing={2} style={{padding:"5%"}}>

                {/**<Grid item xs={4} style={{display:"flex"}}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(7000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                        </React.Fragment>}>
                        <img src={Auth} style={{width:'100%'}}/>
                      </Tooltip>

                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Pre-Flight Authorization
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Appendix C - Form 4B09 - To be completed before each flight
                        </Typography></CardContent></div></CardActionArea></Card></Grid>*/}

                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(2000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                        </React.Fragment>}>
                        <img src={AccIcon} style={{width:'100%'}}/>
                      </Tooltip>

                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Account Management
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Click here to manage Student or Customer Accounts
                        </Typography></CardContent></div></CardActionArea></Card></Grid>


                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(4000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                      Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                        <img src={InsIcon} style={{width:'100%'}}/>
                      </Tooltip>
                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Staff Management
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Click here to Register Instructors, edit Instructor details etc
                        </Typography></CardContent></div></CardActionArea></Card></Grid>




                  <Grid item xs={4}>
                  <Card  style={{display: 'flex', height:"100%"}}>
                  <CardActionArea
                      onClick={this.continue(5000)}
                      style={{ display: 'flex',flexDirection: 'row'}}>

                    <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                    <Tooltip enterDelay={6000} leaveDelay={500}
                      title={
                        <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                          <img src={HeliFleet} style={{width:'100%'}}/>
                        </Tooltip>
                      </div>

                    <div style={{ display: 'flex',flexDirection: 'column'}}>
                      <CardContent style={{flex: '1 0 auto'}}>
                        <Typography component="h5" variant="h5">
                          Fleet Management
                          </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Click here to manage Add, edit AirCraft details, set Prices etc
                          </Typography></CardContent></div></CardActionArea></Card></Grid>


                    <Grid item xs={4}>
                    <Card  style={{display: 'flex', height:"100%"}}>
                    <CardActionArea
                        onClick={this.continue(6000)}
                        style={{ display: 'flex',flexDirection: 'row'}}>

                <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                <Tooltip enterDelay={6000} leaveDelay={500}
                  title={
                    <React.Fragment>
                    <div>Icons made by <a href="https://www.flaticon.com/authors/smartline" title="Smartline">Smartline</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>              </React.Fragment>}>
                      <img src={FinIcon} style={{width:'100%'}}/>
                    </Tooltip>
                        </div>

                <div style={{ display: 'flex',flexDirection: 'column'}}>
                        <CardContent style={{flex: '1 0 auto'}}>
                          <Typography component="h5" variant="h5">
                            Finances
                            </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                          Click here to manage finances, make a payment etc
                            </Typography></CardContent></div></CardActionArea></Card></Grid>

                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(2999)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

            <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
            <Tooltip enterDelay={6000} leaveDelay={500}
              title={
                <React.Fragment>
                Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                <img src={TrIcon} style={{width:'100%'}}/>
                </Tooltip>
                    </div>

            <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Training Portal
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Record a lesson undertaken, View training History and Matrix
                        </Typography></CardContent></div></CardActionArea></Card></Grid>





                        <Dialog maxWidth="md" style={{padding:"10%"}} fullWidth
                        backdrop
                        open={values.banner==true||values.banner=="true"||values.banner=="True"}>
                        <DialogContent dividers>
                        <Typography component="h4" variant="h4">What's New?</Typography>
                        <Typography variant="caption" display="block" gutterBottom>JEFF v1.1.1 build 230920</Typography>
                        </DialogContent>
                        <DialogContent dividers style={{textAlign:'center'}}>
                        <img src={Banner} style={{width:'40%'}}/>
                        <Typography variant="caption" display="block" gutterBottom>Training Matrix Sheet</Typography>
                        </DialogContent>
                        <DialogContent>
                        <Typography variant="body1" display="block" gutterBottom>
                        -Now able to bypass Student Signature authorization when student not present<br></br>
                        -Pilot/instructor details now recorded in Pre-flight Authorization<br></br>
                        -quick return to Daily Pre-flight summary after submitting a record
                        -Ability to change flight type added
                        -Jeff now remembers if a Pre-flight Authorization was a Training flight
                        -Bug fixed enabling fuel Qty change for pre-flight authorizations
                        </Typography>
                        <Typography variant="body1" display="block" gutterBottom>
                        -Training Matrix Generation & Export is now available via Training Portal<br></br>
                        -Return/Enter key activated to submit login credentials<br></br>
                        -"What's New?" banner to keep you up-to-date with the latest updates to JEFF
                        </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={this.handleDoNotShowAgain} color="primary">
                          Do Not Show Again
                          </Button>
                          <Button onClick={this.handleClose} color="primary" autoFocus>
                          Okay
                          </Button>
                          </DialogActions>



                        </Dialog>

                </Grid>



                </div>
              </React.Fragment>
            </MuiThemeProvider>
          )
        case "i":
          return(
            <MuiThemeProvider>
              <React.Fragment>

                <div>
                <Grid container spacing={2} style={{padding:"5%"}}>
                <Grid item xs={4} style={{display:"flex"}}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(7000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                        </React.Fragment>}>
                        <img src={Auth} style={{width:'100%'}}/>
                      </Tooltip>

                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Pre-Flight Authorization
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Appendix C - Form 4B09 - To be completed before each flight
                        </Typography></CardContent></div></CardActionArea></Card></Grid>

                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(2000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                        </React.Fragment>}>
                        <img src={AccIcon} style={{width:'100%'}}/>
                      </Tooltip>

                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Account Management
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Click here to manage Student or Customer Accounts
                        </Typography></CardContent></div></CardActionArea></Card></Grid>


                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(4000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                      Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                        <img src={InsIcon} style={{width:'100%'}}/>
                      </Tooltip>
                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Staff Management
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Click here to View Instructors details
                        </Typography></CardContent></div></CardActionArea></Card></Grid>




                  <Grid item xs={4}>
                  <Card  style={{display: 'flex', height:"100%"}}>
                  <CardActionArea
                      onClick={this.continue(6000)}
                      style={{ display: 'flex',flexDirection: 'row'}}>

                    <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                    <Tooltip enterDelay={6000} leaveDelay={500}
                      title={
                        <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                          <img src={FinIcon} style={{width:'100%'}}/>
                        </Tooltip>
                      </div>



                <div style={{ display: 'flex',flexDirection: 'column'}}>
                        <CardContent style={{flex: '1 0 auto'}}>
                          <Typography component="h5" variant="h5">
                            Finances
                            </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                          Click here to manage finances, make a payment etc
                            </Typography></CardContent></div></CardActionArea></Card></Grid>

                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(2999)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

            <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
            <Tooltip enterDelay={6000} leaveDelay={500}
              title={
                <React.Fragment>
                Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                <img src={TrIcon} style={{width:'100%'}}/>
                </Tooltip>
                    </div>

            <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Training Portal
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Record a lesson undertaken, View training History and Matrix
                        </Typography></CardContent></div></CardActionArea></Card></Grid>







                </Grid>

                <Dialog maxWidth="md" style={{padding:"10%"}} fullWidth
                backdrop
                open={values.banner==true||values.banner=="true"||values.banner=="True"}>
                <DialogContent dividers>
                <Typography component="h4" variant="h4">What's New?</Typography>
                <Typography variant="caption" display="block" gutterBottom>JEFF v1.1.1 build 230920</Typography>
                </DialogContent>
                <DialogContent dividers style={{textAlign:'center'}}>
                <img src={Banner} style={{width:'40%'}}/>
                <Typography variant="caption" display="block" gutterBottom>Training Matrix Sheet</Typography>
                </DialogContent>
                <DialogContent>
                <Typography variant="body1" display="block" gutterBottom>
                -Now able to bypass Student Signature authorization when student not present<br></br>
                -Pilot/instructor details now recorded in Pre-flight Authorization<br></br>
                -quick return to Daily Pre-flight summary after submitting a record
                -Ability to change flight type added
                -Jeff now remembers if a Pre-flight Authorization was a Training flight
                -Bug fixed enabling fuel Qty change for pre-flight authorizations
                </Typography>
                <Typography variant="body1" display="block" gutterBottom>
                -Training Matrix Generation & Export is now available via Training Portal<br></br>
                -Return/Enter key activated to submit login credentials<br></br>
                -"What's New?" banner to keep you up-to-date with the latest updates to JEFF
                </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDoNotShowAgain} color="primary">
                  Do Not Show Again
                  </Button>
                  <Button onClick={this.handleClose} color="primary" autoFocus>
                  Okay
                  </Button>
                  </DialogActions>



                </Dialog>



                </div>
              </React.Fragment>
            </MuiThemeProvider>
          )
        case "p":
          return(
            <MuiThemeProvider>
              <React.Fragment>

                <div>
                <Grid container spacing={2} style={{padding:"5%"}}>
                <Grid item xs={4} style={{display:"none"}}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(7000)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                        </React.Fragment>}>
                        <img src={Auth} style={{width:'100%'}}/>
                      </Tooltip>

                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Pre-Flight Authorization
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Appendix C - Form 4B09 - To be completed before each flight
                        </Typography></CardContent></div></CardActionArea></Card></Grid>

                <Grid item xs={4}>
                <Card  style={{display: 'flex', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(2500)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                        Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                        </React.Fragment>}>
                        <img src={AccIcon} style={{width:'100%'}}/>
                      </Tooltip>

                    </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      View Accounts
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Click here to view Student or Customer Accounts
                        </Typography></CardContent></div></CardActionArea></Card></Grid>





                <Grid item xs={4}>
                <Card  style={{display: 'none', height:"100%"}}>
                <CardActionArea
                    onClick={this.continue(2999)}
                    style={{ display: 'flex',flexDirection: 'row'}}>

            <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
            <Tooltip enterDelay={6000} leaveDelay={500}
              title={
                <React.Fragment>
                Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                <img src={TrIcon} style={{width:'100%'}}/>
                </Tooltip>
                    </div>

            <div style={{ display: 'flex',flexDirection: 'column'}}>
                    <CardContent style={{flex: '1 0 auto'}}>
                      <Typography component="h5" variant="h5">
                      Training Portal
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      Record a lesson undertaken, View training History and Matrix
                        </Typography></CardContent></div></CardActionArea></Card></Grid>







                </Grid>



                </div>
              </React.Fragment>
            </MuiThemeProvider>
          )

        case "s":
          return(
              <MuiThemeProvider>
                <React.Fragment>

                  <div>
                  <Grid container spacing={2} style={{padding:"5%"}}>

                  <Grid item xs={4}>
                  <Card  style={{display: 'flex', height:"100%"}}>
                  <CardActionArea
                      onClick={e=>{e.preventDefault();this.viewMyAccount()}}
                      style={{ display: 'flex',flexDirection: 'row'}}>

                    <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                    <Tooltip enterDelay={6000} leaveDelay={500}
                      title={
                        <React.Fragment>
                          Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
                          </React.Fragment>}>
                          <img src={AccIcon} style={{width:'100%'}}/>
                        </Tooltip>

                      </div>

                    <div style={{ display: 'flex',flexDirection: 'column'}}>
                      <CardContent style={{flex: '1 0 auto'}}>
                        <Typography component="h5" variant="h5">
                        My Account
                          </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        Manage Your Account Details
                          </Typography></CardContent></div></CardActionArea></Card></Grid>








                      <Grid item xs={4}>
                      <Card  style={{display: 'flex', height:"100%"}}>
                      <CardActionArea
                          onClick={this.continue(6000)}
                          style={{ display: 'flex',flexDirection: 'row'}}>

                  <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
                  <Tooltip enterDelay={6000} leaveDelay={500}
                    title={
                      <React.Fragment>
                      <div>Icons made by <a href="https://www.flaticon.com/authors/smartline" title="Smartline">Smartline</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>              </React.Fragment>}>
                        <img src={FinIcon} style={{width:'100%'}}/>
                      </Tooltip>
                          </div>

                  <div style={{ display: 'flex',flexDirection: 'column'}}>
                          <CardContent style={{flex: '1 0 auto'}}>
                            <Typography component="h5" variant="h5">
                              My Finances
                              </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                            Manage finances, make a payment etc
                              </Typography></CardContent></div></CardActionArea></Card></Grid>

                  <Grid item xs={4}>
                  <Card  style={{display: 'flex', height:"100%"}}>
                  <CardActionArea
                      onClick={this.continue(2999)}
                      style={{ display: 'flex',flexDirection: 'row'}}>

              <div style={{maxWidth:"20%" ,paddingLeft:'2%'}}>
              <Tooltip enterDelay={6000} leaveDelay={500}
                title={
                  <React.Fragment>
                  Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>                  </React.Fragment>}>
                  <img src={TrIcon} style={{width:'100%'}}/>
                  </Tooltip>
                      </div>

              <div style={{ display: 'flex',flexDirection: 'column'}}>
                      <CardContent style={{flex: '1 0 auto'}}>
                        <Typography component="h5" variant="h5">
                        My Training
                          </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        View lessons undertaken, View training History and Matrix
                          </Typography></CardContent></div></CardActionArea></Card></Grid>







                  </Grid>



                  </div>
                </React.Fragment>
              </MuiThemeProvider>
            )

          break;
        default:
          return(
              <MuiThemeProvider>
                <React.Fragment>
                <div>
                Unauthorized Login. Please sign out!
                </div>
                </React.Fragment>
              </MuiThemeProvider>
            )


      }



  }
}


export default HomePage
