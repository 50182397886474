import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReactExport from 'react-data-export';
import Generate from './Generate.js';
import Download from './Download.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export class TrainingMatrixExport extends Component{



    constructor(props) {
      super(props);
      this.state={download:"none",generate:"none",loading:true,value:0,step1:0,allConds:"",flightRecords:"",}
    }

  /*method to select what conditions are displayed depending on formID selection from the previous page*/




    back = e => {
        e.preventDefault();
        this.props.setStep(3201)
      }




componentDidMount(){
  const {values} =this.props;
  const {allConds,flightRecords} = this.state;

  fetch(`${process.env.REACT_APP_SERVER_URL}/getAllFlightConds`, {
    headers: {
    'x-access-token': values.token
  }})
  .then(response => response.json())
  .then(response=>{console.log(response.data);
                  this.setState({allConds:response.data})
                })
  .catch(err=>console.error(err))
  .then(
    fetch(`${process.env.REACT_APP_SERVER_URL}/getFlightHistoryforStudent?stuId=${this.props.values.searchKeyword.stu_id}`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{console.log(response.data);
                    this.setState({flightRecords:response.data,loading:false,generate:"",download:"none"})
                  })



  )



}

generateData(){
  const {allConds,flightRecords}=this.state;
  const {values}=this.props;
  //this.setState({loading:true});

  allConds.forEach(function (element) {
  element.Date1= "";
  element.Date1Ins="";
  element.Date2= "";
  element.Date2Ins="";
  element.Date3 = "";
  element.Date3Ins="";
  element.DateNA = "";

  });
  fetch(allConds.map(
    i=>
      flightRecords.map(
      p=>{if(p.ratings!="NULL"){JSON.parse(p.ratings).map(
        x=>{if (i.con_id.trim()===x.cid.trim()) {

                if (x.rate==1) {
                  if (i.Date3=="" || i.Date3>p.takeOffDateTime) {i.Date3=p.takeOffDateTime;i.Date3Ins=p.ins_name; console.log(i)}
                }else if(x.rate==2) {
                  console.log("2");
                  if (i.Date2=="" || i.Date2>p.takeOffDateTime) {i.Date2=p.takeOffDateTime;i.Date2Ins=p.ins_name; console.log(i)}
                }else if(x.rate==3) {
                  console.log("3");

                  if (i.Date1=="" || i.Date1>p.takeOffDateTime) { i.Date1=p.takeOffDateTime;i.Date1Ins=p.ins_name; console.log(i)}
                }

              }

        }

      )}
      }
    )
  ))
  .then(
    allConds.map(
      d=>{{if (d.Date1!="") {
        const formattedDate1=moment(d.Date1).format("DD/MM/YYYY");
          d.Date1=formattedDate1;

      }}
      {if (d.Date2!="") {
        const formattedDate2=moment(d.Date2).format("DD/MM/YYYY");
          d.Date2=formattedDate2;

      }}
      {if (d.Date3!="") {
        const formattedDate3=moment(d.Date3).format("DD/MM/YYYY");
          d.Date3=formattedDate3;

      }}

        }
    )
  )
  .then(this.setState({multiDataSet:[
      {
          columns: [
              {title: "", width: {wpx: 50}},//pixels width
              {title: values.searchKeyword.fname+" "+values.searchKeyword.lname+" - Training Matrix", width: {wpx: 1000},style: { font: { sz: "24", bold: true }}},//pixels width
              {title: "", width: {wpx: 100}},//pixels width
              {title: "", width: {wpx: 100}},//pixels width
              {title: "", width: {wpx: 100}},//pixels width
              {title: "", width: {wpx: 100}},//pixels width
              {title: "", width: {wpx: 100}},//pixels width
              {title: "", width: {wpx: 100}},//pixels width


          ],
          data: [
            [
            {value: ""},//pixels width
            {value: "Generated on "+moment().format("DD/MM/YYYY")},//pixels width
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
          ],[
            {value: ""},//pixels width
            {value: ""},//pixels width
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
            {value: ""},
          ],
              [
                {value: ""},//pixels width
                {value: "COLOUR CODES"},//pixels width
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
              ],[
                {value: ""},//pixels width
                {value: "BLUE - STANDARD REQUIRED BEFORE FIST SOLO IN THE CIRCUIT",style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},//pixels width
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
                {value: ""},
              ],
              [
                {value: ""},//pixels width
                {value: "ORANGE - STANDARD REQUIRED BEFORE FIRST SOLO IN THE TRAINING AREA", style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: "STANDARD"},
                {value: "3"},
                {value: "STANDARD"},
                {value: "2"},
                {value: "STANDARD"},
                {value: "1"},,
              ],
              [
                {value: ""},
                {value: "RED - STANDARD REQUIRED BEFORE FIRST SOLO NAVIGATION", style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: "INSTRUCTOR"},
                {value: "DATE"},
                {value: "INSTRUCTOR"},
                {value: "DATE"},
                {value: "INSTRUCTOR"},
                {value: "DATE"},
              ],
              [
                {value: "C1", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Communicating in the aviation environment", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "C1.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Communicating face-to-face", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "pronounces words clearly, using an accent that does not cause difficulties in understanding", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[2].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[2].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[2].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[2].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[2].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[2].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[2].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[2].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[2].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[2].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[2].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[2].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "conveys information in clearly structured sentences without confusion or ambiguity", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[3].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[3].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[3].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[3].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[3].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[3].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[3].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[3].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[3].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[3].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[3].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[3].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "uses an extensive vocabulary to accurately communicate on general and technical topics, without excessive use of jargon, slang or colloquial language", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[4].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[4].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[4].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[4].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[4].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[4].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[4].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[4].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[4].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[4].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[4].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[4].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "speaks fluently without long pauses, repetition or excessive false starts", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[5].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[5].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[5].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[5].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[5].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[5].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[5].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[5].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[5].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[5].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[5].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[5].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "responds to communications with actions that demonstrate that the information has been received and understood", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[6].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[6].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[6].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[6].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[6].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[6].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[6].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[6].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[6].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[6].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[6].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[6].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "exchanges information clearly in a variety of situations with both expert and non-expert English speakers while giving and receiving timely and appropriate responses", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[7].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[7].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[7].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[7].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[7].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[7].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[7].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[7].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[7].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[7].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[7].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[7].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "uses appropriate techniques to validate communications", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[8].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[8].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[8].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[8].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[8].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[8].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[8].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[8].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[8].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[8].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[8].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[8].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "C1.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Operational communication using an aeronautical radio", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain effective communication with others on operational matters", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[10].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[10].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[10].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[10].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[10].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[10].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[10].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[10].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[10].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[10].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[10].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[10].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "communicate effectively in unfamiliar, stressful or non-standard situations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[11].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[11].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[11].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[11].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[11].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[11].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[11].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[11].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[11].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[11].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[11].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[11].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "apply the phonetic alphabet", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[12].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[12].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[12].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[12].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[12].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[12].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[12].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[12].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[12].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[12].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[12].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[12].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "transmit numbers", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[13].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[13].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[13].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[13].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[13].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[13].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[13].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[13].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[13].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[13].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[13].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[13].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "make appropriate transmissions using standard aviation phraseology", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[14].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[14].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[14].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[14].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[14].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[14].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[14].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[14].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[14].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[14].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[14].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[14].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "use plain English effectively when standard phraseology is inadequate", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[15].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[15].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[15].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[15].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[15].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[15].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[15].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[15].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[15].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[15].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[15].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[15].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "receive appropriate responses to transmissions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[16].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[16].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[16].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[16].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[16].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[16].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[16].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[16].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[16].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[16].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[16].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[16].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "respond to transmissions and take appropriate action", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[17].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[17].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[17].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[17].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[17].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[17].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[17].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[17].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[17].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[17].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[17].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[17].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "recognise and manage communication errors and misunderstandings effectively", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[18].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[18].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[18].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[18].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[18].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[18].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[18].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[18].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[18].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[18].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[18].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[18].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "seek clarification in the time available if a message is unclear or uncertainty exists", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[19].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[19].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[19].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[19].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[19].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[19].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[19].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[19].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[19].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[19].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[19].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[19].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "react appropriately to a variety of regional accents", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[20].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[20].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[20].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[20].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[20].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[20].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[20].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[20].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[20].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[20].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[20].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[20].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(l)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "communicate effectively in unexpected, stressful or non-standard situations using standard phraseology or plain English", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[21].Date1Ins =="" ? allConds[9].Date1Ins == "" ? allConds[0].Date1Ins : allConds[9].Date1Ins : allConds[21].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[21].Date1 =="" ? allConds[9].Date1 == "" ? allConds[0].Date1 : allConds[9].Date1 : allConds[21].Date1 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[21].Date2Ins =="" ? allConds[9].Date2Ins == "" ? allConds[0].Date2Ins : allConds[9].Date2Ins : allConds[21].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[21].Date2 =="" ? allConds[9].Date2 == "" ? allConds[0].Date2 : allConds[9].Date2 : allConds[21].Date2 , style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[21].Date3Ins =="" ? allConds[9].Date3Ins == "" ? allConds[0].Date3Ins : allConds[9].Date3Ins : allConds[21].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[21].Date3 =="" ? allConds[9].Date3 == "" ? allConds[0].Date3 : allConds[9].Date3 : allConds[21].Date3 , style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "C2", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Perform pre- and post-flight actions and procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "C2.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Pre-flight actions and procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "complete all required pre-flight administration documentation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[24].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[24].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[24].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[24].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[24].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[24].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "obtain, interpret and apply information contained in the required pre-flight operational documentation, including the following:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(i) minimum equipment list (MEL)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[26].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[26].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[26].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[26].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[26].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[26].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(ii) maintenance release", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[27].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[27].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[27].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[27].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[27].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[27].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(iii) weather forecasts", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[28].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[28].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[28].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[28].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[28].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[28].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(iv) local observations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[29].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[29].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[29].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[29].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[29].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[29].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(v) Notice to Airmen (NOTAM)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[30].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[30].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[30].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[30].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[30].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[30].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(vi) global navigation satellite system (GNSS) receiver autonomous integrity monitoring (RAIM) information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[31].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[31].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[31].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[31].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[31].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[31].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(vii) En Route Supplement Australia (ERSA)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[32].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[32].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[32].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[32].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[32].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[32].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(viii) Aeronautical Information Package (AIP)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[33].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[33].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[33].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[33].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[33].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[33].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify special aerodrome procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[34].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[34].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[34].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[34].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[34].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[34].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify all relevant radio and navigation aid facilities to be used during the flight (if applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[35].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[35].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[35].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[35].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[35].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[35].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "determine the suitability of the current and forecast weather conditions for the proposed flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[36].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[36].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[36].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[36].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[36].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[36].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "using the aircraft documents, calculate the following for a given set of environmental and operational conditions:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(i) weight and balance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[38].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[38].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[38].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[38].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[38].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[38].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(ii) in-ground and out-of-effect hover performance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[39].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[39].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[39].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[39].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[39].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[39].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(iii) take-off and landing performance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[40].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[40].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[40].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[40].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[40].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[40].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(iv) fuel requirements", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[41].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[41].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[41].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[41].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[41].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[41].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "determine whether the aircraft is serviceable for the proposed flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[42].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[42].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[42].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[42].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[42].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[42].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],

              [
                {value: "C2.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Perform pre-flight inspection", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify and secure equipment and documentation that is required for the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[44].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[44].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[44].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[44].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[44].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[44].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "complete an internal and external check of the aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[45].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[45].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[45].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[45].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[45].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[45].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify all defects or damage to the aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[46].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[46].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[46].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[46].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[46].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[46].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "report to, and seek advice from, qualified personnel to determine the action required in relation to any identified defects or damage", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[47].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[47].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[47].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[47].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[47].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[47].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure all aircraft locking and securing devices, covers and bungs are removed and stowed securely", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[48].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[48].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[48].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[48].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[48].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[48].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "certify the aircraft flight technical log entering any defects or endorsements to permissible unserviceabilities as appropriate", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[49].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[49].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[49].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[49].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[49].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[49].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "complete and certify the daily inspection (if authorised to do so)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[50].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[50].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[50].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[50].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[50].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[50].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],


              [
                {value: "C2.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Post-flight actions and procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "shut down aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[52].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[52].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[52].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[52].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[52].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[52].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "conduct post-flight inspection and secure the aircraft (if applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[53].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[53].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[53].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[53].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[53].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[53].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "complete all required post-flight administration documentation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[54].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[54].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[54].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[54].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[54].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[54].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "C3", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Operate aeronautical radio", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "C3.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Operate radio equipment", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "confirm serviceability of radio equipment", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[57].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[57].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[57].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[57].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[57].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[57].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "conduct transmission and receipt of radio communications using appropriate procedures and phraseology", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[58].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[58].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[58].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[58].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[58].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[58].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain a listening watch and respond appropriately to applicable transmissions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[59].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[59].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[59].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[59].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[59].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[59].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "conduct appropriate emergency and urgency transmissions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[60].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[60].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[60].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[60].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[60].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[60].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "C3.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Manage R/T equipment malfunctions", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform radio failure procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[62].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[62].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[62].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[62].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[62].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[62].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "use fault finding procedures and perform corrective actions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[63].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[63].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[63].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[63].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[63].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[63].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "C3.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Operate Transponder", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "operate a transponder during normal, abnormal and emergency operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[65].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[65].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[65].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[65].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[65].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[65].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "recall transponder emergency codes", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[66].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[66].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[66].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[66].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[66].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[66].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "C4", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Manage fuel", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "C4.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Plan fuel requirements", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "determine the required fuel reserves", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[69].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[69].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[69].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[69].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[69].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[69].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "determine the quantity of fuel required taking into account operational requirements and relevant abnormal or emergency conditions and contingencies", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[70].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[70].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[70].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[70].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[70].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[70].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "determine the total fuel required for the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[71].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[71].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[71].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[71].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[71].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[71].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "C4.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Manage fuel system", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "verify fuel quantity on-board aircraft prior to flight using two independent methods", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[73].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[73].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[73].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[73].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[73].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[73].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure the fuel caps are secured", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[74].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[74].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[74].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[74].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[74].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[74].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform fuel quality check prior to flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[75].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[75].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[75].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[75].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[75].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[75].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure fuel drain cocks are closed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[76].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[76].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[76].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[76].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[76].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[76].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "monitor fuel usage during the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[77].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[77].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[77].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[77].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[77].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[77].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "accurately maintain fuel log", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[78].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[78].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[78].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[78].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[78].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[78].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "calculate and state endurance at any point during flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[79].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[79].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[79].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[79].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[79].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[79].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform fuel tank changes correctly", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[80].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[80].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[80].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[80].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[80].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[80].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain fuel load within aircraft limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[81].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[81].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[81].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[81].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[81].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[81].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "operate the fuel cross-feed system correctly (if fitted)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[82].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[82].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[82].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[82].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[82].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[82].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "operate fuel pumps and engine controls correctly", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[83].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[83].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[83].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[83].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[83].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[83].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(m)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "configure the aircraft correctly to achieve best endurance performance and correctly calculate the revised operational endurance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[84].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[84].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[84].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[84].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[84].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[84].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "C4.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Refuel aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify the correct type of fuel to be used", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[86].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[86].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[86].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[86].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[86].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[86].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure aircraft is earthed prior to refuelling and defueling operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[87].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[87].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[87].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[87].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[87].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[87].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "correctly load and unload fuel", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[88].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[88].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[88].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[88].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[88].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[88].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure required fuel quantity is loaded", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[89].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[89].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[89].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[89].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[89].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[89].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure fuel caps are closed and secured after fuelling operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[90].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[90].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[90].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[90].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[90].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[90].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform fuel quality checks", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[91].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[91].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[91].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[91].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[91].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[91].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "C5", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Manage passengers and cargo", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "C5.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Manage passengers", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "supervise passenger safety", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[94].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[94].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[94].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[94].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[94].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[94].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "encourage passengers to participate in and contribute to the safe outcome of the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[95].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[95].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[95].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[95].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[95].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[95].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "conduct pre-flight passenger safety briefing", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[96].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[96].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[96].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[96].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[96].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[96].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure passengers are aware of, and avoid interference with, flight and systems controls", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[97].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[97].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[97].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[97].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[97].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[97].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure passengers are aware of, and comply with, the use of seat harnesses", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[98].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[98].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[98].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[98].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[98].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[98].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure passengers are aware of the use of escape hatches, exits and emergency equipment on board the aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[99].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[99].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[99].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[99].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[99].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[99].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "manage passenger safety in the event of abnormal or in-flight emergency situations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[100].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[100].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[100].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[100].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[100].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[100].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "C5.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Aid and assist passengers", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "establish and maintain clear communications with passengers", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[102].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[102].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[102].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[102].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[102].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[102].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "assist with passenger comfort both when airside and in flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[103].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[103].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[103].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[103].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[103].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[103].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "C5.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Manage cargo", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "manage loading, unloading and security of cargo during flight operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[105].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[105].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[105].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[105].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[105].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[105].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify dangerous goods and apply procedures to ensure safety and security", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[106].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[106].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[106].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[106].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[106].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[106].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "NTS1", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Non-technical skills 1", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: " NTS1.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Maintain effective lookout", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain traffic separation using a systematic visual scan technique at a rate determined by traffic density, visibility and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[109].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[109].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[109].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[109].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[109].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[109].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain radio listening watch and interpret transmissions to determine traffic location and intentions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[110].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[110].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[110].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[110].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[110].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[110].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform airspace-cleared procedure before commencing any manoeuvre", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[111].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[111].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[111].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[111].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[111].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[111].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: " NTS1.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Maintain situational awareness", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "monitor all aircraft systems using a systematic scan technique", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[113].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[113].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[113].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[113].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[113].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[113].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "collect information to facilitate ongoing system management", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[114].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[114].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[114].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[114].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[114].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[114].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "monitor flight environment for deviations from planned operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[115].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[115].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[115].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[115].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[115].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[115].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "collect flight environment information to update planned operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[116].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[116].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[116].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[116].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[116].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[116].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: " NTS1.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Maintain situational awareness", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify problems", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[118].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[118].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[118].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[118].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[118].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[118].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "analyse problems", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[119].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[119].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[119].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[119].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[119].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[119].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify solutions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[120].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[120].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[120].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[120].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[120].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[120].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "assess solutions and risks", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[121].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[121].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[121].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[121].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[121].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[121].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "decide on a course of action", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[122].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[122].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[122].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[122].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[122].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[122].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "communicate plans of action (if appropriate)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[123].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[123].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[123].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[123].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[123].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[123].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "allocate tasks for action (if appropriate)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[124].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[124].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[124].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[124].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[124].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[124].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "take actions to achieve optimum outcomes for the operation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[125].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[125].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[125].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[125].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[125].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[125].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "monitor progress against plan", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[126].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[126].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[126].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[126].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[126].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[126].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "re-evaluate plan to achieve optimum outcomes", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[127].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[127].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[127].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[127].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[127].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[127].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: " NTS1.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Set priorities and manage tasks", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "organise workload and priorities to ensure optimum outcome of the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[129].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[129].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[129].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[129].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[129].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[129].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "plan events and tasks to occur sequentially", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[130].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[130].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[130].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[130].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[130].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[130].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "anticipate events and tasks to ensure sufficient opportunity for completion", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[131].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[131].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[131].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[131].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[131].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[131].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "use technology to reduce workload and improve cognitive and manipulative activities", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[132].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[132].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[132].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[132].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[132].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[132].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: " NTS1.5", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Maintain effective communications and interpersonal relationships", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "establish and maintain effective and efficient communications and interpersonal relationships with all stakeholders to ensure the optimum outcome of the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[134].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[134].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[134].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[134].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[134].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[134].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "define and explain objectives to stakeholders", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[135].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[135].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[135].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[135].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[135].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[135].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "demonstrate a level of assertiveness that ensures the optimum completion of the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[136].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[136].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[136].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[136].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[136].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[136].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "NTS2", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Non-technical skills 2", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: " NTS2.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Recognise and manage threats", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify relevant environmental or operational threats that are likely to affect the safety of the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[139].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[139].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[139].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[139].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[139].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[139].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify when competing priorities and demands may represent a threat to the safety of the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[140].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[140].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[140].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[140].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[140].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[140].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "develop and implement countermeasures to manage threats", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[141].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[141].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[141].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[141].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[141].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[141].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "monitor and assess flight progress to ensure a safe outcome, or modify actions when a safe outcome is not assured", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[142].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[142].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[142].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[142].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[142].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[142].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: " NTS2.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Recognise and manage errors", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "apply checklists and standard operating procedures to prevent aircraft handling, procedural or communication errors", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[144].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[144].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[144].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[144].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[144].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[144].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify committed errors before safety is affected or the aircraft enters an undesired state", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[145].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[145].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[145].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[145].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[145].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[145].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "monitor the following to collect and analyse information to identify potential or actual errors:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(i) aircraft systems using a systematic scan technique", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[147].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[147].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[147].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[147].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[147].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[147].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(ii) the flight environment", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[148].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[148].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[148].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[148].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[148].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[148].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "(iii) other crew", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[149].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[149].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[149].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[149].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[149].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[149].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "implement countermeasures to prevent errors or take action in the time available to correct errors before the aircraft enters an undesired state ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[150].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[150].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[150].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[150].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[150].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[150].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: " NTS2.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Recognise and manage undesired aircraft state", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "recognise an undesired aircraft state", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[152].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[152].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[152].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[152].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[152].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[152].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "prioritise tasks to ensure an undesired aircraft state is managed effectively", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[153].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[153].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[153].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[153].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[153].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[153].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "apply corrective actions to recover an undesired aircraft state in a safe and timely manner", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[154].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[154].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[154].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[154].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[154].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[154].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "H1", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Control helicopter on the ground - stationary", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "H1.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Start engine and rotor", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is positioned with a view to safety and rotor clearance when starting engine and rotors", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[157].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[157].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[157].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[157].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[157].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[157].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "wind conditions are assessed for start", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[158].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[158].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[158].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[158].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[158].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[158].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform pre-start checklists actions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[159].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[159].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[159].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[159].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[159].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[159].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform engine start and rotor engagement", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[160].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[160].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[160].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[160].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[160].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[160].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "rotor disc position is controlled during start", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[161].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[161].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[161].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[161].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[161].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[161].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "engine is operated within limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[162].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[162].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[162].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[162].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[162].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[162].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "emergencies are managed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[163].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[163].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[163].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[163].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[163].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[163].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "H1.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Stop engine and rotor", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "wind conditions are assessed and appropriate allowances made", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[165].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[165].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[165].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[165].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[165].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[165].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is positioned with a view to safety and rotor clearance when stopping engine and rotors", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[166].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[166].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[166].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[166].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[166].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[166].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform engine shutdown and rotor stop", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[167].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[167].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[167].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[167].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[167].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[167].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "rotor disc position is controlled during shutdown", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[168].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[168].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[168].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[168].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[168].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[168].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "engine and transmission system indications are monitored and managed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[169].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[169].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[169].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[169].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[169].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[169].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "H1.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Control main rotor disc and anti-torque system", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain the main rotor disc attitude during all RPM opperations ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[171].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[171].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[171].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[171].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[171].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[171].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "set anti-torque pedal position to compensate for main rotor torque", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[172].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[172].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[172].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[172].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[172].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[172].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "rotor disc attitude and RRPM are managed while performing other tasks or actions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[173].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[173].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[173].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[173].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[173].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[173].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
              ],
              [
                {value: "H2", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Control helicopter in hovering flight", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "H2.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Lift off to hover and perform hover checks", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "aircraft performance is calculated for the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[176].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[176].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[176].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[176].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[176].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[176].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "pre-take-off checks are performed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[177].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[177].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[177].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[177].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[177].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[177].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "flight controls are set to prepare for lift-off to the hover", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[178].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[178].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[178].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[178].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[178].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[178].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "flight and power controls are used to lift helicopter off the surface to a stable hover at the appropriate height for the helicopter while controlling heading", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[179].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[179].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[179].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[179].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[179].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[179].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "wind effect is anticipated and accounted for with appropriate control inputs to maintain position over nominated hover point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[180].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[180].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[180].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[180].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[180].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[180].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "awareness of adverse effects of rotor downwash on surrounding aircraft, people, objects and environment is demonstrated", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[181].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[181].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[181].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[181].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[181].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[181].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "perform hover checks", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[182].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[182].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[182].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[182].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[182].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[182].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "flight control functions, centre of gravity and hover power requirements are checked", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[183].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[183].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[183].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[183].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[183].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[183].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "at a constant and safe hover height, commence, maintain and stop a hover taxi manoeuvre while maintaining power and RRPM within the limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[184].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[184].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[184].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[184].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[184].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[184].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "coordinated corrective action is used to counter the effects of wind", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[185].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[185].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[185].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[185].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[185].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[185].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "implications of environmental conditions are assessed and appropriate compensation is made", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[186].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[186].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[186].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[186].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[186].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[186].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(l)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is maintained clear of obstructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[187].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[187].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[187].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[187].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[187].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[187].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(m)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[188].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[188].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[188].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[188].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[188].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[188].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "H2.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Hover helicopter in crosswind and tailwind", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is maintained in hovering flight remaining over a nominated hover point at a nominated height and heading in cross and tail winds", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[190].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[190].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[190].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[190].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[190].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[190].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "coordinated corrective action is used to maintain a constant rate of turn and counter the effects of wind", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[191].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[191].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[191].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[191].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[191].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[191].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "H2.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Perform turns around the mast", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is turned around the mast while maintaining a constant height  at a specified rate of turn", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[193].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[193].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[193].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[193].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[193].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[193].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "turn is completed on a nominated heading", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[194].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[194].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[194].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[194].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[194].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[194].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "controlled corrective action is used to control the effects of wind", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[195].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[195].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[195].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[195].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[195].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[195].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is maintained clear of obstructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[196].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[196].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[196].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[196].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[196].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[196].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility, obstructions and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[197].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[197].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[197].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[197].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[197].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[197].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "RPM is managed within limits during the turn", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[198].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[198].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[198].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[198].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[198].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[198].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "H2.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Perform turns around nose and tail", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is turned around a nominated point on or forward of the nose while maintaining a constant height and specified rate of movement around that point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[200].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[200].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[200].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[200].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[200].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[200].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is turned around a nominated point on or aft of the tail while maintaining a constant height and specified rate of movement around that point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[201].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[201].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[201].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[201].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[201].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[201].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "controlled corrective action is taken to counter the effects of wind", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[202].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[202].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[202].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[202].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[202].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[202].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is maintained clear of obstructions during turning manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[203].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[203].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[203].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[203].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[203].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[203].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility, obstructions and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[204].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[204].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[204].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[204].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[204].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[204].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "turns in a specified direction are commenced and stopped at a specified heading", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[205].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[205].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[205].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[205].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[205].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[205].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "RPM is managed within limits during the turn", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[206].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[206].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[206].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[206].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[206].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[206].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain ground track at a constant distance from the nominated point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[207].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[207].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[207].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[207].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[207].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[207].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "anti-torque pedals are used to ensure helicopter nose or tail is pointed at the nominated turning point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[208].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[208].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[208].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[208].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[208].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[208].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
              ],
              [
                {value: "H2.5", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Perform sideways and rearwards flight", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is transitioned from static hover to sideways and rearward flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[210].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[210].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[210].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[210].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[210].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[210].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "lookout is maintained in direction of flight using a systematic scan technique at a rate determined by traffic density, visibility, obstructions and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[211].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[211].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[211].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[211].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[211].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[211].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "rearward movement is only conducted after visually checking behind helicopter, and height is adjusted as required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[212].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[212].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[212].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[212].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[212].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[212].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter directional control is maintained and manoeuvred clear of obstructions during sidewards and backwards flight manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[213].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[213].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[213].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[213].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[213].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[213].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "RPM is managed within limits during the turn", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[214].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[214].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[214].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[214].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[214].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[214].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "maintain rate of movement of helicopter at a safe speed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[215].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[215].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[215].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[215].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[215].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[215].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "sideways and rearward flight is terminated over a nominated hover point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[216].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[216].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[216].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[216].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[216].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                {value: allConds[216].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
              ],
              [
                {value: "H2.6", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Land from the hover", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "complete pre-landing checks (if applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[218].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[218].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[218].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[218].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[218].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[218].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is lowered on to a nominated point from hovering flight using a controlled rate of descent, without adverse longitudinal, lateral, yawing or rolling movements", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[219].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[219].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[219].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[219].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[219].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[219].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "ensure helicopter is stable on its landing gear prior to fully lowering collective", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[220].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[220].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[220].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[220].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[220].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[220].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "after-landing checks are performed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[221].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[221].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[221].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[221].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[221].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[221].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],

              [
                {value: "H2.7", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Manage a mishandled landing", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify when an adverse landing situation is developing", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[223].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[223].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[223].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[223].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[223].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[223].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "appropriate action is taken to discontinue the landing and return to a safe hover", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[224].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[224].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[224].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[224].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[224].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[224].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],

              [
                {value: "H2.8", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Manage a mishandled lift off", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "identify when an adverse lift off situation is developing", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[226].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[226].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[226].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[226].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[226].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[226].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "appropriate action is taken to discontinue the landing and return to a safe hover", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[227].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[227].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[227].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[227].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[227].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[227].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "H3", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "Taxi helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
              ],
              [
                {value: "H3.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Ground taxi helicopter (Reserved)", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
              {value: "H3.1(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "(Perform ground taxiing manoeuvres (Reserved)?)", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            ],
            [
            {value: "H3.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "Perform air taxiing manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
          ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is manoeuvred over the ground on a prescribed track at constant height associated with ground effect and speed adjusted to suit helicopter type, surface conditions, congestion, maintenance of control and to avoid collision with obstacles or other aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[232].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[232].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[232].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[232].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[232].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[232].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
                  [
                    {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "as far as operational limitations allow, the landing gear is aligned with the direction of travel", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[233].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[233].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[233].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[233].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[233].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[233].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                  ],
                      [
                        {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                        {value: "awareness of adverse effects of rotor downwash on surrounding aircraft, people, objects and environment is demonstrated", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                        {value: allConds[234].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                        {value: allConds[234].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                        {value: allConds[234].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                        {value: allConds[234].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                        {value: allConds[234].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                        {value: allConds[234].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                      ],
                          [
                            {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                            {value: "RPM is managed within normal operating limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                            {value: allConds[235].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                            {value: allConds[235].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                            {value: allConds[235].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                            {value: allConds[235].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                            {value: allConds[235].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                            {value: allConds[235].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                          ],
                  [
                    {value: "H3.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "Perform air transiting manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                  ],
                  [
                    {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "helicopter is manoeuvred, while allowing for prevailing conditions, over a prescribed track within the aerodrome boundaries that is clear of obstacles at a height not above 100ft AGL at airspeeds greater than speeds used for air taxiing", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[237].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[237].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[237].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[237].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[237].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[237].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "throughout air transit manoeuvres, helicopter performance is considered", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[238].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[238].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[238].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[238].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[238].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[238].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "awareness of adverse effects of rotor downwash on surrounding aircraft, people, objects and environment is demonstrated", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[239].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[239].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[239].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[239].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[239].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[239].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "manipulate instruments, switches or devices, when safe to do so, including when the release of the collective pitch lever is required, while maintaining height, heading, speed, and attitude and not exceeding RPM or power limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[240].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[240].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[240].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[240].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[240].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[240].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "adjust air transit ground speed to suit helicopter type, traffic conditions, congestion, and maintenance of control and to avoid collision with obstacles or other aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[241].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[241].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[241].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[241].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[241].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[241].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[242].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[242].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[242].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[242].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[242].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[242].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: "appropriate risk management is applied during air transit manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                    {value: allConds[243].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[243].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                    {value: allConds[243].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[243].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                    {value: allConds[243].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                    {value: allConds[243].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
                  ],
                  [
                    {value: "H4", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "Take-off helicopter and approach to hover", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
                  ],[
                {value: "H4.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "Carry out pre-take-off checks", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
                {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
              ],
              [
                {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "pre-take-off checks and safety briefings are completed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[246].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[246].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[246].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[246].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[246].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[246].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "select a safe take-off path", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[247].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[247].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[247].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[247].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[247].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[247].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "helicopter is lined up in the take-off direction and positioned at the optimum position within the helicopter landing site (HLS)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[248].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[248].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[248].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[248].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[248].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[248].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],
              [
                {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: "line-up checks are performed in accordance with approved checklist, when appropriate", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[249].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[249].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
                {value: allConds[249].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[249].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
                {value: allConds[249].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
                {value: allConds[249].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
              ],[
            {value: "H4.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "Take-off helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
            {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
          ],
          [
            {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: "air traffic control clearances are obtained and compliance is maintained with clearance conditions and requirements (within controlled airspace)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[251].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[251].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[251].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[251].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[251].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
            {value: allConds[251].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
          ],
          [
            {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: "helicopter is transitioned from a stationary position in a specified direction passing through translational lift, remaining clear of obstructions and establishing and maintaining a recommended or nominated climb speed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[252].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[252].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[252].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[252].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[252].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
            {value: allConds[252].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
          ],
          [
            {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: "helicopter flight path is controlled using appropriate control techniques including awareness of helicopter performance considerations, aircraft balance and flight control trim (if applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[253].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[253].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[253].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[253].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[253].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
            {value: allConds[253].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
          ],
          [
            {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: "power and attitude are managed for the flight path, departure profile and Indicated Airspeed (IAS) being flown", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[254].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[254].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[254].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[254].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[254].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
            {value: allConds[254].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
          ],
          [
            {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: "local and published noise abatement requirements and curfews are observed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[255].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[255].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[255].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[255].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[255].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
            {value: allConds[255].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
          ],
          [
            {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: "situational awareness of circuit traffic is maintained and conflict is avoided", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[256].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[256].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
            {value: allConds[256].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[256].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
            {value: allConds[256].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
            {value: allConds[256].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
          ],[
        {value: "H4.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "Approach to hover", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
        {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "a suitable termination point is selected", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[258].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[258].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[258].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[258].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[258].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[258].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "pre-landing checks are completed in accordance with approved checklist", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[259].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[259].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[259].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[259].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[259].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[259].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "appropriate approach angle and track is intercepted and approach manoeuvres are conducted in accordance with  air traffic control instructions (when in controlled airspace)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[260].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[260].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[260].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[260].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[260].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[260].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "helicopter is descended to the hover at the selected termination point at a reducing  rate of closure and along a specified track on an approach angle appropriate to the helicopter type and conditions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[261].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[261].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[261].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[261].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[261].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[261].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "align the landing gear to the planned approach direction", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[262].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[262].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[262].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[262].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[262].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[262].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "recognise and control loss of translational lift", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[263].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[263].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[263].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[263].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[263].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[263].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "RPM is managed within normal operational limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[264].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[264].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[264].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[264].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[264].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[264].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
        {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: "approach is terminated at the hover over the termination point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[265].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[265].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
        {value: allConds[265].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[265].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
        {value: allConds[265].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
        {value: allConds[265].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],[
    {value: "H4.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "Perform go-around procedure", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  ],
  [
    {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "critical situations are recognised and timely decisions are made to go-around in circumstances that require discontinuing a circuit or approach", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[267].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[267].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[267].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[267].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[267].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[267].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "initiate the go-around ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[268].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[268].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[268].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[268].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[268].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[268].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "set power and attitude to initiate safe climb at appropriate IAS from any position in the circuit", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[269].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[269].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[269].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[269].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[269].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[269].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "obstructions and traffic are appropriately avoided during the climb following a decision to go-around", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[270].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[270].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[270].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[270].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[270].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[270].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[271].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[271].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[271].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[271].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[271].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[271].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "situational awareness of circuit traffic is maintained throughout go-round procedure", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[272].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[272].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[272].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[272].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[272].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[272].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "after take-off checks are completed in accordance with approved checklist (as required)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[273].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[273].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[273].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[273].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[273].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[273].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "local and published noise abatement requirements and curfews are observed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[274].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[274].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[274].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[274].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
    {value: allConds[274].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[274].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
  ],
  [
    {value: "H5", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "Control helicopter in normal flight", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  ],[
{value: "H5.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Climb helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "from straight and level flight, adjust attitude and power to achieve a climb  at normal, best rate (VY), best angle (VX) and cruise climb flight configurations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[277].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[277].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[277].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[277].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[277].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[277].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "appropriate altimeter settings are set", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[278].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[278].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[278].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[278].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[278].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[278].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is maintained in balanced flight during adjustment to  power", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[279].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[279].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[279].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[279].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[279].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[279].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "power is maintained as altitude increases", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[280].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[280].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[280].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[280].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[280].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[280].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is levelled off from climb at nominated altitude", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[281].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[281].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[281].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[281].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[281].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[281].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "lookout is maintained during climb using a systematic scan technique at a rate determined by traffic density, visibility and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[282].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[282].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[282].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[282].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[282].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[282].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "situational awareness is maintained", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[283].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[283].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[283].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[283].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[283].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[283].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
],[

      {value: "H5.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "Maintain straight and level flight", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
      {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "adjust attitude and power  to achieve a constant height, heading and speed while remaining in balanced flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[285].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[285].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[285].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[285].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[285].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[285].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[286].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[286].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[286].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[286].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[286].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[286].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "natural horizon is used as primary attitude reference", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[287].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[287].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[287].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[287].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[287].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[287].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "nominated altitude is maintained", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[288].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[288].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[288].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[288].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[288].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[288].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],[
      {value: "H5.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "Descend helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
      {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "adjust attitude and power  to enter and maintain a descent from straight and level", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[290].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[290].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[290].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[290].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[290].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[290].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "helicopter is levelled from a descent at a nominated altitude", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[291].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[291].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[291].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[291].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[291].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[291].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[292].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[292].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[292].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[292].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[292].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[292].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "clearance ahead and below is maintained", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[293].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[293].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[293].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[293].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[293].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[293].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "air traffic control altitude restrictions are observed (as required)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[294].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[294].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[294].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[294].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[294].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[294].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "helicopter does not exceed aircraft flight manual limits during descent", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[295].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[295].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[295].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[295].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[295].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[295].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "set appropriate altimeter settings", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[296].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[296].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[296].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[296].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[296].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[296].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "situational awareness is maintained at all times during helicopter descent", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[297].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[297].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[297].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[297].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[297].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[297].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],[
      {value: "H5.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "Turn helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
      {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "attitude and power are adjusted to enter and maintain turns at varying rates from level, climbing and descending flight to achieve nominated tracks, during:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "(i) level turns", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[300].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[300].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[300].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[300].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[300].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[300].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "(ii) climbing turns with 20° bank angle", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[301].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[301].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[301].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[301].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[301].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[301].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "(iii) powered descending  turn with 30° bank angle", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[302].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[302].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[302].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[302].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[302].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[302].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "helicopter is rolled out from the turn to achieve a nominated heading or geographical feature", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[303].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[303].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[303].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[303].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[303].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[303].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "ensure helicopter is balanced and trimmed (if applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[304].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[304].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[304].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[304].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[304].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[304].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "lookout is maintained in direction of turn and above or below using a systematic scan technique at a rate determined by traffic density, visibility and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[305].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[305].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[305].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[305].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[305].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[305].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],
      [
      {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "engine operating limits are not exceeded", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[306].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[306].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[306].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[306].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[306].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[306].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      ],[
      {value: "H5.5", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "Control helicopter at any speed  ", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
      {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "adjust attitude and power to accelerate or decelerate the helicopter from any specified airspeed within the flight envelope to any other specified airspeed within the flight envelope while maintaining balanced flight and subsequently maintaining the new specified airspeed.", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[308].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[308].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[308].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[308].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[308].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[308].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "height awareness is maintained at all times and appropriate adjustments are made as required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[309].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[309].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[309].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[309].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[309].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[309].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "wind conditions are monitored and appropriate allowance is made", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[310].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[310].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[310].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[310].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[310].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[310].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "helicopter is suitably controlled to ensure that it is operated within aircraft flight manual limits", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[311].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[311].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[311].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[311].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
      {value: allConds[311].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[311].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],[
      {value: "H5.6", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "Perform circuits and approaches", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
      {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "circuits are joined and conducted in accordance with AIP and or local procedures at normal and low altitude appropriate to the helicopter type", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[313].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[313].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[313].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[313].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[313].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[313].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "due allowance is made for the wind ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[314].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[314].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[314].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[314].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[314].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[314].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "all appropriate checklist items are completed when performing circuits and approaches", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[315].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[315].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[315].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[315].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[315].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[315].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "radiotelephone procedures are followed during circuit operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[316].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[316].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[316].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[316].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[316].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[316].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "the approach path applicable to the helicopter type is intercepted and maintained whilst remaining clear of other traffic", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[317].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[317].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[317].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[317].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[317].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[317].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "helicopter approach is conducted to establish hover or conduct a landing at the nominated termination point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[318].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[318].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[318].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[318].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[318].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[318].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "lookout is maintained during circuits and approaches using a systematic scan technique at a rate determined by traffic density, visibility and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[319].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[319].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[319].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[319].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[319].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[319].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "conflicting traffic is recognised and appropriate responses are made", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[320].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[320].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[320].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[320].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[320].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[320].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "right of way rules are applied and compliance with the rules is maintained", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[321].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[321].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[321].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[321].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[321].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[321].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "weather conditions are monitored and appropriate responses are made", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[322].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[322].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[322].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[322].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[322].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[322].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],
      [
      {value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "fuel status is monitored and appropriate responses are made", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[323].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[323].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[323].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[323].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[323].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[323].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      ],[
      {value: "H5.7", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "Comply with airspace requirements", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
      ],
      [
      {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "suitable aeronautical charts are interpreted and used to maintain airspace compliance requirements", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[325].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[325].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[325].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[325].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[325].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      {value: allConds[325].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      ],
      [
      {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "circuit departure is performed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[326].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[326].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[326].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[326].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[326].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      {value: allConds[326].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      ],
      [
      {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "helicopter is maintained within a specified area and/or track while complying with air traffic requirements, controlled or restricted airspace conditions or limitations and reacting to factors that affect the safe progress of a flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[327].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[327].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[327].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[327].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[327].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      {value: allConds[327].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      ],
      [
      {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "orientation is maintained to geographical features with the aid of suitable charts and maps", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[328].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[328].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[328].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[328].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[328].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      {value: allConds[328].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      ],
      [
      {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: "circuit join is conducted", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[329].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[329].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
      {value: allConds[329].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[329].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
      {value: allConds[329].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      {value: allConds[329].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
      ],
      [
        {value: "H6", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "Execute advanced helicopter manoeuvres and procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
        {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
      ],[
    {value: "H6.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "Turn helicopter steeply", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
    ],
    [
    {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[332].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[332].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[332].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[332].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[332].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[332].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    ],
    [
    {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "level turns of 45° bank angle is achieved without altitude change to nominated heading", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[333].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[333].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[333].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[333].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[333].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[333].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    ],
    [
    {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "descending turns of 45° bank angle is achieved to a nominated heading and minimum height loss of 500ft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[334].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[334].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[334].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[334].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[334].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[334].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    ],
    [
    {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "maintain balance in turns", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[335].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[335].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[335].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[335].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[335].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[335].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    ],
    [
    {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: "helicopter operating limits are not exceeded", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[336].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[336].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
    {value: allConds[336].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[336].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
    {value: allConds[336].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    {value: allConds[336].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
    ],[
  {value: "H6.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "Perform autorotative flight", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
  ],
  [
  {value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "an appropriate action plan including task priorities is formulated that ensures the safe completion of autorotative manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[338].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[338].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[338].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[338].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[338].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[338].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "autorotative flight is entered and maintained at a nominated speed and heading in balanced flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[339].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[339].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[339].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[339].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[339].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[339].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "autorotative flight is performed at the optimum range and minimum descent rate speeds", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[340].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[340].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[340].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[340].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[340].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[340].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "heading is altered through 180° and 360° with the helicopter in balanced flight at a nominated speed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[341].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[341].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[341].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[341].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[341].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[341].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "helicopter is recovered  to normal flight from autorotative flight using power to a climb at nominated heading and speed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[342].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[342].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[342].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[342].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[342].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[342].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "helicopter is recovered to a power termination into wind, using appropriate  control inputs, the helicopter is flared at the appropriate height to reduce groundspeed and reduce rate of descent, control RRPM in limits, the helicopter is levelled and power is used to reduce rate of descent and establish a hover or hover taxi, control yaw throughout", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[343].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[343].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[343].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[343].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[343].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[343].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "helicopter autorotative landing to touchdown is performed into wind, using appropriate control inputs, the helicopter is flared at the appropriate height to reduce groundspeed and reduce rate of descent, the helicopter is levelled prior to touchdown and collective is used to reduce rate of descent and cushion the landing, control yaw throughout. The landing is completed in accordance with the procedures in the aircraft flight manual", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[344].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[344].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[344].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[344].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[344].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[344].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  ],
  [
  {value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility and terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[345].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[345].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[345].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[345].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[345].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[345].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],
  [
  {value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: "situational awareness is maintained at all times during autorotative flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[346].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[346].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
  {value: allConds[346].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[346].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
  {value: allConds[346].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  {value: allConds[346].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
  ],[
{value: "H6.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Land on, and lift off sloping ground", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "stakeholders are briefed to ensure safe operations in the vicinity of the helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[348].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[348].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[348].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[348].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[348].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[348].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "surface and slope conditions are assessed to be suitable and in limits for the helicopter type", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[349].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[349].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[349].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[349].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[349].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[349].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is landed from the hover onto sloping ground using the appropriate slope landing techniques relevant to the helicopter type", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[350].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[350].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[350].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[350].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[350].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[350].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "ensure security of the helicopter on the sloping ground surface prior to reducing rotor RPM or engine shutdown", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[351].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[351].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[351].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[351].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[351].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[351].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is lifted off from sloping ground to a hover using the appropriate slope landing techniques relevant to the helicopter type", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[352].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[352].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[352].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[352].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[352].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[352].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "control inputs and adjustments during landing on and lifting off are made in response to wind, surface and applicable aircraft limitations, using appropriate slope landing techniques and helicopter handling procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[353].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[353].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[353].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[353].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[353].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[353].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "any abnormal situations are recognised and appropriate controlled corrective action is implemented", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[354].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[354].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[354].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[354].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[354].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[354].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "lookout is maintained using a systematic scan technique at a rate determined by traffic density, visibility or terrain", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[355].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[355].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[355].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[355].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[355].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[355].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "situational awareness is maintained at all times during lift-offs and landings on sloping ground", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[356].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[356].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[356].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[356].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[356].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[356].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "H6.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Land, manoeuvre and take off and in a Confined Area (CA)", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "confined area is assessed against pilots own capabilities and helicopter performance, complete power assessment, a decision is made to operate in the area and a reconnaissance is conducted", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[358].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[358].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[358].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[358].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[358].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[358].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "appropriate approach and departure path is identified,  maintain approach path to nominated termination point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[359].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[359].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[359].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[359].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[359].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[359].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is landed safely in a confined area using appropriate landing techniques", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[360].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[360].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[360].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[360].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[360].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[360].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is manoeuvred in a confined area while remaining clear of obstructions, and within helicopter operating limitations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[361].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[361].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[361].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[361].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[361].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[361].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "appropriate allowance is made for the effects of wind during manoeuvres in a confined area", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[362].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[362].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[362].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[362].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[362].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[362].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter departure plan and contingency procedures are briefed prior to departure (must include power assessment and nominate the abort point)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[363].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[363].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[363].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[363].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[363].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[363].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is safely taken off from a confined area using appropriate departure techniques", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[364].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[364].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[364].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[364].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[364].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[364].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "situational awareness is maintained at all times during manoeuvres in a confined area", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[365].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[365].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[365].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[365].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[365].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[365].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "H6.5", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Execute limited power take-off, approach and landing", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "need for limited power manoeuvres is identified", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[367].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[367].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[367].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[367].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[367].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[367].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter performance is calculated and power requirements confirmed prior to the commencement of limited power operations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[368].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[368].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[368].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[368].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[368].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[368].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "a decision to conduct limited power manoeuvres is implemented and an appropriate action plan is formulated to conduct limited power operations, pilot ability and limitations are considered", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[369].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[369].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[369].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[369].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[369].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[369].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "an appropriate area for a safe take-off and landing suitable for the limited power available is selected", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[370].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[370].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[370].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[370].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[370].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[370].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "limited power take-off is performed, applying maximum or nominated power while maintaining optimum RRPM", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[371].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[371].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[371].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[371].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[371].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[371].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "limited power approach and landing is performed, whilst controlling airspeed and optimum RRPM appropriate to power available and landing environment", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[372].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[372].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[372].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[372].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[372].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[372].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "situational awareness is maintained at all times during limited power manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[373].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[373].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[373].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[373].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[373].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[373].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "appropriate allowance is made for the effects of wind during limited power manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[374].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[374].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[374].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[374].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[374].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[374].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "H7", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Manage abnormal and emergency helicopter flight situations", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "H7.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Manage a forced landing from level flight, after take-off and on approach", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "emergency situation requiring a forced landing is correctly identified", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[377].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[377].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[377].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[377].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[377].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[377].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "autorotative flight is entered and established at nominated speed and heading in balanced flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[378].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[378].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[378].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[378].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[378].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[378].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "immediate actions are performed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[379].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[379].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[379].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[379].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[379].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[379].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "control RRPM within limitations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[380].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[380].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[380].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[380].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[380].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[380].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "a landing area within autorotative distance is selected and an appropriate action plan is formulated to ensure safety of the helicopter", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[381].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[381].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[381].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[381].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[381].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[381].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "emergency procedures are implemented and task priorities are allocated to all actions to ensure aircraft, flight crew and passenger safety", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[382].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[382].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[382].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[382].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[382].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[382].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "emergency radio message of intentions are transmitted", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[383].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[383].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[383].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[383].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[383].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[383].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "helicopter is aligned with prevailing wind direction when possible with as slow as practical ground speed while maintaining control of the helicopter; situational awareness is maintained at all times during forced landing manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[384].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[384].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[384].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[384].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[384].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[384].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],[
{value: "H7.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Manage an engine failure at the hover or during taxi", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "hover heights and taxi surfaces are selected to maximise options in the event of an engine failure", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[386].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[386].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[386].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[386].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[386].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[386].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "emergency situation involving an engine failure is correctly identified", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[387].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[387].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[387].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[387].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[387].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[387].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "immediate actions are performed in accordance with the aircraft flight manual", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[388].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[388].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[388].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[388].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[388].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[388].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform a controlled touchdown", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[389].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[389].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[389].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[389].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[389].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[389].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
],[
{value: "H7.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Manage a tail rotor malfunction in flight and at the hover", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "hover heights and surfaces are selected to maximise options in the event of tail rotor malfunction", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[391].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[391].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[391].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[391].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[391].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[391].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "tail rotor malfunction is correctly identified", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[392].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[392].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[392].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[392].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[392].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[392].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "immediate actions in the event of a tail rotor malfunction during flight are performed in accordance with the aircraft flight manual", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[393].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[393].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[393].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[393].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[393].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[393].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "control of the helicopter is established and a suitable landing area is selected", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[394].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[394].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[394].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[394].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[394].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[394].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "all emergency checks are performed and a plan is formulated to achieve an approach and landing", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[395].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[395].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[395].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[395].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[395].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[395].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "emergency radio messages of intentions are transmitted", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[396].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[396].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[396].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[396].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[396].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[396].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "a controlled emergency landing with a malfunctioning tail rotor is performed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[397].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[397].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[397].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[397].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[397].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[397].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "situational awareness is maintained at all times during tail rotor malfunction handling manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[398].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[398].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[398].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[398].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[398].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[398].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "H7.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Manage a jammed flight control system", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "jammed or malfunctioning flight control system malfunction is correctly identified", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[400].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[400].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[400].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[400].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[400].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[400].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "control of the helicopter is established and a suitable landing area is selected", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[401].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[401].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[401].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[401].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[401].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[401].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "all emergency checks are performed and a plan is formulated to achieve an approach and landing", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[402].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[402].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[402].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[402].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[402].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[402].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "emergency radio messages of intentions are transmitted;", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[403].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[403].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[403].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[403].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[403].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[403].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "a controlled landing with a jammed flight control is performed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[404].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[404].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[404].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[404].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[404].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[404].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "situational awareness is maintained at all times during jammed flight control handling manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[405].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[405].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[405].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[405].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[405].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[405].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "H7.5", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Manage a malfunction of a helicopter operating system ", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "abnormal situations involving a helicopter system malfunction are correctly identified and confirmed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[407].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[407].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[407].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[407].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[407].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[407].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "appropriate emergency procedures are conducted in accordance with the aircraft flight manual while maintaining control of the helicopter flight path", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[408].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[408].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[408].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[408].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[408].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[408].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "system malfunctions are managed whilst situational awareness is maintained at all times during a system malfunction", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[409].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[409].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[409].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[409].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[409].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[409].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "H7.6", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Manage upset recovery (this element is included to cover situations where the pilot has to recover the aircraft to a safe and stable flight condition in visual conditions) ", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply correct techniques for upset recovery in various configurations as follows:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) recognise upset condition", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[412].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[412].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[412].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[412].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[412].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[412].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) maintain references by visual cues", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[413].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[413].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[413].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[413].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[413].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[413].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) recover to level flight condition; configure aircraft appropriately", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[414].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[414].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[414].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[414].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[414].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[414].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "IFF", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Full instrument panel manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "IFF.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Determine and monitor the serviceability of flight instruments and instrument power sources", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine serviceability of flight and navigational instruments", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[417].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[417].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[417].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[417].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[417].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[417].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform functional checks of flight and navigational instruments where applicable prior to take-off", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[418].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[418].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[418].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[418].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[418].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[418].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "monitor flight instrument and instrument power sources and react to any warnings, unserviceability or erroneous indications", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[419].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[419].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[419].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[419].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[419].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[419].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "IFF.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform manoeuvres using full instrument panel", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "interpret flight instrument indications and apply procedures and techniques to achieve and maintain a specified flight path using the aircraft's full instrument panel", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[421].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[421].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[421].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[421].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[421].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[421].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "set and maintain power and attitude by reference to the full instrument panel to achieve the following:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) straight and level performance during normal cruise within the flight tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[423].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[423].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[423].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[423].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[423].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[423].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) nominated climb performance within the flight tolerance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[424].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[424].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[424].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[424].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[424].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[424].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) descent performance within the flight tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[425].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[425].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[425].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[425].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[425].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[425].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "set and maintain power and attitude by reference to the full instrument panel to establish a rate 1 turn onto a nominated heading within the flight tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[426].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[426].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[426].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[426].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[426].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[426].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "IFF.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Recover from upset situations and unusual attitudes ", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "correctly identify upset situations and unusual attitudes under simulated IMC", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[428].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[428].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[428].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[428].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[428].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[428].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recover to controlled flight from upset situations and unusual attitudes under simulated IMC from any combination of the following aircraft states:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) high and low-nose attitudes ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[430].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[430].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[430].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[430].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[430].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[430].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) varying angles of bank ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[431].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[431].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[431].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[431].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[431].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[431].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) various power settings ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[432].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[432].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[432].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[432].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[432].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[432].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iv) various aircraft configurations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[433].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[433].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[433].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[433].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[433].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[433].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(v) unbalanced flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[434].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[434].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[434].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[434].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[434].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[434].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "IFL", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Limited instrument panel manoeuvres", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "IFL.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Recognise failure of attitude indicator and stabilised heading indicator", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "monitor flight instruments and instrument power sources and recognise warning indicators or erroneous instrument indications", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[437].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[437].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[437].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[437].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[437].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[437].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "transition from a full instrument panel to a limited instrument panel", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[438].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[438].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[438].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[438].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[438].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[438].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "IFL.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform manoeuvres - limited panel", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "interpret and respond appropriately to instrument indications", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[440].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[440].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[440].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[440].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[440].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[440].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply power and attitude settings to achieve straight and level performance during:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) normal cruise", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[442].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[442].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[442].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[442].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[442].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[442].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) in a helicopter - at minimum power for level flight speed", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[443].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[443].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[443].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[443].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[443].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[443].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply power and attitude settings to achieve:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i)  nominated climb performance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[445].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[445].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[445].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[445].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[445].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[445].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii)  nominated descent performance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[446].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[446].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[446].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[446].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[446].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[446].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii)  during climb, descent and straight and level flight, rate 1 turns onto a nominated heading", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[447].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[447].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[447].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[447].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[447].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[447].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "balance aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[448].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[448].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[448].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[448].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[448].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[448].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "establish level flight at a nominated altitude, from a climb or descent during straight or turning flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[449].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[449].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[449].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[449].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[449].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[449].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "IFL.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Recover from upset situations and unusual attitudes - limited panel", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "correctly identify upset situations and unusual attitudes under simulated IMC", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[451].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[451].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[451].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[451].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[451].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[451].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recover to stabilised straight and level flight using approved techniques  from upset situations and unusual attitudes under simulated IMC from any combination of the following aircraft states:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) high and low-nose attitudes ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[453].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[453].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[453].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[453].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[453].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[453].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) varying angles of bank ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[454].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[454].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[454].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[454].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[454].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[454].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) various power settings ", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[455].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[455].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[455].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[455].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[455].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[455].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iv) various aircraft configurations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[456].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[456].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[456].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[456].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[456].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[456].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(v) unbalanced flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[457].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[457].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[457].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[457].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[457].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[457].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "IFL.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Re-establish visual flight", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "transition from visual flight conditions to instrument flight conditions while maintaining control of the aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[459].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[459].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[459].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[459].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[459].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[459].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform a manoeuvre to re-establish visual flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[460].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[460].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[460].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[460].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[460].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[460].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "implement a plan that ensures the flight continues in VMC", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[461].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[461].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[461].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[461].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[461].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[461].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "NAV", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Navigate Aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "NAV.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Prepare documents and flight plan", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "select and prepare appropriate navigation charts for the intended flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[464].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[464].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[464].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[464].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[464].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[464].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "select a suitable route and altitude considering weather, terrain, airspace, NOTAMs and alternate landing areas", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[465].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[465].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[465].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[465].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[465].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[465].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "obtain and interpret meteorological forecasts, NOTAMs and operational information applicable to the planned flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[466].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[466].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[466].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[466].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[466].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[466].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine whether the planned flight can be conducted under the applicable flight rules and taking account of the beginning and end of daylight times", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[467].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[467].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[467].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[467].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[467].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[467].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "calculate and document critical point (CP) and point of no return (PNR) locations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[468].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[468].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[468].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[468].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[468].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[468].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "complete a flight plan to the planned destination and alternates", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[469].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[469].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[469].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[469].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[469].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[469].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "lodge suitable flight notification for search and rescue (SAR) purposes", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[470].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[470].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[470].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[470].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[470].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[470].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Comply with airspace procedures while navigating", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify airspace restrictions and dimensions applicable to the flight", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[472].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[472].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[472].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[472].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[472].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[472].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "obtain and comply with air traffic clearances, if applicable;", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[473].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[473].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[473].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[473].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[473].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[473].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "establish planned track on departure within 5 nm of airfield or apply alternative procedure if required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[474].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[474].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[474].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[474].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[474].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[474].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Conduct departure procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "organise cockpit to ensure charts, documentation and navigational calculator are accessible from the control seat", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[476].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[476].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[476].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[476].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[476].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[476].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "comply with all departure procedures, clearances and noise abatement requirements", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[477].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[477].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[477].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[477].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[477].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[477].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "establish planned track on departure within 5 nm of airfield or apply alternative procedure if required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[478].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[478].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[478].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[478].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[478].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[478].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "calculate estimated time of arrival (ETA) for first waypoint", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[479].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[479].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[479].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[479].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[479].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[479].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Navigate aircraft enroute", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain a navigation cycle that ensures accurate tracking, and apply track correctional techniques to re-establish track prior to waypoint or destination", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[481].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[481].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[481].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[481].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[481].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[481].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain heading to achieve a nominated track", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[482].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[482].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[482].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[482].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[482].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[482].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain and revise ETAs (±2 minutes) for waypoint or destination", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[483].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[483].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[483].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[483].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[483].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[483].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain track in accordance with published flight path tolerances in controlled airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[484].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[484].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[484].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[484].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[484].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[484].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "navigate using accepted map-reading techniques", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[485].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[485].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[485].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[485].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[485].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[485].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain navigation and fuel log to monitor tracking, ETAs and fuel status", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[486].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[486].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[486].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[486].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[486].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[486].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "use appropriate techniques to obtain a positive fix at suitable interval;", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[487].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[487].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[487].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[487].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[487].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[487].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain awareness of route, en route terrain, en route and destination weather, and react appropriately to changing weather conditions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[488].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[488].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[488].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[488].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[488].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[488].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform pre-descent and turning point checks", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[489].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[489].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[489].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[489].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[489].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[489].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain appropriate radio communication and listening watch with ATS and other aircraft if radio is fitted and used", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[490].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[490].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[490].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[490].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[490].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[490].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "configure the aircraft as required for the following environmental and operational conditions:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) turbulence", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[492].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[492].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[492].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[492].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[492].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[492].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) holding", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[493].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[493].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[493].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[493].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[493].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[493].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) maximum range", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[494].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[494].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[494].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[494].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[494].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[494].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(l)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain awareness of search and rescue times (SARTIME) and revise as require;", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[495].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[495].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[495].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[495].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[495].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[495].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(m)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "monitor aircraft systems, manage fuel and engine to ensure aircraft is operated to achieve flight plan objectives", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[496].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[496].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[496].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[496].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[496].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[496].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.5", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Navigate at low level and in reduced visibility", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],

[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "configure the aircraft as required for the following environmental and operational conditions:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) reduced visibility", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[499].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[499].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[499].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[499].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[499].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[499].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) low cloud base", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[500].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[500].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[500].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[500].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[500].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[500].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "navigate aeroplane at minimum heights (not below 500 ft AGL, clear of built-up areas) and remain in VMC", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[501].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[501].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[501].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[501].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[501].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[501].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain separation from terrain, obstacles, allowing for wind and turbulence at low level", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[502].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[502].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[502].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[502].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[502].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[502].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "avoid noise sensitive areas", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[503].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[503].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[503].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[503].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[503].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[503].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "operate appropriately in the vicinity of aerodromes and landing areas", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[504].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[504].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[504].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[504].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[504].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[504].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.6", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform lost procedure", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],

[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "acknowledge positional uncertainty in a timely manner", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[506].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[506].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[506].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[506].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[506].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[506].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "configure aircraft for range and endurance as required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[507].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[507].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[507].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[507].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[507].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[507].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply recognised method to re-establish aircraft position", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[508].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[508].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[508].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[508].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[508].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[508].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "fix position", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[509].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[509].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[509].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[509].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[509].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[509].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "use radio to request assistance, if applicable", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[510].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[510].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[510].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[510].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[510].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[510].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "plan a timely precautionary search and landing if unable to complete flight safely to suitable aerodrome", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[511].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[511].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[511].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[511].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[511].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[511].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.7", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform diversion procedure ", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],

[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "make timely decision to divert", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[513].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[513].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[513].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[513].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[513].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[513].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify an acceptable alternate aerodrome", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[514].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[514].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[514].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[514].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[514].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[514].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "select a suitable route and cruising level", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[515].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[515].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[515].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[515].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[515].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[515].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "revise flight plan considering weather, terrain, airspace and fuel available", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[516].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[516].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[516].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[516].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[516].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[516].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "advise ATS of an intention to divert", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[517].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[517].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[517].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[517].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[517].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[517].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.8", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Use instrument navigation systems ", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],

[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "initialise navigation system (as applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[519].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[519].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[519].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[519].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[519].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[519].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "conduct navigation system validity check (as applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[520].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[520].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[520].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[520].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[520].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[520].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "conduct RAIM check if required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[521].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[521].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[521].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[521].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[521].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[521].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "select, load, check and activate the flight plan (as applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[522].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[522].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[522].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[522].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[522].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[522].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "navigate on departure, enroute and on arrival using GNSS", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[523].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[523].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[523].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[523].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[523].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[523].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "operate instrument navigation systems correctly", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[524].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[524].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[524].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[524].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[524].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[524].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "use instrument navigation systems to assist with navigation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[525].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[525].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[525].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[525].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[525].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[525].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "confirm waypoints and fixes using instrument navigation systems", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[526].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[526].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[526].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[526].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[526].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[526].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "NAV.9", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Execute arrival procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],

[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "obtain updated relevant aerodrome information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[528].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[528].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[528].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[528].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[528].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[528].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine landing direction and aerodrome suitability", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[529].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[529].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[529].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[529].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[529].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[529].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "conduct arrival", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[530].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[530].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[530].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[530].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[530].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[530].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify and avoid all traffic", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[531].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[531].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[531].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[531].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[531].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[531].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "observe local and published noise abatement requirements and curfews", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[532].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[532].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[532].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[532].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[532].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[532].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],

[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "cancel SARWATCH", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[533].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[533].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[533].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[533].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[533].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[533].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "RNE", style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "Radio Navigation - en route", style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f59e42"}}}},
],[
{value: "RNE.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Operate and monitor radio navigation aids and systems", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "select and operate navigation aids and systems", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[536].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[536].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[536].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[536].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[536].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[536].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "monitor and take appropriate action in relation to the integrity of navigation aid systems information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[537].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[537].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[537].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[537].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[537].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[537].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "RNE.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Navigate the aircraft using navigation aids and systems", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine aircraft position fix solely with reference to navigation aids and systems", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[539].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[539].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[539].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[539].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[539].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[539].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "intercept tracks to and from navigation aids and systems", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[540].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[540].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[540].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[540].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[540].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[540].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain tracks within specified tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[541].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[541].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[541].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[541].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[541].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[541].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "record, assess and revise timings as required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[542].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[542].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[542].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[542].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[542].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[542].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recognise station passage", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[543].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[543].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[543].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[543].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[543].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[543].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "ONTA", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Operate at non-towered aerodromes", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "ONTA.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Non-towered aerodrome – pre-flight preparation", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "using a current ERSA and NOTAM, for the non-towered aerodrome or landing area, extract all of the relevant operational information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[546].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[546].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[546].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[546].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[546].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[546].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "interpret the extracted information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[547].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[547].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[547].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[547].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[547].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[547].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify all special aerodrome procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[548].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[548].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[548].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[548].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[548].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[548].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "check current weather forecast and local observations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[549].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[549].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[549].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[549].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[549].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[549].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify all relevant radio and navigation aid frequencies", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[550].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[550].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[550].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[550].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[550].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[550].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "ONTA.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Taxi aircraft at a non-towered aerodrome or landing area", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "refer to aerodrome or landing area chart (if available)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[552].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[552].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[552].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[552].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[552].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[552].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "set local QNH or area QNH", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[553].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[553].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[553].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[553].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[553].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[553].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "broadcast intentions on appropriate frequency", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[554].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[554].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[554].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[554].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[554].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[554].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "obtain and interpret traffic information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[555].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[555].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[555].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[555].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[555].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[555].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain lookout for, and separation from, other aircraft, wildlife and other obstructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[556].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[556].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[556].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[556].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[556].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[556].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recognise ground markings during taxi and take appropriate action", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[557].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[557].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[557].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[557].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[557].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[557].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "taxi aircraft to holding point", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[558].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[558].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[558].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[558].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[558].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[558].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "use strobes when crossing any runway", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[559].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[559].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[559].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[559].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[559].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[559].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "refer to aerodrome or landing area chart (if available)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[560].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[560].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[560].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[560].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[560].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[560].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "ONTA.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform departure at a non-towered aerodrome or landing area", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "check and ensure runway approach is clear prior to entering a runway", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[562].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[562].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[562].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[562].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[562].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[562].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "correctly set transponder code and mode prior to entering runway for take-off", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[563].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[563].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[563].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[563].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[563].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[563].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "confirm runway approaches clear in all directions prior to entering runway", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[564].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[564].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[564].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[564].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[564].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[564].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "broadcast line up details", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[565].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[565].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[565].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[565].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[565].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[565].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "transmit appropriate radio calls and maintain separation with other aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[566].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[566].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[566].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[566].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[566].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[566].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "advise air service provider of departure details, if required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[567].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[567].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[567].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[567].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[567].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[567].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "conduct departure", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[568].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[568].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[568].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[568].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[568].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[568].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "ONTA.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform arrival and landing at a non-towered aerodrome or landing area", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "check ERSA and NOTAM prior to entering circuit area", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[570].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[570].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[570].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[570].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[570].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[570].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "set correct area or local QNH", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[571].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[571].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[571].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[571].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[571].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[571].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "use correct radio frequency to transmit inbound calls as required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[572].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[572].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[572].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[572].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[572].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[572].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain effective lookout", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[573].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[573].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[573].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[573].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[573].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[573].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain aircraft separation and avoid other traffic", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[574].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[574].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[574].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[574].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[574].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[574].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain tracking tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[575].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[575].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[575].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[575].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[575].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[575].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine wind velocity", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[576].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[576].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[576].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[576].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[576].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[576].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine landing direction", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[577].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[577].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[577].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[577].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[577].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[577].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "confirm runway is serviceable for the operation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[578].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[578].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[578].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[578].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[578].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[578].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "determine circuit direction", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[579].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[579].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[579].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[579].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[579].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[579].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "conduct landing area inspection (if applicable)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[580].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[580].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[580].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[580].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[580].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[580].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(l)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "position aircraft in the circuit in preparation for landing and maintain separation from traffic", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[581].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[581].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[581].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[581].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[581].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[581].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(m)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "make all necessary circuit radio calls", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[582].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[582].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[582].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[582].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[582].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[582].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(n)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "verify runway is clear of other traffic, wildlife and other obstructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[583].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[583].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[583].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[583].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[583].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[583].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(o)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "land the aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[584].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[584].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[584].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[584].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[584].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[584].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(p)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "vacate runway", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[585].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[585].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[585].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[585].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[585].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[585].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(q)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "cancel SARWATCH, if applicable", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[586].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[586].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[586].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[586].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[586].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[586].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "OGA", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Operate in Class G airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "OGA", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Operate aircraft in Class G airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain tracking and altitude tolerances to remain outside controlled airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[589].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[589].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[589].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[589].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[589].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[589].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply separation tolerances between IFR flights, and IFR and VFR flights", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[590].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[590].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[590].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[590].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[590].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[590].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "when using an aircraft radio:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) monitor appropriate radio frequency", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[592].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[592].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[592].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[592].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[592].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[592].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) make appropriate radio calls", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[593].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[593].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[593].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[593].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[593].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[593].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) obtain operational information from air services provider and other aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[594].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[594].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[594].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[594].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[594].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[594].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iv) use information to ensure aircraft separation is maintained", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[595].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[595].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[595].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[595].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[595].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[595].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(v) apply loss of radio communication procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[596].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[596].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[596].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[596].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[596].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[596].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "using a suitable chart:", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(i) operate clear of active aerodromes and landing areas in the vicinity of the aircraft", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[598].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[598].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[598].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[598].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[598].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[598].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(ii) identify and remain clear of controlled and restricted airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[599].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[599].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[599].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[599].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[599].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[599].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "(iii) take appropriate action when operating in the vicinity of a danger area", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[600].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[600].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[600].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[600].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[600].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[600].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform actions in the event of abnormal operations and emergencies", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[601].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[601].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[601].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[601].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[601].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[601].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recall transponder emergency code and communication failure code", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[602].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[602].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[602].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[602].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "e00000"}}}},
{value: allConds[602].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[602].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "CTR", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Operate at a controlled aerodrome", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "CTR.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Controlled aerodrome pre-flight preparation", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "using a current ERSA and NOTAM, for the controlled aerodrome, extract all the relevant operational information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[605].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[605].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[605].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[605].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[605].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[605].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "interpret the extracted information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[606].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[606].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[606].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[606].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[606].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[606].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify all special aerodrome procedures", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[607].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[607].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[607].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[607].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[607].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[607].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "check current weather forecast and local observations", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[608].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[608].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[608].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[608].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[608].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[608].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify all relevant radio and navigation aid frequencies", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[609].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[609].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[609].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[609].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[609].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[609].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "CTR.2", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "obtain and comply with ATC clearances", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "obtain and comply with ATC clearances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[611].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[611].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[611].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[611].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[611].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[611].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "manoeuvre aircraft to holding point as instructed and take appropriate action to avoid other aircraft and obstructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[612].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[612].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[612].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[612].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[612].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[612].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recognise ground markings during taxi and take appropriate action", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[613].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[613].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[613].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[613].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[613].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[613].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recognise lighting signals and take appropriate action", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[614].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[614].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[614].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[614].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[614].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[614].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "identify airport runway incursion hotspots", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[615].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[615].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[615].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[615].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[615].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[615].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "manoeuvre aircraft to avoid jet blast hazard", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[616].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[616].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[616].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[616].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[616].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[616].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "request taxi guidance if unsure of position", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[617].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[617].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[617].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[617].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[617].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[617].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "use strobes when crossing any runway", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[618].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[618].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[618].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[618].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[618].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[618].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "CTR.3", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform departure from controlled aerodrome", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "receive and correctly read back an airways clearance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[620].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[620].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[620].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[620].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[620].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[620].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "check and ensure runway approach is clear prior to entering a runway", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[621].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[621].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[621].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[621].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[621].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[621].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "correctly set transponder code and mode prior to entering runway for take-off", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[622].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[622].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[622].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[622].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[622].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[622].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "comply with ATC departure instructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[623].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[623].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[623].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[623].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[623].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[623].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "advise ATC as soon as possible if unable to comply with clearance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[624].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[624].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[624].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[624].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[624].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[624].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "contact approach with airborne report or give departure call to tower", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[625].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[625].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[625].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[625].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[625].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[625].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain lookout", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[626].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[626].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[626].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[626].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[626].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[626].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "avoid wake turbulence", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[627].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[627].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[627].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[627].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[627].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[627].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "comply with airways clearances within tracking and altitude tolerances and maintain traffic lookout until clear of the aerodrome control zone", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[628].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[628].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[628].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[628].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[628].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[628].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],[
{value: "CTR.4", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Perform arrival and landing at controlled aerodrome", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "check ERSA and NOTAM prior to entering control area and extract required operational information", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[630].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[630].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[630].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[630].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[630].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[630].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "receive ATIS and correctly set the appropriate QNH", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[631].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[631].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[631].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[631].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[631].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[631].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "request and receive ATC clearance and set correct transponder code prior to entering control area", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[632].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[632].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[632].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[632].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[632].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[632].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "advise ATC as soon as possible if unable to comply with clearance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[633].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[633].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[633].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[633].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[633].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[633].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain lookout at all times", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[634].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[634].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[634].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[634].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[634].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[634].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "update QNH as required", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[635].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[635].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[635].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[635].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[635].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[635].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain tracking tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[636].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[636].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[636].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[636].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[636].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[636].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "establish aircraft on the correct leg of the circuit in preparation for landing and maintain separation from traffic", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[637].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[637].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[637].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[637].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[637].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[637].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "confirm clearance to land", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[638].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[638].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[638].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[638].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[638].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[638].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "vacate runway and obtain taxi clearance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[639].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[639].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "6988fa"}}}},
{value: allConds[639].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[639].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ff9900"}}}},
{value: allConds[639].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[639].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
  {value: "CTA", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "Operate in controlled airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
  {value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "f2cf44"}}}},
],[
{value: "CTA.1", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "Operate aircraft in controlled airspace", style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
{value: "",style: {fill: {patternType: "solid", fgColor: {rgb: "faebaf"}}}},
],
[
{value: "(a)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "comply with airways clearance requirements for operating in all classes of airspace, including lead time required for flight plan submission, contents, ‘clearance void time’, and ‘readback’ requirement", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[642].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[642].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[642].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[642].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[642].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[642].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(b)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply airways clearance requirements for entering, operating in and departing from CTA and CTR, including details that need to be provided to ATC, and what details to expect from ATC", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[643].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[643].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[643].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[643].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[643].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[643].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(c)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain control area protection tolerances", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[644].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[644].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[644].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[644].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[644].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[644].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(d)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maintain tracking and altitude tolerances when operating on an airways clearance", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[645].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[645].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[645].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[645].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[645].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[645].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(e)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "reconfirm any clearance items when doubt exists", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[646].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[646].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[646].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[646].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[646].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[646].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(f)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "advise ATC as soon as possible if unable to maintain clearance due to adverse weather conditions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[647].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[647].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[647].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[647].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[647].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[647].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(g)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "follow ATC requirements for a change of level in CTA, including in an emergency situation", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[648].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[648].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[648].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[648].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[648].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[648].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(h)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "comply with departure, climb, transition to cruise (levelling out), cruise, change of levels, descent and visual approach procedures in CTA and CTR instructions", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[649].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[649].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[649].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[649].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[649].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[649].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(i)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "apply separation standards between IFR flights, and IFR and VFR flights in the various classes of CTA", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[650].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[650].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[650].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[650].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[650].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[650].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(j)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform appropriate actions in the event of the loss of radio communication in CTA and CTR", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[651].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[651].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[651].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[651].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[651].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[651].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(k)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform appropriate actions in the event of abnormal operations and emergency procedures in CTA and CTR", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[652].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[652].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[652].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[652].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[652].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[652].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(l)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "operate under radar vectoring procedures, including radio procedures and phraseologies", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[653].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[653].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[653].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[653].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[653].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[653].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(m)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "maximum permissible time interval between ATC transmissions during radar vectoring are not exceeded", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[654].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[654].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[654].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[654].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[654].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[654].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(n)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "perform appropriate actions in the event of abnormal operations and emergencies", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[655].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[655].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[655].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[655].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[655].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[655].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],
[
{value: "(o)", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: "recall transponder emergency code and communication failure code", style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[656].Date1Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[656].Date1, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[656].Date2Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[656].Date2, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[656].Date3Ins, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
{value: allConds[656].Date3, style: {fill: {patternType: "solid", fgColor: {rgb: "ffffff"}}}},
],


          ]
      }
  ],loading:false,generate:"none",download:""}))






}




      render() {
        const {values}=this.props;
  return(

    <MuiThemeProvider>
      <React.Fragment>
      <Grid item xs={12} style={{margin: "2%", display:this.state.generate}}>
      <h3>Click below to prepare the report.</h3> <br></br><h5>Once the report is ready, click "Download Report"</h5>
      <RaisedButton fullWidth style={{margin: "2%"}} label="Prepare Data" secondary={true} onClick={e=>{e.preventDefault();this.generateData()}}/>
      <RaisedButton fullWidth style={{margin: "2%"}} label="Back" primary={true} onClick={this.back}/></Grid>
      <Grid item xs={12} style={{margin: "2%", display:this.state.download}}>
      <h3>Report is ready to be downloaded</h3> <br></br><h5>Click the button below</h5>
      <ExcelFile  filename={values.searchKeyword.fname+"_"+values.searchKeyword.lname+"_"+moment().format("DDMMYYYY")+"_TrainingMatrix"} element={<RaisedButton style={{margin: "2%"}} fullWidth label="Download Report" secondary={true}/>}>
          <ExcelSheet dataSet={this.state.multiDataSet} name="Training Matrix"/>
      </ExcelFile>
      <RaisedButton fullWidth style={{margin: "2%"}} label="Back" primary={true} onClick={this.back}/></Grid>



          <Dialog style={{padding:"10%"}}
          backdrop
          open={this.state.loading}>
          <DialogContent dividers>
          <CircularProgress />
          </DialogContent>


          </Dialog>

      </React.Fragment>
      </MuiThemeProvider>


  )

}}
export default TrainingMatrixExport
//02
