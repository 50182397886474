import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';



export class AddAircraft extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    this.props.addAirCraft();
    this.props.nextStep();
  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }





  render() {
    const { values, handleChange, logServiceDate} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Enter New Aircraft Details</h3>
              </Grid>
              <Grid item xs={4}>
              <TextField
                  id="rego"
                  label="Aircraft Registration"
                  type="rego"
                  name="Aircraft Registration"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={handleChange('rego')}
                  value={values.rego}
                  /></Grid>


              <Grid item xs={4}>
              <TextField
                  id="wingtype"
                  label="Aircraft Type"
                  name="Aircraft Type"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={handleChange('wing_type')}
                  value={values.wing_type}
                  /></Grid>
              <Grid item xs={4}>
              <TextField
                  id="counter"
                  label="Hours flown since last service"
                  name="Hours flown since last service"
                  margin="normal"
                  type="number"
                  variant="outlined" style={{width:'100%'}}
                  onChange={handleChange('wing_counter')}
                  value={values.wing_counter}
                  /></Grid>


              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
              InputLabelProps={{ shrink: true }}
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Date of Last Service"
                onChange={logServiceDate}
                value={values.wingLastService}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
              </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4}>
              <TextField
                  id="serviceNotes"
                  label="Last Service Notes(if any)"
                  name="Last Service Notes "
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={handleChange('wingLastServiceNotes')}
                  value={values.wingLastServiceNotes}
                  /></Grid>








              <Grid item xs={4}>
              <OutlinedInput
                  id="costPerHour"
                  type="number"
                  inputProps={{ step: "5.00", min:"0.00" }}
                  fullWidth="true"
                  style={{marginTop:'4.5%',marginBottom:'2%'}}
                  value={values.costPerHour}
                  onChange={handleChange('costPerHour')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  aria-describedby="Cost per hour"

                  labelWidth={0}
                  /> </Grid>




                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default AddAircraft
