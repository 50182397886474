import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AvatarPic from './avatar.png'
import Webcam from 'react-webcam';
import ModelPhoto from './modelPhoto.png'


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const videoConstraints = {
  width: 151.181102362,
  height: 188.976377953,
  facingMode: "user"
};





export class UpdateSignature extends Component{




  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue =(stepNo)=> e => {
    e.preventDefault();
    this.props.setStep(stepNo);
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }







  render() {
    const {values, setPassportPhoto,passportPhoto, setStep} = this.props;

    return(

      <MuiThemeProvider>
        <React.Fragment>

      

          <div>
          <Grid container spacing={2} style={{padding:'10%',paddingTop:'2%'}}>
          <Grid item xs={12}>

            <Paper style={{opacity:0.89}}>


              <Grid style={{padding:'2%'}}>
              <Grid item xs={12} style={{textAlign:'center', padding:'2%'}}>

                <img src={values.stu_Signature} style={{height:'100%',width:'100%'}}
                    style={{borderStyle:'solid'}}/>
                        </Grid>
                        </Grid>



                <Grid item>
                  <RaisedButton label="Use Existing Signature" onClick={this.continue(2501)}  fullWidth='true' style={{marginTop:20}}/>

                  <RaisedButton label="Change Signature" primary={true} onClick={this.continue(2801)} fullWidth='true' style={{marginTop:20}}/>
                  <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.continue(2501)}>Go Back</Button>
                </Grid>

              </Paper>
              </Grid>





</Grid>
            </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default UpdateSignature
