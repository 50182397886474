import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { validations, validateAll } from 'indicative/validator';
import * as moment from 'moment';




export class FlightAuthSelectDate extends Component{

  constructor(props) {
    super(props);
    const { values,handleChange} = this.props;

    this.state = {value:0,
      notInDatabase:"",
      checkbox:false,
      inDatabase:"none",
      searchJson: "",
      summaryDateerr:"",
      summaryDate: moment(values.psp1).format('MM/DD/YYYY'),
    };


  }



  back = e => {
    e.preventDefault();
    this.props.clearState();
    this.props.setStep(7000);
  }

  continue= e => {
   e.preventDefault();
   this.props.getAccountInfo(0);
   this.props.getCCExpiry(7002);
   this.props.nextStep();
 }









  dateChange=(date)=>{
      this.setState({summaryDateerr:"",
                     summaryDate: moment(date).format('MM/DD/YYYY')});
      this.props.handleChangeInput("psp1",date);

  }

  validate=e=>{
    e.preventDefault();
     this.validateEmail()

  }
  validateEmail=e=>{
    const { values} = this.props;

    const data = this.state;
    const rules= {summaryDate  : 'required|max:10',

  }
    const messages={
      alpha                 : 'Alphabetical characters only!',
      date1                 : 'Invalid Date',
      number                : 'Numbers Only',
      float                 : "That's invalid",
      required              : 'This Cannot be empty',
      min                   : "That's too short!",
      max                   : "Try Again",
      email                 : 'Invalid Email',
      alpha_numeric         : 'Illegal Characters in the password',
      'password.confirmed'  : 'The password confirmation does not match the entered password',
            }

    validateAll(data, rules, messages)
    .catch(errors =>{console.log(errors);

      const formattedErrors = {}
      errors.forEach(error =>this.setState({[error.field+'err']:error.message})
      )})
    .then(i=> {if (this.state.summaryDateerr=="" ) {
       fetch(`${process.env.REACT_APP_SERVER_URL}/getPreflightAuth?date=${this.state.summaryDate}`, {
      headers: {
      'x-access-token': values.token
    }})
       .then(response=>response.json())
       .then(response=>{
         this.props.handleChangeInput("flightRecords",response.data);
         this.props.setStep(7006)
       })

      } else{}})}




  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Select Date</h3>
              </Grid>

              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                  disabled={this.state.editenabled}
                  InputLabelProps={{ shrink: true }}
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date_picker"
                label="Date of Summary"
                onChange={this.dateChange}
                value={this.state.summaryDate}
                error={this.state.summaryDateerr!=""}
                helperText={this.state.summaryDateerr != "" ? this.state.summaryDateerr : ''}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
                </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} style={{display:"flex"}}>
            <RaisedButton style={{marginTop:"2%"}} fullWidth label="View" primary={true} onClick={this.validate}/>
              </Grid>

              </Grid>

              <Grid item xs={12} style={{display:"flex"}}>
              <RaisedButton style={{marginTop:"2%"}} fullWidth label="Back" primary={true} onClick={this.back}/>
                </Grid>







              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default FlightAuthSelectDate
