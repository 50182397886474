import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Cards from 'react-credit-cards';


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


const handleDateChange = e => {
   console.log(e);

 };

export class VerifyNewStudentDetails extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }






  continue = e => {
    e.preventDefault();
    this.props.addStudent();

  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

addStudent= e => {
  this.props.nextStep();


}




  render() {
    const { values, handleChange, log} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >




          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={5}>


              <Grid item xs={12}>
              <h3>Verify New Account Details</h3>
              </Grid>
              <Grid item xs={4}>
              <img src={values.passportPhoto} style={{paddingLeft:'35%',paddingRight:'35%',height:'60%'}}/>
                  </Grid>
                  <Grid item xs={4}>
                  <Cards
                    cvc={values.creditCardCVC}
                    expiry={values.creditCardExpiry}
                    focused="name"
                    name={values.creditCardName}
                    number={values.creditCardNumber}
                      />
                      <Cards
                        cvc={values.creditCardCVC}
                        expiry={values.creditCardExpiry}
                        focused="cvc"
                        name={values.creditCardName}
                        number={values.creditCardNumber}
                          />
                      </Grid>
                  <Grid item xs={4}>
                  <Avatar style={{ margin: 20 }} src={values.profilePic} size={200}/>
                      </Grid>
                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="First_Name"
                          label="First Name"
                          type="fname"
                          name="First Name"
                          autoComplete="fname"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.fName}
                          /></Grid>


                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Middle_Names"
                          label="Middle Names"
                          type="mname"
                          name="Middle Name"
                          autoComplete="mname"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.mNames}
                          /></Grid>
                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Last_Name"
                          label="Last Name"
                          type="lname"
                          name="Last Name"
                          autoComplete="lname"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.lName}
                          /></Grid>


                      <Grid item xs={4}>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <KeyboardDatePicker
                          disabled
                          InputLabelProps={{ shrink: true }}
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dob_picker"
                        label="Date Of Birth"
                        value={values.dob}
                        style={{width:'100%' ,marginTop:'4.5%'}}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        /></MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Email"
                          label="E-mail"
                          type="email"
                          name="email"
                          autoComplete="email"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.email}
                          /></Grid>








                      <Grid item xs={2}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="phone"
                          label="phone"
                          type="phone"
                          name="phone"
                          autoComplete="phone"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.phone}
                          /></Grid>

                      <Grid item xs={2}>
                      <FormControl variant="outlined" style={{width:'100%',marginTop:'9%'}}

                      >
                              <InputLabel  htmlFor="outlined-age-simple">
                                Blood Type
                                </InputLabel>


                              <Select
                              disabled
                              value={values.bloodType}

                            inputProps={{
                              name: 'bloodtype',
                              id: 'bloodtype',
                            }}
                                >

                                <MenuItem value={"A1"}>A+</MenuItem>
                                <MenuItem value={"A0"}>A-</MenuItem>
                                <MenuItem value={"B1"}>B+</MenuItem>
                                <MenuItem value={"B0"}>B-</MenuItem>
                                <MenuItem value={"O1"}>O+</MenuItem>
                                <MenuItem value={"O0"}>O-</MenuItem>
                                <MenuItem value={"AB1"}>AB+</MenuItem>
                                <MenuItem value={"AB0"}>AB-</MenuItem>
                                </Select>

                                </FormControl></Grid>

                      <Grid item xs={12}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="res_address"
                          label="Residential Address"
                          type="address"
                          name="Address"
                          autoComplete="address"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.rAddress}
                          /></Grid>

                      <Grid item xs={12}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="m_address"
                          label="Mailing Address"
                          type="address"
                          name="Address"
                          autoComplete="address"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.mAddress}
                          /></Grid>

                      <Grid item xs={8}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Next_of_Kin"
                          label="Next of Kin"
                          autoComplete="name"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.nextKin}
                          /></Grid>

                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Next_of_Kin_phone"
                          label="Next of Kin Contact"
                          autoComplete="phone"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.nextKinPhone}
                          /></Grid>

                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="ARN"
                          label="ARN"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.stu_arn}
                          /></Grid>

                      <Grid item xs={4}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Weight"
                          label="Current Weight"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.cWeight}
                          /></Grid>
                          <Grid item xs={12}>

                    <Grid item xs={6}>
                  <FormControl
                      required variant="outlined" style={{width:'100%',marginTop:'5%'}}

                  >
                          <InputLabel  htmlFor="outlined-age-simple">
                            Currently held License type
                          </InputLabel>


                          <Select
                            disabled
                            value={values.CLicType}
                            error={this.state.CLicTypeerr!=""}
                            helperText={this.state.CLicTypeerr != "" ? this.state.CLicTypeerr : ''}
                            inputProps={{name: 'age',id: 'licType',}}>
                            <MenuItem value="none">
                            <em>None</em>
                            </MenuItem>
                            <MenuItem value={"RPLH"}>RPL-H</MenuItem>
                            <MenuItem value={"RPLA"}>RPL-A</MenuItem>
                            <MenuItem value={"PPLH"}>PPL-H</MenuItem>
                            <MenuItem value={"PPLA"}>PPL-A</MenuItem>
                            <MenuItem value={"CPLH"}>CPL-H</MenuItem>
                            <MenuItem value={"CPLA"}>CPL-A</MenuItem>
                            <MenuItem value={"ATPLH"}>ATPL-H</MenuItem>
                            <MenuItem value={"ATPLA"}>ATPL-A</MenuItem>
                            <MenuItem value={"other"}>Other - See notes</MenuItem>


                            </Select>

                            </FormControl></Grid>
                      <Grid item xs={6}>
                    <FormControl variant="outlined" style={{width:'100%',marginTop:'5%'}}

                    >
                            <InputLabel  shrink htmlFor="outlined-age-simple">
                              License type
                            </InputLabel>


                            <Select
                            disabled
                            value={values.LicType}

                          inputProps={{
                            name: 'age',
                            id: 'outlined-age-simple',
                          }}
                              >
                              <MenuItem value="">
                              <em>None</em>
                              </MenuItem>
                              <MenuItem value={"RPLH"}>RPL-H</MenuItem>
                              <MenuItem value={"PPLH"}>PPL-H</MenuItem>
                              <MenuItem value={"CPLH"}>CPL-H</MenuItem>
                              </Select>

                              </FormControl></Grid></Grid>

                              <Grid item xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox disabled checked={values.medical1Checked}  value="class1Check" />
                                }
                                label="Class 1 Medical"
                                />
                              </Grid>
                              <Grid item xs={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>

                              <KeyboardDatePicker
                                disabled
                                disablePast
                                autoOk={true}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="dob_picker"
                                label="Date Of Expiry"
                                value={values.medical1}
                                style={{width:'100%'}}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                /></MuiPickersUtilsProvider>
                              </Grid>

                              <Grid item xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox disabled checked={values.medical2Checked}  value="class2Check" />
                                }
                                label="Class 2 Medical"
                                />
                              </Grid>
                              <Grid item xs={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>

                              <KeyboardDatePicker
                                disabled
                                disablePast
                                autoOk={true}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="dob_picker"
                                label="Date Of Expiry"
                                value={values.medical2}
                                style={{width:'100%'}}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                /></MuiPickersUtilsProvider>
                              </Grid>

                              <Grid item xs={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox disabled checked={values.medical3Checked}  value="class3Check" />
                                }
                                label="Class 3 Medical"
                                />
                              </Grid>
                              <Grid item xs={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>

                              <KeyboardDatePicker
                                disabled
                                disablePast
                                autoOk={true}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="dob_picker"
                                label="Date Of Expiry"
                                value={values.medical3}
                                style={{width:'100%'}}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                /></MuiPickersUtilsProvider>
                              </Grid>

                      <Grid item xs={12}>
                      <TextField
                          disabled
                          InputLabelProps={{ shrink: true }}
                          id="Notes"
                          label="Special Notes"
                          margin="normal"
                          variant="outlined" style={{width:'100%'}}
                          value={values.accNotes}
                          /></Grid>


                  <Grid item xs={12}>
                  <img style={{ margin: 20 }} src={values.signatureUpload} />
                  <Typography style={{padding:'1%'}}>
                  **By confirming and submitting this application you agree to terms and conditions set out by your Flight training organisation and our Privacy policy. You will be emailed a New Student info pack and a link for First time login into your account at the email address supplied
                  </Typography>
                  <RaisedButton fullWidth style={{height:60}} label="Confirm & Submit" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default VerifyNewStudentDetails
