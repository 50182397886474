import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Toggle from 'material-ui/Toggle';
import DatePicker from 'material-ui/DatePicker';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ActionFavorite from 'material-ui/svg-icons/action/favorite';
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border';



const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));






export class LessonOne extends Component{



  constructor(props) {
    super(props);
    this.state={value:0}
  }

/*method to select what conditions are displayed depending on formID selection from the previous page*/



  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }



  changeButton = (cid)=> e =>{
    e.preventDefault();
    this.props.handleChangeRadio(cid,e.target.value);


  }

  handleClickOpen = () => {
      this.setState({
        open: true,
      });
    };

  handleClose = () => {
      this.setState({
        open: false
      });
    };









  render() {
    const { values, handleChange, defaultButton, formRating,createRadioGroups} = this.props;
    const ratingState= values.formRating;



    return(



      <MuiThemeProvider>
        <React.Fragment>



          <div style={{ padding: 10}}>
          <Grid container spacing={1}>

            <Grid item xs={12} style={{marginLeft:'5%'}}><h1>{values.formId}</h1></Grid>


            <Grid container style={{marginLeft:'5%', marginRight:'5%'}}>
            <Grid item xs={2} style={{marginTop:20}}><h3>ID</h3></Grid>

              <Grid item xs={6} style={{marginTop:20}}><h3>Lesson Content (Elements & Performance Criteria)</h3></Grid>
                <Grid item xs={4} style={{marginTop:10}}>
                  <RadioGroup defaultValue='N/A' name='example'  style={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel disabled value="3" control={<Radio color="primary" />} label='3' labelPlacement="top"/>
                    <FormControlLabel disabled value="2" control={<Radio color="primary" />} label='2' labelPlacement="top"/>
                    <FormControlLabel disabled value="1" control={<Radio color="primary" />} label='1' labelPlacement="top"/>
                    <FormControlLabel disabled value="N/A" control={<Radio color="primary" />}label='N/A' labelPlacement="top"/>
                  </RadioGroup>
                </Grid>

            </Grid>
            <GridList style={{height: 400,maxWidth:'90%',marginLeft:'5%', marginRight:'5%', marginTop:5}}>

              <Grid item xs={12} style={{marginTop:20}}>{createRadioGroups()}</Grid>

                </GridList>
                <Grid container spacing={1} style={{ marginTop: 50}}>
                <Grid item xs={6}>
                <RaisedButton fullWidth label="Back" secondary={true} onClick={this.handleClickOpen}/>
                <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.open}>
                <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                ARE YOU SURE?
                </DialogTitle>
                <DialogContent dividers>
                <Typography gutterBottom>
                All selections in the Lesson Content (Elements & Performance Criteria) may be lost upon this action and will be reset to Default Values!
                Are you sure you want to Continue?
                </Typography>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.back} color="primary">
                YES, Go back
                </Button>
                <Button onClick={this.handleClose} color="primary">
                No, Stay here
                </Button>
                </DialogActions>
                </Dialog>

                </Grid>
                <Grid item xs={6}>
                <RaisedButton fullWidth label="Next" primary={true} onClick={this.continue}/>
                </Grid>
                </Grid>
                </Grid>
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default LessonOne
