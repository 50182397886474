import React, { Component, useState } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';



export class SetPrice extends Component{

  constructor(props) {
    super(props);
    this.state = {
      value:0,
      cost:0    };
  }


  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }



  componentDidMount(){
    const { values,handleChange} = this.props;

    fetch(`${process.env.REACT_APP_SERVER_URL}/getCustomPrice?stuId=${values.stu_id_edit}&rego=${values.searchKeyword.rego}`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{
      this.props.handleChangeInput('costPerHour',response.data[0].costPerHour);})
    .catch(err=>console.error(err))

  }

  render() {
    const { values, handleChange, logServiceDate} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Set training cost per hour for {values.fName} {values.mNames} {values.lName} for {values.rego} of type {values.wing_type}</h3>
              </Grid>


              <Grid item xs={12}>
              <OutlinedInput
                  floatingLabelText="Current Hourly Rate"
                  fullWidth="true"
                  id="costPerHour"
                  type="number"
                  inputProps={{ step: "5.00", min:"0.00" }}
                  fullWidth="true"
                  style={{marginTop:'4.5%',marginBottom:'2%'}}
                  value={values.costPerHour}
                  onChange={handleChange('costPerHour')}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  aria-describedby="Cost per hour"

                  labelWidth={0}
                  /> </Grid>

                  <Grid item xs={12}>
                  <TextField
                      fullWidth="true"
                      id="notes"
                      label="Notes"
                      name="Last Service Notes "
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange('transNotes')}
                      value={values.transNotes}
                      /></Grid>



                  <Grid item xs={12}>
                  <RaisedButton fullWidth="true" style={{height:60,marginTop:"4%"}} label="Next" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth="true" label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SetPrice
