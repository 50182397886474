import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';


export class VerifyNewStudentDetails extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue = e => {
    e.preventDefault();
    this.props.addInstructor(4009);
    this.props.nextStep();
  }
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }






  render() {
    const { values, handleChange, log} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={5}>


              <Grid item xs={12}>
              <h3>Verify New Instructor Details</h3>
              </Grid>
              <Grid item xs={6}>
              <img src={values.ins_PassPhoto} style={{paddingLeft:'35%',paddingRight:'35%',height:'60%'}}/>
                  </Grid>
                  <Grid item xs={6}>
                  <Avatar style={{ margin: 20 }} src={values.ins_profPhoto} size={200}/>
                      </Grid>
              <Grid item xs={4}>
              <TextField
                  disabled
                  id="First_Name"
                  label="First Name"
                  type="fname"
                  name="First Name"
                  autoComplete="fname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_fname}
                  /></Grid>


              <Grid item xs={4}>
              <TextField
                  disabled
                  id="Middle_Names"
                  label="Middle Names"
                  type="mname"
                  name="Middle Name"
                  autoComplete="mname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_mNames}
                  /></Grid>
              <Grid item xs={4}>
              <TextField
                  disabled
                  id="Last_Name"
                  label="Last Name"
                  type="lname"
                  name="Last Name"
                  autoComplete="lname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_lname}
                  /></Grid>


              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                  disabled
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Date Of Birth"
                value={values.ins_dob}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                />
                <KeyboardDatePicker
                    disabled
                  disablePast
                  autoOk={true}
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="medicalExpiry_picker"
                  label="Medical Expiry"
                  value={values.ins_medicalExpiry}
                  style={{width:'100%' ,marginTop:'4.5%'}}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4}>
              <TextField
                  disabled
                  id="Email"
                  label="E-mail"
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_email}
                  /></Grid>








              <Grid item xs={2}>
              <TextField
                  disabled
                  id="phone"
                  label="phone"
                  type="phone"
                  name="phone"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_tpNo}
                  /></Grid>

              <Grid item xs={2}>
              <FormControl variant="outlined" style={{width:'100%',marginTop:'9%'}}
              value={values.ins_btype}
              >
                      <InputLabel  htmlFor="outlined-age-simple">
                        Blood Type
                        </InputLabel>


                      <Select
                          disabled

                    inputProps={{
                      name: 'bloodtype',
                      id: 'bloodtype',
                    }}
                        >

                        <MenuItem value={"A1"}>A+</MenuItem>
                        <MenuItem value={"A0"}>A-</MenuItem>
                        <MenuItem value={"B1"}>B+</MenuItem>
                        <MenuItem value={"B0"}>B-</MenuItem>
                        <MenuItem value={"O1"}>O+</MenuItem>
                        <MenuItem value={"O0"}>O-</MenuItem>
                        <MenuItem value={"AB1"}>AB+</MenuItem>
                        <MenuItem value={"AB0"}>AB-</MenuItem>
                        </Select>

                        </FormControl></Grid>

              <Grid item xs={12}>
              <TextField
                  disabled
                  id="res_address"
                  label="Residential Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_rAddress}
                  /></Grid>

              <Grid item xs={12}>
              <TextField
                  disabled
                  id="m_address"
                  label="Mailing Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_mAddress}
                  /></Grid>

              <Grid item xs={8}>
              <TextField
                  disabled
                  id="Next_of_Kin"
                  label="Next of Kin"
                  autoComplete="name"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_nextKin}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled
                  id="Next_of_Kin_phone"
                  label="Next of Kin Contact"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_nextKinPhone}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled
                  id="ARN"
                  label="ARN"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_arn}
                  /></Grid>

              <Grid item xs={4}>
              <OutlinedInput
                  disabled
                  id="cWeight"
                  type="number"
                  inputProps={{ step: "5.00", min:"0.00" }}
                  fullWidth="true"
                  style={{marginTop:'4.5%',marginBottom:'2%'}}
                  value={values.ins_cWeight}
                  endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                  aria-describedby="Weight"

                  labelWidth={0}
                  /></Grid>


                  <Grid item xs={4}>


                  </Grid>

                  <Grid item xs={4} style={{display:this.state.editenabled===true?"none":""}}>
                  <FormControl
                    required='true'
                    variant="outlined"
                    style={{width:'100%',marginTop:'4.5%',marginBottom:'2%'}}>

                        <InputLabel  htmlFor="outlined-age-simple">
                            Role
                            </InputLabel>


                          <Select
                          disabled
                            value={values.xs_level}


                        inputProps={{
                          name: 'role',
                          id: 'role',
                        }}
                            >
                            <MenuItem value={"sa"}>Super User    (Full System Admin Access)</MenuItem>
                            <MenuItem value={"i"} >Instructor    (Academic Admin Access)</MenuItem>
                            <MenuItem value={"p"} >Charter Pilot (Pre-Flight auth & View Access) </MenuItem>

                            </Select>

                            </FormControl></Grid>


              <Grid item xs={12}>
              <TextField
                  disabled
                  id="Notes"
                  label="Special Notes"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  value={values.ins_notes}
                  /></Grid>


                  <Grid item xs={12}>
                  <img style={{ margin: 20 }} src={values.signatureUpload} />
                  <RaisedButton fullWidth style={{height:60}} label="Confirm & Submit" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default VerifyNewStudentDetails
