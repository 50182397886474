import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));





export class SelectWingsForm extends Component{

    constructor(props) {
      super(props);
      this.state = {value:0,loading:true};
      const { values,handleChange} = this.props;


    }



    back = e => {
      this.props.prevStep();
    }



    componentDidMount(){
      const { values} = this.props;

      fetch(`${process.env.REACT_APP_SERVER_URL}/getWings`, {
        headers: {
        'x-access-token': values.token
      }})
      .then(response => response.json())
      .then(response=>{this.props.handleChange('suggestionsWings',response.data);this.setState({loading:false})})
      .catch(err=>console.error(err))
    }

    onTagsChange = (event, value) => {
        const { values,handleChange} = this.props;
        handleChange('searchKeyword',value)

        this.props.nextStep();

      }
      componentWillUnmount(){

        this.props.getAccountInfo();

      }





  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Select Aircraft</h3>
              </Grid>
              <Grid item xs={12} style={{display:values.step==3003?"":"none"}}>
              <p4>Current : {values.rego}</p4>
              </Grid>
              <Grid item xs={12}
              style={{marginTop:'5%'}}
              >


                  <p4>Start typing Aircraft rego or select the option from the suggestions</p4>
                  <Autocomplete
                    autoFocus="true"
                    disableClearable='true'
                    disableOpenOnFocus="true"
                    options={values.suggestionsWings}
                    getOptionLabel={option => option.rego}
                    onChange={this.onTagsChange}
                    renderTags={() => {}}

                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        margin="normal"
                        autoFocus
                        fullWidth
                      />
                    )}
                    />
                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton
                        style={{marginTop:'5%',display:values.rego==""?"none":""}}
                        fullWidth
                        label="No Change"
                        primary={true}
                        onClick={this.props.nextStep}
                        />
                      <RaisedButton
                        fullWidth
                        label="Back"
                        secondary={true}
                        onClick={this.back}
                        style={{marginTop:'5%'}}/>
                      </Grid>

                      </Grid>

</Grid>
<Dialog style={{padding:"10%"}}
backdrop
open={this.state.loading}>
<DialogContent dividers>
<CircularProgress />
</DialogContent>


</Dialog>         </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SelectWingsForm
