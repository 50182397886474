import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';




export class AddComments extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
    const { values,handleChange} = this.props;


  }



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }






  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    const totalcost=[values.costPerHour*values.fl_clock];
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>

              <Grid item xs={6}>
              <h3>Pre-Flight Knowledge Completed?</h3>
              </Grid>



              <Grid item xs={6}>
              <h3>Proceed to Next Lesson?</h3>
              </Grid>


              <Grid xs={12}/>




              <Grid item xs={6}>
              <RadioGroup defaultValue={values.pfk_bool} onChange={handleChange('pfk_bool')} style={{display: 'flex', flexDirection: 'row'}}>
                <FormControlLabel value="YES" control={<Radio color="primary" />} label='YES' labelPlacement="right"/>
                <FormControlLabel value="NO" control={<Radio color="primary" />}label='NO' labelPlacement="right"/>

              </RadioGroup>
              </Grid>

              <Grid item xs={6}>
              <RadioGroup defaultValue={values.nextLesson_bool} onChange={handleChange('nextLesson_bool')} style={{display: 'flex', flexDirection: 'row'}}>
                <FormControlLabel value="YES" control={<Radio color="primary" />} label='YES' labelPlacement="right"/>
                <FormControlLabel value="NO" control={<Radio color="primary" />}label='NO' labelPlacement="right"/>

              </RadioGroup>
              </Grid>
              <Grid xs={12}/>

              <Grid item xs={12}>
              <h3>Comments and Outcome</h3>
              <OutlinedInput
                  id="comments"
                  fullWidth="true"
                  style={{marginTop:'1%',marginBottom:'2%'}}
                  value={values.commts}
                  onChange={handleChange('commts')}/>
                  </Grid>






                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.props.nextStep}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>



</Grid>
              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default AddComments
