import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';




export class SelectEngineHours extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
    const { values,handleChange} = this.props;


  }

  continue = e => {
    const { values} = this.props;

    const soloGHf    =  parseFloat(values.soloGH);
    const soloNavf   =  parseFloat(values.soloNav);
    const dualGHf    =  parseFloat(values.dualGH);
    const dualNavf   =  parseFloat(values.dualNav);
    const totalFlightTime=parseFloat(soloGHf+soloNavf+dualGHf+dualNavf).toFixed(1);
    this.props.handleChangeInput('fl_clock',totalFlightTime);

    this.props.nextStep();
}

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }






  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    const soloGHf    =  parseFloat(values.soloGH);
    const soloNavf   =  parseFloat(values.soloNav);
    const dualGHf    =  parseFloat(values.dualGH);
    const dualNavf   =  parseFloat(values.dualNav);
    const totalFlightTime=parseFloat(soloGHf+soloNavf+dualGHf+dualNavf).toFixed(1);
    const totalcost=(values.costPerHour*totalFlightTime).toFixed(2);
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>





              <Grid xs={12}>
              <h2>Enter Previous Aeronautical Experience</h2>

              </Grid>
              <Grid item xs={12} style={{paddingTop:'2%'}}/>
              <Grid item xs={3}>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Dual G/H Hrs</InputLabel>
              <OutlinedInput
                  id="soloGH"
                  type="number"
                  autoFocus="true"
                  inputProps={{ step: "0.1", min:"0.0" }}
                  fullWidth="true"
                  style={{marginTop:'2%',marginBottom:'2%'}}
                  value={values.dualGH}
                  onChange={handleChange('dualGH')}
                  endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                  aria-describedby="Solo General Handling"

                  labelWidth={0}
                  />
                  </FormControl>

                </Grid>

                  <Grid item xs={3}>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Dual Nav Hrs</InputLabel>
                  <OutlinedInput
                      id="soloGH"
                      type="number"
                      autoFocus="true"
                      inputProps={{ step: "0.1", min:"0.0" }}
                      fullWidth
                      style={{marginTop:'2%',marginBottom:'2%'}}
                      value={values.dualNav}
                      onChange={handleChange('dualNav')}
                      endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                      aria-describedby="Solo General Handling"

                      labelWidth={0}
                      />
                      </FormControl></Grid>

                      <Grid item xs={3}>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Solo G/H Hrs</InputLabel>
                      <OutlinedInput
                          id="soloGH"
                          type="number"
                          autoFocus="true"
                          inputProps={{ step: "0.1", min:"0.0" }}
                          fullWidth
                          style={{marginTop:'2%',marginBottom:'2%'}}
                          value={values.soloGH}
                          onChange={handleChange('soloGH')}
                          endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                          aria-describedby="Solo General Handling"

                          labelWidth={0}
                          />
                          </FormControl></Grid>


                          <Grid item xs={3}>
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Solo Nav Hrs</InputLabel>
                          <OutlinedInput
                              id="soloGH"
                              type="number"
                              autoFocus="true"
                              inputProps={{ step: "0.1", min:"0.0" }}
                              fullWidth
                              style={{marginTop:'2%',marginBottom:'2%'}}
                              value={values.soloNav}
                              onChange={handleChange('soloNav')}
                              endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                              aria-describedby="Solo General Handling"

                              labelWidth={0}
                              />
                              </FormControl></Grid>
                              <Grid item xs={4}>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Total Flight Hours</InputLabel>
                              <OutlinedInput
                              disabled
                                  id="flightHours"
                                  type="number"
                                  autoFocus="true"
                                  fullWidth
                                  style={{marginTop:'2%',marginBottom:'2%'}}
                                  value={totalFlightTime}
                                  endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
                                  aria-describedby="Engine hours as per the flight clock"

                                  labelWidth={0}
                                  />             </FormControl></Grid>


                                  <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                      id="comments"
                                      label="Comments(detailed description of previous training history)"
                                      name="comments"
                                      margin="normal"
                                      variant="outlined" style={{width:'100%'}}
                                      onChange={handleChange('commts')}
                                      value={values.commts}
                                      /></Grid>






                  <Grid item xs={12}>
                  <RaisedButton fullWidth style={{height:60}} label="Next" primary={true} onClick={this.continue}/>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>



</Grid>
              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SelectEngineHours
