import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AvatarPic from './avatar.png';
import { validate } from 'indicative/validator';
import passwordHash from 'password-hash';



export class ResetAccPassword extends Component{

        constructor(props) {
          super(props);
          this.state = {value:0,
                        errors:''};
                      }




        back = e => {
          e.preventDefault();
          this.props.prevStep();
        }

        handleChange = (event, index, value) => this.setState({value});

      handleSubmit= e => {
        e.preventDefault();
        console.log(this.state);

        const data = this.state;
        const rules= {password:'required|string|min:8|max:20|confirmed|alpha_numeric'}
        const messages={
          required              : 'Password cannot be empty',
          min                   : 'Requires at least 8 characters',
          max                   : 'Password too long',
          alpha_numeric         : 'Illegal Characters in the password',
          'password.confirmed'  : 'The password confirmation does not match the entered password',
                }

        validate(data, rules, messages)
        .then(()=>{
          const hashedPassword = passwordHash.generate(this.state.password,{saltLength:10})
          console.log(hashedPassword)
          console.log(passwordHash.verify(this.state.password, hashedPassword))
          this.setState({errors:''})
          this.props.handleChangeInput("kiHash",hashedPassword)
          this.props.handleChangeInput("isTemp","true")

          this.props.setStep(2601);
        })

        .catch(errors =>{
          console.log(errors);

          const formattedErrors = {}
          errors.forEach( error => formattedErrors[error.field] = error.message)
          this.setState({errors: formattedErrors})
        })

      }

      password1= e => {
        this.setState({password:e.target.value})
      console.log(this.state.password);
      }

      password2= e => {
        this.setState({password_confirmation:e.target.value})
      console.log(this.state.password_confirmation);

      }

  render() {
    const {values,handleChange} = this.props;

    return(

      <MuiThemeProvider>
        <React.Fragment>




          <div>
          <Grid container spacing={2} style={{padding:'10%'}}>
          <Grid item xs={2}/>
          <Grid item xs={8}>


          <Paper style={{opacity:0.89}}>


          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs style={{textAlign:'center', padding:'2%'}}>

              <h2>
                Set a temporary Password for the Account below
              </h2>

              <Avatar style={{ margin: 20 }} src={values.profilePic} size={150}/>

              <TextField
                  autoFocus
                  margin="dense"
                  id="password"
                  label="Enter New Password"
                  type="password"
                  onChange={this.password1}
                  value={this.passValue1}
                  fullWidth
              />
              <TextField
                  autoFocus
                  id="password_confirmation"
                  margin="dense"
                  id="insPass"
                  label="Confirm New Password"
                  type="password"
                  onChange={this.password2}

                  fullWidth
              />
            <Grid item xs={12}>
            <p style={{color:"red"}}>
            {this.state.errors.password}
            </p>
            </Grid>


            <Grid item>
            <RaisedButton label="Continue" onClick={this.handleSubmit} primary={true}  fullWidth='true' style={{marginTop:20}}/>
            <Button variant="outlined" color="GREEN" fullWidth='true' style={{marginTop:20}} onClick={this.back}>Go Back  </Button>

            </Grid>
              </Grid>

            </Grid>
          </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}/>



        </Grid>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default ResetAccPassword
