import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HeliFleet from './zResourcesimg/helicopter.png';
import InsIcon from './zResourcesimg/pilot.png';
import AccIcon from './zResourcesimg/helmet.png';
import FinIcon from './zResourcesimg/profit.png';
import TrIcon from './zResourcesimg/steering-wheel.png';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from 'material-ui/Avatar';
import IdleTimer from 'react-idle-timer'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from 'material-ui/TextField/TextField';
import Slide from '@material-ui/core/Slide';
import PasswordHash from 'password-hash';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';



export class NavDrawer extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0,
                  authenticate:false,
                  pass:'',
                  passerr:'none',
                  drawer:false,
                  backdrop:"none",
                  authenticateStatus:"Authenticate",
                  anchorEl:false};
    this.idleTimer = null

    this.onIdle = this._onIdle.bind(this)


  }

toggleDrawer = (open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({drawer: open });
  }
handleClose=()=>{
  this.setState({anchorEl:false})
}
handleMenu=()=>{
  this.setState({anchorEl:true})
}
setStep=(step)=>e=>{
  e.preventDefault();
  this.props.setStep(step);
}
passwordSet=e=>{
  e.preventDefault();
  this.setState({pass:e.target.value});
}



 _onIdle=e=> {
   this.setState({authenticate:true})


 }

 logout=e=> {
   e.preventDefault();
   this.props.setStep(1002);


 }
 logout2=e=> {
   this.props.setStep(1002);


 }
 keyPress=(e)=>{
       if(e.keyCode == 13){
          this.continue();
          // put the login here
       }
    }

 reauth=e=>{
   e.preventDefault();
   this.setState({backdrop:"",authenticateStatus:"Authenticating "})
   const {values} = this.props;

   fetch(`${process.env.REACT_APP_SERVER_URL}/authKi?email=${values.loggedInUser.email}`, {
      headers: {
      'x-access-token': values.token
    }})
   .then(response => response.json())
   .then(response=>{if(response.data.length>0){
     const hashed= response.data[0].keypass;
     const isTemp= response.data[0].isTemp;

     this.setState({ passSecure    : PasswordHash.verify(this.state.pass,hashed)});

     if(this.state.passSecure==true && isTemp=="true"){
       this.props.setStep(1000)
     }else if(this.state.passSecure==true && isTemp=="false"){
       console.log("access granted")
       this.setState({backdrop:"none",authenticate:false,pass:"",passerr:"none",authenticateStatus:"Authenticate"})}

   else{this.setState({backdrop:"none",authenticate:true,passerr:"",authenticateStatus:"Authenticate"})}


 }})
   .catch(err=>console.log(err))
 }



render() {
      const {values} = this.props;
      switch (values.loggedInUser.accessLevel) {
        case "sa":
        return (

          <div>

          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <AppBar color='secondary' style={{zIndex:+2}}title={values.appBarTitle} onLeftIconButtonClick={this.toggleDrawer(true)}>
            <Toolbar>
            <div>
               <IconButton
               disabled
                 aria-label="account of current user"
                 aria-controls="menu-appbar"
                 aria-haspopup="true"
                 color="inherit"
               >
                 <Avatar  src={values.loggedInUser.profilePic} size={40}/>
               </IconButton>
              </div>


            </Toolbar>


          </AppBar>

          <Dialog
            fullScreen
            backdropDisabled
            TransitionComponent={Slide}
            open={this.state.authenticate}>
          <div style={{textAlign:'center',paddingTop:50, paddingLeft:"20%", paddingRight:"20%"}}>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.logout2}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Authentication Required!
          </DialogTitle>
          <DialogContent dividers>
          <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
          <Typography gutterBottom variant="subtitle1">
            {values.loggedInUser.fName} {values.loggedInUser.lName}
          </Typography>
         <Typography gutterBottom>
          Your session has timed out. Please enter your password below to re-authenticate
          </Typography>
          <Typography style={{display:this.state.passerr,color:"red"}}gutterBottom>
           Incorrect Password. Try again!
           </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="Pass"
            hintText="Enter Your Password"
            type="password"
            onKeyDown={this.keyPress}
            onChange={this.passwordSet}
            value={this.state.pass}
            fullWidth
            />
          </DialogContent>

          <DialogActions>
          <Button onClick={this.reauth} style={{display:"flex"}}color="primary">
          {this.state.authenticateStatus} <CircularProgress style={{display:this.state.backdrop}}/>
          </Button>
          <Button onClick={this.logout} style={{display:"flex"}}color="primary">
          Logout
          </Button>
          </DialogActions>
          </div>
          </Dialog>

            <SwipeableDrawer style={{zIndex:1}}open={this.state.drawer} onClose={this.toggleDrawer(false)}>
            <div
              role="presentation"
              style={{width:"100%"}}
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <List>

                  <ListItem but ton >

                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary=" "/>
                  </ListItem>

              </List>

                <List style={{marginTop:20}}>

                    <ListItem button onClick={this.setStep(1004)}>

                      <ListItemIcon><HomeIcon/></ListItemIcon>
                      <ListItemText primary="Home"/>
                    </ListItem>

                </List>



                <List>

                    <ListItem button onClick={this.setStep(2999)}>

                      <ListItemIcon><img src={TrIcon} style={{maxWidth:25,maxHeight:25,maxHeight:25}}/></ListItemIcon>
                      <ListItemText primary="Training Mgt" style={{marginRight:20}}/>
                    </ListItem>

                </List>

                  <List>

                      <ListItem button onClick={this.setStep(2000)}>

                        <ListItemIcon><img src={AccIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="Account Mgt" style={{marginRight:20}}/>
                      </ListItem>

                  </List>

                  <List>

                      <ListItem button onClick={this.setStep(4000)}>

                        <ListItemIcon><img src={InsIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="Instructor Mgt" style={{marginRight:20}}/>
                      </ListItem>

                  </List>


                  <List>

                      <ListItem button onClick={this.setStep(5000)}>

                        <ListItemIcon><img src={HeliFleet} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="Fleet Mgt" style={{marginRight:20}}/>
                      </ListItem>

                  </List>






              <Divider />
              <Divider style={{marginTop:50}} />
              <List>

                  <ListItem button onClick={this.setStep(1001)}>

                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary="Log out" style={{marginRight:20}}/>
                  </ListItem>

              </List>
            </div>
            </SwipeableDrawer>

          </div>
        )

        case "i":
        return (

          <div>

          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <AppBar style={{zIndex:+2}}title={values.appBarTitle} onLeftIconButtonClick={this.toggleDrawer(true)}>
            <Toolbar>
            <div>
               <IconButton
               disabled
                 aria-label="account of current user"
                 aria-controls="menu-appbar"
                 aria-haspopup="true"
                 color="inherit"
               >
                 <Avatar  src={values.loggedInUser.profilePic} size={40}/>
               </IconButton>
              </div>


            </Toolbar>


          </AppBar>

          <Dialog
            fullScreen
            backdropDisabled
            TransitionComponent={Slide}
            open={this.state.authenticate}>
          <div style={{textAlign:'center',paddingTop:50, paddingLeft:"20%", paddingRight:"20%"}}>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.logout2}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Authentication Required!
          </DialogTitle>
          <DialogContent dividers>
          <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
          <Typography gutterBottom variant="subtitle1">
            {values.loggedInUser.fName} {values.loggedInUser.lName}
          </Typography>
         <Typography gutterBottom>
          Your session has timed out. Please enter your password below to re-authenticate
          </Typography>
          <Typography style={{display:this.state.passerr,color:"red"}}gutterBottom>
           Incorrect Password. Try again!
           </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="Pass"
            hintText="Enter Your Password"
            type="password"
            onKeyDown={this.keyPress}
            onChange={this.passwordSet}
            value={this.state.pass}
            fullWidth
            />
          </DialogContent>

          <DialogActions>
          <Button onClick={this.reauth} style={{display:"flex"}}color="primary">
          {this.state.authenticateStatus} <CircularProgress style={{display:this.state.backdrop}}/>
          </Button>
          <Button onClick={this.logout} style={{display:"flex"}}color="primary">
          Logout
          </Button>
          </DialogActions>
          </div>
          </Dialog>

            <SwipeableDrawer style={{zIndex:1}}open={this.state.drawer} onClose={this.toggleDrawer(false)}>
            <div
              role="presentation"
              style={{width:"100%"}}
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <List>

                  <ListItem but ton >

                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary=" "/>
                  </ListItem>

              </List>

                <List style={{marginTop:20}}>

                    <ListItem button onClick={this.setStep(1004)}>

                      <ListItemIcon><HomeIcon/></ListItemIcon>
                      <ListItemText primary="Home"/>
                    </ListItem>

                </List>



                <List>

                    <ListItem button onClick={this.setStep(2999)}>

                      <ListItemIcon><img src={TrIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                      <ListItemText primary="Training Mgt" style={{marginRight:20}}/>
                    </ListItem>

                </List>

                  <List>

                      <ListItem button onClick={this.setStep(2000)}>

                        <ListItemIcon><img src={AccIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="Account Mgt" style={{marginRight:20}}/>
                      </ListItem>

                  </List>

                  <List>

                      <ListItem button onClick={this.setStep(4000)}>

                        <ListItemIcon><img src={InsIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="Instructor Mgt" style={{marginRight:20}}/>
                      </ListItem>

                  </List>








              <Divider />
              <Divider style={{marginTop:50}} />
              <List>

                  <ListItem button onClick={this.setStep(1001)}>

                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary="Log out" style={{marginRight:20}}/>
                  </ListItem>

              </List>
            </div>
            </SwipeableDrawer>

          </div>
        )

        case "p":
        return (

          <div>

          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <AppBar style={{zIndex:+2}}title={values.appBarTitle} onLeftIconButtonClick={this.toggleDrawer(true)}>
            <Toolbar>
            <div>
               <IconButton
               disabled
                 aria-label="account of current user"
                 aria-controls="menu-appbar"
                 aria-haspopup="true"
                 color="inherit"
               >
                 <Avatar  src={values.loggedInUser.profilePic} size={40}/>
               </IconButton>
              </div>


            </Toolbar>


          </AppBar>

          <Dialog
            fullScreen
            backdropDisabled
            TransitionComponent={Slide}
            open={this.state.authenticate}>
          <div style={{textAlign:'center',paddingTop:50, paddingLeft:"20%", paddingRight:"20%"}}>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.logout2}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Authentication Required!
          </DialogTitle>
          <DialogContent dividers>
          <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
          <Typography gutterBottom variant="subtitle1">
            {values.loggedInUser.fName} {values.loggedInUser.lName}
          </Typography>
         <Typography gutterBottom>
          Your session has timed out. Please enter your password below to re-authenticate
          </Typography>
          <Typography style={{display:this.state.passerr,color:"red"}}gutterBottom>
           Incorrect Password. Try again!
           </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="Pass"
            hintText="Enter Your Password"
            type="password"
            onKeyDown={this.keyPress}
            onChange={this.passwordSet}
            value={this.state.pass}
            fullWidth
            />
          </DialogContent>

          <DialogActions>
          <Button onClick={this.reauth} style={{display:"flex"}}color="primary">
          {this.state.authenticateStatus} <CircularProgress style={{display:this.state.backdrop}}/>
          </Button>
          <Button onClick={this.logout} style={{display:"flex"}}color="primary">
          Logout
          </Button>
          </DialogActions>
          </div>
          </Dialog>

            <SwipeableDrawer style={{zIndex:1}}open={this.state.drawer} onClose={this.toggleDrawer(false)}>
            <div
              role="presentation"
              style={{width:"100%"}}
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <List>

                  <ListItem but ton >

                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary=" "/>
                  </ListItem>

              </List>

                <List style={{marginTop:20}}>

                    <ListItem button onClick={this.setStep(1004)}>

                      <ListItemIcon><HomeIcon/></ListItemIcon>
                      <ListItemText primary="Home"/>
                    </ListItem>

                </List>



                  <List>

                      <ListItem button onClick={this.setStep(2500)}>

                        <ListItemIcon><img src={AccIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="View Account" style={{marginRight:20}}/>
                      </ListItem>

                  </List>











              <Divider />
              <Divider style={{marginTop:50}} />
              <List>

                  <ListItem button onClick={this.setStep(1001)}>

                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary="Log out" style={{marginRight:20}}/>
                  </ListItem>

              </List>
            </div>
            </SwipeableDrawer>

          </div>
        )

        case "s":
        return (

          <div>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <AppBar style={{zIndex:+2}}title={values.appBarTitle} onLeftIconButtonClick={this.toggleDrawer(true)}>
            <Toolbar>
            <div>
               <IconButton
               disabled
                 aria-label="account of current user"
                 aria-controls="menu-appbar"
                 aria-haspopup="true"
                 color="inherit"
               >
                 <Avatar  src={values.loggedInUser.profilePic} size={40}/>
               </IconButton>
              </div>


            </Toolbar>


          </AppBar>
          <Dialog
            fullScreen
            backdropDisabled
            TransitionComponent={Slide}
            open={this.state.authenticate}>
          <div style={{textAlign:'center',paddingTop:50, paddingLeft:"20%", paddingRight:"20%"}}>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.logout2}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Authentication Required!
          </DialogTitle>
          <DialogContent dividers>
          <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
          <Typography gutterBottom variant="subtitle1">
            {values.loggedInUser.fName} {values.loggedInUser.lName}
          </Typography>
         <Typography gutterBottom>
          Your session has timed out. Please enter your password below to re-authenticate
          </Typography>
          <Typography style={{display:this.state.passerr,color:"red"}}gutterBottom>
           Incorrect Password. Try again!
           </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="Pass"
            hintText="Enter Your Password"
            type="password"
            onKeyDown={this.keyPress}
            onChange={this.passwordSet}
            value={this.state.pass}
            fullWidth
            />
          </DialogContent>

          <DialogActions>
          <Button onClick={this.reauth} style={{display:"flex"}}color="primary">
          {this.state.authenticateStatus} <CircularProgress style={{display:this.state.backdrop}}/>
          </Button>
          <Button onClick={this.logout} style={{display:"flex"}}color="primary">
          Logout
          </Button>
          </DialogActions>
          </div>
          </Dialog>
            <SwipeableDrawer style={{zIndex:1}}open={this.state.drawer} onClose={this.toggleDrawer(false)}>
            <div
              role="presentation"
              style={{width:"100%"}}
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <List>

                  <ListItem but ton >

                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary=" "/>
                  </ListItem>

              </List>

                <List style={{marginTop:20}}>

                    <ListItem button onClick={this.setStep(1004)}>

                      <ListItemIcon><HomeIcon/></ListItemIcon>
                      <ListItemText primary="Home"/>
                    </ListItem>

                </List>

                <List>

                    <ListItem button onClick={this.setStep(2999)}>

                      <ListItemIcon><img src={TrIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                      <ListItemText primary="My Training" style={{marginRight:20}}/>
                    </ListItem>

                </List>




                  <List>

                      <ListItem button onClick={this.setStep(6000)}>

                        <ListItemIcon><img src={FinIcon} style={{maxWidth:25,maxHeight:25}}/></ListItemIcon>
                        <ListItemText primary="My Finances" style={{marginRight:20}}/>
                      </ListItem>

                  </List>



              <Divider />
              <Divider style={{marginTop:50}} />
              <List>

                  <ListItem button onClick={this.setStep(1001)}>

                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary="Log out" style={{marginRight:20}}/>
                  </ListItem>

              </List>
            </div>
            </SwipeableDrawer>

          </div>
        )

          break;
        default:
        return (

          <div>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <AppBar style={{zIndex:+2}}title={values.appBarTitle} onLeftIconButtonClick={this.toggleDrawer(true)}>
            <Toolbar>
            <div>
               <IconButton
               disabled
                 aria-label="account of current user"
                 aria-controls="menu-appbar"
                 aria-haspopup="true"
                 color="inherit"
               >
                 <Avatar  src={values.loggedInUser.profilePic} size={40}/>
               </IconButton>
              </div>


            </Toolbar>


          </AppBar>
          <Dialog
            fullScreen
            backdropDisabled
            TransitionComponent={Slide}
            open={this.state.authenticate}>
          <div style={{textAlign:'center',paddingTop:50, paddingLeft:"20%", paddingRight:"20%"}}>
          <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.logout2}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Authentication Required!
          </DialogTitle>
          <DialogContent dividers>
          <Avatar style={{ margin: 20 }} src={values.loggedInUser.profilePic} size={150}/>
          <Typography gutterBottom variant="subtitle1">
            {values.loggedInUser.fName} {values.loggedInUser.lName}
          </Typography>
          <Typography gutterBottom>
          Your session has timed out. Please enter your password below to re-authenticate
          </Typography>
          <Typography style={{display:this.state.passerr,color:"red"}}gutterBottom>
           Incorrect Password. Try again!
           </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="Pass"
            hintText="Enter Your Password"
            type="password"
            onKeyDown={this.keyPress}
            onChange={this.passwordSet}
            value={this.state.pass}
            fullWidth
            />
          </DialogContent>

          <DialogActions>
          <Button onClick={this.reauth} style={{display:"flex"}}color="primary">
          {this.state.authenticateStatus} <CircularProgress style={{display:this.state.backdrop}}/>
          </Button>
          <Button onClick={this.logout} style={{display:"flex"}}color="primary">
          Logout
          </Button>
          </DialogActions>
          </div>
          </Dialog>
            <SwipeableDrawer style={{zIndex:1}}open={this.state.drawer} onClose={this.toggleDrawer(false)}>
            <div
              role="presentation"
              style={{width:"100%"}}
              onClick={this.toggleDrawer(false)}
              onKeyDown={this.toggleDrawer(false)}
            >
              <List>

                  <ListItem but ton >

                    <ListItemIcon></ListItemIcon>
                    <ListItemText primary=" "/>
                  </ListItem>

              </List>

                <List style={{marginTop:20}}>

                    <ListItem button onClick={this.setStep(1004)}>

                      <ListItemIcon><HomeIcon/></ListItemIcon>
                      <ListItemText primary="Home"/>
                    </ListItem>

                </List>





              <Divider />
              <Divider style={{marginTop:50}} />
              <List>

                  <ListItem button onClick={this.setStep(1001)}>

                    <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                    <ListItemText primary="Log out" style={{marginRight:20}}/>
                  </ListItem>

              </List>
            </div>
            </SwipeableDrawer>

          </div>
        )

      }



      }

}
export default NavDrawer;
