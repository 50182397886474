import React, { Component } from 'react';
import SelectStudentForm from './SelectStudentForm';
import LessonOne from './LessonOne';
import SignIns from './SignIns';
import SignStu from './SignStu.js';
import FormReview from './FormReview';
import Success from './Success';
import SelectForm from './SelectForm';
import EffectsOfControls from './forms/01_EffectsOfControls';
import Coordination from './forms/02_Coordination';
import AdvancedCoordination from './forms/03_AdvancedCoordination';
import Hover from './forms/04_Hover';
import HoverTurnsAndHoverTaxi from './forms/05_HoverTurnsAndHoverTaxi';
import LandingsAndLiftOffs from './forms/06_LandingsAndLiftOffs';
import Transitions from './forms/07_Transitions';
import Circuits from './forms/08_Circuits';
import MaximumPerformanceTakeOffsAndSteepApproaches from './forms/09_MaximumPerformanceTakeOffsAndSteepApproaches';
import LimitedPowerOperations from './forms/10_LimitedPowerOperations';
import BasicAutorotation from './forms/11_BasicAutorotation';
import EngineFailureInTheHoverAndHoverTaxi from './forms/12_EngineFailureInTheHoverAndHoverTaxi';
import PracticeForcedLandings from './forms/13_PracticeForcedLandings';
import TailRotorFailures from './forms/14_TailRotorFailures';
import JammedFlightControls from './forms/15_JammedFlightControls';
import AdvancedEmergencies from './forms/16_AdvancedEmergencies';
import CircuitsPreSolo from './forms/17_CircuitsPreSolo';
import FirstSoloCircuit from './forms/18_FirstSoloCircuit';
import CircuitConsolidation from './forms/19_CircuitConsolidation';
import SoloCircuits from './forms/20_SoloCircuits';
import CircuitConsolidation2 from './forms/21_CircuitConsolidation2';
import SoloCircuits2 from './forms/22_SoloCircuits2';
import CircuitConsolidation3 from './forms/23_CircuitConsolidation3';
import SoloCircuits3 from './forms/24_SoloCircuits3';
import SlopeLandings from './forms/25_SlopeLandings';
import ConfinedAreas from './forms/26_ConfinedAreas';
import SteepTurns from './forms/27_SteepTurns';
import PreTrainingAreaSolo from './forms/28_PreTrainingAreaSolo';
import FirstTrainingAreaSolo from './forms/29_FirstTrainingAreaSolo';
import ConfinedAreasConsolidation from './forms/30_ConfinedAreasConsolidation';
import TrainingAreaSolo from './forms/31_TrainingAreaSolo';
import EmergenciesConsolidation from './forms/32_EmergenciesConsolidation';
import TrainingAreaSolo2 from './forms/33_TrainingAreaSolo2';
import NavEx1 from './forms/34_NavEx1';
import NavEx2 from './forms/35_NavEx2';
import NavEx3 from './forms/36_NavEx3';
import NavEx4FirstSolo from './forms/37_NavEx4FirstSolo';
import NavEx5 from './forms/38_NavEx5';
import NavEx6SoloNav12DME from './forms/39_NavEx6SoloNav12DME';
import NavEx7 from './forms/40_NavEx7';
import NavEx8SoloNavR555 from './forms/41_NavEx8SoloNavR555';
import NavEx9 from './forms/42_NavEx9';
import NavEx10SoloNav from './forms/43_NavEx10SoloNav';
import NavEx11 from './forms/44_NavEx11';
import NavEx12SoloNav from './forms/45_NavEx12SoloNav';
import NavEx13 from './forms/46_NavEx13';
import NavEx14SoloNav from './forms/47_NavEx14SoloNav';
import DualConsolidation from './forms/48_DualConsolidation';
import SoloConsolidation from './forms/49_SoloConsolidation';
import PreCPL from './forms/50_PreCPL';
import AccCompetency from './forms/51_AccCompetency';








export class AddRecord extends Component{
state ={
  stu_name:'',
  stu_arn:'',
  ins_name:'',
  ins_arn:'',
  rego:'',
  fl_date:'',
  fl_time:'',
  fl_clock:'',
  current_date:'',
  pfk_bool:'',
  nextLesson_bool:'',
  commts:'',
  open: false,
  formId:'',
  formRating:''
}

//proceed to next step
nextStep = () => {
  const {step} = this.state;
  this.setState({
    step: step + 1
  });
}
//back to previous step
prevStep = () => {
  const {step} = this.state;
  this.setState({
    step: step - 1
  });
}

startAgain = () => {
  const {step} = this.state;
  this.setState({
    stu_name:'',
    stu_arn:'',
    ins_name:'',
    ins_arn:'',
    rego:'',
    fl_date:'',
    fl_time:'',
    fl_clock:'',
    current_date:'',
    pfk_bool:'',
    nextLesson_bool:'',
    commts:'',
    open: false,
    formId:'',
    formRating:''
  });
}
setFormId= (id) => {
  const {formId} = this.state;
  this.setState({
    formId: id})
  }

setRadio= (formValues) => {
  const {formRating} = this.state;
  this.setState({
    formRating: formValues})
  }

handleChangeRadio =(cid,newRate) =>{
  this.setState(prevState => ({

  formRating: prevState.formRating.map(
    el => el.cid === cid? { ...el, rate: newRate }: el
  )


}))

}



//handle change
handleChange = input => e => {
  this.setState({[input]: e.target.value});
}




  render(){
    const { step } = this.state;
    const { open, formId, formRating, stu_name, stu_arn, ins_name, ins_arn, fl_date, fl_time, fl_clock, commts, current_date, pfk_bool, nextLesson_bool, rego, } = this.state;
    const values = {open, formId, formRating, stu_name, stu_arn, ins_name, ins_arn, fl_date, fl_time, fl_clock, commts, current_date, pfk_bool, nextLesson_bool, rego, };

    switch (step) {
      case 3000:
        return (
          <SelectStudentForm
          nextStep={this.nextStep}
          handleChange={this.handleChange}
          values={values}
          />
        )
        case 3002:
          return (
            <SelectForm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            startAgain={this.startAgain}
            handleChange={this.handleChange}
            values={values}
            setRadio={this.setRadio} //*set default values of the form to the state array object*//
            setFormId={this.setFormId}
            />
          )
        case 3003:
          return(
          <LessonOne
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          startAgain={this.startAgain}
          setRadio={this.setRadio} //*set default values of the form to the state array object*//
          defaultRadio={this.defaultRadio}
          handleChange={this.handleChange}
          handleChangeRadio={this.handleChangeRadio}
          values={values}

          />
        )
        case 3004:
          return (
          <SignIns
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          startAgain={this.startAgain}
          handleChange={this.handleChange}
          values={values}
          />
        )
        case 3005:
          return (
            <SignStu
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            startAgain={this.startAgain}
            handleChange={this.handleChange}
            values={values}
            />
          )
        case 3006:
            return (
              <FormReview
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              startAgain={this.startAgain}
              handleChange={this.handleChange}
              values={values}
              />
            )
        case 3007:
                return (
                  <AccCompetency
                  nextStep={this.nextStep}
                  prevStep={this.prevStep}
                  startAgain={this.startAgain}
                  handleChange={this.handleChange}
                  values={values}
                  />
                )
        default:
          return
    }
  }
}

export default AddRecord;
