import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import TimePicker from 'material-ui/TimePicker';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ActionFavorite from 'material-ui/svg-icons/action/favorite';
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border';








export class StudentFlightHistory extends Component{



  constructor(props) {
    super(props);
    this.state={value:0,dateErr:false,dateFrom:parseInt('-2208961860000'),dateTo:Date.now()}
  }

/*method to select what conditions are displayed depending on formID selection from the previous page*/


  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.startAgain();
  }


logFromDate = (date)=>{
  this.setState({dateFrom: moment(date).valueOf()})
}

logToDate = (date)=>{
    this.setState({dateTo: moment(date).valueOf()})
}

searchbyDate = e =>{
  e.preventDefault();
  if (this.state.logToDate<this.state.logFromDate) {
    this.setState({dateErr:true})

  } else {
    this.props.createRadioGroups(this.state.dateFrom,this.state.dateTo);

  }
}



  render() {
    const { values, handleChange, defaultButton, formRating,createRadioGroups} = this.props;
    const ratingState= values.formRating;



    return(



      <MuiThemeProvider>
        <React.Fragment>



          <div>


            <Grid item xs={12} style={{marginLeft:'5%'}}><h1>Flight History </h1></Grid>
           <Grid container  style={{marginLeft:'2%',marginRight:'2%',paddingLeft:'2%',paddingRight:'2%'}}>
           <Grid item xs={4}></Grid><Grid item xs={3}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>


                    <KeyboardDateTimePicker
                        required
                      disableFuture
                      ampm={false}
                      autoOk={true}
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy HH:mm"
                      margin="normal"
                      id="dateFrom"
                      label="From"
                      onChange={this.logFromDate}
                      value={this.state.dateFrom}
                      style={{marginTop:'2%',marginBottom:'2%'}}
                      />

                      </MuiPickersUtilsProvider></Grid>

                      <Grid item xs={3}>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>



                              <KeyboardDateTimePicker
                              InputLabelProps={{ shrink: true }}
                              autoOk={true}
                              ampm={false}
                              variant="inline"
                              inputVariant="outlined"
                              label="Date to"
                              value={moment(this.state.dateTo).valueOf()}
                              onChange={this.logToDate}
                              onError={console.log}
                              style={{marginTop:'2%',marginBottom:'2%'}}
                              disableFuture
                              format="dd/MM/yyyy HH:mm"
                                />
                                </MuiPickersUtilsProvider></Grid>

                              </Grid>


            <Paper style={{marginLeft:'2%',marginRight:'2%',paddingLeft:'2%',paddingRight:'2%'}}>
            <Grid container  spacing={1}>


            <Grid item xs={1}> <h4>Record ID</h4></Grid>
            <Grid item xs={1}> <h4>Student</h4></Grid>
            <Grid item xs={1}> <h4>Date & Time</h4></Grid>
            <Grid item xs={1}> <h4>Lesson ID</h4></Grid>
            <Grid item xs={1}> <h4>Rego & Instructor</h4></Grid>
            <Grid item xs={1}> <h4>Hours</h4></Grid>
            <Grid item xs={1}> <h4>Total Time</h4></Grid>
            <Grid item xs={1}> <h4>Cost</h4></Grid>
            <Grid item xs={3}> <h4>Comments</h4></Grid>

            </Grid>

          <GridList style={{height:400}}>
              <Grid item xs={12}>{createRadioGroups(moment(this.state.dateFrom).valueOf(), moment(this.state.dateTo).valueOf())}</Grid>
              </GridList>



              </Paper>
                <Grid item xs={12} style={{ margin: "2%"}}>
                <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                <Dialog
                  fullWidth="true"
                  maxWidth="md"
                  aria-labelledby="customized-dialog-title"
                  open={this.state.dateErr}
                  >
                  <DialogContent style={{textAlign:'center'}}id="customized-dialog-title" >
                    <Typography  style={{padding:'2%'}}variant="h5">  Error</Typography>
                    <Typography style={{padding:'2%'}} variant="body2">  "Date to" cannot be before "Date from"</Typography>

                        </DialogContent>
                <DialogActions>



                 <Button fullWidth style={{marginBottom:'2%'}} onClick={e=>{e.preventDefault();this.setState({dateErr:false})}} color="primary" >
                   OK
                 </Button>

                </DialogActions>
                </Dialog>

                </Grid>

          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default StudentFlightHistory
