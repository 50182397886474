import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from '@material-ui/core/TextField';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from '@material-ui/core/InputLabel';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import * as moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { validations, validateAll } from 'indicative/validator';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export class EditStudentAccount extends Component{

  constructor(props) {
    super(props);
    const {values, handleChangeInput}=this.props;
  if(values.loggedInUser.accessLevel=="s" ||values.loggedInUser.accessLevel=="p"){
    this.state = {value         : 0,
      medical1   : false,
      medical2   : false,
      medical3   : false,
      medical1err : false,
      medical2err : false,
      medical3err : false,
      disclaimer    : true,
      fNameerr       : "",
      mNameserr      : "",
      lNameerr       : "",
      doberr         : "",
      emailerr       : "",
      mAddresserr    : "",
      rAddresserr    : "",
      phoneerr       : "",
      nextKinerr     : "",
      nextKinPhoneerr: "",
      cWeighterr     : "",
      stu_arnerr     : "",
      LicTypeerr     : "",
      accNoteserr    : "",
      bloodTypeerr   : "",
      CLicTypeerr    :  "",
      oldEmail       : values.email,
                  editdisabled  :true,
                  edit          :"none"};
  }else{
    this.state = {value         : 0,
      medical1   : false,
      medical2   : false,
      medical3   : false,
      medical1err : false,
      medical2err : false,
      medical3err : false,
      disclaimer    : true,
      fNameerr       : "",
      mNameserr      : "",
      lNameerr       : "",
      doberr         : "",
      emailerr       : "",
      mAddresserr    : "",
      rAddresserr    : "",
      phoneerr       : "",
      nextKinerr     : "",
      nextKinPhoneerr: "",
      cWeighterr     : "",
      stu_arnerr     : "",
      LicTypeerr     : "",
      accNoteserr    : "",
      bloodTypeerr   : "",
      CLicTypeerr    : "",
      oldEmail       : values.email,
                  editdisabled  :false,
                  edit          :""};
  }
}





  continue = e => {
    const {values}=this.props;
    if (this.state.oldEmail!=values.email) {

      this.props.handleChangeInput("emailChanged",true);

      if (this.state.medical1err=="" && this.state.medical2err=="" && this.state.medical3err=="" && this.state.fNameerr=="" && this.state.mNameserr=="" && this.state.lNameerr=="" && this.state.doberr=="" && this.state.emailerr=="" && this.state.mAddresserr=="" && this.state.rAddresserr=="" && this.state.phoneerr=="" && this.state.nextKinerr=="" && this.state.nextKinPhoneerr=="" && this.state.cWeighterr=="" && this.state.stu_arnerr=="" && this.state.LicTypeerr=="" && this.state.accNoteserr=="" && this.state.bloodTypeerr=="")  {
        console.log("next stepping")
        this.props.setStep(2505);
      } else {
          console.log(this.state)
          console.log('err');
      }

    } else {
      if (this.state.medical1err=="" && this.state.medical2err=="" && this.state.medical3err=="" && this.state.fNameerr=="" && this.state.mNameserr=="" && this.state.lNameerr=="" && this.state.doberr=="" && this.state.emailerr=="" && this.state.mAddresserr=="" && this.state.rAddresserr=="" && this.state.phoneerr=="" && this.state.nextKinerr=="" && this.state.nextKinPhoneerr=="" && this.state.cWeighterr=="" && this.state.stu_arnerr=="" && this.state.LicTypeerr=="" && this.state.accNoteserr=="" && this.state.bloodTypeerr=="")  {
        console.log("next stepping")
        this.props.setStep(2505);
      } else {
          console.log(this.state)
          console.log('err');
      }

    }


}

checkedFunc=nam=>e=>{
  if (e.target.checked==false) {
this.props.handleChangeInput(nam,moment().format('MM/DD/YYYY'))
this.props.handleChangeInput(nam+"Checked",e.target.checked)
this.setState({[nam]:e.target.checked})
}else{
  this.props.handleChangeInput(nam+"Checked",e.target.checked)
  this.setState({[nam]:e.target.checked})

}
}
  retakePassport = (e) => {
    e.preventDefault()
    this.props.setStep(2502);
  }

  updateCardInfo = (e) => {
    e.preventDefault()
    this.props.getCCInfo(2850);
  }


resetPassword = (e) => {
  e.preventDefault()
  this.props.setStep(2600);
}

profPic = (e) => {
  e.preventDefault()
  this.props.setStep(2700);
}

redoSignature = (e) => {
  e.preventDefault()
  this.props.setStep(2800);
}


  back = e => {
    const {values}=this.props;
    if(values.loggedInUser.accessLevel=="s"){
      e.preventDefault();
      this.props.clearState();
      this.props.setStep(1004)
    }else{
    e.preventDefault();
    this.props.clearState();
    this.props.prevStep();
  }}


    validate=e=>{
      e.preventDefault();
       this.validateEmail()

    }
    handleChange = field => e => {
      console.log(e.target.value)
      const { values, log,handleChangeInput, handlePicChange} = this.props;

    e.preventDefault();
    this.setState({[field+"err"]:''})
    handleChangeInput(field,e.target.value);
    /// Do something here
  }

  log =  e => {
    console.log(e)
    const { log} = this.props;

  this.setState({dob:''})
  log(e);
  /// Do something here
  }



    validateEmail=e=>{
      const { values} = this.props;
      const data = values
      const rules= {fName       : 'string|required|string|max:50',
                    mNames      : 'string|string|max:50',
                    lName       : 'string|required|string|max:50',
                    dob         : 'date|required',
                    mAddress    : 'required|string|min:2|max:300',
                    rAddress    : 'required|string|min:2|max:300',
                    phone       : 'required|max:14',
                    nextKin     : 'required|string|max:300',
                    nextKinPhone: 'required|number',
                    cWeight     : 'float',
                    LicType     : 'max:7|required',
                    stu_arn     : 'number|required',
                    accNotes    : 'max:5000',
                    bloodType   : 'required',
                    email       : 'required|email'

    }
      const messages={
        alpha                 : 'Alphabetical characters only!',
        date1                 : 'Invalid Date',
        number                : 'Numbers Only',
        float                 : "That's invalid",
        required              : 'This Cannot be empty',
        min                   : "That's too short!",
        max                   : "That's too long!",
        alpha_numeric         : 'Illegal Characters in the password',
        'password.confirmed'  : 'The password confirmation does not match the entered password',
              }

              validateAll(data, rules, messages)
              .catch(errors =>{
                console.log(errors);

                const formattedErrors = {}
                errors.forEach(error =>this.setState({[error.field+"err"]:error.message})
                )




      })
      .then(this.continue)

    }









  render() {
    const { values, handleChange, log} = this.props;
    const xyz=values.searchJson.fname;
    console.log(xyz)
    return(

      <MuiThemeProvider>
        <React.Fragment >





          <div>

              <Paper style={{margin:'5%',marginTop:'0', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Account Details</h3>
              </Grid>
              <Grid item xs={12} style={{textAlign:'center', padding:'2%'}}>
              <Avatar src={values.profilePic} size={200} />
              </Grid>
              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="First_Name"
                  label="First Name"
                  type="fname"
                  name="First Name"
                  autoComplete="fname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('fName')}
                  value={values.fName}
                  error={this.state.fNameerr!=""}
                  helperText={this.state.fNameerr != "" ? this.state.fNameerr : ''}

                  /></Grid>


              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editdisabled}
                  id="Middle_Names"
                  label="Middle Names"
                  type="mname"
                  name="Middle Name"
                  autoComplete="mname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('mNames')}
                  value={values.mNames}
                  error={this.state.mNameserr!=""}
                  helperText={this.state.mNameserr != "" ? this.state.mNameserr : ''}

                  /></Grid>
              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="Last_Name"
                  label="Last Name"
                  type="lname"
                  name="Last Name"
                  autoComplete="lname"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('lName')}
                  value={values.lName}
                  error={this.state.lNameerr!=""}
                  helperText={this.state.lNameerr != "" ? this.state.lNameerr : ''}

                  /></Grid>


              <Grid item xs={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>

              <KeyboardDatePicker
                  disabled={this.state.editdisabled}
                  required
                disableFuture
                autoOk={true}
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="dob_picker"
                label="Date Of Birth"
                onChange={this.log}
                value={values.dob}
                error={this.state.doberr!=""}
                helperText={this.state.doberr != "" ? this.state.doberr : ''}
                style={{width:'100%' ,marginTop:'4.5%'}}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                /></MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={4}>
              <TextField

                  id="Email"
                  label="E-mail"
                  type="email"
                  name="email"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('email')}
                  value={values.email}
                  /></Grid>








              <Grid item xs={2}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="phone"
                  label="phone"
                  type="phone"
                  name="phone"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('phone')}
                  value={values.phone}
                  error={this.state.phoneerr!=""}
                  helperText={this.state.phoneerr != "" ? this.state.phoneerr : ''}
                  /></Grid>

              <Grid item xs={2}>
              <FormControl
                required='true'
                variant="outlined"
                style={{width:'100%',marginTop:'9%'}}>

                    <InputLabel  htmlFor="outlined-age-simple">
                        Blood Type
                        </InputLabel>


                      <Select
                          disabled={this.state.editdisabled}
                      onChange={this.handleChange('bloodType')}
                      value={values.bloodType}
                      error={this.state.bloodTypeerr!=""}
                      helperText={this.state.bloodTypeerr != "" ? this.state.bloodTypeerr : ''}

                    inputProps={{
                      name: 'bloodType',
                      id: 'bloodType',
                    }}
                        >
                        <MenuItem value={"x"}>Unsure</MenuItem>
                        <MenuItem value={"A1"}>A+</MenuItem>
                        <MenuItem value={"A0"}>A-</MenuItem>
                        <MenuItem value={"B1"}>B+</MenuItem>
                        <MenuItem value={"B0"}>B-</MenuItem>
                        <MenuItem value={"O1"}>O+</MenuItem>
                        <MenuItem value={"O0"}>O-</MenuItem>
                        <MenuItem value={"AB1"}>AB+</MenuItem>
                        <MenuItem value={"AB0"}>AB-</MenuItem>
                        </Select>

                        </FormControl></Grid>

              <Grid item xs={12}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="res_address"
                  label="Residential Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('rAddress')}
                  value={values.rAddress}
                  error={this.state.rAddresserr!=""}
                  helperText={this.state.rAddresserr != "" ? this.state.rAddresserr : ''}
                  /></Grid>

              <Grid item xs={12}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="m_address"
                  label="Mailing Address"
                  type="address"
                  name="Address"
                  autoComplete="address"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('mAddress')}
                  value={values.mAddress}
                  error={this.state.mAddresserr!=""}
                  helperText={this.state.mAddresserr != "" ? this.state.mAddresserr : ''}
                  /></Grid>

              <Grid item xs={8}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="Next_of_Kin"
                  label="Next of Kin"
                  autoComplete="name"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('nextKin')}
                  value={values.nextKin}
                  error={this.state.nextKinerr!=""}
                  helperText={this.state.nextKinerr != "" ? this.state.nextKinerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="Next_of_Kin_phone"
                  label="Next of Kin Contact"
                  autoComplete="phone"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('nextKinPhone')}
                  value={values.nextKinPhone}
                  error={this.state.nextKinPhoneerr!=""}
                  helperText={this.state.nextKinPhoneerr != "" ? this.state.nextKinPhoneerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editdisabled}
                  id="ARN"
                  label="ARN"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('stu_arn')}
                  value={values.stu_arn}
                  error={this.state.stu_arnerr!=""}
                  helperText={this.state.stu_arnerr != "" ? this.state.stu_arnerr : ''}
                  /></Grid>

              <Grid item xs={4}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="Weight"
                  label="Current Weight"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('cWeight')}
                  value={values.cWeight}
                  error={this.state.cWeighterr!=""}
                  helperText={this.state.cWeighterr != "" ? this.state.cWeighterr : ''}
                  /></Grid>
                  <Grid item xs={12}>

              <Grid item xs={6}>
            <FormControl
                required variant="outlined" style={{width:'100%',marginTop:'5%'}}

            >
                    <InputLabel  htmlFor="outlined-age-simple">
                      Currently held License type
                    </InputLabel>


                    <Select
                      value={values.CLicType}
                      onChange={this.handleChange('CLicType')}
                      error={this.state.CLicTypeerr!=""}
                      helperText={this.state.CLicTypeerr != "" ? this.state.CLicTypeerr : ''}
                      inputProps={{name: 'age',id: 'licType',}}>
                      <MenuItem value="none">
                      <em>None</em>
                      </MenuItem>
                      <MenuItem value={"RPLH"}>RPL-H</MenuItem>
                      <MenuItem value={"RPLA"}>RPL-A</MenuItem>
                      <MenuItem value={"PPLH"}>PPL-H</MenuItem>
                      <MenuItem value={"PPLA"}>PPL-A</MenuItem>
                      <MenuItem value={"CPLH"}>CPL-H</MenuItem>
                      <MenuItem value={"CPLA"}>CPL-A</MenuItem>
                      <MenuItem value={"ATPLH"}>ATPL-H</MenuItem>
                      <MenuItem value={"ATPLA"}>ATPL-A</MenuItem>
                      <MenuItem value={"other"}>Other - See notes</MenuItem>


                      </Select>

                      </FormControl></Grid>
              <Grid item xs={6}>
            <FormControl
                required variant="outlined" style={{width:'100%',marginTop:'5%'}}

            >
                    <InputLabel  htmlFor="outlined-age-simple">
                      License type
                    </InputLabel>


                    <Select
                        disabled={this.state.editdisabled}
                      value={values.LicType}
                      onChange={this.handleChange('LicType')}
                      error={this.state.LicTypeerr!=""}
                      helperText={this.state.LicTypeerr != "" ? this.state.LicTypeerr : ''}
                      inputProps={{name: 'age',id: 'licType',}}>
                      <MenuItem value="none">
                      <em>None</em>
                      </MenuItem>
                      <MenuItem value={"RPLH"}>RPL-H</MenuItem>
                      <MenuItem value={"PPLH"}>PPL-H</MenuItem>
                      <MenuItem value={"CPLH"}>CPL-H</MenuItem>
                      </Select>

                      </FormControl></Grid></Grid>
                      <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox disabled={this.state.editdisabled} checked={values.medical1Checked} onChange={this.checkedFunc("medical1")} value="class1Check" />
                        }
                        label="Class 1 Medical"
                        />
                      </Grid>
                      <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <KeyboardDatePicker
                        disabled={values.medical1Checked==false||values.loggedInUser.accessLevel=="s"}
                        disablePast
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dob_picker"
                        onChange={this.props.log1}
                        value={values.medical1}
                        style={{width:'100%'}}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        /></MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox disabled={this.state.editdisabled} checked={values.medical2Checked} onChange={this.checkedFunc("medical2")} value="class2Check" />
                        }
                        label="Class 2 Medical"
                        />
                      </Grid>
                      <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <KeyboardDatePicker
                        disabled={values.medical2Checked==false||values.loggedInUser.accessLevel=="s"}
                        disablePast
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dob_picker"
                        onChange={this.props.log2}
                        value={values.medical2}
                        style={{width:'100%'}}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        /></MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox disabled={this.state.editdisabled} checked={values.medical3Checked} onChange={this.checkedFunc("medical3")} value="class3Check" />
                        }
                        label="Class 3 Medical"
                        />
                      </Grid>
                      <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>

                      <KeyboardDatePicker
                        disabled={values.medical3Checked==false||values.loggedInUser.accessLevel=="s"}
                        disablePast
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="dob_picker"
                        onChange={this.props.log3}
                        value={values.medical3}
                        style={{width:'100%'}}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        /></MuiPickersUtilsProvider>
                      </Grid>

              <Grid item xs={12}>
              <TextField
                  disabled={this.state.editdisabled}
                  required
                  id="Notes"
                  label="Special Notes"
                  margin="normal"
                  variant="outlined" style={{width:'100%'}}
                  onChange={this.handleChange('accNotes')}
                  value={values.accNotes}
                  error={this.state.accNoteserr!=""}
                  helperText={this.state.accNoteserr != "" ? this.state.accNoteserr : ''}
                  />
                  </Grid>
                  <Grid item xs={12}>

                    </Grid>


                    <Grid item xs={3} style={{ marginTop: 10,display:this.state.edit}}>
                    <RaisedButton fullWidth label="View Credit Card Info" secondary={true} onClick={this.updateCardInfo}/>
                      </Grid>
                    <Grid item xs={3} style={{ marginTop: 10,display:this.state.edit}}>
                    <RaisedButton fullWidth label="Change Profile Picture" secondary={true} onClick={this.profPic}/>
                      </Grid>
                      <Grid item xs={3} style={{ marginTop: 10,display:this.state.edit}}>
                      <RaisedButton fullWidth label="Retake Passport Photo" secondary={true} onClick={this.retakePassport}/>
                        </Grid>
                        <Grid item xs={3} style={{ marginTop: 10,display:this.state.edit}}>
                        <RaisedButton fullWidth label="Change Signature" secondary={true} onClick={this.redoSignature}/>
                          </Grid>
                        <Grid item xs={12} style={{ marginTop: 10,display:this.state.edit}}>
                          <RaisedButton fullWidth label="Reset Password" secondary={true} onClick={this.resetPassword}/>
                            </Grid>


                  <Grid item xs={12} style={{display:this.state.edit}}>
                  <RaisedButton fullWidth style={{marginTop:10, height:60}} label="Complete Entry" primary={true} onClick={this.validate}/>
                    </Grid>


                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>
                      </Grid>

                      </Grid>


              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default EditStudentAccount
