import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';




export class SelectStudentForm extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0,loading:true};
    const { values,handleChange} = this.props;


  }



  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }




onTagsChange = (event, value) => {
    const { values,handleChange} = this.props;
  

      fetch(handleChange('searchKeyword',value))
      .then(response=>{response.json();this.props.getAccountInfo(3204);})




  }
  componentDidMount(){
    const { values} = this.props;

    fetch(`${process.env.REACT_APP_SERVER_URL}/getAccountNames`, {
      headers: {
      'x-access-token': values.token
    }})
    .then(response => response.json())
    .then(response=>{this.props.handleChange('suggestionsStu',response.data);this.setState({loading:false})})
    .catch(err=>console.error(err))

  }
  componentWillUnmount(){


  }



  render() {
    const { values, handleChange, log,handleChangeInput} = this.props;
    return(

      <MuiThemeProvider>
        <React.Fragment >



          <div>

              <Paper style={{margin:'5%',marginTop:'5%', opacity:0.9, paddingTop:'0.5%', paddingLeft:'5%', paddingRight:'5%', paddingBottom:'5%'}}>
              <Grid container spacing={1}>


              <Grid item xs={12}>
              <h3>Change Account Selection</h3>
              <p4>Current : {values.fName} {values.lName}</p4>
              </Grid>
              <Grid item xs={12}
              style={{marginTop:'5%'}}
              >


              <p4>Start typing Student Name or ID# and select the option from the suggestions</p4>


              <Autocomplete
                disableClearable='true'
                disableOpenOnFocus="true"
                options={values.suggestionsStu}
                getOptionLabel={option => option.stu_id+' '+option.fname+' '+option.lname}
                onChange={this.onTagsChange}
                renderTags={() => {}}

                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    autoFocus
                    margin="normal"
                    fullWidth
                  />
                )}
                />



                    <Grid item xs={12} style={{ marginTop: 10}}>
                    <RaisedButton
                        fullWidth
                        label="No Change"
                        primary={true}
                        onClick={e=>{e.preventDefault();this.props.getAccountInfo(3204)}}
                        style={{marginTop:'5%'}}/>
                    <RaisedButton
                        fullWidth
                        label="Back"
                        secondary={true}
                        onClick={this.back}
                        style={{marginTop:'1%'}}/>
                      </Grid>

                      </Grid>

</Grid>
<Dialog style={{padding:"10%"}}
backdrop
open={this.state.loading}>
<DialogContent dividers>
<CircularProgress />
</DialogContent>


</Dialog>
              </Paper>



          </div>
  </React.Fragment>

      </MuiThemeProvider>
    )
  }
}


export default SelectStudentForm
