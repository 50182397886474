import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';




const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class SuccessPreFlight extends Component{
  state ={
    step: '3006',
    userName:'',
    uniquePass:'',
    profilePic:'',
    firstName:'',
    stu_name:'',
    stu_arn:'',
    ins_name:'',
    ins_arn:'',
    rego:'',
    fl_date:'',
    fl_time:'',
    fl_clock:'',
    current_date:'',
    pfk_bool:'',
    nextLesson_bool:'',
    commts:'',
    open: false,
    formId:'',
    formRating:''

  }


  constructor(props) {
    super(props);
    this.state = {value:0};
  }




  continue = e => {
    e.preventDefault();
    this.props.clearState();
    this.props.setStep(1004);
  }

  handleChange = (event, index, value) => this.setState({value});



  render() {
    const { step, userName, uniquePass,open, formId, formRating, stu_name, stu_arn, ins_name, ins_arn, fl_date, fl_time, fl_clock, commts, current_date, pfk_bool, nextLesson_bool, rego } = this.state;
    const values = {userName, uniquePass,open, formId, formRating, stu_name, stu_arn, ins_name, ins_arn, fl_date, fl_time, fl_clock, commts, current_date, pfk_bool, nextLesson_bool, rego};

    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>
          <Grid container spacing={2} style={{padding:50}}>
          <Grid item xs={2}/>
          <Grid item xs={8}>


          <Paper>


          <Grid item xs={12} sm container>
            <Grid item xs container direction="column">
              <Grid item xs style={{textAlign:'center', padding:20}}>

              <h2>
                Task Completed Successfully!
              </h2>

          <Grid item>
          <RaisedButton label="Go Home" secondary={true} onClick={this.continue} fullWidth='true' style={{marginTop:20}}/>
          <RaisedButton label="Form-4B09 Date Selection" secondary={true} onClick={e=>{e.preventDefault();this.props.setStep(7005)}} fullWidth='true' style={{marginTop:20}}/>
          <RaisedButton label="Back to Pre-Flight Authorization Main Menu" secondary={true} onClick={e=>{e.preventDefault();this.props.clearState();this.props.setStep(7000)}} fullWidth='true' style={{marginTop:20}}/>

          </Grid>

              </Grid>

            </Grid>
          </Grid>
            </Paper>
          </Grid>

          <Grid item xs={2}/>



        </Grid>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default SuccessPreFlight
