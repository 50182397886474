import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';





export class SignStu extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue =(id)=> e => {
    e.preventDefault();
    this.formId(id);
    this.props.setRadio(id);
    this.props.nextStep();

  }
  formId = id => {
    this.props.setFormId(id);

  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }




  render() {
    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>
          <Grid container spacing={5} style={{padding:50}}>

          <GridList style={{height: '10%',maxWidth:'95%', marginRight:'2.5%', marginLeft:'2.5%', marginTop:35}}>
            <Grid item xs={3}>
              <Card>
                <CardActionArea value='h1' onClick={this.continue("01_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Effects Of Controls
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 01_105CPL(H)<br/>
                  EFFECTS OF CONTROLS
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("02_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Coordination
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 02_105CPL(H)<br/>
                  COORDINATION
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("03_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Advanced Coordination
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 03_105CPL(H)<br/>
                  ADVANCED COORDINATION
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("04_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Hover
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 04_105CPL(H)<br/>
                  HOVER
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("05_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                Hover Turns And Hover Taxi

                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 05_105CPL(H)<br/>
                  Hover Turns and Hover Taxi
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("06_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Landing and Lift-offs
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 06_105CPL(H)<br/>
                  Landing and Lift-offs
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("07_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Transitions
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 07_105CPL(H)<br/>
                  Transitions
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("08_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Circuits
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 08_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("09_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Max.Performance/Steep Approaches
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 09_105CPL(H)<br/>
                  Max.Performance Take Offs & Steep Approaches
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("10_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Limited Power Operations
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 10_105CPL(H)<br/>
                  Limited Power Operations
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("11_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Basic Autorotation
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 11_105CPL(H)<br/>
                  Basic Autorotation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("12_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Engine Failure(Hover)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 12_105CPL(H)<br/>
                  Engine Failure in the hover and hover taxi
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("13_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Practice Forced Landings
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 13_105CPL(H)<br/>
                  PFLs
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("14_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Tail Rotor Failures
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 14_105CPL(H)<br/>
                  Tail Rotor Failures
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("15_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Jammed Flight Controls
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 15_105CPL(H)<br/>
                  Jammed Flight Controls
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("16_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Advanced Emergencies
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 16_105CPL(H)<br/>
                  Advanced Emergencies
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("17_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Circuits (Pre-Solo)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 17_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("18_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Solo Circuits #1
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 18_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("19_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Circuit Consolidation #1
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 19_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("20_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Solo Circuits #2
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 20_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("21_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Circuit Consolidation #2
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 21_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("22_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Solo Circuits #3
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 22_105CPL(H)<br/>
                  Effects of Controls
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("23_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Circuit Consolidation #3
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 23_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("24_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                Solo Circuits #4
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 24_105CPL(H)<br/>
                  Circuits
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("25_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Sloped Landings
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 25_105CPL(H)<br/>
                  Sloped Landings
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("26_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Confined Areas
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 26_105CPL(H)<br/>
                  Confined Area Operations
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("27_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Steep Turns
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 27_105CPL(H)<br/>
                  Steep Turns
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("28_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Pre-training Area Solo
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 28_105CPL(H)<br/>
                  Training Area Solo
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("29_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Training Area Solo #1
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 29_105CPL(H)<br/>
                  Training Area Solo
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("30_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Conf. Areas Consolidation
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 30_105CPL(H)<br/>
                  Confined Area Operations
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("31_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Training Area Solo #2
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 31_105CPL(H)<br/>
                  Training Area Solo
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("32_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Emergencies Consolidation
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 32_105CPL(H)<br/>
                  Emergencies
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("33_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Training Areas Solo #3
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 33_105CPL(H)<br/>
                  Training Areas Solo
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("34_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: Lane Of Entry 1
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 34_105CPL(H)<br/>
                  Navigation Excercise #1
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("35_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                Navigation: Lane Of Entry 2
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 35_105CPL(H)<br/>
                  Navigation Excercise #2
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("36_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                Navigation: Lane Of Entry 13
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 36_105CPL(H)<br/>
                  Navigation Excercise #3
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("37_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                 Navigation: Lane Of Entry (1st Solo Nav)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 37_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("38_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: 12 D.M.E Step
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 38_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("39_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: 12 D.M.E Step (Solo)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 39_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("40_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: R555
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 40_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("41_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: R555 (Solo)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 41_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("42_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: YSHL - YMIG
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 42_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("43_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: YSHL - YMIG (Solo)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 43_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("44_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: Other (Class C)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 44_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("45_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                Navigation: Other (Solo)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 45_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("46_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: YBTH - YSCN
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 46_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("47_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Navigation: YBTH - YSCN (Solo)
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 47_105CPL(H)<br/>
                  Navigation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("48_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Dual Consolidation
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 48_105CPL(H)<br/>
                  Dual Pre-CPL
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("49_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Solo Consolidation
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 49_105CPL(H)<br/>
                  Solo Pre-CPL
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("50_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Pre-CPL
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                  Lesson 50_105CPL(H)<br/>
                  Pre-CPL Consolidation
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
              <CardActionArea value='h1' onClick={this.continue("51_105CPL_H")}>
                <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  Accelerated competency form
                  </Typography>
                <Typography variant="caption" color="textSecondary" component="p">
                Lesson 51_105CPL(H)<br/>
                Misc
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>

            </GridList>
            <Grid item xs={12} style={{ marginTop: 50}}>
            <RaisedButton fullWidth label="Back" secondary={true} onClick={this.back}/>

            </Grid>


          </Grid>




          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default SignStu
