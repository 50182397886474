import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar/AppBar';
import TextField from 'material-ui/TextField/TextField';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from 'material-ui/Avatar';
import Fab from '@material-ui/core/Fab';
import RaisedButton from 'material-ui/RaisedButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';




const styles = {
  block: {
    maxWidth: 2000,
  },

  thumbOff: {
    backgroundColor: 'red',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: '#1771F1',
  },
  trackSwitched: {
    backgroundColor: '#B7D4FF',
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export class FleetManagement extends Component{

  constructor(props) {
    super(props);
    this.state = {value:0};
  }


  continue =(stepNo)=> e => {
    e.preventDefault();
    this.props.clear();
    this.props.setStep(stepNo);
  }
  formId = id => {
    this.props.setFormId(id);

  }

  back = e => {
    e.preventDefault();
    this.props.startAgain();
  }




  render() {
    return(

      <MuiThemeProvider>
        <React.Fragment>

          <div>
          <Grid container spacing={2} style={{padding:50}}>


            <Grid item xs={4}>
              <Card>
                <CardActionArea value='h1' onClick={this.continue(5001)}>
                <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Add a New Aircraft
                  </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Click here to add a New Aircraft
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardActionArea value='h1' onClick={this.continue(5100)}>
                <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Edit Aircraft details
                  </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Click here to edit Aircraft details including Price per hour
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardActionArea value='h1' onClick={this.continue(5300)}>
                <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Set Individual Prices
                  </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Click here to set individual price points
                  </Typography>
                  </CardContent>
                  </CardActionArea>
                  </Card>
            </Grid>

            <Grid item xs={12}>
            <RaisedButton label="Back" secondary={true} onClick={this.back} fullWidth='true' style={{marginTop:20}}/>
            </Grid>

          </Grid>



          </div>
        </React.Fragment>
      </MuiThemeProvider>
    )
  }
}


export default FleetManagement
